import React from 'react';
import { useFormContext, Controller } from 'react-hook-form';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';

const CustomFormRadio = (props) => {
  const { control } = useFormContext();
  const { name, label, options, defaultValue, sx, row, customStyles = {} } = props;
  return (
    <FormControl sx={sx} component="fieldset">
      <FormLabel component="legend">{label}</FormLabel>
      <Controller
        rules={{ required: true }}
        control={control}
        name={name}
        label={label}
        render={({ field }) => {
          return (
            <RadioGroup
              name="radio-buttons-group"
              sx={{ ...customStyles, m: 0 }}
              row={row}
              defaultValue={defaultValue}
              {...field}
            >
              {options.map((item) => (
                <FormControlLabel
                  key={item.value}
                  sx={item.value === 'self' ? { width: '100%' } : { ...customStyles }}
                  disabled={item.disabled}
                  value={item.value}
                  control={<Radio />}
                  label={item.label}
                />
              ))}
            </RadioGroup>
          );
        }}
      />
    </FormControl>
  );
};

export default CustomFormRadio;
