import React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { useHistory } from 'react-router-dom';
import CircularProgress from '@mui/material/CircularProgress';
const AuthorizationButtons = ({
  active,
  disabled = false,
  loading,
  onClick = () => {},
  setActive,
  title = 'Next',
  backTitle = 'Back',
}) => {
  const history = useHistory();

  const handleBack = () => {
    if (active === 1) {
      localStorage.removeItem('signupWizard');
      window.close();
      return history.goBack();
    }
    return setActive((prevActiveStep) => prevActiveStep - 1);
  };
  return (
    <Box>
      <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', mt: 3 }}>
        <Button id="actionButtonBack" onClick={handleBack} color="primary" variant="outlined" sx={{ mr: 1 }}>
          {backTitle}
        </Button>

        <Button
          id="actionButtonTitle"
          disabled={loading || disabled}
          color="primary"
          type="submit"
          variant="contained"
          onClick={onClick}
        >
          {!loading && title}
          {loading && <CircularProgress style={{ width: '25px', height: '25px' }} />}
        </Button>
      </Box>
    </Box>
  );
};

export default AuthorizationButtons;
