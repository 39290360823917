import React, { memo } from 'react';
import { Box, Collapse } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { StyledShowMoreLessButton, StyledShowMoreLessText } from 'components/Order/OrderStatusChange/styles';
import useDeliveryDispatchDetails from 'components/Order/OrderDeliverDetails/hooks/useDeliveryDispatchDetails';

const DispatchDeliveryDetails = ({ orderId }) => {
  const { t } = useTranslation();
  const {
    btnName,
    loadingDeliveryDetails,
    deliveryDetailsData,
    handleClick,
    showDetails,
    handleCloseDetails,
  } = useDeliveryDispatchDetails(orderId);
  return (
    <>
      <Collapse in={showDetails}>
        <Box>
          <StyledShowMoreLessText>
            {t('Delivered by')} {deliveryDetailsData?.providerName}
          </StyledShowMoreLessText>
          <StyledShowMoreLessText>
            {t('Phone Number:')} {deliveryDetailsData?.phoneNumber}
          </StyledShowMoreLessText>
        </Box>
      </Collapse>
      <StyledShowMoreLessButton
        sx={{ mt: showDetails ? 2 : 0 }}
        variant="outlined"
        disabled={loadingDeliveryDetails}
        loading={loadingDeliveryDetails}
        onClick={showDetails ? handleCloseDetails : handleClick}
      >
        {btnName}
      </StyledShowMoreLessButton>
    </>
  );
};

export default memo(DispatchDeliveryDetails);
