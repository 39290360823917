import React, { useState, useCallback, useEffect, useMemo } from 'react';
import qs from 'qs';
import { OrdersList, OrderView, ReportView } from 'components/Order';
import { getOrders, setShowReport } from 'redux/actions/orders';
import { useLocation, useHistory, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getPeriods, ORDERS_FILTER_STATUS, INITIAL_PERIOD, INITIAL_STATUS, ORDER_GROUP } from 'constants/config';
import { Box } from '@mui/material';
import Private from 'components/Private';
import ErrorBoundaryWrapper from 'components/ErrorBoundaryWrapper';
import { RESET_ORDERS_PAGINATION, SET_ORDERS_PARAMS } from 'redux/actions/types';
import instance from '../services/api';
import { useTranslation } from 'react-i18next';

const Orders = () => {
  const history = useHistory();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { search } = useLocation();
  const { data } = useSelector(({ user }) => user);
  const { currentBusiness } = useSelector(({ business }) => business);
  const {
    orders: { showReport },
  } = useSelector((orders) => orders);

  const [order, setOrder] = useState(null);
  const { orderList, paginationConfig, params, newOrders, modifiedOrders, disabledActions } = useSelector(
    ({ orders }) => orders
  );
  const { page, skip, limit, count } = paginationConfig;
  const { start, end } = params;
  let { current } = useParams();
  const [loading, setLoading] = useState(true);
  const periods = useMemo(() => getPeriods(), []);

  const query = useMemo(() => {
    const { status, period, order } = qs.parse(search, { ignoreQueryPrefix: true });
    return {
      status: ORDERS_FILTER_STATUS[status] ? status : INITIAL_STATUS,
      period: periods[period] ? period : INITIAL_PERIOD,
      order,
    };
  }, [periods, search]);

  useEffect(() => {
    const { start, end } = periods[query.period];
    dispatch({
      type: SET_ORDERS_PARAMS,
      payload: {
        status: query.status,
        end,
        start,
      },
    });
  }, [query.status, end, start, search]);

  const handleToggleReport = useCallback(() => {
    dispatch(setShowReport(!showReport));
  }, [showReport]);

  useEffect(() => {
    if (order) {
      const foundOrder = orderList.find((item) => item._id === order._id);
      if (!foundOrder) {
        setOrder(null);
      }
    }
    if (page > 1 && !orderList.length) {
      dispatch({
        type: RESET_ORDERS_PAGINATION,
      });
    }
  }, [orderList]);

  const handleGetOrders = useCallback(() => {
    setLoading(true);
    if (currentBusiness && currentBusiness?.id) {
      instance.defaults.headers.business = currentBusiness?.id;
      dispatch(getOrders()).then(() => {
        setLoading(false);
      });
    }
  }, [periods, query.period, query.status, skip, page, newOrders.length, modifiedOrders.length, currentBusiness]);

  const findOrderById = (orders, id) => {
    for (let order of orders) {
      if (order._id === id) {
        return order;
      }
      if (order.suborders) {
        const foundSubOrder = order.suborders.find((subOrder) => subOrder._id === id);
        if (foundSubOrder) {
          return { ...foundSubOrder, business: order.business };
        }
      }
    }
    return null;
  };

  const handleSelect = useCallback(
    (row) => {
      const { _id } = row;

      history.replace({
        pathname: `/orders${current === _id ? '' : `/${_id}`}`,
        search,
      });
    },
    [current, history, search]
  );

  useEffect(() => {
    handleGetOrders();
  }, [handleGetOrders]);

  useEffect(() => {
    if (current) {
      const orderData = findOrderById(orderList, current);
      if (orderData) {
        setOrder(orderData);
        dispatch(setShowReport(false));
      }
    } else {
      setOrder(null);
    }
  }, [current, history, orderList]);

  useEffect(() => {
    dispatch({ type: 'ORDERS_EMPTY' });
    const appContent = document.querySelector('.app-content');
    // appContent.classList.add('p-0');
    return () => {
      dispatch({
        type: RESET_ORDERS_PAGINATION,
      });
      // appContent.classList.remove('p-0');
    };
  }, [dispatch]);

  // SubOrdersList
  return (
    <ErrorBoundaryWrapper>
      <Box display="flex">
        <div className="order-content">
          <div className="orderListBlock">
            <OrdersList data={orderList} onSelect={handleSelect} current={current} loading={loading} />
          </div>
        </div>
        <Private permission="ORDER_EDIT">
          <OrderView
            disabledActions={disabledActions}
            orderList={orderList}
            data={order}
            business={data}
            orderId={current}
            loading={loading}
            handleSelect={handleSelect}
          />
        </Private>
        {!!showReport && <ReportView onClose={handleToggleReport} />}
      </Box>
    </ErrorBoundaryWrapper>
  );
};

export default Orders;
