export const showPriceRange = (product) => {
  let result = `$${parseFloat(product?.price).toFixed(2)}`;
  if (product?.isOnePrice) {
    result = `$${parseFloat(product?.price).toFixed(2)}`;
  } else if (product?.minPrice || product?.minPrice === 0) {
    result = `$${parseFloat(product?.minPrice).toFixed(2)} - $${parseFloat(product?.maxPrice).toFixed(2)}`;
  }
  return result;
};

export const onWheelPreventChangeNumberField = (e) => {
  e.target.blur();
  e.stopPropagation();
};

export const addDefaultPriceOverride = (currentCategory, menu, CONTEXT_TYPES, formState) => {
  const providerPriceOverride = [];
  let targetConnectedProviders;
  if (currentCategory) {
    targetConnectedProviders = currentCategory.connectedProviders;
  } else {
    targetConnectedProviders = menu.connectedProviders;
  }
  Object.keys(targetConnectedProviders).map((modItem) => {
    if (targetConnectedProviders[modItem]) {
      providerPriceOverride.push({
        context_type: CONTEXT_TYPES.provider,
        context_value: modItem,
        name: modItem,
        price: 0,
      });
    }
  });
  formState.list.forEach((item) => {
    item.priceOverride.forEach((it) => {
      if (!it.priceOverride?.length) {
        it.priceOverride = [...providerPriceOverride];
      }
    });
  });
  return formState;
};

export const getNextIDsAfterConfirmPopup = (data) => {
  let newProductId = '';
  let newCatId = '';

  if (!data) {
    return null;
  }

  if (data?.search) {
    newProductId = data?.search?.split('pid=')?.[1]?.split('&')?.[0];
    newCatId = data?.search.split('cid=')?.[1]?.split('&')?.[0];
  }

  return {
    newProductId,
    newCatId,
  };
};

export const menuAvailableStyles = (available) => {
  return {
    opacity: available ? 1 : 0.6,
    pointerEvents: available ? 'auto' : 'none',
  };
};
