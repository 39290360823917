import React, { useEffect, useMemo } from 'react';
import CheckActive from 'assets/icons/checkbox_active.svg';
import CheckInactive from 'assets/icons/checkbox_inactive.svg';
import EditIcon from '@mui/icons-material/Edit';
import { Typography } from '@mui/material';
import { getCoupons } from 'redux/actions/coupons';
import DeleteIcon from 'assets/icons/delete.svg';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import i18n from 'i18n';
import { MarketingTypes } from 'components/Coupons/types';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';

export const useCouponsData = (isPopular = false) => {
  const { activeCoupons, expiredCoupons, loading } = useSelector(({ coupons }) => coupons);
  const dispatch = useDispatch();

  useEffect(() => {
    if (isPopular) {
      dispatch(getCoupons({ active: true }));
    } else {
      dispatch(getCoupons({ active: true }));
      dispatch(getCoupons({ active: false }));
    }
  }, [isPopular]);

  return {
    activeCoupons,
    expiredCoupons,
    isLoading: loading,
  };
};

export const useCouponsActiveTableColumns = (handleDelete, handleEdit) => {
  const { t } = useTranslation();
  const genericColumns = [
    {
      field: 'couponName',
      headerName: t('website_settings.coupone_name'),
      flex: 1,
      sortable: false,
      filterable: false,
      editable: false,
    },
    {
      field: 'couponTitle',
      headerName: t('website_settings.coupone_title'),
      flex: 1,
      sortable: false,
      filterable: false,
      editable: false,
    },
    {
      field: 'couponCode',
      headerName: t('website_settings.coupon_code'),
      flex: 1,
      sortable: false,
      filterable: false,
      editable: false,
    },
    {
      field: 'amount',
      headerName: t('website_settings.amount_percent'),
      flex: 1,
      sortable: false,
      editable: false,
      filterable: false,
      renderCell: ({ row, value }) => {
        return row.percent ? (
          <Typography sx={{ textAlign: 'right', width: '100%' }}>{`${row.percent}%`}</Typography>
        ) : (
          <Typography sx={{ textAlign: 'right', width: '100%' }}>{`$${row.amount}`}</Typography>
        );
      },
    },
    {
      field: 'startDate',
      headerName: t('website_settings.start_date_'),
      editable: false,
      flex: 1,
      sortable: false,
      filterable: false,
      renderCell: ({ value }) => {
        return moment(value).format('MM/DD/YYYY');
      },
    },
    {
      field: 'endDate',
      headerName: t('website_settings.end_date_'),
      editable: false,
      flex: 1,
      sortable: false,
      filterable: false,
      renderCell: ({ value }) => {
        return moment(value).format('MM/DD/YYYY');
      },
    },
    {
      field: 'useForMarketing',
      headerName: t('menu.available_marketing'),
      editable: false,
      flex: 1,
      sortable: false,
      filterable: false,
      renderCell: ({ row }) => {
        if (row?.type && row?.type === MarketingTypes.SYSTEM) {
          return <img src={CheckInactive} alt="ONLINE_ICON" />;
        } else if (row?.type && row?.type === MarketingTypes.MARKETING) {
          return <img src={CheckActive} alt="ONLINE_ICON" />;
        }
      },
    },
    {
      field: 'freeDelivery',
      headerName: t('website_settings.free_delivery'),
      editable: false,
      flex: 1,
      sortable: false,
      filterable: false,
      renderCell: ({ row }) => {
        return <img src={row.freeDelivery ? CheckActive : CheckInactive} alt="ONLINE_ICON" />;
      },
    },
  ];
  const activeCoupons = useMemo(
    () => [
      ...genericColumns,
      {
        field: 'Tools',
        headerName: t('settings.tools'),
        type: 'actions',
        flex: 1,
        renderCell: (params) => {
          return (
            <Box sx={{ display: 'flex' }}>
              <IconButton aria-label="delete" onClick={() => handleEdit(params?.row)}>
                <EditIcon />
              </IconButton>
              <IconButton aria-label="delete" onClick={() => handleDelete(params?.row)}>
                <img src={DeleteIcon} alt="delete icon" />
              </IconButton>
            </Box>
          );
        },
      },
    ],
    [i18n.language]
  );

  return activeCoupons;
};

export const useCouponsInactiveTableColumns = (handleDelete, handleEdit) => {
  const { t } = useTranslation();
  const genericColumns = [
    {
      field: 'couponName',
      headerName: t('website_settings.coupone_name'),
      flex: 1,
      sortable: false,
      filterable: false,
      editable: false,
    },
    {
      field: 'couponTitle',
      headerName: t('website_settings.coupone_title'),
      flex: 1,
      sortable: false,
      filterable: false,
      editable: false,
    },
    {
      field: 'couponCode',
      headerName: t('website_settings.coupon_code'),
      flex: 1,
      sortable: false,
      filterable: false,
      editable: false,
    },
    {
      field: 'amount',
      headerName: t('website_settings.amount_percent'),
      flex: 1,
      sortable: false,
      editable: false,
      filterable: false,
      renderCell: ({ row, value }) => {
        return row.percent ? (
          <Typography sx={{ textAlign: 'right', width: '100%' }}>{`${row.percent}%`}</Typography>
        ) : (
          <Typography sx={{ textAlign: 'right', width: '100%' }}>{`$${row.amount}`}</Typography>
        );
      },
    },
    {
      field: 'startDate',
      headerName: t('website_settings.start_date_'),
      editable: false,
      flex: 1,
      sortable: false,
      filterable: false,
      renderCell: ({ value }) => {
        return moment(value).format('MM/DD/YYYY');
      },
    },
    {
      field: 'endDate',
      headerName: t('website_settings.end_date_'),
      editable: false,
      flex: 1,
      sortable: false,
      filterable: false,
      renderCell: ({ value }) => {
        return moment(value).format('MM/DD/YYYY');
      },
    },
    {
      field: 'useForMarketing',
      headerName: t('menu.available_marketing'),
      editable: false,
      flex: 1,
      sortable: false,
      filterable: false,
      renderCell: ({ row }) => {
        if (row?.type && row?.type === MarketingTypes.SYSTEM) {
          return <img src={CheckInactive} alt="ONLINE_ICON" />;
        } else if (row?.type && row?.type === MarketingTypes.MARKETING) {
          return <img src={CheckActive} alt="ONLINE_ICON" />;
        }
      },
    },
    {
      field: 'freeDelivery',
      headerName: t('website_settings.free_delivery'),
      editable: false,
      flex: 1,
      sortable: false,
      filterable: false,
      renderCell: ({ row }) => {
        return <img src={row.freeDelivery ? CheckActive : CheckInactive} alt="ONLINE_ICON" />;
      },
    },
  ];
  const expiredCoupons = useMemo(
    () => [
      ...genericColumns,
      {
        field: 'Tools',
        headerName: t('settings.tools'),
        type: 'actions',
        flex: 1,
        renderCell: (params) => {
          return (
            <Box sx={{ display: 'flex' }}>
              <IconButton aria-label="delete" onClick={() => handleEdit(params?.row)}>
                <EditIcon />
              </IconButton>
              <IconButton aria-label="delete" onClick={() => handleDelete(params?.row)}>
                <img src={DeleteIcon} alt="delete icon" />
              </IconButton>
            </Box>
          );
        },
      },
    ],
    [i18n.language]
  );

  return expiredCoupons;
};
