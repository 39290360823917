import React, { memo } from 'react';
import { List } from 'react-virtualized';
import 'react-virtualized/styles.css';
import { Box } from '@mui/material';
import { PauseResumeAllBusinessListItem, PauseResumeDots } from './styles';

// Sample data

const VirtualizedList = ({ list }) => {
  const rowRenderer = ({ index, key, style }) => {
    return (
      <Box key={key} style={style} sx={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
        <PauseResumeDots />
        <PauseResumeAllBusinessListItem>{list[index].companyName}</PauseResumeAllBusinessListItem>
      </Box>
    );
  };
  return <List width={350} height={300} rowCount={list.length} rowHeight={44} data={list} rowRenderer={rowRenderer} />;
};
export default memo(VirtualizedList);
