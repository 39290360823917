import React from 'react';
import { Typography } from '@mui/material';

const Currency = ({ sx = {}, target = null, value = 0, warning = false }) => {
  return (
    <Typography sx={sx}>
      <strong className={warning ? 'warning-text' : ''}>
        {target ? '(' : ''}
        {Number(value).toLocaleString('en-US', { style: 'currency', currency: 'USD' })}
        {target ? ')' : ''}
      </strong>
    </Typography>
  );
};

export default Currency;
