import React, { useMemo } from 'react';
import CheckActive from 'assets/icons/checkbox_active.svg';
import CheckInactive from 'assets/icons/checkbox_inactive.svg';

import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';

import { DataGrid } from '@mui/x-data-grid';
import { Typography } from '@mui/material';
import EmptyMenu from 'components/MenuNew/EmptyMenu';
import { useTranslation } from 'react-i18next';
import i18n from 'i18n';
import IconButton from '@mui/material/IconButton';

const eventActionsEnums = {
  PrintTicket: 'settings.print_ticket',
  PrintReceipt: 'settings.print_receipt',
  SMS: 'settings.sms_',
  Fax: 'settings.fax',
  Email: 'batch_report.email',
  RunZReportAndEmail: 'Run Z report and email',
  RunXReportAndEmail: 'Run X report and email',
};

const EventTable = ({ eventList, handleDelete, handleEventItemMode, showEvent, printers }) => {
  const { t } = useTranslation();

  const columns = useMemo(() => {
    return [
      {
        field: 'type',
        headerName: t('settings.events_type'),
        flex: 1,
        sortable: false,
        filterable: false,
        editable: false,
        renderCell: ({ value }) => {
          return <Typography sx={{ fontWeight: 'bold' }}>{value?.name || ''}</Typography>;
        },
      },
      {
        field: 'action',
        headerName: t('schedule.actions'),
        flex: 1,
        sortable: false,
        editable: false,
        filterable: false,
        renderCell: ({ value }) => {
          return <Typography>{t(eventActionsEnums[value?.name]) || ''}</Typography>;
        },
      },
      {
        field: 'sendTo',
        headerName: t('settings.send_to'),
        editable: false,
        flex: 1,
        sortable: false,
        filterable: false,
        renderCell: (params) => {
          const { value, row } = params;
          const {
            action: { name },
          } = row;
          if (name === 'PrintTicket' || name === 'PrintReceipt') {
            return printers.list.find((item) => item._id === value)?.name || '';
          }
          return value;
        },
      },
      {
        field: 'copy',
        headerName: t('settings.copy'),
        sortable: false,
        filterable: false,
        editable: false,
        flex: 1,
      },
      {
        field: 'active',
        headerName: t('header.active'),
        sortable: false,
        filterable: false,
        flex: 1,
        editable: false,
        renderCell: ({ value }) => {
          return <img src={value ? CheckActive : CheckInactive} alt="ONLINE_ICON" />;
        },
      },
      {
        field: 'Tools',
        headerName: t('settings.tools'),
        type: 'actions',
        hide: false,
        flex: 1,
        renderCell: (params) => {
          return (
            <Box sx={{ display: 'flex' }}>
              <IconButton disabled={showEvent} aria-label="delete" onClick={() => handleEventItemMode(params.id)}>
                <EditIcon />
              </IconButton>
              <IconButton disabled={showEvent} aria-label="delete" onClick={(e) => handleDelete(e, params.id)}>
                <DeleteIcon />
              </IconButton>
            </Box>
          );
        },
      },
    ];
  }, [printers.list, eventList, i18n.language]);

  return (
    <>
      <Paper>
        <Box
          sx={{
            my: 2,
            '& .MuiDataGrid-columnHeaders': {
              backgroundColor: (theme) => theme.palette.primary.main,
              color: 'white',
            },
            '& .MuiDataGrid-row	': {
              '&:nth-of-type(odd)': {
                backgroundColor: (theme) => theme.palette.action.hover,
              },
            },
            '& .MuiDataGrid-sortIcon': {
              color: 'white',
            },
            '& .MuiDataGrid-filterIcon': {
              color: 'white',
            },
            '& .MuiDataGrid-menuIcon	': {
              '& .MuiButtonBase-root': { color: 'white' },
            },
          }}
        >
          <DataGrid
            getRowId={(row) => row._id}
            autoHeight={true}
            hideFooterPagination={true}
            hideFooterSelectedRowCount={true}
            isEditable={false}
            rows={eventList}
            columns={columns}
            components={{
              NoRowsOverlay: () => (
                <Box sx={{ mt: '100px' }}>
                  <EmptyMenu text={t('settings.there_are_no _vents_yet')} />
                </Box>
              ),
            }}
            // pageSize={5}
            // rowsPerPageOptions={[5]}
          />
        </Box>
      </Paper>
    </>
  );
};

export default EventTable;
