import React from 'react';
import LinkIcon from '@mui/icons-material/Link';
import { LinkIconMain } from 'pages/Settings/BusinessSettings/styles';

const LinkIconButton = () => {
  return (
    <LinkIconMain>
      <LinkIcon sx={{ color: '#929398' }} />
    </LinkIconMain>
  );
};

export default LinkIconButton;
