import instance from '../../../services/api';
import { toast } from 'react-toastify';
import { cloneDeep } from 'lodash';
import {
  SET_MENU_CATEGORIES_V2_SOCKET_CREATE,
  SET_MENU_CATEGORIES_V2_SOCKET_UPDATE,
  SET_MENU_CATEGORIES_V2_SOCKET_DELETE,
  SET_CATEGORY_PRODUCTS_V2,
  SET_LOADING,
  SET_LOADING_PRODUCT,
} from '../types';
import { getCategoryProductsV2 } from './products';

export const importCategories = (provider, target) => (dispatch) => {
  return instance.get(`/categories/import/${provider}/${target}`).then(({ data: { data } }) => {
    // dispatch(getMenus());
    return data;
  });
};
export const reorderCategories = (data) => (dispatch, getState) => {
  const {
    menu: { menu },
  } = getState();

  let mySortedArray = data.map((x) => menu.categories.find((y) => y.id === x));
  menu.categories?.forEach((cat) => {
    cat.bulkInfo.isSaved = false;
  });

  dispatch({
    type: 'MENU_ORDER_CATEGORIES',
    payload: {
      ...menu,
      categories: mySortedArray,
    },
  });

  return instance.put(
    `categories/sort`,
    data.map((_id, sortIndex) => ({ _id, sortIndex }))
  );
};

// Sockets Categories
export const createCategory = (data) => (dispatch, getState) => {
  dispatch({
    type: SET_MENU_CATEGORIES_V2_SOCKET_CREATE,
    payload: data,
  });
};

export const updateCategory = (data) => (dispatch, getState) => {
  const {
    menu: { menu },
  } = getState();
  const menusCopy = cloneDeep(menu);
  const categoryDataIndex =
    menu?.categories &&
    menu.categories.findIndex((menuData) => {
      return menuData._id === data._id;
    });
  if (categoryDataIndex != -1) {
    const newData = menusCopy.categories[categoryDataIndex];

    menusCopy.categories[categoryDataIndex] = {
      ...newData,
      ...data,
    };
  }

  dispatch({
    type: SET_MENU_CATEGORIES_V2_SOCKET_UPDATE,
    payload: menusCopy.categories,
  });
};

export const deleteCategory = (data) => (dispatch, getState) => {
  // const {
  //   menu: { menu },
  // } = getState();
  // const menusCopy = { ...menus };
  // const { menu, category } = data;
  // const menuTypeById = Object.values(menusCopy).find((menuData) => {
  //   return menuData.id === menu._id;
  // })?.type;
  // delete menusCopy[menuTypeById]?.categories[category];
  dispatch({
    type: SET_MENU_CATEGORIES_V2_SOCKET_DELETE,
    payload: data.category,
  });
};

export const setCategoryFromSocket = (data) => (dispatch, getState) => {
  const {
    menu: {
      menu: { categories },
    },
  } = getState();

  const copyCategory = [...categories];
  let foundIndex = copyCategory.findIndex((item) => item?._id === data?._id);
  if (foundIndex != -1) {
    copyCategory[foundIndex] = data;
  }
  dispatch({
    type: SET_CATEGORY_PRODUCTS_V2,
    payload: copyCategory,
  });
  if (data?._id) {
    return dispatch(getCategoryProductsV2(data?._id));
  }
};

export const sortCategory = (data) => (dispatch, getState) => {
  const { menu } = getState().menu;
  // SET_MENU_CATEGORIES_V2
  // const menusCopy = { ...menus };
  // const categoriesByKey = {};
  // data.forEach((item) => {
  //   categoriesByKey[item._id] = item;
  // });
  // menusCopy.main.categories = categoriesByKey;
  // dispatch({
  //   type: SET_MENU_CATEGORIES_V2_SOCKET_SORT,
  //   payload: menusCopy,
  // });
};
export const importCategory = (data) => (dispatch) => {};

export const addMenuCategory = (body) => (dispatch, getState) => {
  const {
    menu: { menu },
  } = getState();
  return instance
    .post('/categories', {
      ...body,
      sort: menu.categories?.length || 0,
      menuId: menu._id,
    })
    .then(({ data: { data } }) => {
      // return dispatch(getMenus()).then(() => data._id);
      dispatch({ type: SET_LOADING_PRODUCT, payload: false });
      return data;
    });
};

export const updateMenuCategory = (id, body) => (dispatch) => {
  return instance.put(`/categories/${id}`, body).catch((err) => {
    toast.error('Fail to update category!');
    return Promise.reject(err);
  });
};

export const deleteMenuCategory = (id) => (dispatch) => {
  return instance.delete(`/categories/${id}`).catch((err) => {
    return Promise.reject(err);
  });
};

export const patchCategorySoldOutById = (catId, body) => (dispatch) => {
  return instance
    .patch(`categories/sold-out/${catId}`, {
      status: body.status,
    })
    .then((res) => {
      dispatch(updateCategory(res.data.data._id));
      return res.data.data;
    })
    .catch((err) => {
      return Promise.reject(err);
    });
};
