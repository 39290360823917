export const PAGE_TITLES = {
  home: 'dashboards_',
  orders: 'orders_',
  history: 'orders_history_',
  reports: 'reports_',
  providers: 'providers_',
  website: 'website_',
  menu: 'menu_',
  settings: 'settings_',
  main: 'Main',
  coupons: 'coupons_',
  tutorials: 'tutorials_',
  help: 'help_',
  schedule: 'schedule_',
  directory: 'directory_',
  marketing: 'marketing_',
  rewards: 'rewards_',
};

export const SETTINGS_TITLES = {
  account: 'Account',
  business: 'Business',
  usersAndRoles: 'User and Roles',
  billing: 'Billing Info',
  printer: 'Printers',
  events: 'Event Notifications',
  brands: 'Brand Locations',
  admin: 'Admin',
  language: 'Language',
  advanced: 'Advanced',
  directory: 'Directory',
  marketing: 'Marketing',
};

export const TABLE_ORDERING = {
  tables: 'Tables',
  sections: 'Sections',
  settings: 'Settings',
  employees: 'Employees',
};
