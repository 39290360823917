import React from 'react';
import { Box } from '@mui/material';
import Preloader from 'components/Preloader';
import { useTranslation } from 'react-i18next';
// import QrSettings from 'pages/TableOrdering/Settings/QrSettings';
import DineInForm from 'pages/TableOrdering/Settings/DineInForm';
import { Table_Ordering_Config } from 'pages/TableOrdering/config';
import TakeOutForm from 'pages/TableOrdering/Settings/TakeOutForm';
import DeliveryForm from 'pages/TableOrdering/Settings/DeliveryForm';
import AccordionWrapper from 'pages/TableOrdering/Settings/AccordionWrapper';
import { useTableOrderingSettings } from 'pages/TableOrdering/hooks/useTableOrderingSettings';

const Settings = () => {
  const { t } = useTranslation();
  const {
    settingsDineIn,
    handleDineInSubmit,
    settingsTakeOut,
    handleTakeOutSubmit,
    handleDeliverySubmit,
    settingsDelivery,
    loading,
    loadingSettings,
    selectedOptions,
  } = useTableOrderingSettings();

  if (loading) {
    return <Preloader />;
  }
  return (
    <Box>
      <AccordionWrapper
        children={
          <DineInForm
            formMethods={settingsDineIn}
            handleSubmit={handleDineInSubmit}
            loadingSettings={loadingSettings}
            selectedOptions={selectedOptions}
          />
        }
        title={t(Table_Ordering_Config.DINE_IN_SETTINGS.title)}
        expandedPanel={Table_Ordering_Config.DINE_IN_SETTINGS.expandedPanel}
      />
      <AccordionWrapper
        children={
          <TakeOutForm
            formMethods={settingsTakeOut}
            handleSubmit={handleTakeOutSubmit}
            loadingSettings={loadingSettings}
            selectedOptions={selectedOptions}
          />
        }
        title={t(Table_Ordering_Config.TAKEOUT_SETTINGS.title)}
        expandedPanel={Table_Ordering_Config.TAKEOUT_SETTINGS.expandedPanel}
      />
      <AccordionWrapper
        children={
          <DeliveryForm
            formMethods={settingsDelivery}
            handleSubmit={handleDeliverySubmit}
            loadingSettings={loadingSettings}
            selectedOptions={selectedOptions}
          />
        }
        title={t(Table_Ordering_Config.DELIVERY_SETTINGS.title)}
        expandedPanel={Table_Ordering_Config.DELIVERY_SETTINGS.expandedPanel}
      />
      {/*<Box sx={{ boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)' }}>*/}
      {/*  <QrSettings />*/}
      {/*</Box>*/}
    </Box>
  );
};

export default Settings;
