import React from 'react';
import { FormProvider } from 'react-hook-form';
import { Box, Grid, Alert } from '@mui/material';
import CustomTextInput from 'components/Forms/CustomTextInput';
import { useTranslation } from 'react-i18next';

import { useLogin } from 'hooks/useLogin';
import {
  FooterAppContent,
  FooterLogin,
  ForgotPassword,
  ForgotPasswordGrid,
  LoginButton,
  LoginFormGridItem,
  LoginFormLogo,
  MainLoginBlock,
  SignUpGrid,
  SignUpLink,
  SignUpText,
} from './Signup/styles';
import logo from 'assets/icons/logo.png';
import LoginPhone from 'assets/images/Phone.png';
import LabelRequired from 'components/FormFields/LabelRequired';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import LoginContent from 'components/LoginContent';
import GooglePayButton from 'components/UI/GooglePayButton';
import AppStoreButton from 'components/UI/AppStoreButton';
import { MainGrid, StoreButtonsWrapper, StoreText, StoreTextSecond } from 'components/UI/styles';

const Login = () => {
  const { t } = useTranslation();
  const {
    handleValidSubmit,
    errorMessage,
    userLoginFormMethods,
    submitted,
    visiblePassword,
    showPassword,
    loading,
  } = useLogin();

  return (
    <MainGrid container spacing={2}>
      <Grid item xs={12} sm={12} md={7} lg={7} xl={7} sx={{ display: { xs: 'none', sm: 'none', md: 'block' } }}>
        <Box className={'loginBanner'}>
          <StoreButtonsWrapper>
            <Box sx={{ maxWidth: '407px', margin: '0 auto' }}>
              <StoreText>{t('sign_in.main_text')}</StoreText>
              <StoreTextSecond>{t('sign_in.second_text')}</StoreTextSecond>
            </Box>
            <AppStoreButton />
            <GooglePayButton />
            <Box sx={{ mt: 7 }}>
              <img src={LoginPhone} alt={'phone'} />
            </Box>
          </StoreButtonsWrapper>
        </Box>
      </Grid>
      <LoginFormGridItem item xs={12} sm={12} md={5} lg={5} xl={5}>
        <LoginFormLogo>
          <img width="100%" src={logo} alt="order.co" />
        </LoginFormLogo>
        {/*<LoginFormErrorMessage>{errorMessage && <Alert severity="error">{errorMessage}</Alert>}</LoginFormErrorMessage>*/}
        <MainLoginBlock>
          <FormProvider {...userLoginFormMethods}>
            <form onSubmit={userLoginFormMethods.handleSubmit(handleValidSubmit)}>
              <Grid container>
                <Grid item sx={{ mb: 1 }} xs={12}>
                  <CustomTextInput
                    sx={{ width: '100%', minHeight: '64px' }}
                    name="email"
                    label={<LabelRequired>{t('sign_in.email_address')}</LabelRequired>}
                  />
                </Grid>
                <Grid item xs={12}>
                  <CustomTextInput
                    sx={{ width: '100%', minHeight: '84px' }}
                    InputProps={{
                      type: showPassword ? 'text' : 'password',
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton aria-label="toggle password visibility" onClick={visiblePassword} edge="end">
                            {showPassword ? (
                              <VisibilityOff sx={{ fontSize: '1.2rem' }} />
                            ) : (
                              <Visibility sx={{ fontSize: '1.2rem' }} />
                            )}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                    label={<LabelRequired>{t('sign_in.password')}</LabelRequired>}
                    name="password"
                  />
                </Grid>
                <ForgotPasswordGrid item xs={12}>
                  <ForgotPassword to="/forgot-password">{t('_login_.forgot_password')}</ForgotPassword>
                </ForgotPasswordGrid>
                <Grid item xs={12}>
                  <div className="mt-4">
                    <LoginButton
                      id="login"
                      variant="contained"
                      color="primary"
                      type="submit"
                      disabled={submitted || loading}
                      loading={loading}
                      block
                    >
                      {t('_login_.login')}
                    </LoginButton>
                  </div>
                </Grid>
                <SignUpGrid item xs={12}>
                  <SignUpText>{t('_login_.signup_text')}</SignUpText>
                  <SignUpLink to="/signup/accountInfo">{t('_login_.signup')}</SignUpLink>
                </SignUpGrid>
              </Grid>
              <FooterAppContent item xs={12}>
                <StoreButtonsWrapper>
                  <Box sx={{ maxWidth: '407px', margin: '0 auto' }}>
                    <StoreText>{t('sign_in.text_app')}</StoreText>
                  </Box>
                  <AppStoreButton />
                  <GooglePayButton />
                </StoreButtonsWrapper>
              </FooterAppContent>
              <FooterLogin item xs={12}>
                <LoginContent />
              </FooterLogin>
            </form>
          </FormProvider>
        </MainLoginBlock>
      </LoginFormGridItem>
    </MainGrid>
  );
};

export default Login;
