import React, { useContext } from 'react';
import { LoadingButton } from '@mui/lab';
import { FormControl, FormHelperText, Grid, TextField, Typography } from '@mui/material';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { useTranslation } from 'react-i18next';
import { SubscriptionContext } from 'context/subscriptionPlanConfigContext';
import { useApplyCoupon } from 'components/ApplyCoupon/useApplyCoupon';
import { formatPrice } from '../../helpers/formPrice';

const ApplyCoupon = ({
  couponValue,
  setCouponValue,
  setIsApplay,
  handleAcceptSetupFree,
  acceptAgreeToPaySetupFeeCharges,
  acceptAgreeToPaySetupFee,
  showSubscription = false,
  checkNewPlans,
  selectedPlan = '',
}) => {
  const { t } = useTranslation();
  const { setUpFee, setUpFeeEnabled } = useContext(SubscriptionContext);
  const {
    handleChange,
    applyCoupon,
    loadingCoupon,
    setupFree,
    error,
    successMessage,
    discountedSetupFeeAmount,
  } = useApplyCoupon(setCouponValue, setIsApplay, showSubscription, checkNewPlans, selectedPlan, couponValue);
  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography sx={{ mt: 2, fontSize: '16px', fontWeight: 'bold' }}>{t('sign_up.employee_id')} </Typography>
        </Grid>
        <Grid item xs={5}>
          <TextField
            sx={{ width: '100%' }}
            value={couponValue}
            name={t('sign_up.employee_id_name')}
            onChange={handleChange}
            size="small"
            label={t('sign_up.enter_id')}
            variant="outlined"
            InputLabelProps={{
              shrink: true,
            }}
          />
          {error && <Typography sx={{ color: '#D93D41', fontSize: '12px', fontWeight: 400 }}>{error}</Typography>}
          {successMessage && (
            <Typography sx={{ color: 'green', fontSize: '12px', fontWeight: 400 }}>{successMessage}</Typography>
          )}
        </Grid>
        <Grid item xs={2}>
          <LoadingButton
            sx={{ minWidth: '120px', p: 1, background: '#FF8A00', '&:hover': { background: '#FF8A00' } }}
            variant="contained"
            color="primary"
            disabled={!couponValue}
            onClick={applyCoupon}
            loading={loadingCoupon}
          >
            {t('orders_history.apply')}
          </LoadingButton>
        </Grid>
        {Number(setUpFeeEnabled) === 1 && (
          <Grid item xs={12}>
            <FormControl name="accept" component="fieldset" error={acceptAgreeToPaySetupFee} variant="standard">
              <FormGroup sx={{ height: '10px', display: 'flow' }}>
                <FormControlLabel
                  control={
                    <Checkbox
                      onChange={handleAcceptSetupFree}
                      checked={acceptAgreeToPaySetupFeeCharges}
                      error={true}
                      name="accept"
                    />
                  }
                  label={
                    <Typography>
                      {t('header.i_agree_charged')}{' '}
                      <strong style={{ textDecoration: setupFree ? 'line-through' : '' }}>{`$${setUpFee}`}</strong>{' '}
                      {setupFree && (
                        <strong>
                          {`${discountedSetupFeeAmount ? formatPrice(discountedSetupFeeAmount) : formatPrice(0)} `}
                        </strong>
                      )}
                      {t('header.setup_fee_today')}
                    </Typography>
                  }
                />
                {acceptAgreeToPaySetupFee && (
                  <FormHelperText sx={{ marginTop: '-18px' }}>{t('help.field_is_required')}</FormHelperText>
                )}
              </FormGroup>
            </FormControl>
          </Grid>
        )}
      </Grid>
    </>
  );
};

export default ApplyCoupon;
