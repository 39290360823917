import { useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import useQuery from 'hooks/useQuery';
export const useLocalizationPopover = () => {
  let languageLimit = 2;
  const query = useQuery();
  const mode = query.get('mode');
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const [currentSelectedField, setCurrentSelectedField] = useState('name');
  const [currentSelectedLabel, setCurrentSelectedLabel] = useState('name');
  const [currentSelectedValues, setCurrentSelectedValues] = useState('');
  const { currentBusiness } = useSelector(({ business }) => business);
  const handleClickPopover = (event, name, label, value) => {
    if (!open) {
      setCurrentSelectedField(name);
      setCurrentSelectedLabel(t(label));
      if (value) {
        setCurrentSelectedValues(value);
      }
      setOpen(currentBusiness?.languages?.length >= languageLimit && mode !== 'create');
      event.stopPropagation();
    }
  };
  const handleClose = (event) => {
    setOpen(false);
    if (event) {
      event?.stopPropagation();
    }
  };
  return {
    setOpen,
    open,
    handleClose,
    handleClickPopover,
    currentBusiness,
    languageLimit,
    currentSelectedField,
    currentSelectedLabel,
    currentSelectedValues,
  };
};
