import React, { memo } from 'react';
import moment from 'moment-timezone';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { t } from 'i18next';

const OrderDate = ({ date, label = t('orders_history.be_ready'), showIcon = true, tzId }) => {
  const { currentBusiness } = useSelector(({ business }) => business);

  const timeZoneId = currentBusiness?.timezone ? currentBusiness.timezone.timeZoneId : 'America/Los_Angeles';

  return (
    <div className="order-date">
      <small className="mr-1">{label}:</small>
      {showIcon && <i className="icon icon-alarm-check"></i>}
      <p className="text">
        {moment(date)
          .tz(tzId || timeZoneId)
          .format('hh:mm A - ddd, MMM Do')}
      </p>
    </div>
  );
};

export default OrderDate;
