import React from 'react';
import image from '../../assets/images/empty_products.png';

const EmptyMenu = ({ text = 'There are no any data', style = '', children }) => {
  return (
    <div className={`empty-menu ${style}`}>
      <div className="empty-content">
        <img className="image" src={image} alt="EMPTY-PRODUCTS" />
        <h5 className="center">{text}</h5>
        {children}
      </div>
    </div>
  );
};

export default EmptyMenu;
