import React from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Box, Typography } from '@mui/material';
import { ChargeBackViewMain } from './styles';
import { OrderView } from 'components/Order';
import { checkChargeBackState } from '../constants';

const Details = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const { chargeBack } = useSelector((store) => store);
  const { selected, loadingSelected } = chargeBack;
  const { data } = useSelector(({ user }) => user);
  const stateChargeBack = checkChargeBackState(history.location.pathname);

  if (!selected) {
    return (
      <ChargeBackViewMain className="aside-view shadow-sm">
        <Box color="#6c757d" textAlign="center" p={5}>
          <i className="h1 icon icon-orders"></i>
          <Typography variant="h5" fontWeight={500}>
            {t('chargeBack.select_charge_back')}
          </Typography>
        </Box>
      </ChargeBackViewMain>
    );
  }

  return (
    <>
      <OrderView
        orderList={selected[stateChargeBack]}
        data={{
          ...selected?.orderData,
          business: [
            {
              ...selected?.orderMetaData?.business,
              timezone: { timeZoneId: selected?.orderMetaData?.business?.timezone },
            },
          ],
        }}
        business={data}
        orderId={selected?._id}
        loading={loadingSelected}
        disabledActions={loadingSelected}
      />
    </>
  );
};

export default Details;
