import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { CopyMain, CopyMsg, CopyMsgContainer, TriangleDown } from 'pages/Settings/BusinessSettings/styles';

const CopyIcon = ({ currentBusiness }) => {
  const { t } = useTranslation();
  const [showCopyMessage, setShowCopyMessage] = useState(false);
  const [copyMessage, setCopyMessage] = useState(t('settings.copy_to_clipboard'));

  const handleCopy = () => {
    setShowCopyMessage(true);
    setCopyMessage(t('settings.copied'));
    const copyURL = currentBusiness?.signUpProgress?.url || '';
    navigator?.clipboard?.writeText(copyURL);
    setTimeout(() => {
      setShowCopyMessage(false);
    }, 2000);
  };

  return (
    <CopyMain
      onClick={handleCopy}
      onMouseEnter={() => {
        setCopyMessage(t('settings.copy_to_clipboard'));
        setShowCopyMessage(true);
      }}
      onMouseLeave={() => {
        setShowCopyMessage(false);
      }}
    >
      <CopyMsgContainer className={`message-container ${showCopyMessage ? 'show fade-in' : ''}`}>
        <TriangleDown />
        <CopyMsg>{copyMessage}</CopyMsg>
      </CopyMsgContainer>
      <ContentCopyIcon />
    </CopyMain>
  );
};

export default CopyIcon;
