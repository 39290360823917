import React, { useCallback, useState, useEffect, useContext } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { Grid, Button } from '@mui/material';
import { Divider } from '@mui/material';
import { Typography } from '@mui/material';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import { toast } from 'react-toastify';
import { useStripe, useElements, Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import GooglAutocomplete from 'components/FormFields/GoogleAutocomplate';
import CreditCardDetail from 'components/CreditCardDetails/CreditCardDetail';
import CustomTextInput from 'components/Forms/CustomTextInput';
import confirm from 'components/Confirm';

import SubscriptionPlans from 'components/SubscriptionPlans';
import { updateUserBilling, deleteUserBilling, getUserBilling } from 'redux/actions/user';
import Preloader from 'components/Preloader';
import LabelTooltip from 'components/FormFields/LabelTooltip';
import { useSocket } from 'providers/Socket';
import ErrorBoundaryWrapper from 'components/ErrorBoundaryWrapper';
import { PaymentPeriodicityContext } from 'context/paymentPeriodicityContext';
import { useTranslation } from 'react-i18next';
import ConfirmPromptModal from 'components/ConfirmPromptModal/ConfirmPromptModal';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);
const BillingInfo = () => {
  const { t } = useTranslation();

  const { subscribe, unsubscribe } = useSocket();
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState({});
  const [addressInfo, handleAddressInfo] = useState({});
  const { periodicity, setPeriodicity } = useContext(PaymentPeriodicityContext);

  const handleGetBilling = useCallback(() => {
    getUserBilling()
      .then(({ data: { data } }) => {
        setData(data);
        setPeriodicity(data.paymentTerm);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    handleGetBilling();
  }, [handleGetBilling, periodicity]);

  const handleSelectedPlan = (data) => {
    setData(data);
  };

  useEffect(() => {
    subscribe('session.update', handleGetBilling);
    return () => {
      unsubscribe('session.update', handleGetBilling);
    };
  }, [handleGetBilling, subscribe, unsubscribe]);

  const handleSubmit = (body) => {
    setLoading(true);
    updateUserBilling(body)
      .then(() => {
        handleGetBilling();
      })
      .catch((err) => {
        toast.error(err?.response?.data?.message?.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleDelete = useCallback(async (id) => {
    const message = (
      <p>
        {t('sign_up.are_you_sure_the_card')}
        <br />
        {t('sign_up.your_account_charged_service_removed_card')}
      </p>
    );

    const option = {
      title: '',
      message: message,
      cancelText: t('orders.cancel'),
      confirmText: t('schedule.remove'),
      confirmColor: 'primary',
    };
    const confirmed = await confirm(option);
    if (confirmed) {
      deleteUserBilling(id).then(() => {
        setData((prev) => ({ ...prev, card: null }));
      });
    }
  }, []);

  const getBillingAddressFields = (addressInfo) => {
    handleAddressInfo(addressInfo);
  };

  return (
    <div className="billing-setting">
      <Typography sx={{ my: 2 }} variant="h5">
        {t('settings.billing_settings')}
      </Typography>
      <Grid container columns={{ xs: 12 }}>
        <Grid item xs={9}>
          {loading ? (
            <Preloader />
          ) : (
            <Elements stripe={stripePromise}>
              <BillingForm
                key="BillingForm"
                addressInfo={addressInfo}
                getBillingAddressFields={getBillingAddressFields}
                handleSelectedPlan={handleSelectedPlan}
                data={data}
                onSubmit={handleSubmit}
                onDelete={handleDelete}
              />
            </Elements>
          )}
        </Grid>
      </Grid>
    </div>
  );
};

const BillingForm = ({ getBillingAddressFields, data, onSubmit, onDelete, handleSelectedPlan, addressInfo }) => {
  const { t } = useTranslation();
  const stripe = useStripe();
  const elements = useElements();
  const [addressFieldError, handleAddressFieldError] = useState(false);
  const [isDirtyCard, setIsDirtyCard] = useState(false);
  const [isError, setHandleError] = useState('');
  const billingFormValidationModel = yup.object().shape({
    city: yup.string().required('field_is_required_'),
    state: yup.string().required('field_is_required_'),
    zipCode: yup.string().required('field_is_required_'),
    address1: yup.string().required('field_is_required_'),
  });

  const billingFormMethods = useForm({
    defaultValues: data,
    mode: 'all',
    resolver: yupResolver(billingFormValidationModel),
  });

  const {
    reset,
    getValues,
    formState: { isSubmitting, isSubmitted, register },
  } = billingFormMethods;

  useEffect(() => {
    if (data && Object.keys(data).length) {
      reset(data, { keepDirty: false });
    }
  }, [data]);

  const handleSubmit = useCallback(
    (values) => {
      if (!values?.address1) {
        billingFormMethods.setError('address1', { message: 'field_is_required_' });
        setHandleError(t('field_is_required_'));
      }
      if (!values?.state) {
        billingFormMethods.setError('state', { message: 'field_is_required_' });
      }
      if (!values?.zipCode) {
        billingFormMethods.setError('zipCode', { message: 'field_is_required_' });
      }
      if (!values?.city) {
        billingFormMethods.setError('city', { message: 'field_is_required_' });
      }

      const stripeAddress = {
        address_city: values?.city || '',
        address_line1: values?.address1 || '',
        address_line2: values?.address2 || '',
        address_country: values?.country || '',
        address_state: values?.state || '',
        address_zip: values?.zipCode || '',
      };
      stripe
        .createToken(elements.getElement('cardNumber'), stripeAddress)
        .then(({ token }) => {
          if (token) {
            if (!addressFieldError) {
              onSubmit({
                token: token.id,
                metadata: {
                  place_id: addressInfo?.placeId,
                },
              });
            }
          } else {
            toast.error('Credit card info is required!');
          }
        })
        .catch(() => {
          toast.error('Stripe Error.');
        });
    },
    [data, onSubmit, stripe, addressFieldError]
  );

  const getAddressFieldsHandle = (addressInfo) => {
    const exist = !Boolean(addressInfo.address);
    getBillingAddressFields(addressInfo);
    handleAddressFieldError(exist);
    billingFormMethods.setValue('fullAddress', addressInfo.fullAddress || '', { shouldDirty: true });
    billingFormMethods.setValue('address1', addressInfo.address1 || getValues().address1 || '', { shouldDirty: true });

    billingFormMethods.reset(
      {
        city: addressInfo.city,
        state: addressInfo.state,
        zipCode: addressInfo.zipCode,
        address: addressInfo.address,
        address1: addressInfo.address1,
        address2: addressInfo.address2,
        country: addressInfo.country,
      },
      { keepDirty: true }
    );
  };

  const onChangeAddressInput = (address) => {
    const exist = !Boolean(address);
    handleAddressFieldError(exist);
    billingFormMethods.setValue('address', address || '', { shouldDirty: true });
    billingFormMethods.setValue('address1', addressInfo.address1 || address || '', { shouldDirty: true });
  };

  const handlePlan = (id) => {
    const formDataValues = billingFormMethods.getValues();
    handleSelectedPlan({ ...formDataValues, planId: id });
  };

  const handleInvalidSubmit = (err) => {
    stripe.createToken(elements.getElement('cardNumber')); //for validations stripe
  };

  useEffect(() => {
    var headerOffset = 120;
    // StripeElement--invalid
    const elements = document.getElementsByClassName('MuiOutlinedInput-input');
    for (let i = 0; i < elements.length; i++) {
      const inValid = elements[i].getAttribute('aria-invalid');
      if (inValid) {
        const elementPosition = elements[i].getBoundingClientRect().top;
        const offsetPosition = elementPosition + window.pageYOffset - headerOffset;
        window.scrollTo({
          top: 50,
          behavior: 'smooth',
        });
        break;
      }
    }
  }, [isSubmitting]);

  return (
    <ErrorBoundaryWrapper>
      <Box>
        <FormProvider {...billingFormMethods}>
          <ConfirmPromptModal
            handleConfirm={billingFormMethods.handleSubmit(handleSubmit, handleInvalidSubmit)}
            submitData={billingFormMethods.getValues()}
            hasUnsavedChanges={billingFormMethods.formState.isDirty || isDirtyCard}
            formState={billingFormMethods}
            isFormError={
              !billingFormMethods.getValues('address1') ||
              !billingFormMethods.getValues('zipCode') ||
              !billingFormMethods.getValues('city') ||
              !billingFormMethods.getValues('state')
            }
          />
          <form onSubmit={billingFormMethods.handleSubmit(handleSubmit, handleInvalidSubmit)}>
            <Grid container columns={{ xs: 12 }}>
              <Grid item xs={6}>
                <div className="border-bottom pb-3 mb-4">
                  <Typography variant="h6">
                    <LabelTooltip
                      id="cardInfoTooltip"
                      value={t('settings.card_info')}
                      tooltip="Used to process Orders.co Subscription payments to adjust plans."
                    />
                  </Typography>
                </div>
                {data?.card?.length ? (
                  data?.card.map((item) => {
                    return (
                      <Box>
                        <Card sx={{ p: 1, mb: 2 }}>
                          <Grid container spacing={2} columns={{ xs: 12 }}>
                            <Grid item xs={4}>
                              <Box>
                                <Typography>{item.brand}</Typography>
                              </Box>
                            </Grid>
                            <Grid item xs={8}>
                              <span className="mr-3">**** **** **** {item.last4}</span>
                              <span className="mr-2">
                                {String(item.exp_month).padStart(2, '0')}/{item.exp_year}
                              </span>
                              <Button
                                id="billingInfoDelete"
                                onClick={() => {
                                  setIsDirtyCard(false);
                                  onDelete(item.id);
                                }}
                              >
                                <i className="icon icon-trash-2"></i>
                              </Button>
                            </Grid>
                          </Grid>
                        </Card>
                        <Card sx={{ p: 1 }}>
                          <Grid container spacing={2} columns={{ xs: 12 }}>
                            <Grid item xs={4}>
                              <Box>
                                <Typography>{t('settings.billing_address')}</Typography>
                              </Box>
                            </Grid>
                            <Grid item xs={8}>
                              <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                                <Typography variant="caption">
                                  {data?.card[0]?.address_line1 ? data?.card[0]?.address_line1 : ''}
                                </Typography>
                                <Typography variant="caption">
                                  {data?.card[0]?.address_line2 ? data?.card[0]?.address_line2 : ''}
                                </Typography>
                                <Typography variant="caption">
                                  {data?.card[0]?.address_city ? data?.card[0]?.address_city : ''}
                                </Typography>
                                <Typography variant="caption">
                                  {data?.card[0]?.address_state ? data?.card[0]?.address_state : ''}
                                </Typography>
                                <Typography variant="caption">
                                  {data?.card[0]?.address_country ? data?.card[0]?.address_country : ''}
                                </Typography>
                              </Box>
                            </Grid>
                          </Grid>
                        </Card>
                      </Box>
                    );
                  })
                ) : (
                  <CreditCardDetail isDirty={isDirtyCard} setIsDirtyCard={setIsDirtyCard} />
                )}
              </Grid>
            </Grid>
            {!data?.card?.length ? (
              <Grid container spacing={2} columns={{ xs: 12 }}>
                <Grid item xs={12}>
                  <Divider sx={{ my: 2 }} />
                  <Typography variant="h6">
                    <LabelTooltip
                      id="cardInfoTooltip"
                      value="Billing Address"
                      tooltip="Used to process Orders.co Subscription payments &amp; to adjust plans."
                    />
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <GooglAutocomplete
                    getAddressFields={getAddressFieldsHandle}
                    onChangeAddressInput={onChangeAddressInput}
                    name="address1"
                    label={t('order_view.address')}
                    placeholder=""
                    required={true}
                    initialValue={''}
                    register={register}
                    formSubmitted={isSubmitted}
                    isError={isError}
                  />
                </Grid>
                <Grid item xs={12}>
                  <CustomTextInput
                    sx={{ width: '100%' }}
                    defaultValue={data?.addressInfo?.address2 || ''}
                    name="address2"
                    label={t('order_view.address2')}
                  />
                </Grid>
                <Grid item xs={4}>
                  <CustomTextInput
                    sx={{ width: '100%' }}
                    defaultValue={data?.addressInfo?.state || ''}
                    name="state"
                    label={t('settings.state')}
                  />
                </Grid>
                <Grid item xs={4}>
                  <CustomTextInput
                    sx={{ width: '100%' }}
                    defaultValue={data?.addressInfo?.city || ''}
                    name="city"
                    label={t('settings.city')}
                  />
                </Grid>
                <Grid item xs={4}>
                  <CustomTextInput
                    sx={{ width: '100%' }}
                    defaultValue={data?.addressInfo?.zipCode || ''}
                    name="zipCode"
                    label={t('settings.zip_code')}
                  />
                </Grid>
              </Grid>
            ) : (
              ''
            )}
            {data?.plan && (
              <>
                <Typography variant="h6" sx={{ my: 2 }}>
                  {t('settings.subscription_plan')}
                </Typography>
                <SubscriptionPlans
                  required={false}
                  disabled={true}
                  handleSelectedPlan={handlePlan}
                  selectedPlan={data.plan?._id}
                  type={data.plan?.type}
                  addonPlan={data.plan}
                />
                <Typography sx={{ marginTop: 1 }}>
                  {t('settings.please_contact the_subscription_plan')}
                  {/* Please contact the support team to change your subscription plan{' '} */}
                </Typography>
              </>
            )}
            <Box sx={{ mt: 2 }}>
              <Button id="billingInfoSave" type="submit" variant="contained" color="primary" className="px-5">
                {t('settings.save')}
              </Button>
            </Box>
          </form>
        </FormProvider>
      </Box>
    </ErrorBoundaryWrapper>
  );
};

export default BillingInfo;
