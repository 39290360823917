import React, { useRef } from 'react';
import { sendMessage } from 'redux/actions/user';
import { v4 as uuidv4 } from 'uuid';

import { useForm, FormProvider } from 'react-hook-form';

import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import CardContent from '@mui/material/CardContent';
import Card from '@mui/material/Card';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import MenuItem from '@mui/material/MenuItem';
import CustomTextInput from 'components/Forms/CustomTextInput';
import Preloader from 'components/Preloader';
import { yupResolver } from '@hookform/resolvers/yup';
import PhoneIcon from '@mui/icons-material/Phone';
import DraftsIcon from '@mui/icons-material/Drafts';
import * as yup from 'yup';
import { Typography } from '@mui/material';
import useQr from 'hooks/useQr';
import { useTranslation } from 'react-i18next';

const Help = () => {
  const { t } = useTranslation();
  const options = [
    { name: t('menu.name'), id: uuidv4() },
    { name: t('menu.website'), id: uuidv4() },
    { name: t('help.connecting'), id: uuidv4() },
    { name: t('orders_history.provider'), id: uuidv4() },
    { name: t('settings.name'), id: uuidv4() },
    { name: t('help.account_cancellation'), id: uuidv4() },
    { name: t('help.other'), id: uuidv4() },
  ];

  const { loading, qr } = useQr();
  const websiteInfo = yup.object().shape({
    description: yup.string().max(10000, 'maximum_10.000_').required('field_is_required_'),
    issueType: yup.string().required('this_field_is_invalid_'),
  });
  const helpFormMethods = useForm({
    defaultValues: { issueType: '', description: '' },
    mode: 'all',
    resolver: yupResolver(websiteInfo),
  });

  const handleValidSubmit = (values) => {
    sendMessage(values).then(() => {
      helpFormMethods.reset();
    });
  };

  return (
    <Card>
      <CardContent className="help-page">
        <Typography sx={{ mb: 2 }} variant="h5">
          {/* Help */}
          {t('help.name')}
        </Typography>
        <FormProvider {...helpFormMethods}>
          <form noValidate autoComplete="off" onSubmit={helpFormMethods.handleSubmit(handleValidSubmit)}>
            <Grid
              container
              flexDirection={{
                xs: 'column-reverse',
                sm: 'column-reverse',
                md: 'column-reverse',
                lg: 'row',
                xl: 'row',
              }}
              columns={12}
            >
              <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                <Typography sx={{ mb: 2 }} variant="h6">
                  {/* Send message */}
                  {t('help.send_message')}
                </Typography>
                <Box sx={{ boxShadow: '0 0 4px hsl(0deg 0% 9% / 18%)', padding: 2 }}>
                  <CustomTextInput
                    sx={{ width: '100%', height: '72px' }}
                    select={true}
                    name="issueType"
                    label={t('help.issue_type')}
                    // helperText={t('issueType')}
                  >
                    {options.map((item) => {
                      return (
                        <MenuItem key={item.id} value={item.name}>
                          {item.name}
                        </MenuItem>
                      );
                    })}
                  </CustomTextInput>
                  {/* {helpFormMethods.errors.issueType && <p>{helpFormMethods.errors.issueType.message}</p>} */}
                  <CustomTextInput
                    sx={{ width: '100%', mt: 2 }}
                    multiline={true}
                    name="description"
                    // label="Message"
                    label={t('help.message')}
                    // helperText={t('description')}
                    rows={5}
                  />{' '}
                  {/* {helpFormMethods.errors.description && <p>{helpFormMethods.errors.description.message}</p>} */}
                </Box>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={6} xl={6} className="help-infos">
                <Typography variant="h6" display={{ lg: 'none' }} sx={{ mb: 4 }}>
                  {/* Contact us */}
                  {t('help.contact_us')}
                </Typography>
                <Box
                  width={{
                    xs: '100%',
                    sm: '100%',
                    md: '100%',
                  }}
                  justifyContent={{
                    xs: 'start',
                    sm: 'start',
                    md: 'start',
                    lg: 'space-around',
                    xl: 'space-around',
                  }}
                  flexDirection={{ xs: 'column', sm: qr ? 'row' : 'column', md: qr ? 'row' : 'column' }}
                  sx={{ display: 'flex', justifyContent: qr ? 'center' : 'flex-start' }}
                >
                  {!qr ? (
                    <Typography
                      variant="h6"
                      display={{ xs: 'none', sm: 'none', md: 'none', lg: 'flex', xl: 'flex' }}
                      marginBottom={{
                        md: 4,
                        lg: 2,
                      }}
                    >
                      {/* Contact us */}
                      {t('help.contact_us')}
                    </Typography>
                  ) : (
                    <></>
                  )}
                  {qr ? (
                    <Box sx={{ width: '200px', mb: 7, position: 'relative', mr: 2 }}>
                      <Typography
                        variant="h6"
                        display={{ xs: 'none', sm: 'none', md: 'none', lg: 'flex', xl: 'flex' }}
                        marginBottom={{
                          md: 4,
                          lg: 2,
                        }}
                      >
                        {/* Contact us */}
                        {t('help.contact_us')}
                      </Typography>
                      <Box sx={{ width: '200px', position: 'relative' }}>
                        {loading ? (
                          <Preloader overlay={true} />
                        ) : (
                          <img src={qr} alt="Live Chat" style={{ width: '100%' }} />
                        )}
                      </Box>
                      <Typography sx={{ mt: 2, textAlign: 'center', fontSize: '20px', fontWeight: 500 }}>
                        {/* Chat with us 24/7 */}
                        {t('help.chat_with_us')}
                      </Typography>
                    </Box>
                  ) : (
                    <></>
                  )}

                  <Box
                    marginTop={{
                      lg: 5,
                    }}
                    sx={{ mb: 3, display: 'flex', flexDirection: 'column' }}
                  >
                    <Link href="tel:+18555001070" underline="none" sx={{ display: 'flex', flexWrap: 'wrap' }}>
                      <IconButton
                        size="small"
                        variant="contained"
                        sx={{
                          // background: (theme) => theme.palette.primary.main,
                          color: '#2D7FF9',
                          mr: 2,
                          '&:hover': {
                            background: (theme) => theme.palette.primary.main,
                          },
                        }}
                      >
                        <PhoneIcon />
                      </IconButton>
                      <Typography sx={{ fontSize: '20px', color: 'black' }}>+1 (855) 500-1070</Typography>
                    </Link>
                    <Link href="mailto:help@orders.co" underline="none" sx={{ display: 'flex', flexWrap: 'wrap' }}>
                      <IconButton
                        size="small"
                        variant="outlined"
                        sx={{
                          color: '#2D7FF9',
                          mr: 2,
                          '&:hover': {
                            background: (theme) => theme.palette.primary.main,
                          },
                        }}
                      >
                        <DraftsIcon />
                      </IconButton>
                      <Typography sx={{ fontSize: '20px', color: 'black' }}>help@orders.co</Typography>
                    </Link>
                  </Box>
                </Box>
              </Grid>
            </Grid>
            <Button id="helpSend" color="primary" variant="contained" type="submit" sx={{ mt: 4, px: 5 }}>
              {t('help.send')}
            </Button>
          </form>
        </FormProvider>
      </CardContent>
    </Card>
  );
};

export default Help;
