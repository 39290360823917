import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Box, Button, Typography } from '@mui/material';
import { withAsyncPaginate } from 'react-select-async-paginate';
import Creatable from 'react-select/creatable';
import { mapTargetToDoordash } from 'redux/actions/Menu/menuV2';
import { useDispatch } from 'react-redux';
import { green } from '@mui/material/colors';
import { loadOptions } from './loadOptions';
import { useTranslation } from 'react-i18next';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  autoFocus: false,
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 240,
    },
  },
};

const CreatableAsyncPaginate = withAsyncPaginate(Creatable);
const increaseUniq = (uniq) => uniq + 1;

const TagretMultySelect = ({ data, targetData, target }) => {
  const dispatch = useDispatch();
  const [isAddingInProgress, setIsAddingInProgress] = useState(false);
  const [cacheUniq, setCacheUniq] = useState(0);
  const { t } = useTranslation();

  const [selected, setSelected] = useState(null);
  const [initialSelected, setInitialSelectedSelected] = useState(null);
  // const [selectedAsCustom, setSelectedAsCustom] = useState(false);

  const [loading, setLoading] = React.useState(false);
  const [success, setSuccess] = React.useState(false);

  useEffect(() => {
    const model = {};

    data.forEach((item) => {
      item?.mappedData?.forEach((mappedDataItem) => {
        if (targetData.isOnePrice === false) {
          targetData.sizeTypeModifiers &&
            targetData.sizeTypeModifiers[0].list.forEach((listData) => {
              if (mappedDataItem.nativeSizeTypeOptionId === listData._id) {
                model[listData._id] = {
                  value: item,
                  label: (
                    <Box>
                      <Typography sx={{ fontWeight: 'bold' }}>{item.name}</Typography>
                      <Typography sx={{ fontSize: '14px' }}>{`#${item.externalId}`}</Typography>
                    </Box>
                  ),
                  name: `${item.externalId} ${item.name}`,
                };
              }
            });
        } else {
          if (mappedDataItem.nativeId === targetData._id) {
            model[mappedDataItem.nativeId] = {
              value: item,
              label: (
                <Box>
                  <Typography sx={{ fontWeight: 'bold' }}>{item.name}</Typography>
                  <Typography sx={{ fontSize: '14px' }}>{`#${item.externalId}`}</Typography>
                </Box>
              ),
              name: item.externalId,
            };
          }
        }
        //
      });
    });

    if (Object.keys(model).length || !selected) {
      setSelected(model);
      setInitialSelectedSelected(model);
    } else {
      if (targetData.isOnePrice === false) {
        targetData?.sizeTypeModifiers?.[0]?.list?.forEach((item) => {
          model[item._id] = {
            value: 'new',
            label: 'Add as new',
            name: 'Add as new',
          };
        });
      } else {
        model[targetData._id] = {
          value: 'new',
          label: 'Add as new',
          name: 'Add as new',
        };
      }
    }
    setSelected(model);
    setInitialSelectedSelected(model);
  }, [data, targetData]);

  const handleChange = (target, id) => {
    return setSelected({ ...selected, [id]: target });
  };

  const handleMapToDoordash = () => {
    setSuccess(false);
    setLoading(true);
    dispatch(mapTargetToDoordash(selected, initialSelected)).finally(() => {
      // setTimeout(() => {
      setSuccess(true);
      setLoading(false);
      // }, [1000]);
    });
  };

  const onCreateOption = useCallback(
    async (inputValue, data) => {
      setIsAddingInProgress(true);
      const customData = {
        name: `#${inputValue}`,
        nativeId: data._id,
        mappedData: [],
        externalId: inputValue,
      };
      if (target === 'item') {
        customData.nativeCategoryId = targetData.category;
      }
      setSelected({
        ...selected,
        [data._id]: {
          value: customData,
          label: customData.name,
          name: customData.name,
        },
      });

      setIsAddingInProgress(false);
      setCacheUniq(increaseUniq);
      // handleChange(newOption.value);
    },
    [selected, targetData.category]
  );

  const isDisabled = useMemo(() => {
    if (initialSelected && selected) {
      if (targetData.isOnePrice === false) {
        const initialCheckNew = Object.values(initialSelected)?.every((item) => item.value === 'new');
        const selectedCheckNew = Object.values(selected)?.every((item) => item.value === 'new');
        return initialCheckNew && selectedCheckNew;
      } else if (
        initialSelected[targetData._id]?.value._id &&
        initialSelected[targetData._id]?.value._id === selected[targetData._id]?.value._id
      ) {
        return true;
      }
      // if (
      //   targetData.isOnePrice !== false &&
      //   initialSelected[targetData._id]?.value === 'new' &&
      //   selected[targetData._id]?.value === 'new'
      // ) {
      //   return true;
      // } else {
      //   const initialCheckNew = Object.values(initialSelected)?.every((item) => item.value === 'new');
      //   const selectedCheckNew = Object.values(selected)?.every((item) => item.value === 'new');
      //   return initialCheckNew && selectedCheckNew;
      // }
    }
  }, [selected, initialSelected, targetData]);

  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <Box
        sx={{
          width: '100%',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'flex-end',
          flexDirection: 'column',
        }}
      >
        {targetData.isOnePrice === false ? (
          targetData.sizeTypeModifiers[0]?.list.map((item) => {
            return (
              <Box sx={{ display: 'flex', alignItems: 'center', mb: 1.5 }}>
                <Typography sx={{ ml: 12, fontWeight: 'bold' }}>{item.name}</Typography>
                <Box sx={{ width: '480px', ml: 23 }}>
                  <CreatableAsyncPaginate
                    value={selected?.[item._id]}
                    isClearable
                    // isDisabled={isLoading}
                    loadOptions={loadOptions(data)}
                    onChange={(value) => handleChange(value, item._id)}
                    onCreateOption={(value) => onCreateOption(value, item)}
                    // noOptionsMessage={() => <Button onClick={handleAddAsCustom}>Add {targetData.name} as custom</Button>}
                    cacheUniqs={[cacheUniq]}
                    formatCreateLabel={(value) => `Add as custom "${value}"`}
                    cacheOptions
                  />
                </Box>
              </Box>
            );
          })
        ) : (
          <Box sx={{ width: '480px', ml: 23 }}>
            <CreatableAsyncPaginate
              value={selected?.[targetData._id]}
              isClearable
              // isDisabled={isLoading}
              loadOptions={loadOptions(data)}
              onChange={(value) => handleChange(value, targetData._id)}
              onCreateOption={(value) => onCreateOption(value, targetData)}
              // noOptionsMessage={() => <Button onClick={handleAddAsCustom}>Add {targetData.name} as custom</Button>}
              cacheUniqs={[cacheUniq]}
              formatCreateLabel={(value) => `Add as custom "${value}"`}
              cacheOptions
            />
          </Box>
        )}

        <Button id="multySelectSave" disabled={isDisabled} sx={{ mt: 2 }} onClick={handleMapToDoordash}>
          {t('settings.save')}
        </Button>
      </Box>
    </Box>
  );
};

export default TagretMultySelect;
