import React, { useState, useCallback, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useForm, FormProvider } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import CustomTextInput from 'components/Forms/CustomTextInput';
import {
  createAvailabilitySettings,
  updateAvailabilitySettings,
  getAvailabilityById,
  deleteAvailability,
} from 'redux/actions/availability';
import { getAllBusinessSettings } from 'redux/actions/business';
import Preloader from 'components/Preloader';
import { Hours } from 'components/Settings';
import LabelRequired from 'components/FormFields/LabelRequired';
import LabelTooltip from 'components/FormFields/LabelTooltip';
import { useTranslation } from 'react-i18next';
import { confirm } from 'components/MenuNew/ConfirmModal';
import ConfirmPromptModal from 'components/ConfirmPromptModal/ConfirmPromptModal';

const initialHours = {
  sun: { dayOfWeek: 'sun', opened: true, name: '', isOpen24: false, hours: [{ start: '09:00', end: '21:00' }] },
  mon: { dayOfWeek: 'mon', opened: true, name: '', isOpen24: false, hours: [{ start: '09:00', end: '21:00' }] },
  tue: { dayOfWeek: 'tue', opened: true, name: '', isOpen24: false, hours: [{ start: '09:00', end: '21:00' }] },
  wed: { dayOfWeek: 'wed', opened: true, name: '', isOpen24: false, hours: [{ start: '09:00', end: '21:00' }] },
  thu: { dayOfWeek: 'thu', opened: true, name: '', isOpen24: false, hours: [{ start: '09:00', end: '21:00' }] },
  fri: { dayOfWeek: 'fri', opened: true, name: '', isOpen24: false, hours: [{ start: '09:00', end: '21:00' }] },
  sat: { dayOfWeek: 'sat', opened: true, name: '', isOpen24: false, hours: [{ start: '09:00', end: '21:00' }] },
};
const scheduleSchema = yup.object().shape({
  name: yup.string().required('field_is_required_'),
});
const initialData = {
  name: '',
  description: '',
};

const AddEditSchedule = () => {
  const { t } = useTranslation();

  const history = useHistory();
  const { id } = useParams();
  const dispatch = useDispatch();
  const [model, setModel] = useState({});
  const [loading, setLoading] = useState(false);
  const [regularHours, setRegularHours] = useState(initialHours);
  const [specialHours, setSpecialHours] = useState([]);
  const [business, setBusiness] = useState([]);
  const { currentBusiness } = useSelector(({ business }) => business);
  const scheduleModel = useForm({
    defaultValues: initialData,
    mode: 'all',
    resolver: yupResolver(scheduleSchema),
  });

  const { reset } = scheduleModel;

  useEffect(() => {
    getAllBusinessSettings().then((data) => {
      setBusiness(data);
    });
  }, []);

  useEffect(() => {
    if (id) {
      getAvailabilityById(id)
        .then((data) => {
          setLoading(true);
          setSpecialHours(data.specialHours);
          setRegularHours(data.regularHours);
          setModel(data);
          reset(data);
        })
        .finally(() => setLoading(false));
    } else {
      setRegularHours(JSON.parse(JSON.stringify(initialHours)));
    }
  }, [id]);

  const handleSubmit = useCallback(
    (values) => {
      values.business = currentBusiness._id;
      values.type = 'menu';
      if (id) {
        dispatch(updateAvailabilitySettings({ ...model, ...values, regularHours }, id));
        reset({ ...model, ...values, regularHours }, { keepDirty: false });
      } else {
        dispatch(createAvailabilitySettings({ ...model, ...values, regularHours })).then((res) => {
          reset(res, { keepDirty: false });
          if (!scheduleModel.formState.isDirty) {
            setTimeout(() => {
              history.push(`/schedule`);
            });
          }
        });
      }
    },
    [dispatch, id, model, history, regularHours]
  );
  const handleDelete = async () => {
    const confirmed = await confirm({
      title: t('providers_settings.are_you_sure'),
      message: t('settings.do_you_want_remove'),
      cancelText: t('orders.cancel'),
      confirmText: t('general.yes'),
      confirmColor: 'primary',
    });
    if (confirmed) {
      deleteAvailability(model._id).then(() => history.push(`/schedule`));
    }
  };

  return (
    <div className="website-setting">
      <div className="settings-page-content-header">
        <h5>{t('schedule.schedule_Information')}</h5>
      </div>
      {loading ? (
        <Preloader />
      ) : (
        <FormProvider {...scheduleModel}>
          <ConfirmPromptModal
            handleConfirm={scheduleModel.handleSubmit(handleSubmit)}
            submitData={scheduleModel.getValues()}
            hasUnsavedChanges={scheduleModel.formState.isDirty}
            formState={scheduleModel}
          />
          <form onSubmit={scheduleModel.handleSubmit(handleSubmit)}>
            <Grid container columns={12}>
              <Grid item md={6}>
                <CustomTextInput
                  sx={{ width: '100%' }}
                  name="name"
                  label={<LabelRequired>{t('batch_report.name')}</LabelRequired>}
                />
              </Grid>
            </Grid>
            <Grid item md={6}>
              <CustomTextInput
                sx={{ width: '100%', mt: 2 }}
                multiline={true}
                rows={2}
                name="description"
                label={
                  <LabelTooltip
                    id="aboutBusinessTooltip"
                    value={t('schedule.about_schedule')}
                    tooltip="Public visible description about your Schedule Date. “About Us”"
                  />
                }
              />
            </Grid>
            <Grid container columns={12}>
              <Grid item md={8}>
                <Hours businessModel={scheduleModel} data={regularHours} onChange={setRegularHours} />
              </Grid>
            </Grid>
            <div className="form-actions">
              <Button id="addEditScheduleSave" variant="contained" color="primary" type="submit" sx={{ mt: 4, mr: 2 }}>
                {t('settings.save')}
              </Button>
              <Button
                id="addEditScheduleCancel"
                color="secondary"
                variant="outlined"
                sx={{ mt: 4, px: 5, mr: 2 }}
                type="button"
                onClick={() => {
                  reset(model, { keepDirty: false });
                  setTimeout(() => {
                    history.push(`/schedule`);
                  });
                }}
              >
                {t('orders.cancel')}
              </Button>
              {model._id && (
                <Button
                  id="addEditScheduleRemove"
                  sx={{ mt: 4, px: 5 }}
                  color="error"
                  variant="outlined"
                  type="button"
                  onClick={handleDelete}
                >
                  {t('schedule.remove')}
                </Button>
              )}
            </div>
          </form>
        </FormProvider>
      )}
    </div>
  );
};

export default AddEditSchedule;
