import { FormControl, MenuItem, Select } from '@mui/material';
import React, { useMemo, memo } from 'react';
import { getReportsFilterPeriodsHome } from 'constants/config';
import { t } from 'i18next';

const ReportsFilter = ({ query, onSelect }) => {
  const { period } = query;
  const periods = useMemo(() => getReportsFilterPeriodsHome(), []);
  const currentPeriod = useMemo(() => periods[period] || periods.day, [period, periods]);

  return (
    <FormControl sx={{ minWidth: '170px' }}>
      <Select variant="outlined" value={currentPeriod.label} size="small">
        {Object.keys(periods).map((key) => (
          <MenuItem value={periods[key].label} key={key} onClick={() => onSelect('period', key)}>
            {t(periods[key].label)}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};
export default memo(ReportsFilter);
