import React, { useEffect, useState } from 'react';
import Grid from '@mui/material/Grid';
import { accountInfoForm } from './schema';
import { Typography } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import AuthorizationButtons from './ActionButton';
import { FormProvider, useForm, useWatch } from 'react-hook-form';
import InputAdornment from '@mui/material/InputAdornment';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import CustomTextInput from 'components/Forms/CustomTextInput';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { signupUserV2, updateUserV2 } from 'redux/actions/userAuthV2';
import { SIGN_UP_FLOW_AUTHORIZATION } from 'constants/availableButtons';
import CustomMobilePhone from 'components/MobilePhone/CustomMobilePhone';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import LabelRequired from 'components/FormFields/LabelRequired';
const data = localStorage.getItem('accountInfoAuth');
const parsedData = data && JSON.parse(data);

const initialState = parsedData
  ? parsedData
  : {
      firstName: '',
      email: '',
      lastName: '',
      phone: '',
      password: '',
      rePassword: '',
    };

const AccountInfo = ({ availableSteps, setActive, active }) => {
  const { t } = useTranslation();
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const accountInfoFormMethods = useForm({
    defaultValues: initialState,
    mode: 'all',
    resolver: yupResolver(accountInfoForm),
  });
  const { control, getValues, clearErrors, setError } = accountInfoFormMethods;
  const passwordField = useWatch({ control, name: 'password' });
  const rePasswordField = useWatch({ control, name: 'rePassword' });
  const visiblePassword = () => setShowPassword((prevState) => !prevState);
  const visibleConfirmPassword = () => setShowConfirmPassword((prevState) => !prevState);
  useEffect(() => {
    const data = localStorage.getItem('accountInfoAuth');
    const parsedData = data && JSON.parse(data);
    if (parsedData) {
      accountInfoFormMethods.reset(parsedData);
    }
  }, [accountInfoFormMethods]);

  useEffect(() => {
    const currentModel = getValues();
    if (currentModel?.password === currentModel.rePassword) {
      clearErrors('rePassword');
    } else if (currentModel?.password !== currentModel.rePassword && currentModel.rePassword !== '') {
      setError('rePassword', { type: 'custom', message: 'Passwords must match' });
    }
  }, [passwordField, rePasswordField]);

  const handleSubmit = (values) => {
    setLoading(true);
    values.linkedAccountId = id;
    const account = JSON.parse(localStorage.getItem('accountResponse'));
    if (account && Object.keys(account).length > 0) {
      localStorage.setItem('accountInfoAuth', JSON.stringify(values));
      updateUserV2(values, account?.userId)
        .then((res) => {
          setActive(SIGN_UP_FLOW_AUTHORIZATION.AUTHORIZATION_SERVICE_INFO);
          localStorage.setItem('accountResponse', JSON.stringify(res.data.data));
        })
        .catch(() => setLoading(false))
        .finally(() => setLoading(false));
    } else {
      localStorage.setItem('accountInfoAuth', JSON.stringify(values));
      delete values.rePassword;
      signupUserV2(values)
        .then((res) => {
          setActive(SIGN_UP_FLOW_AUTHORIZATION.AUTHORIZATION_SERVICE_INFO);
          localStorage.setItem('accountResponse', JSON.stringify(res.data.data));
        })
        .catch(() => setLoading(false))
        .finally(() => setLoading(false));
    }
  };

  return (
    <>
      <FormProvider {...accountInfoFormMethods}>
        <form onSubmit={accountInfoFormMethods.handleSubmit(handleSubmit)}>
          <Grid container spacing={2}>
            <Grid item md={12} xs={12}>
              <Typography sx={{ fontSize: '16px', fontWeight: 700, mt: 1, mb: 1 }}>
                {t('account_information.account_information')}
              </Typography>
            </Grid>
            <Grid item md={6} xs={12}>
              <CustomTextInput
                sx={{ width: '100%' }}
                name="firstName"
                label={<LabelRequired>{t('account_information.first_name')}</LabelRequired>}
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <CustomTextInput
                sx={{ width: '100%' }}
                name="lastName"
                label={<LabelRequired>{t('account_information.last_name')}</LabelRequired>}
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <CustomTextInput
                sx={{ width: '100%' }}
                name="email"
                label={<LabelRequired>{t('Email')}</LabelRequired>}
              />
            </Grid>
            <Grid item md={6} xs={12} sm={12}>
              <CustomMobilePhone
                sx={{ width: '100%' }}
                inputProps={{ type: 'tel' }}
                name="phone"
                label={<LabelRequired>{t('settings.phone_number')}</LabelRequired>}
              />
            </Grid>
            <Grid item xs={6}>
              <CustomTextInput
                sx={{ width: '100%' }}
                name="password"
                label={<LabelRequired>{t('sign_in.password')}</LabelRequired>}
                InputProps={{
                  type: showPassword ? 'text' : 'password',
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton aria-label="toggle password visibility" onClick={visiblePassword} edge="end">
                        {showPassword ? (
                          <VisibilityOff sx={{ fontSize: '1.2rem' }} />
                        ) : (
                          <Visibility sx={{ fontSize: '1.2rem' }} />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <CustomTextInput
                sx={{ width: '100%' }}
                name="rePassword"
                label={<LabelRequired>{t('settings.confirm_password')}</LabelRequired>}
                InputProps={{
                  type: showConfirmPassword ? 'text' : 'password',
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton aria-label="toggle password visibility" onClick={visibleConfirmPassword} edge="end">
                        {showConfirmPassword ? (
                          <VisibilityOff sx={{ fontSize: '1.2rem' }} />
                        ) : (
                          <Visibility sx={{ fontSize: '1.2rem' }} />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <AuthorizationButtons
                onClick={() => {}}
                steps={availableSteps}
                active={active}
                setActive={setActive}
                disabled={loading || !accountInfoFormMethods.formState.isValid}
                loading={loading}
              />
            </Grid>
          </Grid>
        </form>
      </FormProvider>
    </>
  );
};
export default AccountInfo;
