import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import ModifierGroupForm from '../FormsView/NewModifierGroup/index';
import { set } from 'lodash';
import { CONTEXT_TYPES } from 'constants/menu';

export const FormModifierPage = ({ currentModifier }) => {
  const { t } = useTranslation();
  const [isDirty, setIsDirty] = useState(false);
  const [isChanged, setIsChanged] = useState(false);
  const [formErrors, updateFormErrors] = useState({
    name: '',
    description: '',
    list: [{ name: '', price: '', priceOverride: [{ name: '', price: '' }] }],
  });

  const handleErrors = (formState, formAddOption) => {
    let haveErrors = false;
    const formErrorsCopy = { ...formErrors };
    if (!formState.name && !formAddOption) {
      haveErrors = true;
      set(formErrorsCopy, 'name', { error: true, message: 'field_is_required_' });
    }
    formState.list.forEach((listItem, listItemIndex) => {
      if (!listItem.name) {
        haveErrors = true;
        set(formErrorsCopy, `list[${listItemIndex}].name`, { error: true, message: 'field_is_required_' });
      }
      if (listItem.price === '' && listItem.price !== 0) {
        haveErrors = true;
        set(formErrorsCopy, `list[${listItemIndex}].price`, { error: true, message: 'field_is_required_' });
      }

      listItem.priceOverride.forEach((priceOverrideItem, priceOverrideItemIndex) => {
        if (priceOverrideItem.context_type === CONTEXT_TYPES.modifierItem && priceOverrideItem.name === '') {
          haveErrors = true;
          set(formErrorsCopy, `list[${listItemIndex}].priceOverride[${priceOverrideItemIndex}].name`, {
            error: true,
            message: t('field_is_required_'),
          });
        }
        if (priceOverrideItem.price === '' && priceOverrideItem.price !== 0) {
          haveErrors = true;
          set(formErrorsCopy, `list[${listItemIndex}].priceOverride[${priceOverrideItemIndex}].name`, {
            error: true,
            message: t('field_is_required_'),
          });
        }
      });
    });
    updateFormErrors(formErrorsCopy);
    return haveErrors;
  };

  return (
    <Box sx={{ background: 'white', height: '100%' }}>
      <ModifierGroupForm
        setIsDirty={setIsDirty}
        isDirty={isDirty}
        setIsChanged={setIsChanged}
        isChanged={isChanged}
        currentModifier={currentModifier}
        isOnePrice={true}
        formErrors={formErrors}
        updateFormErrors={updateFormErrors}
        handleErrors={handleErrors}
      />
    </Box>
  );
};

export default FormModifierPage;
