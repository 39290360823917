import React, { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import LoadingButton from '@mui/lab/LoadingButton';
import Box from '@mui/material/Box';
import { Typography } from '@mui/material';
import { updateStopResumeOrders } from 'redux/actions/providers';
import { PAUSE_RESUME_STATUSES } from 'constants/resumePause';

import useCountDown from './useCountDown';

function ResumePauseButton({ hasConnectProvider }) {
  const dispatch = useDispatch();
  const { stopResumeOrders } = useSelector(({ providers }) => providers);
  const { isBrandLoading } = useSelector(({ business }) => business);
  const { statuses } = stopResumeOrders;
  const { status } = statuses;
  const { duration } = useCountDown(statuses, () => {
    return dispatch(
      updateStopResumeOrders({
        ...stopResumeOrders,
        data: {
          ...stopResumeOrders.data,
          action: PAUSE_RESUME_STATUSES.RECEIVING,
        },
        statuses: {
          ...stopResumeOrders.statuses,
          paused_until: '',
          status: PAUSE_RESUME_STATUSES.RECEIVING,
        },
      })
    );
  });

  const iconStyle = useMemo(() => {
    if (status === PAUSE_RESUME_STATUSES.RECEIVING) return { backgroundColor: '#11AF22' };
    if (status === PAUSE_RESUME_STATUSES.PARTIAL || status === PAUSE_RESUME_STATUSES.BUSY)
      return {
        backgroundColor: '#F2AE48',
      };
    return {
      backgroundColor: 'red',
    };
  }, [status]);

  const handleShowResumePause = () => {
    if (!duration) return;
    dispatch(updateStopResumeOrders({ showModal: true }));
  };

  if (!hasConnectProvider && !stopResumeOrders?.loading && !isBrandLoading) {
    return (
      <LoadingButton
        id="resumePauseButton"
        size="small"
        className="mr-2"
        variant="outlined"
        sx={{
          color: 'black',
          textTransform: 'none',
          mb: 2,
          height: '40px',
          width: '200px',
          borderColor: 'rgba(0, 0, 0, 0.23)',
        }}
        disabled={true}
      >
        <Typography sx={{ display: 'flex', alignItems: 'center' }}>N/A</Typography>
      </LoadingButton>
    );
  }

  return (
    <LoadingButton
      id="resumePauseButton"
      size="small"
      className="mr-2"
      onClick={handleShowResumePause}
      variant="outlined"
      loading={stopResumeOrders.loading || isBrandLoading}
      startIcon={
        !isBrandLoading &&
        !stopResumeOrders.loading && <Box sx={{ ...iconStyle, mr: 1, width: 12, height: 12, borderRadius: 90 }} />
      }
      sx={{
        color: 'black',
        textTransform: 'none',
        mb: 2,
        height: '40px',
        width: '200px',
        borderColor: 'rgba(0, 0, 0, 0.23)',
      }}
    >
      <Typography sx={{ display: 'flex', alignItems: 'center' }}>{duration}</Typography>
    </LoadingButton>
  );
}

export default ResumePauseButton;
