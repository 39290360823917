import { Box } from '@mui/material';
import { MultiSelect } from 'react-multi-select-component';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { useDispatch, useSelector } from 'react-redux';
import '../../styles/pages/_filter.scss';
import React, { memo, useEffect, useState } from 'react';
import {
  COLLECT_BUSINESS_IDS_FOR_HISTORY,
  COLLECT_BUSINESS_IDS_FOR_REPORT,
  IS_LOCATION_UNSELECT,
  SET_CURRENT_SELECT_LOCATIONS,
  SET_ALL_SELECTED_LOCATION,
  SET_SELECTED_LOCATIONS,
  ALL_LOCATIONS_DEPENDS_ON_BUSINESS_GROUP,
} from 'redux/actions/types';
import { LabelName } from 'pages/Report/styles';
import { useTranslation } from 'react-i18next';
import Tooltip from '@mui/material/Tooltip';
import dateIssue from 'assets/icons/dateIssue.svg';

const LocationAutoComplete = ({
  locationsList,
  isHistory,
  currentSelectedLocations = [],
  applyFilter = false,
  isReports = false,
  isError = false,
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [isErrorLocation, setIsErrorLocation] = useState(false);
  const { unSelect, selectedLocations, locationIdsForReport, allLocationsDependsOnBusinessGroup } = useSelector(
    ({ business }) => business
  );
  const setData = (option) => {
    const idsReport = [];
    const idsOrderHistory = [];
    if (unSelect && option.length > 1) {
      dispatch({ type: COLLECT_BUSINESS_IDS_FOR_REPORT, payload: [] });
      dispatch({ type: COLLECT_BUSINESS_IDS_FOR_HISTORY, payload: [] });
      dispatch({ type: IS_LOCATION_UNSELECT, payload: true });
      return;
    }
    option.forEach((item) => {
      if (item?.value) {
        return !isHistory ? idsReport.push(item?.value) : idsOrderHistory.push(item?.value);
      }
    });
    if (!isHistory) {
      dispatch({ type: SET_ALL_SELECTED_LOCATION, payload: option });
      dispatch({ type: COLLECT_BUSINESS_IDS_FOR_REPORT, payload: idsReport });
    } else {
      dispatch({ type: COLLECT_BUSINESS_IDS_FOR_HISTORY, payload: idsOrderHistory });
    }
  };

  const handleMultiSelectChange = (option) => {
    setIsErrorLocation(option.length === 0);
    dispatch({ type: SET_SELECTED_LOCATIONS, payload: option });
    setData(option);
    dispatch({ type: SET_CURRENT_SELECT_LOCATIONS, payload: option });
  };

  const customValueRenderer = (selected, _options) => {
    if (selected?.length) {
      const name = selected[0]?.name;
      const extractName = name?.length > 18 ? `${name.slice(0, 18)}...` : name;
      if (selected?.length > 1) {
        return `${extractName} (+${selected?.length - 1} Other)`;
      } else return extractName;
    }
  };
  useEffect(() => {
    if (locationsList && locationsList?.length) {
      if (currentSelectedLocations.length > 0 && applyFilter) {
        const ids = JSON.parse(localStorage.getItem('requestData'));
        const filterItems = currentSelectedLocations.filter((location) => {
          return ids?.locationIds.includes(location?.value);
        });
        dispatch({ type: SET_SELECTED_LOCATIONS, payload: filterItems });
        setData(filterItems);
        return;
      }

      unSelect
        ? dispatch({ type: SET_SELECTED_LOCATIONS, payload: [] })
        : dispatch({ type: SET_SELECTED_LOCATIONS, payload: locationsList });
      unSelect
        ? dispatch({ type: ALL_LOCATIONS_DEPENDS_ON_BUSINESS_GROUP, payload: [] })
        : dispatch({ type: ALL_LOCATIONS_DEPENDS_ON_BUSINESS_GROUP, payload: locationsList });
      unSelect ? setData([]) : setData(locationsList);
    }
  }, [locationsList, unSelect]);

  useEffect(() => {
    setIsErrorLocation(locationIdsForReport === 0);
  }, [locationIdsForReport]);

  return (
    <Box
      className={`${(isErrorLocation && isHistory) || (isReports && isError) ? 'location-autocomplete-error' : ''}`}
      sx={{ width: '300px', marginTop: '20px', position: 'relative' }}
    >
      {isReports ? (
        <LabelName
          sx={{
            color: isError ? '#E21508 !important' : '',
            span: {
              color: '#E21508 !important',
            },
          }}
        >
          All Locations <span>*</span>
        </LabelName>
      ) : (
        <LabelName sx={{ color: isErrorLocation ? '#E21508 !important' : '' }}>All Locations*</LabelName>
      )}
      {(isError || isErrorLocation) && (
        <Tooltip title={t('help.field_is_required')} placement="top" arrow>
          <img
            width={16}
            height={16}
            alt="Date Issue Icon"
            src={dateIssue}
            style={{ top: '12px', position: 'absolute', zIndex: 1, left: '13.5px' }}
          />
        </Tooltip>
      )}
      <MultiSelect
        options={allLocationsDependsOnBusinessGroup}
        hasSelectAll={true}
        // ItemRenderer={({ checked, option, onClick }) => {
        //   return (
        //     <Box sx={{ display: 'flex', gap: '10px', alignItems: 'center' }}>
        //       <Checkbox checked={checked} onClick={onClick} />
        //       <Box>
        //         <Typography>{option?.label}</Typography>
        //         <Typography sx={{ fontSize: '12px' }}>{option?.address}</Typography>
        //       </Box>
        //     </Box>
        //   );
        // }}
        value={selectedLocations}
        debounceDuration={300}
        ArrowRenderer={() => <ArrowDropDownIcon color="action" />}
        valueRenderer={customValueRenderer}
        onChange={handleMultiSelectChange}
        labelledBy={'All Locations...'}
      />
    </Box>
  );
};
export default memo(LocationAutoComplete);
