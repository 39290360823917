// const options = [];
// for (let i = 0; i < 50; ++i) {
//   options.push({
//     value: i + 1,
//     label: `Option ${i + 1}`,
//   });
// }

import { Box, Typography } from '@mui/material';

const sleep = (ms) =>
  new Promise((resolve) => {
    setTimeout(() => {
      resolve(undefined);
    }, ms);
  });

export const loadOptions = (locationsList, isUber) => async (search, prevOptions) => {
  const options = locationsList.map((item) => ({
    value: item?.store_id || item?.storeId || item?.id,
    label: (
      <Box>
        <Typography>
          {item.addressInfo && item.addressInfo.address1 ? `${item.name} - ${item.addressInfo.address1}` : item.name}
        </Typography>
        {isUber && item.web_url && (
          <Typography sx={{ color: (theme) => theme.palette.primary.main, fontSize: '14px' }}>
            <a style={{ color: 'inherit' }} target="blank" href={item.web_url}>
              {item.web_url}
            </a>
          </Typography>
        )}
        {isUber && (
          <Typography sx={{ color: 'gray', fontSize: '14px' }}>
            ID:{item?.store_id || item?.storeId || item?.id}
          </Typography>
        )}
      </Box>
    ),
    name: item.name,
  }));

  await sleep(300);

  let filteredOptions = [];
  if (!search) {
    filteredOptions = options;
  } else {
    const searchLower = search.toLowerCase();

    filteredOptions = options.filter(({ name }) => name.toLowerCase().includes(searchLower));
  }

  const hasMore = filteredOptions.length > prevOptions.length + 10;
  const slicedOptions = filteredOptions.slice(prevOptions.length, prevOptions.length + 10);

  return {
    options: slicedOptions,
    hasMore,
  };
};
