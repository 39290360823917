import React from 'react';
import { FormProvider } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import CustomSwitch from 'components/Forms/CustomSwitch';
import { StyledButtonWrapper, SwitcherWrapper } from '../styles';
// import { Divider, Box } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { SETTINGS_TABLE_OPTIONS } from '../hooks/useTableOrderingSettings';

const TakeOutForm = ({ formMethods, handleSubmit, loading = false, loadingSettings, selectedOptions }) => {
  const { t } = useTranslation();
  return (
    <>
      <FormProvider {...formMethods}>
        <form
          onSubmit={formMethods.handleSubmit(handleSubmit)}
          style={{
            width: '100%',
            mt: 3,
          }}
        >
          <SwitcherWrapper>
            <CustomSwitch
              name="isEnable"
              checked={formMethods.getValues()?.isEnable}
              label={t('table_ordering.takeout_switcher')}
            />
            {/*{formMethods.getValues()?.isEnable && (*/}
            {/*  <Box>*/}
            {/*    <Divider sx={{ mt: 2, mb: 2 }} />*/}
            {/*    <CustomSwitch*/}
            {/*      name="autoConfirm"*/}
            {/*      checked={formMethods.getValues()?.autoConfirm}*/}
            {/*      label={t('table_ordering.takeout_switcher_auto')}*/}
            {/*    />*/}
            {/*  </Box>*/}
            {/*)}*/}
            <StyledButtonWrapper>
              <LoadingButton
                loading={loading || (loadingSettings && selectedOptions === SETTINGS_TABLE_OPTIONS.TAKE_OUT)}
                type={'submit'}
                variant={'contained'}
                disabled={
                  !formMethods.formState.isDirty ||
                  loading ||
                  (loadingSettings && selectedOptions === SETTINGS_TABLE_OPTIONS.TAKE_OUT)
                }
              >
                {t('table_ordering.save')}
              </LoadingButton>
            </StyledButtonWrapper>
          </SwitcherWrapper>
        </form>
      </FormProvider>
    </>
  );
};
export default TakeOutForm;
