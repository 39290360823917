import React from 'react';
import { HeaderContainer, StripedDataGrid } from '../styles';
import { Button, Box } from '@mui/material';
import Preloader from 'components/Preloader';
import { useTranslation } from 'react-i18next';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import { MODE, MUI_TO_SX_STYLES } from 'pages/TableOrdering/config';
import TableOrderModal from 'pages/TableOrdering/Tables/TableOrderModal';
import CreateOrEditForm from 'pages/TableOrdering/Sections/CreateOrEditForm';
import ConfirmPromptModal from 'components/ConfirmPromptModal/ConfirmPromptModal';
import { useTableOrderingSections } from 'pages/TableOrdering/hooks/useTableOrderingSections';

const Sections = () => {
  const { t } = useTranslation();
  const {
    handleSubmit,
    handleCancel,
    formMethods,
    sectionColumns,
    sectionFilter,
    loading,
    open,
    setOpen,
    mode,
    rowsPerPage,
    setRowsPerPage,
    lastCreatedSection,
  } = useTableOrderingSections();

  return (
    <Box>
      <HeaderContainer>
        <ConfirmPromptModal
          handleConfirm={formMethods.handleSubmit(handleSubmit)}
          submitData={formMethods.getValues()}
          hasUnsavedChanges={formMethods.formState.isDirty}
          formState={formMethods}
        />
        <TableOrderModal
          open={open}
          handleClose={handleCancel}
          title={mode === MODE.CREAT ? t('table_ordering.add_section') : t('table_ordering.edit_section')}
          disabled={!formMethods.formState.isDirty || !formMethods.formState.isValid}
          saveTitle={mode === MODE.CREAT ? t('table_ordering.add_section') : t('table_ordering.save')}
          cancelTitle={'Cancel'}
          formMethods={formMethods}
          handleSubmit={handleSubmit}
          loading={loading}
        >
          <CreateOrEditForm />
        </TableOrderModal>
        <Button
          id="sectionsAdd"
          onClick={() => setOpen(true)}
          variant="contained"
          color="primary"
          type="button"
          disabled={open}
          className={`${open ? 'disabledButton' : ''}`}
          startIcon={<AddOutlinedIcon />}
        >
          {t('table_ordering.add_section')}
        </Button>
      </HeaderContainer>
      <StripedDataGrid
        sx={MUI_TO_SX_STYLES}
        autoHeight={true}
        hideFooterSelectedRowCount={true}
        getRowClassName={(params) => (params?.row?.id === lastCreatedSection ? 'highlight_last-selected_item' : '')}
        isEditable={false}
        rows={sectionFilter}
        rowsPerPageOptions={[100]}
        hideFooter={true}
        columns={sectionColumns}
        pageSize={rowsPerPage}
        onPageSizeChange={(newPageSize) => setRowsPerPage(newPageSize)}
        components={{
          LoadingOverlay: Preloader,
        }}
        loading={loading}
        localeText={{ noRowsLabel: t('table_ordering.empty_section') }}
      />
    </Box>
  );
};

export default Sections;
