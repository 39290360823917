import React, { useMemo, useEffect, useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
import * as yup from 'yup';
import DownloadIcon from '@mui/icons-material/Download';
import { toast } from 'react-toastify';
import { useForm, FormProvider } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { downloadsMenuByProvider, downloadsMenuDemo, patchSubscriptionPlan } from 'redux/actions/user';
import { getProviders, updateProvider } from 'redux/actions/providers';
import * as Statuses from 'constants/providerStatuses';
import LabelTooltip from 'components/FormFields/LabelTooltip';
import ChangeAdminModal from 'components/ChangeAdminModal';
import Grid from '@mui/material/Grid';
import CustomTextInput from 'components/Forms/CustomTextInput';
import Checkbox from '@mui/material/Checkbox';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Switch from '@mui/material/Switch';
import { PROVIDER_CONNECTED, PROVIDER_PENDING } from 'constants/providerStatuses';
import Typography from '@mui/material/Typography';
import MenuItem from '@mui/material/MenuItem';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import StyledDataGrid from 'components/DataGridTable';
import useQr from 'hooks/useQr';
import ErrorBoundaryWrapper from 'components/ErrorBoundaryWrapper';
import Preloader from 'components/Preloader';
import { CircularProgress, FormControl, Radio, RadioGroup, Select, TextField } from '@mui/material';
import { handelPaymentMarkAsPaid, handleSuspendAccount } from 'redux/actions/paymentNotification';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import { menuInitialSync } from 'redux/actions/Menu/menuV2';
import { isPermissionWithCompanyType } from 'hooks/useCompanyType';
import { CURRENT_SUBSCRIPTION_PLAN } from 'constants/user';
import { getBillingStatus, getDeviceActivate, setActivationCodesFlag } from 'redux/actions/business';
import moment from 'moment';
import BusinessSettings from 'pages/Settings/BusinessSettings/BusinessSettings';
import VibeCheckSettings from 'pages/Settings/VibeCheckSettings/VibeCheckSettings';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import MenuId from 'assets/icons/menu.svg';
import { confirm } from 'components/MenuNew/ConfirmModal';
import ConfirmPromptModal from 'components/ConfirmPromptModal/ConfirmPromptModal';
import useProviderConfig from 'hooks/useProviderConfig';
import BusinessDialog from 'components/Dialog/BusinessDialog';
import SuccessImage from 'assets/images/successprogress.png';
import DisputeSettings from 'pages/Settings/Dispute/DisputeSettings';
import ResumeSetUpLink from 'components/Settings/ResumeSetUpLink';
import ConfirmProductActions from 'components/ConfirmProductActions';
import { ModalOpenStatusEnum, SET_MODAL_ACTIONS, SET_PRODUCT_EVENTS } from 'components/ConfirmProductActions/constants';
const newPlans = ['Enterprise', 'Growth', 'Essential'];

const schemaAdminForm = yup.object().shape({
  provider: yup.string().required('field_is_required_'),
  status: yup.string().required('field_is_required_'),
  state: yup.string().required('field_is_required_'),
});

const schemaSubscriptionPlanForm = yup.object().shape({
  subscriptionPlan: yup.string().required('field_is_required_'),
});

const MakeAsPaid = () => {
  const { t } = useTranslation();

  const handleCleanHistory = async () => {
    const confirmed = await confirm({
      title: 'confirm_modal.mark_as_paid',
      message: t('settings.do_you_want_paid?'),
      cancelText: t('orders.cancel'),
      confirmText: t('website_settings.update'),
      confirmColor: 'primary',
    });
    if (confirmed) {
      handelPaymentMarkAsPaid();
    }
  };

  return (
    <Typography
      onClick={handleCleanHistory}
      variant="text"
      size="small"
      sx={{
        fontSize: 16,
        ml: 3,
        color: '#2D7FF9',
        textDecoration: 'underline',
        cursor: 'pointer',
      }}
    >
      {t('settings.mark_as_paid')}
    </Typography>
  );
};

const Admin = () => {
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const location = useLocation();
  const { providers: providersConfig } = useProviderConfig();
  const { isTypeSingle } = isPermissionWithCompanyType();
  const [rows, setRows] = useState([]);
  const [loadingMenu, setLoadingMenu] = useState(false);
  const [deviceActivityRow, setDeviceActivityRow] = useState([]);
  const [menuId, setMenuId] = useState('');
  const { loading, qr } = useQr(true);
  const [paymentNotification, setPaymentNotification] = useState(false);
  const [isSuspense, setIsSuspense] = useState('suspend1');
  const [applied, setApplied] = useState(false);
  const [suspendAfter, setSuspendAfter] = useState(0);
  const [checked, setChecked] = useState(null);
  const [billingStatus, setBillingStatus] = useState(null);
  const [isDirtyPaymentNot, setIsDirtyPaymentNot] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const {
    user,
    providers,
    business: { currentBusiness, isBrandLoading },
  } = useSelector(({ user, providers, business }) => ({
    user: user.data,
    providers: providers,
    business: business,
  }));
  const userData = useSelector((state) => state.user);
  const { productEvents } = useSelector((store) => store);
  const subscriptionPlans = userData.subscriptionPlans;
  const currentSubscriptionPlan = userData.currentSubscriptionPlan;
  const businessBundle = currentBusiness.billingInfo?.plan?.name;

  const defaultValuesSubscription = useMemo(
    () => ({
      subscriptionPlan: currentSubscriptionPlan?._id || '',
    }),
    [user, currentSubscriptionPlan]
  );

  const adminFormMethods = useForm({
    defaultValues: {
      state: '',
      provider: '',
      status: '',
      verified: user.verified,
    },
    mode: 'all',
    resolver: yupResolver(schemaAdminForm),
  });

  const subscriptionPlanModel = useForm({
    defaultValues: defaultValuesSubscription,
    mode: 'all',
    resolver: yupResolver(schemaSubscriptionPlanForm),
  });

  const getBilling = () => {
    dispatch(getBillingStatus(currentBusiness.id)).then((res) => {
      setBillingStatus(res?.data?.data);
    });
  };

  useEffect(() => {
    getBilling();
  }, [suspendAfter]);

  useEffect(() => {
    subscriptionPlanModel.reset(
      {
        subscriptionPlan: currentSubscriptionPlan?._id,
      },
      { isDirty: false }
    );
  }, [user, currentSubscriptionPlan]);

  const handleCloseModal = () => setOpenModal(false);

  const handleClickDownload = (value) => {
    setOpenModal(true);
    if (value === 'demo') {
      setLoadingMenu(true);
      dispatch(downloadsMenuDemo({ restaurantMailbox: currentBusiness.restaurantMailbox, menuId }))
        .then((res) => {
          if (res.data?.events?.length) {
            dispatch({ type: SET_PRODUCT_EVENTS, payload: res?.data?.events });
            dispatch({ type: SET_MODAL_ACTIONS, payload: ModalOpenStatusEnum.opened });
          }

          if (res.data.status === 'SUCCESS') {
            setMenuId('');
            setLoadingMenu(false);
          } else {
            setLoadingMenu(false);
          }
        })
        .finally(() => {
          setLoadingMenu(false);
        });
    } else {
      dispatch(downloadsMenuByProvider(value));
    }
  };
  const handleChangeMenuId = (e) => {
    setMenuId(e.target.value);
  };

  const handleProvider = (values) => {
    dispatch(updateProvider({ ...values, id: providers.providerId })).then((data) => {
      dispatch(getProviders());
      adminFormMethods.reset({ ...values, id: providers.providerId }, { keepDirty: false });
    });
  };

  const handleSubscriptionPlan = async (values) => {
    const confirmed = await confirm({
      title: t('settings.change_plan'),
      message: t('settings.are_you_sure_subscription'),
      cancelText: t('orders.cancel'),
      confirmText: t('settings.change'),
      confirmColor: 'primary',
    });
    if (confirmed) {
      const findSelectPlan = subscriptionPlans?.find((plan) => plan._id === values.subscriptionPlan);
      const body = {
        planId: values.subscriptionPlan,
        planValue: findSelectPlan?.value,
      };
      patchSubscriptionPlan(body).then((res) => {
        if (res.status === 200) {
          dispatch({ type: CURRENT_SUBSCRIPTION_PLAN, payload: findSelectPlan });
          subscriptionPlanModel.reset({
            subscriptionPlan: values.subscriptionPlan,
          });
          // dispatch(getBrandList());
        }
      });
    } else {
      subscriptionPlanModel.reset({
        subscriptionPlan: currentSubscriptionPlan?._id,
      });
    }
  };
  const resetPaymentSettings = () => {
    setPaymentNotification(false);
    setIsSuspense(isSuspense);
    setApplied(false);
  };

  useEffect(() => {
    if (currentBusiness) {
      getDeviceActivate(currentBusiness._id).then((res) => {
        setDeviceActivityRow(res.data.data);
        const rowData = [];
        res.data.data?.forEach((item) => {
          rowData.push({ id: item.id, deviceActivationIsRequired: item.deviceActivationIsRequired });
        });
        setChecked(rowData);
      });
    }
  }, [currentBusiness]);

  useEffect(() => {
    if (currentBusiness) {
      setSuspendAfter(currentBusiness.settings.paymentNotification.suspendAfter || 0);
      setPaymentNotification(!currentBusiness.settings.paymentNotification.ignoreIssues);
      if (currentBusiness.settings.paymentNotification.suspendAccount) {
        setIsSuspense('suspend2');
      } else {
        setIsSuspense('suspend1');
      }
    }
    return () => {
      resetPaymentSettings();
    };
  }, [currentBusiness]);

  useEffect(() => {
    const newList = providers.items
      .map((item) => ({ ...item, id: item.name || uuidv4() }))
      .filter((item) => item.srv !== providersConfig.postmates.srv);

    // newList.forEach((item) => {
    //   if (item.srv === providersConfig.ubereats.srv) {
    //     item.name = `${item.name}/Postmates`;
    //   }
    // });
    setRows([
      ...newList.filter((item) => item.id),
      { name: t('settings.menu_from_demo'), srv: 'demo', status: '', id: uuidv4() },
    ]);
  }, [providers]);

  // useEffect(() => {
  //   if (rows.length) {
  //     setRows(
  //       rows.filter((item) => {
  //         if (status && item.srv === provider && isSubmitted) {
  //           item.status = status;
  //         }
  //         return item.id;
  //       })
  //     );
  //   }
  // }, [provider, status, isSubmitted]);
  const downloadIsDisabled = (row) => {
    if (
      ((row.state === Statuses.PROVIDER_STATE_ACTIVE || row.state === Statuses.PROVIDER_STATE_PENDING) &&
        (row.status === PROVIDER_PENDING || row.status === PROVIDER_CONNECTED)) ||
      (row.srv === 'demo' && menuId?.length)
    ) {
      return false;
    } else {
      return true;
    }
  };

  const handleChange = useCallback(
    (_, data) => {
      checked?.forEach((item) => {
        if (item.id === data.id) {
          item.deviceActivationIsRequired = !item.deviceActivationIsRequired;
        }
      });
      setChecked(checked);

      const reqData = {
        locationId: currentBusiness?.locationId || '',
        deviceId: data.deviceId,
        deviceActivationIsRequired: !data.deviceActivationIsRequired,
      };

      setActivationCodesFlag(reqData).then(() => {
        getDeviceActivate(currentBusiness._id).then((res) => {
          setDeviceActivityRow(res.data.data);
          const rowData = [];
          res.data.data?.forEach((item) => {
            rowData.push({ id: item.id, deviceActivationIsRequired: item.deviceActivationIsRequired });
          });
          setChecked(rowData);
        });
        toast.success('Successfully updated.');
      });
    },
    [checked, currentBusiness._id, currentBusiness?.locationId]
  );

  const initialSync = () => {
    setOpenModal(true);
    dispatch(menuInitialSync());
  };

  const columns = useMemo(
    () => [
      {
        field: 'name',
        headerName: t('batch_report.name'),
        flex: 1,
        sortable: false,
        filterable: false,
        editable: false,
        disableColumnMenu: true,
        renderCell: ({ value, row }) => {
          return (
            <>
              <Box sx={{ marginRight: 2 }}>
                {row.srv !== 'demo' ? (
                  <img
                    src={providersConfig[row.srv]?.logoUrl}
                    alt="provider"
                    width="30"
                    height="30"
                    className="rounded"
                  />
                ) : (
                  <img src={MenuId} alt="provider" />
                )}
              </Box>
              {/* {value} */}
              {value === 'Menu from Demo' ? value : `${providersConfig[row.srv]?.label} (${row.srv})`}
              {/* {value} */}
              {/* {`${providersConfig[row.srv]?.label} (${row.srv})`} */}
            </>
          );
        },
      },
      {
        field: 'status',
        headerName: t('settings.status'),
        flex: 1,
        sortable: false,
        editable: false,
        filterable: false,
        disableColumnMenu: true,
        renderCell: ({ value }) => {
          if (!value) {
            return (
              <Box sx={{ mt: 1.5 }}>
                <TextField size="small" placeholder={t('settings.menu_id')} name="" onChange={handleChangeMenuId} />
              </Box>
            );
          }

          return <b className="text-muted">{t(Statuses.getProviderStatus(value))}</b>;
        },
      },
      {
        field: 'state',
        headerName: t('settings.state'),
        editable: false,
        flex: 1,
        sortable: false,
        filterable: false,
        renderCell: ({ value }) => <b className="text-muted">{t(Statuses.getProviderState(value))}</b>,
      },
      {
        field: 'downloads',
        headerName: t('settings.downloads'),
        editable: false,
        flex: 1,
        sortable: false,
        filterable: false,
        renderCell: ({ row }) => {
          return (
            <>
              <Button
                onClick={() => handleClickDownload(row.srv)}
                size="small"
                disabled={
                  downloadIsDisabled(row) ||
                  row?.srv === 'food2' ||
                  row?.srv === 'table_ordering' ||
                  (loadingMenu && row.srv === 'demo')
                }
                variant="contained"
                startIcon={<DownloadIcon />}
              >
                {t('settings.downloads')}
              </Button>
              {loadingMenu && row.srv === 'demo' && <CircularProgress sx={{ ml: 1 }} size={20} />}
            </>
          );
        },
      },
      {
        field: 'Initial menu update',
        headerName: t('settings.initial_menu_update'),
        editable: false,
        flex: 1,
        sortable: false,
        filterable: false,
        renderCell: ({ row }) => {
          return row.srv === 'doordash' ? (
            <Button
              onClick={() => initialSync()}
              size="small"
              disabled={!row.connected || row.status !== PROVIDER_CONNECTED}
              variant="contained"
              startIcon={<FileUploadIcon />}
            >
              {t('settings.initial_menu_update')}
            </Button>
          ) : (
            ''
          );
        },
      },
    ],
    [providersConfig, menuId]
  );

  const column = useMemo(() => [
    {
      field: 'deviceId',
      headerName: t('activation_code.deviceId'),
      flex: 1,
    },
    {
      field: 'deviceActivationCode',
      headerName: t('activation_code.device_activation_code'),
      flex: 1,
    },
    {
      field: 'activityTime',
      headerName: t('activation_code.last_active'),
      flex: 1,
      disableColumnMenu: true,
      renderCell: ({ value }) => {
        return (
          <Box>
            <Typography>
              {value?.length
                ? moment.utc(value).tz(currentBusiness.timezone?.timeZoneId)?.format('MM/DD/YY hh:mm:ss A')
                : ''}
            </Typography>
          </Box>
        );
      },
    },
    {
      field: 'deviceActivationIsRequired',
      headerName: t('activation_code.activation'),
      flex: 1,
      renderCell: ({ row }) => {
        const findDeviceActivationIsRequired = checked?.find((item) => item.id === row.id)?.deviceActivationIsRequired;
        return (
          <Box>
            <Switch
              disabled={!row?.deviceActivationCode}
              checked={!findDeviceActivationIsRequired}
              sx={{
                opacity: !row?.deviceActivationCode ? 0.5 : 1,
                '.Mui-checked.Mui-disabled': {
                  color: '#8888885e',
                },
                '.Mui-checked+.MuiSwitch-track': {
                  backgroundColor: '#888',
                },
              }}
              onChange={(e) => handleChange(e, row)}
              inputProps={{ 'aria-label': 'controlled' }}
            />
          </Box>
        );
      },
    },
  ]);

  const handleChangePaymentNotification = useCallback(({ target }) => {
    const { checked } = target;
    if (!checked) {
      handleSuspendAccount({
        suspendAccount: false,
        ignoreIssues: !checked,
      });
      // dispatch(getBrandList());
    }
    setIsSuspense('suspend1');
    setSuspendAfter(0);
    setPaymentNotification(checked);
    setIsDirtyPaymentNot(true);
  }, []);

  const handleChangeSuspense = ({ target }) => {
    setIsSuspense(target.value);
    setApplied(false);
    setIsDirtyPaymentNot(true);
  };

  const handleSubmitSuspenseAccount = () => {
    const dataObj = {
      suspendAccount: isSuspense === 'suspend1' ? false : true,
      ignoreIssues: false,
    };
    if (isSuspense === 'suspend2') {
      dataObj.suspendAfter = suspendAfter;
      dataObj.unit = 'days';
    }

    handleSuspendAccount(dataObj)
      .then(() => {
        getBilling();
        // dispatch(getBrandList());
        setApplied(true);
      })
      .finally(() => {
        setApplied(true);
        setIsDirtyPaymentNot(false);
      });
  };

  const handleChangeSuspendAfter = ({ target }) => {
    setSuspendAfter(target.value);
    setIsDirtyPaymentNot(true);
  };

  const dataObjSubmitData = {
    suspendAccount: isSuspense === 'suspend1' ? false : true,
    ignoreIssues: false,
  };

  return (
    <ErrorBoundaryWrapper>
      {productEvents.eventData[0]?.tag === 'menuDownload' && (
        <ConfirmProductActions formData={null} handleSubmit={() => {}} />
      )}
      <Grid container spacing={4} columns={{ xs: 12 }}>
        <Grid item xs={6}>
          <Typography variant="h6" sx={{ mb: 2 }}>
            {t('settings.update_provider_status')}
          </Typography>
          {/* <h5 className="mb-4">Update provider status</h5> */}
          <FormProvider {...adminFormMethods}>
            <ConfirmPromptModal
              handleConfirm={adminFormMethods.handleSubmit(handleProvider)}
              submitData={adminFormMethods.getValues()}
              hasUnsavedChanges={adminFormMethods.formState.isDirty}
              formState={adminFormMethods}
            />
            <form onSubmit={adminFormMethods.handleSubmit(handleProvider)}>
              <CustomTextInput
                sx={{ width: '100%', height: '72px' }}
                select={true}
                name="provider"
                label={t('orders_history.provider')}
              >
                <MenuItem value="" key="">
                  {t('settings.select_provider')}
                </MenuItem>
                {providers.items.map(({ srv, name, hidden }) => {
                  return !hidden ? (
                    <MenuItem value={srv} key={`${srv}-${name}`}>
                      {`${providersConfig[srv]?.label} (${srv})`}
                    </MenuItem>
                  ) : null;
                })}
              </CustomTextInput>
              <CustomTextInput
                sx={{ width: '100%', height: '72px' }}
                select={true}
                name="status"
                label={t('settings.status')}
              >
                <MenuItem value="" key="">
                  {t('settings.select_status')}
                </MenuItem>
                <MenuItem value={Statuses.PROVIDER_NO_CONNECTED}>{t('settings.not_connected')}</MenuItem>
                <MenuItem value={Statuses.PROVIDER_CONNECTED}>{t('settings.connected')}</MenuItem>
                <MenuItem value={Statuses.PROVIDER_PENDING}>{t('settings.pending_connect')}</MenuItem>
                <MenuItem value={Statuses.PROVIDER_PENDING_DISCONNECT}> {t('settings.pending_disconnect')}</MenuItem>
              </CustomTextInput>

              <CustomTextInput sx={{ width: '100%', mb: 2 }} select={true} name="state" label={t('settings.state')}>
                <MenuItem value="" key="">
                  {t('settings.select_state')}
                </MenuItem>
                <MenuItem value={Statuses.PROVIDER_STATE_ACTIVE}>{t('settings.active')}</MenuItem>
                <MenuItem value={Statuses.PROVIDER_STATE_INACTIVE}>{t('settings.inactive')}</MenuItem>
                <MenuItem value={Statuses.PROVIDER_STATE_PENDING}>{t('settings.pending')}</MenuItem>
                <MenuItem value={Statuses.PROVIDER_STATE_PENDING_INACTIVE}>{t('settings.pending_inactive')}</MenuItem>
              </CustomTextInput>

              <FormGroup name="enabled">
                <FormControlLabel
                  control={<Checkbox />}
                  label={<LabelTooltip id="isActiveTooltip" value={t('settings.send_email')} tooltip="Send email" />}
                />
              </FormGroup>

              <Button
                id="adminSettingsSave"
                variant="contained"
                type="submit"
                color="primary"
                disabled={true}
                sx={{ width: '100%', maxWidth: '50%' }}
              >
                {t('settings.save')}
              </Button>
            </form>
          </FormProvider>
        </Grid>

        <Grid item xs={6} sx={{ display: 'flex', alignItems: 'flex-start', flexWrap: 'wrap' }}>
          {qr && businessBundle !== 'App Bundle' && (
            <Box sx={{ width: '200px', position: 'relative' }}>
              <Typography variant="h6" sx={{ mb: 2 }}>
                {t('website_settings.qr_code')}
              </Typography>
              {loading ? (
                <Preloader overlay={true} />
              ) : qr ? (
                <img src={qr} alt="Live Chat" style={{ width: '100%' }} />
              ) : (
                <></>
              )}
            </Box>
          )}

          <Box sx={{ display: 'flex', justifyContent: 'center', ml: 1 }} className="admin-disabled">
            <ChangeAdminModal loading={isBrandLoading} />
          </Box>
        </Grid>
      </Grid>

      <Paper>
        <Box sx={{ my: 2 }}>
          <StyledDataGrid
            getRowId={(row) => row.id}
            autoHeight={true}
            hideFooterPagination={true}
            hideFooterSelectedRowCount={true}
            isEditable={false}
            rows={rows}
            columns={columns}
          />
        </Box>
      </Paper>
      {currentBusiness && isTypeSingle && (
        <>
          <Grid container>
            <Grid item xs={6}>
              <Typography sx={{ fontSize: 20, fontWeight: 'bold', mb: 2 }}>
                {t('settings.payment_settings')}{' '}
              </Typography>
              {billingStatus && (
                <Grid item xs={12} sx={{ display: 'flex', mb: 4, flexDirection: 'column' }}>
                  {/* <Typography>{t('settings.billing_status')} - </Typography> */}

                  <Typography>
                    <Box sx={{ fontWeight: 'bold' }} component={'spam'}>
                      {t('settings.billing_status')} -
                    </Box>{' '}
                    {billingStatus.paid ? t('order_view.paid') : t('order_view.unpaid')}
                  </Typography>
                  {!billingStatus.paid && (
                    <Box>
                      {billingStatus.message && (
                        <Typography>
                          <Box sx={{ fontWeight: 'bold' }} component={'spam'}>
                            {t('order_view.message_for_custumer')} -{' '}
                          </Box>
                          {billingStatus.message}
                        </Typography>
                      )}
                      {billingStatus.level && (
                        <Typography>
                          <Box sx={{ fontWeight: 'bold' }} component={'spam'}>
                            {t('order_view.level')} -{' '}
                          </Box>
                          {billingStatus.level}
                        </Typography>
                      )}
                    </Box>
                  )}
                </Grid>
              )}
              <Grid item xs={12} sx={{ display: 'flex', alignItems: 'center' }}>
                <ConfirmPromptModal
                  handleConfirm={handleSubmitSuspenseAccount}
                  notHookForm={true}
                  submitData={
                    isSuspense === 'suspend2'
                      ? { ...dataObjSubmitData, suspendAfter: suspendAfter, unit: 'days' }
                      : dataObjSubmitData
                  }
                  hasUnsavedChanges={isDirtyPaymentNot}
                  formState={adminFormMethods}
                />
                <FormGroup
                  checked={paymentNotification}
                  onChange={handleChangePaymentNotification}
                  name={`suspendAccount`}
                >
                  <FormControlLabel
                    control={<Switch checked={paymentNotification} />}
                    label={t('settings.payment_notification')}
                  />
                </FormGroup>
                {paymentNotification && <MakeAsPaid />}
              </Grid>
              {paymentNotification && (
                <>
                  <Grid item xs={12}>
                    <FormControl sx={{ width: 'max-content' }}>
                      <RadioGroup value={isSuspense} onChange={handleChangeSuspense} name="radio-buttons-group">
                        <FormControlLabel
                          value={'suspend1'}
                          control={<Radio />}
                          label={<Box>{t('settings.show_pop-up_failed')}</Box>}
                        />

                        <FormControlLabel
                          value={'suspend2'}
                          control={<Radio />}
                          label={
                            <Box sx={{ display: 'flex', whiteSpace: 'nowrap', alignItems: 'center' }}>
                              {t('settings.suspend_operations')}
                              <FormControl fullWidth>
                                <Select
                                  sx={{ ml: 2 }}
                                  size="small"
                                  value={suspendAfter}
                                  // label="Days"
                                  onChange={handleChangeSuspendAfter}
                                >
                                  <MenuItem value={0}>{t('settings.now')}</MenuItem>
                                  <MenuItem value={1}>{t('settings.in_1_day')}</MenuItem>
                                  <MenuItem value={2}>{t('settings.in_2_day')}</MenuItem>
                                  <MenuItem value={3}>{t('settings.in_3_day')}</MenuItem>
                                  <MenuItem value={4}>{t('settings.in_4_day')}</MenuItem>
                                </Select>
                              </FormControl>
                            </Box>
                          }
                        />
                      </RadioGroup>
                    </FormControl>
                  </Grid>

                  <Grid item xs={12}>
                    <Button id="adminApply" onClick={handleSubmitSuspenseAccount} variant="contained" color="primary">
                      {t('orders_history.apply')}
                    </Button>
                  </Grid>
                </>
              )}
            </Grid>
            {/* {openModal && (
              <BusinessDialog
                handleClose={handleCloseModal}
                icon={SuccessImage}
                open={openModal}
                description={t('settings.success_message')}
                title={t('settings.success')}
                isAdmin={true}
              />
            )} */}
            <Grid item xs={6}>
              <Grid item xs={12}>
                <Typography sx={{ fontSize: 20, fontWeight: 'bold', mb: 1.5 }}>{t('settings.change_plan')}</Typography>
                <Typography sx={{ fontSize: 16, fontWeight: '500', color: '#6E757C', mb: 2.5 }}>
                  {t('settings.please_select_subscription_change')}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <FormProvider {...subscriptionPlanModel}>
                  <ConfirmPromptModal
                    handleConfirm={subscriptionPlanModel.handleSubmit(handleSubscriptionPlan)}
                    submitData={subscriptionPlanModel.getValues()}
                    hasUnsavedChanges={subscriptionPlanModel.formState.isDirty}
                    formState={subscriptionPlanModel}
                  />
                  <form onSubmit={subscriptionPlanModel.handleSubmit(handleSubscriptionPlan)}>
                    <CustomTextInput
                      sx={{ width: '100%', mb: 2 }}
                      select={true}
                      name="subscriptionPlan"
                      label={t('settings.select_plan')}
                    >
                      {subscriptionPlans.map(({ name, _id, type }) => (
                        <MenuItem value={_id} key={_id}>
                          <Box sx={{ display: 'flex', alignItems: 'center ' }}>
                            {name}
                            {newPlans.includes(name) && type === 'addon' && (
                              <Typography sx={{ ml: 0.5, fontSize: '14px' }}>
                                ({t('settings.additional_brand')})
                              </Typography>
                            )}
                          </Box>
                        </MenuItem>
                      ))}
                    </CustomTextInput>
                    <Button
                      id="adminSave"
                      sx={{
                        maxWidth: '192px',
                        width: '100%',
                        p: 1,
                        mt: 5,
                        fontSize: '16px',
                        '&.Mui-disabled': {
                          color: '#fff',
                        },
                      }}
                      type="submit"
                      disabled={!subscriptionPlanModel?.formState?.isDirty}
                      variant="contained"
                      color="primary"
                    >
                      {t('settings.save')}
                    </Button>
                  </form>
                </FormProvider>
              </Grid>
            </Grid>
          </Grid>
          <Box sx={{ mt: 5 }}>
            <Typography sx={{ fontSize: 20, fontWeight: 'bold', mb: 3 }}>
              {t('activation_code.deviceFooter')}
            </Typography>
            <StyledDataGrid
              getRowId={(row) => row.id}
              autoHeight={true}
              hideFooterPagination={true}
              hideFooterSelectedRowCount={true}
              rows={deviceActivityRow}
              columns={column}
            />
          </Box>
          <Box sx={{ mt: 5 }}>
            <BusinessSettings />
          </Box>
          <Box sx={{ mt: 5 }}>
            <VibeCheckSettings />
          </Box>
          <Box sx={{ mt: 5 }}>
            <DisputeSettings />
          </Box>
          {currentBusiness?.signUpProgress && currentBusiness?.signUpProgress?.step !== 'billing' ? (
            <Box sx={{ mt: 5 }}>
              <ResumeSetUpLink />
            </Box>
          ) : (
            <></>
          )}
        </>
      )}
    </ErrorBoundaryWrapper>
  );
};

export default Admin;
