import React, { useEffect, useState } from 'react';

const OnBoarding = () => {
  const [lastSelected] = useState(JSON.parse(localStorage.getItem('businessInfo')));
  useEffect(() => {
    if (lastSelected?.id) {
      localStorage.setItem('businessId', lastSelected?.id);
    }
    document.body.style.overflow = 'hidden';
    return () => {
      document.body.style.overflow = 'auto';
    };
  }, [lastSelected?.id]);

  return (
    <div className="embed-responsive embed-responsive-16by9">
      <iframe
        className="embed-responsive-item"
        src={`http://66.175.236.109:2003/?businessId=${lastSelected?.id}`}
        allowFullScreen
        title={'OnBoarding'}
      ></iframe>
    </div>
  );
};

export default OnBoarding;
