import { convertBrand } from '../components/Forms/convertBrand';

export const convertForMaterialTable = (list) => {
  if (list && list.length > 0) {
    return list.map((item) => ({
      label: `${item?.companyName}-${item?.addressInfo?.address1 || item?.addressInfo?.address}`,
      brandId: item.brandId,
      type: item.type,
      id: item?._id,
    }));
  }
  return [];
};

export const convertToCorrectBusinessGroupFormat = (businessGroups) => {
  return businessGroups.map((item) => ({
    ...item,
    value: item._id,
    label: item.name,
  }));
};

export const createLocationsFromUnfilteredBrands = (list) => {
  const data = [];
  if (list.length > 0) {
    list.map((item) => {
      if (item.locations && item?.locations.length > 0) {
        item?.locations.map((loc) => {
          data.push({
            value: loc._id,
            label: `${loc?.locationName} \n(${loc?.address})`,
            name: loc.locationName,
            companyName: loc?.companyName || '',
            address: loc?.address || '',
            businessId: loc?.businessId || '',
            brandId: item._id || '',
          });
        });
      }
    });
  }

  return data;
};

const mergeAndFilterBrands = (allUnfilteredBrands, filteredBrands) => {
  const filteredBrandIds = new Set(filteredBrands.map((brand) => brand.value._id));
  const filteredAllUnfilteredBrands = allUnfilteredBrands.filter((brand) => !filteredBrandIds.has(brand.value._id));

  const combinedBrands = [...filteredAllUnfilteredBrands, ...filteredBrands];
  const uniqueBrandsMap = new Map();
  combinedBrands.forEach((brand) => {
    uniqueBrandsMap.set(brand.value._id, brand);
  });
  return Array.from(uniqueBrandsMap.values());
};

const mergeAndFilterLocations = (allUnFilteredLocations, filteredLocations) => {
  const filteredBrandIds = new Set(filteredLocations.map((location) => location.value));
  const filteredAllUnfilteredBrands = allUnFilteredLocations.filter(
    (location) => !filteredBrandIds.has(location.value)
  );

  const combinedLocations = [...filteredAllUnfilteredBrands, ...filteredLocations];
  const uniqueLocationsMap = new Map();
  combinedLocations.forEach((location) => {
    uniqueLocationsMap.set(location.value, location);
  });
  return Array.from(uniqueLocationsMap.values());
};

export const handleBusinessGroupChange = (selectedBusinessGroups, allLocations, allBrands, allBrandList) => {
  const allUnfilteredBrands = convertBrand(allBrandList);
  const allUnFilteredLocations = createLocationsFromUnfilteredBrands(allBrandList);
  let filteredLocations, filteredBrands;
  const selectedBusinessIds = [...new Set(selectedBusinessGroups.flatMap((group) => group.businessIds))];
  if (selectedBusinessGroups.length > 0) {
    filteredLocations = allUnFilteredLocations.filter((location) => selectedBusinessIds.includes(location.businessId));
    const filteredLocationsBrandIds = [...new Set(filteredLocations.flatMap((location) => location.brandId))];
    filteredBrands = allUnfilteredBrands.filter((item) => filteredLocationsBrandIds.includes(item.value._id));

    const allBrandsDependsOnBusinessGroup = mergeAndFilterBrands(allUnfilteredBrands, filteredBrands);
    const allLocationsDependsOnBusinessGroup = mergeAndFilterLocations(allUnFilteredLocations, filteredLocations);

    return {
      filteredLocations: filteredLocations.length ? filteredLocations : allLocations,
      filteredBrands: filteredBrands.length ? filteredBrands : allBrands,
      allLocationsDependsOnBusinessGroup:
        filteredLocations && filteredLocations.length ? allLocationsDependsOnBusinessGroup : allLocations,
      allBrandsDependsOnBusinessGroup:
        filteredBrands && filteredBrands.length ? allBrandsDependsOnBusinessGroup : allBrands,
    };
  }
  return {
    filteredLocations: allLocations,
    filteredBrands: allBrands,
    allLocationsDependsOnBusinessGroup: allLocations,
    allBrandsDependsOnBusinessGroup: allBrands,
  };
};
