import React, { useEffect, useState } from 'react';
import { Box } from '@mui/material';
import '../../styles/pages/_filter.scss';
import { useDispatch, useSelector } from 'react-redux';
import {
  COLLECT_BRAND_BUSINESS_IDS_FOR_REPORT,
  COLLECT_BUSINESS_IDS_FOR_REPORT,
  SET_CURRENT_SELECT_BRANDS,
  SET_CURRENT_SELECT_LOCATIONS,
  SET_SELECTED_BRANDS,
  SET_SELECTED_LOCATIONS,
  ALL_BRANDS_DEPENDS_ON_BUSINESS_GROUP,
  ALL_LOCATIONS_DEPENDS_ON_BUSINESS_GROUP,
} from 'redux/actions/types';
import { MultiSelect } from 'react-multi-select-component';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { LabelName } from 'pages/Report/styles';
import { useTranslation } from 'react-i18next';
import { handleBusinessGroupChange } from '../../utils/business';

export default function BusinessGroup({ businessGroups, allLocations, allBrands }) {
  const dispatch = useDispatch();
  const [selected, setSelected] = useState([]);
  const { allBrandList } = useSelector(({ business }) => business);
  const { t } = useTranslation();

  const setIdsForReport = (locations, brands) => {
    const idsBrandReport = [];
    const idsLocationReport = [];
    brands.forEach((item) => {
      if (item?.value) {
        return idsBrandReport.push(item?.value?._id);
      }
    });
    locations.forEach((item) => {
      if (item?.value) {
        return idsLocationReport.push(item?.value);
      }
    });
    dispatch({ type: COLLECT_BRAND_BUSINESS_IDS_FOR_REPORT, payload: idsBrandReport });
    dispatch({ type: COLLECT_BUSINESS_IDS_FOR_REPORT, payload: idsLocationReport });
  };

  const handleMultiSelectChange = (option) => {
    const {
      filteredLocations,
      filteredBrands,
      allBrandsDependsOnBusinessGroup,
      allLocationsDependsOnBusinessGroup,
    } = handleBusinessGroupChange(option, allLocations, allBrands, allBrandList);
    setIdsForReport(filteredLocations, filteredBrands);
    setSelected(option);
    dispatch({ type: SET_SELECTED_BRANDS, payload: filteredBrands });
    dispatch({ type: SET_SELECTED_LOCATIONS, payload: filteredLocations });
    dispatch({ type: SET_CURRENT_SELECT_BRANDS, payload: filteredBrands });
    dispatch({ type: SET_CURRENT_SELECT_LOCATIONS, payload: filteredLocations });
    dispatch({ type: ALL_BRANDS_DEPENDS_ON_BUSINESS_GROUP, payload: allBrandsDependsOnBusinessGroup });
    dispatch({ type: ALL_LOCATIONS_DEPENDS_ON_BUSINESS_GROUP, payload: allLocationsDependsOnBusinessGroup });
  };

  const customValueRenderer = (selected, _options) => {
    if (selected?.length) {
      const name = selected[0]?.name;
      const extractName = name.length > 15 ? `${name.slice(0, 15)}...` : name;
      if (selected?.length > 1) {
        return `${extractName} (+${selected?.length - 1} Other)`;
      } else return extractName;
    }
  };

  useEffect(() => {
    return () => {
      setIdsForReport(allLocations, allBrands);
      dispatch({ type: SET_SELECTED_BRANDS, payload: allBrands });
      dispatch({ type: SET_SELECTED_LOCATIONS, payload: allLocations });
      dispatch({ type: SET_CURRENT_SELECT_BRANDS, payload: allBrands });
      dispatch({ type: SET_CURRENT_SELECT_LOCATIONS, payload: allLocations });
      dispatch({ type: ALL_BRANDS_DEPENDS_ON_BUSINESS_GROUP, payload: allBrands });
      dispatch({ type: ALL_LOCATIONS_DEPENDS_ON_BUSINESS_GROUP, payload: allLocations });
    };
  }, []);

  return (
    <Box
      id="business-group"
      sx={{
        '#business-group-checkbox': { paddingY: '8px !important', paddingLeft: '12px !important' },
        minWidth: '260px',
      }}
    >
      <LabelName
        id="business-group-checkbox-label"
        sx={{
          backgroundColor: 'white',
          paddingX: '8px',
        }}
      >
        {t('reports_settings.business_group')}
      </LabelName>
      <MultiSelect
        options={businessGroups}
        hasSelectAll={true}
        value={selected}
        debounceDuration={300}
        ArrowRenderer={() => <ArrowDropDownIcon color="action" />}
        valueRenderer={customValueRenderer}
        onChange={handleMultiSelectChange}
        labelledBy={t('reports_settings.business_group')}
      />
    </Box>
  );
}
