import React from 'react';
import Box from '@mui/material/Box';
import { useHistory } from 'react-router-dom';
import ProductForm from '../FormsView/ProductForm/index';
export const FormCategoryPage = () => {
  return (
    <Box sx={{ background: 'white' }}>
      <ProductForm />
    </Box>
  );
};

export default FormCategoryPage;
