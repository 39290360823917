import { useHistory } from 'react-router-dom';
import instance from '../services/api';
import { checkAccessDenied } from 'utils/checkAccessDenied';

const baseURL = process.env.REACT_APP_API_BASE_URL;

const SetupInterceptorAccessDenied = () => {
  let history = useHistory();
  instance.interceptors.response.use(
    function (response) {
      return response;
    },
    function (error) {
      var status = error.response?.status;
      var resBaseURL = error.response?.config?.baseURL;
      if (resBaseURL === baseURL && status === 403) {
        checkAccessDenied(history);
      }
      return Promise.reject(error);
    }
  );
};

export default SetupInterceptorAccessDenied;
