import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Dialog } from '@mui/material';
import {
  DialogActionsImg,
  DialogActionsProductBox,
  DialogActionsProductButton,
  DialogActionsProductConfirmButton,
  DialogActionsTypography,
  DialogContentBox,
  DialogPageProps,
  DialogTextProvider,
  DialogTextSubProvider,
} from './styles';
import DraggableDialogWrapper from './DraggableDialogWrapper';
import {
  ButtonTexts,
  EventActionEnum,
  EventServiceSourceEnum,
  EventStatusesEnum,
  EventTypeIcons,
  ModalOpenStatusEnum,
  SET_MODAL_ACTIONS,
  SET_PRODUCT_EVENTS,
  SET_PRODUCT_EVENTS_NEXT_LOCATION,
} from './constants';
import { updateCachingEvent } from './service';

const ConfirmProductActions = ({ formData, handleSubmit }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { productEvents } = useSelector((state) => state);
  const [isOpen, setOpen] = useState(false);
  const eventData = productEvents?.eventData;
  const event = {};

  eventData?.forEach((element) => {
    Object.assign(event, element);
  });

  const title = event?.title;
  const message = event?.message;
  const type = event?.type;
  const uuid = event?.uuid;

  useEffect(() => {
    if (eventData?.length) {
      updateCachingEvent(uuid, {
        status: EventStatusesEnum.showed,
        serviceSource: EventServiceSourceEnum.partners,
      });
      setOpen(true);
    } else {
      setOpen(false);
    }
  }, [eventData, uuid]);

  const handleCancel = () => {
    setOpen(false);
    dispatch({ type: SET_MODAL_ACTIONS, payload: ModalOpenStatusEnum.cancel });
    updateCachingEvent(uuid, {
      status: EventStatusesEnum.canceled,
      serviceSource: EventServiceSourceEnum.partners,
      action: EventActionEnum.canceled,
    });
    dispatch({ type: SET_PRODUCT_EVENTS, payload: [] });
    dispatch({
      type: SET_PRODUCT_EVENTS_NEXT_LOCATION,
      payload: `${history.location.pathname}${history.location.search}`,
    });
    localStorage.removeItem('SET_MODAL_ACTIONS');
  };

  const handleConfirm = (action) => {
    setOpen(false);
    updateCachingEvent(uuid, {
      status: EventStatusesEnum.confirmed,
      serviceSource: EventServiceSourceEnum.partners,
      action,
    }).then(() => {
      if (formData) {
        handleSubmit(formData).finally(() => {
          if (productEvents.nextLocation) {
            history.push(productEvents.nextLocation);
          } else {
            history.push({
              pathname: history.location.pathname,
              search: history.location.search,
              state: {},
            });
          }
          dispatch({ type: SET_MODAL_ACTIONS, payload: ModalOpenStatusEnum.confirm });
        });
      } else {
        if (productEvents.nextLocation) {
          history.push(productEvents.nextLocation);
        } else {
          history.push({
            pathname: history.location.pathname,
            search: history.location.search,
            state: {},
          });
        }
        dispatch({ type: SET_MODAL_ACTIONS, payload: ModalOpenStatusEnum.confirm });
      }
    });
    localStorage.removeItem('SET_MODAL_ACTIONS');
    dispatch({ type: SET_PRODUCT_EVENTS, payload: [] });
  };

  const renderActionButtons = () => {
    if (ButtonTexts[type]?.length > 1) {
      return (
        <>
          <DialogActionsProductButton variant="outlined" onClick={handleCancel}>
            <DialogActionsTypography color="#626A7A">{ButtonTexts[type]?.[0]}</DialogActionsTypography>
          </DialogActionsProductButton>
          <DialogActionsProductConfirmButton
            variant="contained"
            onClick={() => handleConfirm(EventActionEnum[ButtonTexts[type]?.[1]?.toLowerCase()])}
          >
            <DialogActionsTypography color="#fff">{ButtonTexts[type]?.[1]}</DialogActionsTypography>
          </DialogActionsProductConfirmButton>
        </>
      );
    } else {
      return (
        <DialogActionsProductConfirmButton
          variant="contained"
          onClick={() => handleConfirm(ButtonTexts[type]?.[0]?.toLowerCase())}
        >
          <DialogActionsTypography color="#fff">{ButtonTexts[type]?.[0]}</DialogActionsTypography>
        </DialogActionsProductConfirmButton>
      );
    }
  };

  const handleClose = (event, reason) => {
    setOpen(false);
    dispatch({ type: SET_MODAL_ACTIONS, payload: ModalOpenStatusEnum.close });
    if (reason) {
      updateCachingEvent(uuid, {
        status: EventStatusesEnum.canceled,
        serviceSource: EventServiceSourceEnum.partners,
        action: EventActionEnum.closed,
      });
      dispatch({ type: SET_PRODUCT_EVENTS, payload: [] });
      localStorage.removeItem('SET_MODAL_ACTIONS');
    }
  };

  return isOpen ? (
    <Dialog
      open={isOpen}
      onClose={handleClose}
      aria-labelledby="draggable-dialog-content"
      fullWidth={true}
      PaperComponent={DraggableDialogWrapper}
      PaperProps={{
        sx: DialogPageProps,
      }}
    >
      <DialogContentBox id="draggable-dialog-content">
        <DialogActionsImg component="img" src={EventTypeIcons[type]} alt={type} />
        <DialogTextProvider>{title}</DialogTextProvider>
        {event?.contentType === 'text' ? (
          <DialogTextSubProvider>{message}</DialogTextSubProvider>
        ) : (
          <Box
            dangerouslySetInnerHTML={{
              __html: message,
            }}
          />
        )}
      </DialogContentBox>
      <DialogActionsProductBox>{renderActionButtons()}</DialogActionsProductBox>
    </Dialog>
  ) : (
    <></>
  );
};
export default ConfirmProductActions;
