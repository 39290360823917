import React, { useState } from 'react';
import { forgotPassword } from 'redux/actions/user';
import { Link, useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useForm, FormProvider } from 'react-hook-form';

import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import CustomTextInput from 'components/Forms/CustomTextInput';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { useTranslation } from 'react-i18next';

import Button from '@mui/material/Button';

const ForgotPassword = () => {
  const { t } = useTranslation();

  const history = useHistory();
  const [submitted, setSubmitted] = useState(false);

  const schemaForgotPassword = yup.object().shape({
    email: yup.string().email('this_field_is_invalid_').required('this_field_is_invalid_'),
  });
  const userForgotPasswordFormMethods = useForm({
    defaultValues: { email: '' },
    mode: 'all',
    resolver: yupResolver(schemaForgotPassword),
  });

  const handleValidSubmit = (values) => {
    setSubmitted(true);
    forgotPassword(values)
      .then(() => {
        toast.success('Please check your email!');
        history.push('/login');
      })
      .catch(() => {
        setSubmitted(false);
      });
  };

  return (
    <Box sx={{ width: '100vw', height: '100vh', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
      <div className="auth-box shadow">
        <FormProvider {...userForgotPasswordFormMethods}>
          <form noValidate autoComplete="off" onSubmit={userForgotPasswordFormMethods.handleSubmit(handleValidSubmit)}>
            <Grid container>
              <Grid item xs={12} sx={{ mb: 2 }}>
                <Typography align="center" variant="h4">
                  {t('_login_.forgot_password')}
                </Typography>
              </Grid>
              <Grid item sx={{ mb: 2 }} xs={12}>
                <CustomTextInput sx={{ width: '100%' }} name="email" label={t('sign_in.email_address')} />
              </Grid>

              <Grid item sx={{ mb: 2 }} xs={12}>
                <Button
                  id="forgotPasswordView"
                  color="primary"
                  variant="contained"
                  type="submit"
                  disabled={submitted}
                  sx={{ width: '100%' }}
                >
                  {t('order_view.submit')}
                </Button>
              </Grid>
              <Grid item sx={{ mb: 2 }} xs={12}>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 1 }}>
                  <Link to="/login">{t('_login_.login')}</Link>
                  <Link to="/signup">{t('_login_.signup')}</Link>
                </Box>
              </Grid>
            </Grid>
          </form>
        </FormProvider>
      </div>
    </Box>
  );
};

export default ForgotPassword;
