import instance, { gatewayInstance } from '../../../services/api';
import {
  SET_CATEGORY_PRODUCTS_V2,
  SET_LOADING,
  SET_PAGINATION_V2,
  SET_MENUS_V2,
  SET_LINK_PROVIDER_LOADING,
  SET_LINK_PROVIDER_VISIBILITY,
  SET_CHECKED_PROVIDER,
  SET_PROVIDER_DOORDASH_LOADING,
  SET_PROVIDER_GRUBHUB_LOADING,
  SET_LINK_PROVIDER,
  SET_LOADING_PRODUCT,
} from '../types';
import { getProductById, getProductsAll } from './products';

export const getMenusV2 = () => (dispatch, getState) => {
  const {
    menu: { menus },
    providers: {
      providerConfig: { providers },
    },
  } = getState();
  dispatch({ type: SET_LOADING, payload: true });
  return instance
    .get('/menu/v2')
    .then(({ data: { data } }) => {
      const promise = [];
      dispatch({ type: SET_LOADING, payload: true });
      dispatch({ type: SET_LOADING_PRODUCT, payload: true });
      data.categories?.forEach((catData) => {
        const _id = catData?._id;
        promise.push(
          instance
            .get(`/categories/v2/${_id}?sortDirection=ASC&products=true`)
            .then((res) => {
              const { products } = res?.data?.data;
              const targetCategoryIndex = data.categories?.findIndex((cat) => cat._id === _id);
              if (targetCategoryIndex != -1 && targetCategoryIndex >= 0) {
                data.categories[targetCategoryIndex].products = products;
                data.categories[targetCategoryIndex].productsCount = products.length;
              }
            })
            .finally(() => {
              dispatch({ type: SET_LOADING_PRODUCT, payload: false });
            })
        );
      });
      Promise.allSettled(promise);

      dispatch({ type: SET_MENUS_V2, payload: data });
      const defaultCheckedProviders = Object.keys(data.connectedProviders)
        .map((item) => {
          if (item === 'main') {
            return;
          }
          return { [item]: data.connectedProviders[item] };
        })
        ?.filter((item) => item);
      localStorage.setItem(SET_CHECKED_PROVIDER, JSON.stringify(defaultCheckedProviders));
      return data;
    })
    .catch((err) => {
      return Promise.reject(err);
    })
    .finally(() => dispatch({ type: SET_LOADING, payload: false }));
};

export const getCategoryProductsV2ByCategoryId = ({ selectedCategory, selectedMenu }) => (dispatch, getState) => {
  const { menu } = getState();
  const skip = 0;
  const limit = 1000;
  const { menus } = menu;
  const categoryData = {
    ...menus,
  };
  if (!selectedCategory || !selectedMenu || !categoryData) {
    return;
  }
  if (!categoryData?.products?.length) {
    dispatch({ type: SET_LOADING, payload: true });
  }
  return instance
    .get(`/categories/v2/${selectedCategory}?skip=${skip}&limit=${limit}&sortDirection=ASC&products=true`)
    .then(({ data: { data } }) => {
      categoryData[selectedMenu].categories[selectedCategory] &&
        (categoryData[selectedMenu].categories[selectedCategory].products = data?.products);
      dispatch({
        type: SET_PAGINATION_V2,
        payload: {
          count: Math.ceil(data.count / limit),
        },
      });
      dispatch({
        type: SET_CATEGORY_PRODUCTS_V2,
        payload: { baseData: categoryData },
      });
      return data;
    })
    .finally(() => dispatch({ type: SET_LOADING, payload: false }))
    .catch((err) => {
      return Promise.reject(err);
    });
};

// Link menu with provider

export const menuLinkProvider = (body) => (dispatch) => {
  dispatch({ type: SET_LINK_PROVIDER_LOADING, payload: true });
  return instance
    .post(`/menu/push`, body)
    .then(({ data: { data } }) => {
      dispatch({ type: SET_LINK_PROVIDER_VISIBILITY, payload: false });
    })
    .finally(() => dispatch({ type: SET_LINK_PROVIDER_LOADING, payload: false }));
};

export const categoryLinkProvider = (body) => (dispatch) => {
  dispatch({ type: SET_LINK_PROVIDER_LOADING, payload: true });
  return instance
    .post(`/menu/category/push`, body)
    .then(({ data: { data } }) => {
      dispatch({ type: SET_LINK_PROVIDER_VISIBILITY, payload: false });
    })
    .finally(() => dispatch({ type: SET_LINK_PROVIDER_LOADING, payload: false }));
};

export const productLinkProvider = (body) => (dispatch) => {
  dispatch({ type: SET_LINK_PROVIDER_LOADING, payload: true });
  return instance
    .post(`/menu/product/push`, body)
    .then(({ data: { data } }) => {
      dispatch(getProductsAll(5, 0));
      dispatch({ type: SET_LINK_PROVIDER_VISIBILITY, payload: false });
      dispatch(getProductById(body.id));
    })
    .finally(() => dispatch({ type: SET_LINK_PROVIDER_LOADING, payload: false }));
};
export const linkProviderTarget = (sendDataProvider = []) => (dispatch, getState) => {
  const {
    menu: { linkProvider },
  } = getState();
  const { target, id, connectedProviders } = linkProvider;
  const providers = [];
  Object.keys(connectedProviders)
    .filter((provider) => provider !== 'main')
    .map((item) => {
      providers.push({
        provider: item,
        isLinked: connectedProviders[item],
      });
    });
  if (target === 'menu') {
    return dispatch(menuLinkProvider({ providers })).then(() => {
      if (sendDataProvider.length && target === 'menu') {
        sendDataProvider.map((item) => {
          return dispatch(uploadBulk(item)).then(() => (window.location.href = '/menu/overview'));
        });
      } else {
        window.location.href = '/menu/overview';
      }
    });
  } else if (target === 'category') {
    return dispatch(categoryLinkProvider({ id, providers }));
  } else {
    return dispatch(productLinkProvider({ id, providers }));
  }
};

export const setMenuFromSocket = (data) => (dispatch, getState) => {
  dispatch({ type: SET_MENUS_V2, payload: data });
};

export const updateBulk = () => (dispatch, getState) => {
  const {
    menu: { menu },
  } = getState();

  dispatch({ type: SET_LINK_PROVIDER_LOADING, payload: true });
  return instance
    .post(`menu/bulk`)
    .then(() => {
      menu.bulkInfo.isSaved = true;
      if (menu.modifiersAll) {
        menu.modifiersAll.forEach((modItem) => {
          modItem.bulkInfo.isSaved = true;
        });
      }

      menu.categories.forEach((catItem) => {
        catItem.bulkInfo.isSaved = true;
        catItem.products?.length &&
          catItem.products.forEach((productItem) => {
            productItem.bulkInfo.isSaved = true;
          });
      });
      menu.modifiersAll.forEach((modItem) => {
        modItem.bulkInfo.isSaved = true;
      });
      dispatch({ type: SET_MENUS_V2, payload: menu });
    })
    .finally(() => {
      dispatch({ type: SET_LOADING, payload: false });
      dispatch({ type: SET_LINK_PROVIDER_LOADING, payload: false });
    });
};

export const uploadBulk = (body) => (dispatch) => {
  dispatch({ type: SET_LINK_PROVIDER_LOADING, payload: true });
  return instance.post(`menu/bulk/upload`, body).finally(() => {
    dispatch({ type: SET_LINK_PROVIDER_LOADING, payload: false });
  });
};

export const doordashMapProvider = (body) => (dispatch) => {
  dispatch({ type: SET_PROVIDER_DOORDASH_LOADING, payload: true });
  return instance
    .post(`/menu/bulk/map`, body)
    .then((response) => {
      dispatch({ type: SET_PROVIDER_DOORDASH_LOADING, payload: false });
    })
    .finally(() => {
      dispatch({ type: SET_PROVIDER_DOORDASH_LOADING, payload: false });
    });
};

export const grubHubMapProvider = (body) => (dispatch) => {
  dispatch({ type: SET_PROVIDER_GRUBHUB_LOADING, payload: true });
  return instance
    .post(`/menu/bulk/map`, body)
    .then((response) => {
      dispatch({ type: SET_PROVIDER_GRUBHUB_LOADING, payload: false });
    })
    .finally(() => {
      dispatch({ type: SET_PROVIDER_GRUBHUB_LOADING, payload: false });
    });
};

export const getDoordashMenuTargetData = () => (dispatch, getState) => {
  const {
    business: { currentBusiness },
    menu: { linkProvider, formProduct },
  } = getState();
  dispatch({ type: SET_LINK_PROVIDER_LOADING, payload: true });

  const type = linkProvider.target === 'item' ? 'product' : linkProvider.target;
  let path =
    type === 'product'
      ? `category/${formProduct?.category?._id || formProduct?.category}/${type}`
      : `${type}/${currentBusiness.restaurantMailbox}`;

  return gatewayInstance
    .get(`doordash-menu/${path}`)
    .then((res) => {
      // const filteredList = res.data.data.filter((item) => {
      //   if (!item.mappedData.length) return false;
      //   return item.mappedData.every((mappedDataId) => mappedDataId.nativeId === linkProvider.targetData._id);
      // });

      const filteredList = res.data.data.filter(
        (item) =>
          !item.mappedData.length ||
          !item.mappedData.some((mappedDataId) => mappedDataId.nativeId !== linkProvider.targetData._id)
      );

      dispatch({
        type: SET_LINK_PROVIDER,
        payload: {
          doordashTargetList: filteredList,
        },
      });

      // dispatch(updateCategory(res.data.data._id));
      return res.data.data;
    })
    .catch((err) => {
      return Promise.reject(err);
    })
    .finally(() => {
      dispatch({ type: SET_LINK_PROVIDER_LOADING, payload: false });
    });
};

export const mapTargetToDoordash = (selectedItems, initialSelected) => (dispatch, getState) => {
  const {
    business: { currentBusiness },
    menu: {
      linkProvider: { targetData, target, doordashTargetList },
      menu,
    },
    providers: {
      providerConfig: { provider },
    },
  } = getState();
  const array = [];
  const externalMenuId = doordashTargetList[0]?.externalMenuId || '';
  const restaurantMailbox = currentBusiness.restaurantMailbox;
  Object.keys(selectedItems).forEach((key) => {
    const { value } = selectedItems[key];
    if (targetData.isOnePrice === false) {
      const dataModel = {
        nativeId: targetData._id,
        externalId: value.externalId,
        externalMenuId: value.externalMenuId,
        type: target === 'item' ? 'product' : target,
        restaurantMailbox: value.restaurantMailbox,
        nativeSizeTypeOptionId: key,
        externalCategoryId: value.externalParentId,
      };
      if (value.nativeCategoryId) {
        dataModel.nativeCategoryId = value.nativeCategoryId;
      }
      if (initialSelected[key]?.value?.externalId !== value.externalId) {
        if (value !== 'new') {
          array.push(dataModel);
        }

        if (initialSelected[key]?.value?.externalId && initialSelected[key]?.value.mappedData.length) {
          const mappedDataModel = initialSelected[key]?.value.mappedData.find(
            (mappedDataItem) => mappedDataItem.nativeId === targetData._id
          );
          if (mappedDataModel) {
            delete mappedDataModel.externalId;
            array.push(mappedDataModel);
          }
        }
      }
    } else {
      const dataModel = {
        nativeId: targetData._id,
        externalId: value.externalId,
        externalMenuId: value.externalMenuId,
        type: target === 'item' ? 'product' : target,
        restaurantMailbox: value.restaurantMailbox,
      };
      if (target === 'item') {
        dataModel.externalCategoryId = value.externalParentId;
        if (value.nativeCategoryId) {
          dataModel.nativeCategoryId = value.nativeCategoryId;
        }
      }

      if (initialSelected[key]?.value?.externalId !== value.externalId) {
        if (value !== 'new') {
          array.push(dataModel);
        }

        if (initialSelected[key]?.value?.externalId && initialSelected[key]?.value.mappedData.length) {
          const mappedDataModel = initialSelected[key]?.value.mappedData.find(
            (mappedDataItem) => mappedDataItem.nativeId === targetData._id
          );
          if (mappedDataModel) {
            if (value.nativeCategoryId && target === 'item') {
              mappedDataModel.nativeCategoryId = value.nativeCategoryId;
            }
            delete mappedDataModel.externalId;
            array.push(mappedDataModel);
          }
        }
      }
    }
  });
  array.map((item) => {
    item.externalMenuId = externalMenuId;
    item.restaurantMailbox = restaurantMailbox;
    item.nativeMenuId = menu?._id;
    return item;
  });

  return gatewayInstance
    .put(`doordash-menu/mapping/bulk/crud`, array)
    .then((response) => {
      targetData.connectedProviders[provider.doordash.srv] = true;
    })
    .then(() => {
      dispatch(getDoordashMenuTargetData());
    });
};

export const menuInitialSync = () => (dispatch) => {
  dispatch({ type: SET_PROVIDER_DOORDASH_LOADING, payload: true });
  let mapBodyDoorDash = {
    provider: 'doordash',
    action: 'sync',
    isLinked: true,
  };
  return instance
    .post(`/menu/initial/push`, mapBodyDoorDash)
    .then(() => {
      dispatch({ type: SET_PROVIDER_DOORDASH_LOADING, payload: false });
    })
    .finally(() => {
      dispatch({ type: SET_PROVIDER_DOORDASH_LOADING, payload: false });
    });
};
