import React from 'react';
import { useTranslation } from 'react-i18next';
import CustomTextInput from 'components/Forms/CustomTextInput';
import LabelTooltip from 'components/FormFields/LabelTooltip';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { Grid, Switch, FormGroup, FormControlLabel, InputAdornment, Tooltip, Divider, Box } from '@mui/material';
import {
  MinOrderAmountWebsiteGrid,
  MinOrderDividerBoxGrid,
  StyledLabelForWebsite,
} from 'pages/WebsiteSettings/Rewards/styles';

const MinOrderSettings = ({ modelValues, handlePickupChange, handleDeliveryChangeChange, onChangeAmount }) => {
  const { t } = useTranslation();
  return (
    <MinOrderAmountWebsiteGrid container spacing={2} columns={{ xs: 9 }}>
      <Grid item xs={12}>
        <FormGroup
          sx={{ width: '400px' }}
          checked={modelValues?.minOrderObj?.pickup?.enabled}
          onChange={handlePickupChange}
          name={`minOrderObj.pickup.enabled`}
        >
          <FormControlLabel
            control={<Switch checked={modelValues?.minOrderObj?.pickup?.enabled} />}
            label={<StyledLabelForWebsite>{t('website_settings.minimum_pickup')}</StyledLabelForWebsite>}
          />
        </FormGroup>
        <Box sx={{ width: '100%', mt: modelValues?.minOrderObj?.pickup?.enabled ? 1 : -2 }}>
          {modelValues?.minOrderObj?.pickup?.enabled && (
            <Grid item xs={2}>
              <CustomTextInput
                sx={{ width: '336px' }}
                inputProps={{
                  type: 'number',
                  step: 'any',
                }}
                onChange={onChangeAmount}
                name="minOrderObj.pickup.amount"
                label={<LabelTooltip value={t('website_settings.minimum_pickup_title')} />}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <AttachMoneyIcon fontSize={'small'} />
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <InputAdornment position="end">
                      <Tooltip title={t('website_settings.minimum_$_order')}>
                        <HelpOutlineIcon fontSize={'small'} />
                      </Tooltip>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
          )}
          <MinOrderDividerBoxGrid item xs={2}>
            <Divider />
          </MinOrderDividerBoxGrid>
        </Box>
      </Grid>
      <Grid item xs={12}>
        <FormGroup
          sx={{ width: '400px' }}
          checked={modelValues?.minOrderObj?.delivery?.enabled}
          onChange={handleDeliveryChangeChange}
          name={`minOrderObj.pickup.enabled`}
        >
          <FormControlLabel
            control={<Switch checked={modelValues?.minOrderObj?.delivery?.enabled} />}
            label={<StyledLabelForWebsite>{t('website_settings.minimum_delivery')}</StyledLabelForWebsite>}
          />
        </FormGroup>
        <Box sx={{ width: '100%', mt: modelValues?.minOrderObj?.delivery?.enabled ? 1 : -2 }}>
          {modelValues?.minOrderObj?.delivery?.enabled && (
            <Grid item xs={2}>
              <CustomTextInput
                sx={{ width: '336px' }}
                inputProps={{
                  type: 'number',
                  step: 'any',
                }}
                onChange={onChangeAmount}
                name="minOrderObj.delivery.amount"
                label={<LabelTooltip value={t('website_settings.minimum_delivery_title')} />}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <AttachMoneyIcon fontSize={'small'} />
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <InputAdornment position="end">
                      <Tooltip title={t('website_settings.minimum_$_order_delivery')}>
                        <HelpOutlineIcon fontSize={'small'} />
                      </Tooltip>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
          )}
          <MinOrderDividerBoxGrid item xs={2}>
            <Divider />
          </MinOrderDividerBoxGrid>
        </Box>
      </Grid>
    </MinOrderAmountWebsiteGrid>
  );
};

export default MinOrderSettings;
