import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { getReportListWithPayload } from 'redux/actions/reports';
import { useReportPeriods } from 'components/NewHome/hooks/useReportPeriods';
import { ReportingFilterDefaultValues, ReportType } from 'components/NewHome/constants/constatnts';
import { processSelectFilter } from 'components/NewHome/helpers/helpers';

export const useTopSelling = () => {
  const { uuid } = useParams();
  const { periods, query, currentBusiness } = useReportPeriods();
  const user = useSelector(({ user }) => user.data);
  const [loading, setLoading] = useState(true);
  const [topSellerList, setTopSellerList] = useState([]);

  const handleGetReports = useCallback(() => {
    if (currentBusiness && Object.keys(currentBusiness).length > 0) {
      const selectedBrand = JSON.parse(localStorage.getItem('selectedBrand'));
      const selectedLocation = JSON.parse(localStorage.getItem('selectedLocation'));
      setLoading(true);
      const data = {
        type: ReportType.TOP_SELLERS,
        brandIds: [selectedBrand?.value?._id],
        locationIds: [selectedLocation?.value?._id || selectedBrand?.value?.locations[0]?._id],
        days_of_week: ReportingFilterDefaultValues.days_of_week,
        providers: ReportingFilterDefaultValues.providers,
      };
      const homeReportFilters = processSelectFilter(
        periods[query.period]?.label,
        currentBusiness?.timezone.timeZoneId,
        data
      );
      getReportListWithPayload({ ...homeReportFilters })
        .then(({ data: { data } }) => {
          setTopSellerList(data);
        })
        .finally(setLoading(false));
    }
  }, [periods, query.period, currentBusiness]);
  useEffect(() => {
    const id = user?._id || uuid;
    if (id) {
      handleGetReports(id, uuid);
    }
  }, [handleGetReports, user, uuid]);

  return {
    topSellerList,
    loading,
  };
};
