import styled from '@emotion/styled';
import { Button, Typography } from '@mui/material';

export const ButtonWrapper = styled(Button)({
  marginTop: '16px',
  whiteSpace: 'nowrap',
  '.MuiButton-root': {
    '&.Mui-disabled': {
      opacity: '.6',
      backgroundColor: 'rgba(0, 0, 0, 0.12)',
    },
  },
});

export const LabelName = styled(Typography)({
  position: 'absolute',
  padding: '0 4px',
  top: '-10px',
  left: '10px',
  background: 'white',
  fontSize: '13px',
  color: '#757d85',
  zIndex: '1',
});

export const SupportLabel = styled(Typography)({
  position: 'absolute',
  top: '10px',
  left: '30px',
  background: 'white',
  fontSize: '13px',
  color: '#757d85',
  zIndex: '1',
});
