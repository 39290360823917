import { styled } from '@mui/material/styles';
import { Box, Typography, Button } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { DataGrid, gridClasses } from '@mui/x-data-grid';
export const HeaderContainer = styled(Box)({
  width: '100%',
  display: 'flex',
  justifyContent: 'end',
});

export const HeaderTitle = styled(Typography)({
  fontSize: 20,
  fontWeight: 600,
});

export const HeaderSettings = styled(Box)({
  width: '100%',
  display: 'flex',
  justifyContent: 'space-between',
  background: '#F2F2F2',
  border: '1px solid #EAEAEA',
  alignItems: 'center',
  borderRadius: 4,
  padding: '5px 16px',
});

export const SettingsContent = styled(Box)({
  width: '100%',
  padding: '5px 16px',
  marginTop: 40,
});

export const ImageContainer = styled(Box)({
  width: 155,
  height: 155,
});

export const ImageWrap = styled(Box)({
  width: '100%',
  height: '100%',
});

export const CancelButton = styled(Button)({
  color: '#929398',
  fontSize: '14px',
  fontWeight: 600,
  '&:hover': {
    color: '#929398',
    background: '#fafafa',
  },
});

export const SaveButton = styled(LoadingButton)({
  color: 'white',
  background: '#2D7FF9',
  fontSize: '14px',
  fontWeight: 600,
  boxShadow: 'none',
  '&:hover': {
    background: '#2D7FF9',
    color: 'white',
    fontSize: '14px',
    fontWeight: 600,
    boxShadow: 'none',
  },
});

export const ButtonWrapper = styled(Box)(({ theme }) => ({
  padding: theme.spacing(1, 2),
  display: 'flex',
  gap: '16px',
}));

export const AccordionContentTitle = styled(Typography)({
  fontSize: '22px',
  fontWeight: 400,
  color: ' #3C4350',
  lineHeight: '28px',
  width: '100%',
  margin: '4px 0',
});
export const AccordionEditIcon = styled(Box)({
  display: 'flex',
  justifyContent: 'flex-end',
  width: '100%',
  marginRight: '8px',
});
export const AccordionContentWrapper = styled(Box)({
  display: 'flex',
  width: '100%',
  alignItems: 'center',
});

export const SwitcherWrapper = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
});

export const StyledButtonWrapper = styled(Box)({
  display: 'flex',
  justifyContent: 'end',
  '& > button': {
    maxWidth: '72px',
  },
});

export const StripedDataGrid = styled(DataGrid)(() => ({
  [`& .${gridClasses.row}.highlight_last-selected_item`]: {
    backgroundColor: '#EEF5FF',
  },
}));
