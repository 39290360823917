import React from 'react';
import usePermission from 'hooks/permission';
import { Route, Redirect } from 'react-router-dom';
import * as all from 'constants/permissions';
import { useSelector } from 'react-redux';
import { USER } from 'constants/permissions';

const ProtectedRoute = ({ permission, ...rest }) => {
  const { business } = useSelector(({ business }) => {
    return {
      business,
    };
  });
  const checkPermission = usePermission();
  if (!permission || checkPermission(all[permission])) return <Route {...rest} />;
  return business?.currentBusiness?.roles?.value === USER && <Redirect to={'/orders'} />;
};

export default ProtectedRoute;
