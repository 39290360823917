import React, { useEffect, useState } from 'react';
import Grid from '@mui/material/Grid';
import { toast } from 'react-toastify';
import { Typography } from '@mui/material';
import { useParams } from 'react-router-dom';
import { serviceInfoSchema } from './schema';
import AuthorizationButtons from './ActionButton';
import { yupResolver } from '@hookform/resolvers/yup';
import CustomCheckbox from 'components/Forms/CustomCheckbox';
import CustomTextInput from 'components/Forms/CustomTextInput';
import { FormProvider, useForm, useWatch } from 'react-hook-form';
import GooglAutocomplete from 'components/FormFields/GoogleAutocomplate';
import { legalInfoForInvitation, updateLegalInfoForInvitation } from 'redux/actions/userAuthV2';
import { AvailableButtons, SIGN_UP_FLOW_AUTHORIZATION } from 'constants/availableButtons';
import CustomMobilePhone from 'components/MobilePhone/CustomMobilePhone';
import { useTranslation } from 'react-i18next';
import LabelRequired from 'components/FormFields/LabelRequired';
// import { getBusinessSettings } from 'redux/actions/business';

const data = localStorage.getItem('business');
const parsedData = data && JSON.parse(data);
const initialLegalInfo = parsedData
  ? parsedData
  : {
      sameAsDefault: true,
      businessInfo: {
        phone: '',
        brandName: '',
        companyName: '',
        type: 'single',
        metadata: { communicationEmail: '' },
        addressInfo: { address: '', city: '', state: '', zipCode: '', placeId: '', address2: '', fullAddress: '' },
      },
      legalInfo: {
        legalName: '',
        legalPhone: '',
        addressInfo: { address: '', city: '', state: '', zipCode: '', placeId: '' },
      },
    };

const AuthorizationServiceInfo = ({ availableSteps, active, setActive }) => {
  const { t } = useTranslation();
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const [addressPlaceId, setAddressPlaceId] = useState('');
  const accountInfoFormMethods = useForm({
    defaultValues: initialLegalInfo,
    mode: 'all',
    resolver: yupResolver(serviceInfoSchema),
  });
  const {
    formState: { isSubmitted },
    getValues,
    setValue,
    reset,
    resetField,
    control,
  } = accountInfoFormMethods;

  const sameAsDefaultWatcher = useWatch({ control, name: 'sameAsDefault' });

  useEffect(() => {
    const data = localStorage.getItem('business');
    const parsedData = data && JSON.parse(data);
    accountInfoFormMethods.reset(parsedData);
  }, [accountInfoFormMethods]);

  useEffect(() => {
    const brandInfo = JSON.parse(localStorage.getItem('approvedInvitationInfo'));
    accountInfoFormMethods.setValue('businessInfo.brandName', brandInfo.brandName);
    window.scrollTo(0, 0);
    // getBusinessSettings().then((data) => {
    //   accountInfoFormMethods.setValue('businessInfo.metadata.communicationEmail', data.metadata.communicationEmail);
    // });
  }, []);

  useEffect(() => {
    const currentModel = getValues();
    const legalResponseInfo = JSON.parse(localStorage.getItem('legalResponseInfo'));
    if (currentModel?.sameAsDefault || legalResponseInfo) {
      resetField('legalInfo.legalName', { defaultValue: '' });
      resetField('legalInfo.legalPhone', { defaultValue: '' });
      resetField('legalInfo.addressInfo', { defaultValue: '' });
    } else if (!currentModel?.sameAsDefault && !legalResponseInfo) return false;
  }, [sameAsDefaultWatcher]);

  const getAddressFieldsHandle = (addressInfo, type) => {
    setValue(`${type}.addressInfo.city`, addressInfo?.city || getValues()[type]?.addressInfo?.city || '');
    setValue(`${type}.addressInfo.address`, addressInfo?.address || getValues()[type]?.addressInfo?.address || '');
    setValue(`${type}.addressInfo.state`, addressInfo?.state || getValues()[type]?.addressInfo?.state || '');
    setValue(`${type}.addressInfo.zipCode`, addressInfo?.zipCode || getValues()[type]?.addressInfo?.zipCode || '');
    setValue(`${type}.addressInfo.fullAddress`, addressInfo?.fullAddress || '');
    setValue(
      `${type}.addressInfo.address1`,
      addressInfo?.address ||
        addressInfo?.fullAddress ||
        addressInfo?.address1 ||
        getValues()[type]?.addressInfo?.address1 ||
        getValues()[type]?.addressInfo?.address ||
        ''
    );
    setValue(`${type}.addressInfo.address2`, addressInfo?.address2 || getValues()[type]?.addressInfo?.address2 || '');
    const dataModel = {
      ...getValues(),
      [type]: {
        ...getValues()[type],
        addressInfo: {
          ...getValues()[type].addressInfo,
          ...addressInfo,
        },
      },
    };
    reset(dataModel, {
      keepIsSubmitted: true,
      keepDirty: true,
    });
  };

  const handleSubmit = (values) => {
    setLoading(true);
    const info = JSON.parse(localStorage.getItem('accountResponse'));
    const accountInfoAuth = JSON.parse(localStorage.getItem('accountInfoAuth'));
    const legalAndBusinessInfo = localStorage.getItem('business');
    const parsedData = JSON.parse(legalAndBusinessInfo);
    const currentInvitationService = JSON.parse(localStorage.getItem('currentInvitationService'));
    const currentCouponCode = localStorage.getItem('currentCoupon');
    values.businessInfo['email'] = accountInfoAuth?.email;
    values.metadata = {
      ...values.metadata,
      addressPlaceId: addressPlaceId || currentModel.addressInfo?.placeId || '',
    };
    values.businessInfo.metadata = {
      ...values?.businessInfo.metadata,
      addressPlaceId: addressPlaceId || currentModel.addressInfo?.placeId || '',
    };
    if (!values.sameAsDefault && !parsedData) {
      values.userId = info?.userId;
      if (currentCouponCode) {
        values['oneTimeSetupFeeCouponCode'] = currentCouponCode;
        values['employeeNumber'] = currentCouponCode;
      }
      return legalInfoForInvitation(values, id)
        .then((response) => {
          if (availableSteps === SIGN_UP_FLOW_AUTHORIZATION.AUTHORIZATION_SERVICE_INFO) {
            setActive(SIGN_UP_FLOW_AUTHORIZATION.AUTHORIZATION_SUCCESS);
            localStorage.removeItem('approvedInvitationInfo');
            localStorage.removeItem('currentPlan');
            localStorage.removeItem('accountInfo');
            localStorage.removeItem('accountResponse');
            localStorage.removeItem('currentCoupon');
            toast.success(response?.data?.message);
          } else {
            localStorage.setItem('currentInvitationService', JSON.stringify(response.data.data));
            localStorage.setItem('business', JSON.stringify(values));
            setActive(SIGN_UP_FLOW_AUTHORIZATION.AUTHORIZATION_BILLING_INFO);
          }
        })
        .catch(() => setLoading(false))
        .finally(() => setLoading(false));
    } else if (values.sameAsDefault && !parsedData) {
      const data = JSON.parse(JSON.stringify(values.businessInfo));
      data.legalName = values?.businessInfo?.companyName || '';
      data.legalPhone = values?.businessInfo?.phone || '';
      data.addressInfo.address1 = data.addressInfo.address;
      data.metadata = {
        ...data.metadata,
        place_id: data.addressInfo?.placeId,
      };
      const combineObject = {
        businessInfo: values.businessInfo,
        legalInfo: data,
        userId: info?.userId,
        sameAsDefault: values.sameAsDefault,
        metadata: {
          ...values.metadata,
          addressPlaceId: addressPlaceId || currentModel.addressInfo?.placeId || '',
        },
      };

      if (currentCouponCode) {
        combineObject['oneTimeSetupFeeCouponCode'] = currentCouponCode;
        combineObject['employeeNumber'] = currentCouponCode;
      }
      return legalInfoForInvitation(combineObject, id)
        .then((response) => {
          if (availableSteps === SIGN_UP_FLOW_AUTHORIZATION.AUTHORIZATION_SERVICE_INFO) {
            setActive(SIGN_UP_FLOW_AUTHORIZATION.AUTHORIZATION_SUCCESS);
            localStorage.removeItem('approvedInvitationInfo');
            localStorage.removeItem('currentPlan');
            localStorage.removeItem('accountInfo');
            localStorage.removeItem('accountResponse');
            localStorage.removeItem('currentCoupon');
            toast.success(response?.data?.message);
          } else {
            localStorage.setItem('currentInvitationService', JSON.stringify(response.data.data));
            localStorage.setItem('business', JSON.stringify(combineObject));
            setActive(SIGN_UP_FLOW_AUTHORIZATION.AUTHORIZATION_BILLING_INFO);
          }
        })
        .catch(() => setLoading(false))
        .finally(() => setLoading(false));
    } else if (parsedData && Object.keys(parsedData).length > 0 && !values.sameAsDefault) {
      values.userId = info?.userId;
      values.legalInfo.legalEntityId = currentInvitationService.legalEntityId;
      values.businessInfo.businessId = currentInvitationService.businessId;
      if (currentCouponCode) {
        values['oneTimeSetupFeeCouponCode'] = currentCouponCode;
        values['employeeNumber'] = currentCouponCode;
      }
      return updateLegalInfoForInvitation(values, id)
        .then((response) => {
          localStorage.setItem('currentInvitationService', JSON.stringify(response.data.data));
          localStorage.setItem('business', JSON.stringify(values));
          localStorage.removeItem('currentCoupon');
          setActive(SIGN_UP_FLOW_AUTHORIZATION.AUTHORIZATION_BILLING_INFO);
        })
        .catch(() => setLoading(false))
        .finally(() => setLoading(false));
    } else {
      const data = JSON.parse(JSON.stringify(parsedData.businessInfo));
      data.legalName = values?.businessInfo?.companyName || '';
      data.legalPhone = values?.businessInfo?.phone || '';
      data.addressInfo.address1 = values.businessInfo?.addressInfo?.address;
      data.addressInfo = values.businessInfo?.addressInfo;
      data.phone = values.businessInfo.phone;
      data['legalEntityId'] = currentInvitationService?.legalEntityId;
      values.businessInfo['businessId'] = currentInvitationService?.businessId;
      const combineObject = {
        businessInfo: values.businessInfo,
        legalInfo: data,
        userId: info?.userId,
        sameAsDefault: values.sameAsDefault,
        metadata: {
          ...values.metadata,
          addressPlaceId: addressPlaceId || currentModel.addressInfo?.placeId || '',
        },
      };
      if (currentCouponCode) {
        combineObject['oneTimeSetupFeeCouponCode'] = currentCouponCode;
        combineObject['employeeNumber'] = currentCouponCode;
      }
      return updateLegalInfoForInvitation(combineObject, id)
        .then((response) => {
          localStorage.setItem('business', JSON.stringify(combineObject));
          localStorage.setItem('currentInvitationService', JSON.stringify(response.data.data));
          localStorage.removeItem('currentCoupon');
          setActive(SIGN_UP_FLOW_AUTHORIZATION.AUTHORIZATION_BILLING_INFO);
        })
        .catch(() => setLoading(false))
        .finally(() => setLoading(false));
    }
  };

  const handleCheckButtonTitle = () => (availableSteps === AvailableButtons.SUBMIT ? 'Submit' : 'Next');

  const currentModel = getValues();

  return (
    <>
      <FormProvider {...accountInfoFormMethods}>
        <form onSubmit={accountInfoFormMethods.handleSubmit(handleSubmit)}>
          <Grid container spacing={2}>
            <Grid item md={12} xs={12}>
              <Typography sx={{ fontSize: '16px', fontWeight: 700, mt: 1 }}>
                {t('settings.servicing_location_information')}
              </Typography>
            </Grid>
            <Grid item md={12} xs={12}>
              <CustomTextInput
                sx={{ width: '100%' }}
                disabled={true}
                name="businessInfo.brandName"
                label={t('settings.brand_name')}
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <CustomTextInput
                sx={{ width: '100%' }}
                name="businessInfo.companyName"
                label={<LabelRequired>{t('add_location.location_name')}</LabelRequired>}
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <CustomMobilePhone
                sx={{ width: '100%' }}
                name="businessInfo.phone"
                label={<LabelRequired>{t('batch_report.phone')}</LabelRequired>}
              />
            </Grid>
            <Grid item md={12} xs={12}>
              <GooglAutocomplete
                getAddressFields={(data) => getAddressFieldsHandle(data, 'businessInfo')}
                name="businessInfo.addressInfo.address1"
                initialValue={currentModel?.businessInfo.addressInfo?.address1 || ''}
                inputValue={currentModel?.businessInfo.addressInfo?.address1 || ''}
                label={<LabelRequired>{t('batch_report.address')}</LabelRequired>}
                placeholder=""
                formSubmitted={isSubmitted}
                setAddressPlaceId={setAddressPlaceId}
              />
            </Grid>
            <Grid item md={12} xs={12}>
              <CustomTextInput
                sx={{ width: '100%' }}
                defaultValue={currentModel?.businessInfo.addressInfo?.address2 || ''}
                name="businessInfo.addressInfo.address2"
                label={t('order_view.address2')}
              />
            </Grid>
            <Grid item md={4} xs={12}>
              <CustomTextInput
                sx={{ width: '100%' }}
                defaultValue={currentModel?.businessInfo.addressInfo?.city || ''}
                name="businessInfo.addressInfo.city"
                label={<LabelRequired>{t('settings.city')}</LabelRequired>}
              />
            </Grid>
            <Grid item md={4} xs={12}>
              <CustomTextInput
                sx={{ width: '100%' }}
                defaultValue={currentModel?.businessInfo.addressInfo?.state || ''}
                name="businessInfo.addressInfo.state"
                label={<LabelRequired>{t('settings.state')}</LabelRequired>}
              />
            </Grid>
            <Grid item md={4} xs={12}>
              <CustomTextInput
                sx={{ width: '100%' }}
                inputProps={{ type: 'number' }}
                defaultValue={currentModel?.businessInfo.addressInfo?.zipCode || ''}
                name="businessInfo.addressInfo.zipCode"
                label={<LabelRequired>{t('settings.zip_code')}</LabelRequired>}
              />
            </Grid>
            <Grid item md={12} xs={12}>
              <Typography sx={{ fontWeight: 400, mt: 2, fontSize: '24px' }}>
                {t('settings.communication_information')}
              </Typography>
              <Typography>{t('settings.used_performance_reports')}</Typography>
            </Grid>

            <Grid item md={12} xs={6}>
              <CustomTextInput
                sx={{ width: '100%' }}
                name="businessInfo.metadata.communicationEmail"
                label={<LabelRequired>{t('sign_in.communication_email')}</LabelRequired>}
              />
            </Grid>
            <Grid item xs={12}>
              <CustomCheckbox label={t('settings.legal_information_location_information')} name="sameAsDefault" />
            </Grid>
            {!currentModel?.sameAsDefault && (
              <>
                <Grid item md={12} xs={12}>
                  <Typography sx={{ fontSize: '16px', fontWeight: 500, mt: 1 }}>
                    {t('settings.legal_information')}
                  </Typography>
                </Grid>
                <Grid item md={6} xs={12}>
                  <CustomTextInput
                    defaultValue={currentModel?.legalInfo.legalName || ''}
                    sx={{ width: '100%' }}
                    name="legalInfo.legalName"
                    label={t('settings.legal_name')}
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <CustomMobilePhone
                    defaultValue={currentModel?.legalInfo.legalPhone || ''}
                    sx={{ width: '100%' }}
                    name="legalInfo.legalPhone"
                    label={t('settings.phone_number')}
                  />
                </Grid>
                <Grid item md={12} xs={12}>
                  <GooglAutocomplete
                    getAddressFields={(data) => getAddressFieldsHandle(data, 'legalInfo')}
                    name="legalInfo.addressInfo.address1"
                    initialValue={currentModel?.legalInfo.addressInfo?.address1 || ''}
                    inputValue={currentModel?.legalInfo.addressInfo?.address1 || ''}
                    label={<LabelRequired>{t('batch_report.address')}</LabelRequired>}
                    placeholder=""
                    formSubmitted={isSubmitted}
                  />
                </Grid>
                <Grid item md={12} xs={12}>
                  <CustomTextInput
                    sx={{ width: '100%' }}
                    defaultValue={currentModel?.legalInfo.addressInfo?.address2 || ''}
                    name="legalInfo.addressInfo.address2"
                    label={t('order_view.address2')}
                  />
                </Grid>
                <Grid item md={4} xs={12}>
                  <CustomTextInput
                    sx={{ width: '100%' }}
                    defaultValue={currentModel?.legalInfo.addressInfo?.city || ''}
                    name="legalInfo.addressInfo.city"
                    label={t('settings.city')}
                  />
                </Grid>
                <Grid item md={4} xs={12}>
                  <CustomTextInput
                    sx={{ width: '100%' }}
                    defaultValue={currentModel?.legalInfo.addressInfo?.state || ''}
                    name="legalInfo.addressInfo.state"
                    label={t('settings.state')}
                  />
                </Grid>
                <Grid item md={4} xs={12}>
                  <CustomTextInput
                    sx={{ width: '100%' }}
                    inputProps={{ type: 'number' }}
                    defaultValue={currentModel?.legalInfo.addressInfo?.zipCode || ''}
                    name="legalInfo.addressInfo.zipCode"
                    label={t('settings.zip_code')}
                  />
                </Grid>
              </>
            )}
            <Grid item xs={12}>
              <AuthorizationButtons
                steps={availableSteps}
                setActive={setActive}
                active={active}
                loading={loading}
                title={handleCheckButtonTitle()}
                disabled={!accountInfoFormMethods.formState.isValid}
              />
            </Grid>
          </Grid>
        </form>
      </FormProvider>
    </>
  );
};

export default AuthorizationServiceInfo;
