import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Button, Typography, Box, Paper, Checkbox, Grid } from '@mui/material';
import { useHistory, useLocation } from 'react-router-dom';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import IconButton from '@mui/material/IconButton';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import Divider from '@mui/material/Divider';
import { getProviders, updateProviderMenuFee } from 'redux/actions/providers';
import { PROVIDER_AUTO_CONFIRM_ORDER, PROVIDER_NOT_CONFIRM_ORDER } from 'constants/providerStatuses';
import { updateProviderConfirmOrder } from 'redux/actions/providers';
import * as yup from 'yup';
import { useForm, FormProvider } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import CustomTextInput from 'components/Forms/CustomTextInput';
import Preloader from 'components/Preloader';
import { useTranslation } from 'react-i18next';
import ProviderCredential from './ProviderCredential';
import { confirm } from 'components/MenuNew/ConfirmModal';
import useProviderConfig from 'hooks/useProviderConfig';
import ConfirmProviderAction from 'components/Confirm/ConfirmProviderAction';
import ProviderConfirmImage from 'assets/icons/ProviderConfirm.png';
import PhoneImage from 'assets/images/PhoneIcon.png';
import { useAutoConfirmModal } from 'hooks/useAutoConfirmModal';
import ConfirmPromptModal from 'components/ConfirmPromptModal/ConfirmPromptModal';
const ProviderSettings = () => {
  const { t } = useTranslation();
  const { providers } = useProviderConfig();
  const location = useLocation();
  const { openAutoConfirmModal, handleCloseAutoConfirmModal, handleOpenAutoConfirmModal } = useAutoConfirmModal();
  const { currentBusiness } = useSelector(({ business }) => business);
  const { items, providerId, providersSettings, loading } = useSelector(({ providers }) => providers);
  const [data, setData] = useState(location?.state?.data);
  const [id, setId] = useState({});
  const [credentialTablet, setCredentialTablet] = useState({});
  const [openModal, setOpenModal] = useState(false);
  // const [autoConfirm, setAutoConfirm] = useState(data.confirmOrder === PROVIDER_AUTO_CONFIRM_ORDER);

  const dispatch = useDispatch();
  const history = useHistory();

  const invitationFormSchema = yup.object().shape({
    percent: yup.lazy((value) => {
      if (value === null || value === '') {
        return yup.string();
      }

      return yup
        .number()
        .transform((value) => (isNaN(value) ? null : value))
        .nullable();
    }),
    roundup: yup.boolean(),
  });

  const serviceFeeFormMethods = useForm({
    mode: 'all',
    defaultValues: providersSettings[data?.srv]?.feeInfo || { enabled: false },
    resolver: yupResolver(invitationFormSchema),
  });
  const {
    register,
    getValues,
    watch,
    reset,
    formState: { dirtyFields, isDirty },
  } = serviceFeeFormMethods;
  const convertNameByProvider = (providerName) => {
    return providers[data?.srv]?.label === providers?.food2?.label ? providers[data?.srv]?.label : providerName;
  };
  const handleConfirmOrderChange = useCallback(
    ({ target }) => {
      const { checked } = target;
      dispatch(
        updateProviderConfirmOrder({
          id: providerId,
          confirmOrder: checked ? PROVIDER_AUTO_CONFIRM_ORDER : PROVIDER_NOT_CONFIRM_ORDER,
          provider: data?.srv,
        })
      ).then(() => {
        return dispatch(getProviders());
      });
    },
    [dispatch, providerId, data]
  );

  const handleApplyServiceFee = async (values) => {
    const body = {
      ...values,
      provider: data?.srv === providers?.food2?.srv ? providers?.website?.srv : data?.srv,
    };
    const confirmed = await confirm({
      title: 'confirm_modal.service_fee',
      message: `${t('settings.are_you_to_update')} ${convertNameByProvider(data?.name)} ${t(
        'settings.menu_items_prices_by'
      )} ${getValues().percent} percent?`,
      cancelText: t('orders.cancel'),
      confirmText: t('website_settings.update'),
      confirmColor: 'primary',
    });
    if (confirmed) dispatch(updateProviderMenuFee(body));
  };

  const checked = useMemo(() => {
    const newData = items.find((item) => item?.srv === data?.srv);
    return newData?.confirmOrder === PROVIDER_AUTO_CONFIRM_ORDER;
  }, [data?.srv, items]);

  const showFee = watch('enabled');
  useEffect(() => {
    if (location?.state?.data && location?.state?.id) {
      items.forEach((provider) => {
        if (provider?.srv === providers.doordash.srv) {
          setCredentialTablet(provider?.tabletAuth || {});
        }
      });
      setData(location?.state?.data);
      setId(location?.state?.id);
    } else {
      Object.keys(providers).forEach((item) => {
        if (location.pathname.includes(item)) {
          if (items.length > 0) {
            items.forEach((provider) => {
              if (provider?.srv === item) {
                setCredentialTablet(provider?.tabletAuth || {});
                setData(provider);
              }
            });
          }
        }
      });
    }
  }, [location?.state, setData, setId, items]);

  useEffect(() => {
    if (!showFee && dirtyFields.enabled) {
      dispatch(updateProviderMenuFee({ enabled: false, provider: data?.srv }));
    }
  }, [data?.srv, showFee]);

  useEffect(() => {
    if (providersSettings[data?.srv] && providersSettings[data?.srv]?.feeInfo) {
      reset(providersSettings[data?.srv].feeInfo);
      // reset({
      //   ...providersSettings[data?.srv].feeInfo,
      //   percent:
      //     providersSettings[data?.srv].feeInfo.percent === null ? '' : providersSettings[data?.srv].feeInfo.percent,
      // });
    }
  }, [data?.srv, providersSettings]);

  const handleOpenModal = () => setOpenModal(true);
  const handleClose = () => setOpenModal(false);

  const isDisableAutoConfirm = useMemo(() => {
    return (
      data?.srv === providers?.doordash_cartwheel?.srv ||
      data?.srv === providers?.grubhub_cartwheel?.srv ||
      currentBusiness?.roles?.name !== 'super'
    );
  }, [data]);

  const isDisableServiceFee = useMemo(() => {
    return data?.srv === providers?.ezcater?.srv;
  }, [data]);

  const showConfirmButton = useMemo(() => {
    return providers[data?.srv]?.label === providers?.food2?.label;
  }, [providers, data]);

  return (
    <Paper>
      {loading && <Preloader overlay={true} />}
      <Box sx={{ my: 2, display: 'flex', pt: 2 }}>
        <IconButton aria-label="delete" size="small" sx={{ mr: 2 }}>
          <ArrowBackIcon onClick={() => history.goBack()} />
        </IconButton>
        <Typography variant="h5">{providers[data?.srv]?.label}</Typography>
      </Box>
      <Divider />
      <ConfirmProviderAction
        handleClose={handleClose}
        showModal={openModal}
        title={'settings.service_text'}
        description={'settings.service_desc'}
        label={providers[data?.srv]?.label}
        icon={ProviderConfirmImage}
        iconName={'ezCater'}
      />
      <ConfirmProviderAction
        handleClose={handleCloseAutoConfirmModal}
        showModal={openAutoConfirmModal}
        title={'website_settings.auto_confirm'}
        description={'website_settings.auto_confirm_desc'}
        label={''}
        icon={PhoneImage}
        iconName={'support phone number'}
        phoneNumber={'website_settings.phoneNumber'}
      />
      <ConfirmPromptModal
        handleConfirm={serviceFeeFormMethods.handleSubmit(handleApplyServiceFee)}
        submitData={getValues()}
        hasUnsavedChanges={isDirty}
        formState={serviceFeeFormMethods}
        isLoading={loading}
      />
      <Grid container spacing={2} sx={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
        <Grid item xs={6}>
          <Box sx={{ my: 1, width: '100%', padding: 2 }}>
            {!showConfirmButton && (
              <Box>
                <Typography sx={{ fontSize: '20px', fontWeight: 500 }} variant={'h4'}>
                  {t('providers_settings.order_confirmation')}
                </Typography>
                <FormGroup id={`${id}-manual`} onClick={isDisableAutoConfirm ? handleOpenAutoConfirmModal : () => {}}>
                  <FormControlLabel
                    sx={{
                      width: 'max-content',
                      '& .MuiFormControlLabel-label.Mui-disabled': {
                        color: isDisableAutoConfirm ? '#2c405a' : '#93c2fd',
                        opacity: 1,
                      },
                      '&.Mui-disabled + .MuiSwitch-track': {
                        backgroundColor: isDisableAutoConfirm ? '#a8a8a8' : '#93c2fd',
                        opacity: 1,
                      },
                    }}
                    disabled={isDisableAutoConfirm}
                    control={<Switch checked={checked} disabled={false} onChange={handleConfirmOrderChange} />}
                    label={t('providers_settings.auto_confirm_orders')}
                  />
                </FormGroup>
              </Box>
            )}
            <FormProvider {...serviceFeeFormMethods}>
              <form onSubmit={serviceFeeFormMethods.handleSubmit(handleApplyServiceFee)}>
                <Box sx={{ mt: 2 }}>
                  {/*{data?.srv !== 'table_ordering' ? (*/}
                  <>
                    <Typography sx={{ fontSize: '20px', fontWeight: 500 }} variant={'h4'}>
                      {t('providers_settings.service_fee')}
                    </Typography>

                    <FormGroup sx={{ width: '180px' }} onClick={isDisableServiceFee ? handleOpenModal : () => {}}>
                      <FormControlLabel
                        sx={{
                          width: 'max-content',
                          '& .MuiFormControlLabel-label.Mui-disabled': {
                            color: isDisableServiceFee ? '#2c405a' : '#93c2fd',
                            opacity: 1,
                          },
                          '& .MuiSwitch-switchBase.Mui-disabled': {
                            color: isDisableServiceFee ? 'white' : '#307ff9',
                          },
                          '& .MuiSwitch-switchBase.Mui-disabled + .MuiSwitch-track': {
                            backgroundColor: isDisableServiceFee ? '#a8a8a8' : '#307ff9',
                            opacity: 1,
                          },
                        }}
                        control={
                          <Switch
                            disabled={isDisableServiceFee}
                            checked={isDisableServiceFee ? false : getValues().enabled}
                            {...register('enabled')}
                          />
                        }
                        label={t('providers_settings.enable_service_fee')}
                      />
                    </FormGroup>
                  </>
                  {/*) : (*/}
                  {/*  <></>*/}
                  {/*)}*/}
                  {showFee && (
                    <Box sx={{ mt: 2, width: '400px' }}>
                      <CustomTextInput
                        inputProps={{ type: 'number', min: '0' }}
                        min="0"
                        name="percent"
                        label="Service fee(%)"
                        size="small"
                        sx={{ width: '100%', mt: 1 }}
                      />
                      <Typography sx={{ fontSize: '12px', mt: 1 }}>
                        {t('providers_settings.once_applied_added')}
                        {` ${convertNameByProvider(data?.name)} `} {t('providers_settings.menu_items_and')}
                        {` `} {`${convertNameByProvider(data?.name)}`}
                        {` `}
                        {t('providers_settings.price_')}
                      </Typography>
                      <FormGroup sx={{ mt: 2 }}>
                        <FormControlLabel
                          control={<Checkbox checked={getValues().roundup} name="roundup" {...register('roundup')} />}
                          label={t('providers_settings.round_up_provider_prices')}
                        />
                      </FormGroup>
                      <Typography sx={{ fontSize: '12px' }}>{t('providers_settings.the_round_up_follows')}</Typography>
                      <Typography sx={{ fontSize: '12px' }}>
                        {t('providers_settings.e_g_if')}
                        {` `}
                        {`${convertNameByProvider(providers[data?.srv]?.label)}`}
                        {` `}
                        {t('providers_settings.menu_item_w_')}
                      </Typography>
                      <Typography sx={{ fontSize: '12px' }}>{t('providers_settings.e_g_the_price_to_')}</Typography>
                      <Typography sx={{ fontSize: '12px' }}>
                        {t('providers_settings.if')} {` `}
                        {` ${convertNameByProvider(data?.srv)}`}
                        {` `}
                        {t('providers_settings.menu_item_modifier_')}
                      </Typography>
                      <Typography sx={{ fontSize: '12px' }}>
                        {t('providers_settings.e_g_the_price_')}
                        <br />
                        {t('providers_settings.or')}
                        <br />
                        {t('providers_settings.e_g_the_price_after')}
                      </Typography>
                      <Button
                        id="providerSettingsApply"
                        type="submit"
                        sx={{ mt: 7 }}
                        variant="contained"
                        disabled={!isDirty}
                      >
                        {t('orders_history.apply')}
                      </Button>
                    </Box>
                  )}
                </Box>
              </form>
            </FormProvider>
          </Box>
        </Grid>
        <Grid item xs={6} sx={{ width: '100%', maxWidth: '400px' }}>
          {data?.srv === providers.doordash.srv && currentBusiness?.roles?.name === 'super' && (
            <ProviderCredential credential={credentialTablet} provider={data} />
          )}
        </Grid>
      </Grid>
    </Paper>
  );
};

export default ProviderSettings;
