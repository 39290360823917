import { Box, Link, Paper, Typography } from '@mui/material';
import PhoneIcon from 'assets/icons/chargeBack/phoneIcon.svg';
import UpdatedPlanIcon from 'assets/icons/chargeBack/updated_plan_icon.svg';
import {
  DescUpdatedPlan,
  MainContainer,
  PaperWrap,
  PhoneContainer,
  PhoneUpdatedPlan,
  TitleUpdatedPlan,
} from './styles';
import { useTranslation } from 'react-i18next';

const UpdatePlan = () => {
  const { t } = useTranslation();

  return (
    <PaperWrap>
      <MainContainer>
        <Box sx={{ textAlign: 'center' }}>
          <Box component="img" src={UpdatedPlanIcon} alt="Updated Plan Icon" />
          <TitleUpdatedPlan>{t('chargeBack.update_your_plan.title')}</TitleUpdatedPlan>
        </Box>
        <Box>
          <DescUpdatedPlan>{t('chargeBack.update_your_plan.desc')}</DescUpdatedPlan>
          <DescUpdatedPlan mt={2}>{t('chargeBack.update_your_plan.subDesc')}</DescUpdatedPlan>
        </Box>
        <PhoneContainer>
          <Box component="img" src={PhoneIcon} alt="Charge back phone" />
          <PhoneUpdatedPlan href={`tel:+${t('chargeBack.update_your_plan.phone')}`} sx={{}}>
            {t('chargeBack.update_your_plan.phone')}
          </PhoneUpdatedPlan>
        </PhoneContainer>
      </MainContainer>
    </PaperWrap>
  );
};

export default UpdatePlan;
