import { useState } from 'react';
import { styled } from '@mui/material/styles';
import MuiAccordion from '@mui/material/Accordion';
import { Divider, AccordionDetails } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import { AccordionContentTitle, AccordionContentWrapper } from 'pages/TableOrdering/styles';
const AccordionItem = styled((props) => <MuiAccordion disableGutters elevation={0} square {...props} />)(() => ({
  '&::before': {
    display: 'none',
  },
  marginTop: '24px',
  borderRadius: '6px',
}));
const AccordionSummaryItem = styled((props) => (
  <MuiAccordionSummary expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />} {...props} />
))(({ theme }) => ({
  flexDirection: 'row-reverse',
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1),
  },
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(0deg)',
  },
  '& .MuiAccordionSummary-expandIconWrapper': {
    transform: 'rotate(-90deg)',
  },
}));
const AccordionWrapper = ({ children, title, expandedPanel }) => {
  const [expanded, setExpanded] = useState(true);
  const handleChange = () => setExpanded((prevState) => !prevState);
  return (
    <AccordionItem
      sx={{ boxShadow: '0px 1px 2px 0px rgba(0, 0, 0, 0.30)' }}
      expanded={expanded === expandedPanel}
      onChange={() => handleChange()}
    >
      <AccordionSummaryItem
        expandIcon={<ExpandMoreIcon />}
        aria-controls={`${expandedPanel}d-content`}
        id={`${expandedPanel}d-header`}
      >
        <AccordionContentWrapper>
          <AccordionContentTitle>{title}</AccordionContentTitle>
        </AccordionContentWrapper>
      </AccordionSummaryItem>
      <Divider />
      <AccordionDetails sx={{ mt: 2, mb: 0.5 }}>{children}</AccordionDetails>
    </AccordionItem>
  );
};
export default AccordionWrapper;
