import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import i18n from '../i18n';
import { useDispatch } from 'react-redux';
import { useState } from 'react';
import { signIn } from 'redux/actions/user';
import { useMatchTablet } from './useMatchMobile';

const schemaUserLogin = yup.object().shape({
  email: yup.string().email('this_field_is_invalid_').required('field_is_required_'),
  password: yup
    .string()
    .min(6, 'this_field_min_6_characters_')
    .max(255, 'this_field_max_255_characters_')
    .required('field_is_required_'),
});
export const useLogin = () => {
  // const changeLanguage = (language) => {
  //   i18n.changeLanguage(language);
  // };
  const dispatch = useDispatch();
  const { isMatchTablet } = useMatchTablet();
  const [submitted, setSubmitted] = useState(false);
  const [loading, setLoading] = useState(false);
  const [errorMessage, updateErrorMessage] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const userLoginFormMethods = useForm({
    defaultValues: { email: '', password: '' },
    mode: 'all',
    resolver: yupResolver(schemaUserLogin),
  });
  const visiblePassword = () => setShowPassword((prevState) => !prevState);
  const handleValidSubmit = (values) => {
    setLoading(true);
    updateErrorMessage('');
    setSubmitted(true);
    dispatch(signIn(values))
      .catch((err) => {
        if (err?.response?.status === 401) {
          userLoginFormMethods.setError('password', {
            message: 'Your username or password is wrong. Please try again.',
          });
        }
        setLoading(false);
        setSubmitted(false);
      })
      .finally(() => setLoading(false));
  };
  return {
    handleValidSubmit,
    errorMessage,
    userLoginFormMethods,
    submitted,
    visiblePassword,
    showPassword,
    isMatchTablet,
    loading,
  };
};
