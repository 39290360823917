import React, { useState, useEffect } from 'react';

import { useSelector, useDispatch } from 'react-redux';
import Preloader from 'components/Preloader';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import LabelTooltip from 'components/FormFields/LabelTooltip';
import confirm from 'components/Confirm';
import PrinterItem from './PrinterItem';
import { getPrintersList, addPrinter, deletePrinter, updatePrinter } from 'redux/actions/printers';
import PrinterTable from './PrinterTable';
import { MenuItem } from '@mui/material';
import ErrorBoundaryWrapper from 'components/ErrorBoundaryWrapper';
import { useTranslation } from 'react-i18next';

const initialValues = {
  default: false,
  ipAddress: '',
  name: '',
  port: '',
  type: '',
  ticketStyle: 'regular',
  innerMacAddress: '',
};

const Printers = () => {
  const [showPrinter, handleShowPrinter] = useState(false);
  const [printerModel, handlePrinterModel] = useState(initialValues);
  const [mode, handleMode] = useState(null);
  const { list, loading } = useSelector(({ printers }) => printers);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  // Delete Printer Item.
  const handleDelete = async (e, reportItem) => {
    e.stopPropagation();
    const { _id, haveEvents } = reportItem;
    const option = {
      title: '',
      message: t('settings.are_delete_printer'),
      cancelText: t('orders.cancel'),
      confirmText: t('table_ordering.yes_delete'),
      confirmColor: 'primary',
    };
    if (haveEvents) {
      option.message = 'The printer you want to remove has assigned events. Do you want to remove it';
      option.cancelText = 'No';
      option.confirmText = 'Yes';
    }
    const confirmed = await confirm(option);
    if (confirmed) {
      dispatch(deletePrinter(_id));
      return true;
    }
  };

  const handlePrinterItemMode = (mode, id) => {
    handleShowPrinter(true);
    handleMode(mode);
    if (id) {
      const modelData = list.find((item) => item._id === id);
      handlePrinterModel(modelData);
    } else {
      handlePrinterModel(initialValues);
    }
  };

  const handleSubmit = (val) => {
    if (mode === 'Edit') {
      dispatch(updatePrinter({ ...val })).then(() => {
        handleShowPrinter(false);
        handlePrinterModel(initialValues);
      });
    } else {
      dispatch(addPrinter({ ...val })).then(() => {
        handleShowPrinter(false);
        handlePrinterModel(initialValues);
      });
    }
  };

  const refreshList = () => {
    dispatch(getPrintersList());
  };

  useEffect(() => {
    dispatch(getPrintersList());
  }, []);

  return (
    <ErrorBoundaryWrapper>
      <div className="website-setting printer-settings">
        <Typography sx={{ my: 2 }} variant="h5">
          <LabelTooltip id="printerSettings" value={t('settings.printers_settings')} tooltip="" />
        </Typography>
        <>
          {/* Add Printers */}
          <Grid container columns={{ xs: 12 }}>
            {/* <Row className="align-item-baseline"> */}
            <Grid item xs={8}>
              <TextField
                size="small"
                sx={{ width: '100%' }}
                select
                InputLabelProps={{ shrink: true }}
                label={t('settings.select_printer')}
                onChange={() => {}}
                disabled={true}
              >
                <MenuItem> {t('settings.add_printers')} </MenuItem>
                <MenuItem>{t('settings.add_printers_2')} </MenuItem>
                <MenuItem>{t('settings.add_printers_3')}</MenuItem>
                <MenuItem>{t('settings.add_printers_4')}</MenuItem>
                <MenuItem>{t('settings.add_printers_5')}</MenuItem>
              </TextField>
            </Grid>
            <Grid item xs={4}>
              <div className="d-flex justify-content-end">
                <Button
                  id="printersAdd"
                  variant="contained"
                  color="primary"
                  type="button"
                  className="mx-2"
                  disabled={true}
                >
                  <span className="icon icon-plus"></span>
                  <span className=""> {t('settings.add')}</span>
                </Button>
                <Button
                  id="printersTitle"
                  color="primary"
                  type="button"
                  variant="contained"
                  disabled={showPrinter}
                  className={`ml-2  ${showPrinter && 'disabled'}`}
                  onClick={() => handlePrinterItemMode('Add')}
                >
                  <span className="icon icon-plus"></span>
                  <span className="">{t('settings.add_printer_title')}</span>
                </Button>
              </div>
            </Grid>
          </Grid>
          {/* Add Printer Manually */}
          {showPrinter && (
            <PrinterItem
              initialValues={initialValues}
              printerModel={printerModel}
              handlePrinterModel={handlePrinterModel}
              handleSubmit={handleSubmit}
              mode={mode}
              handleShowPrinter={handleShowPrinter}
            />
          )}
          <div className="event-list">
            <Typography sx={{ mt: 2 }} variant="h5">
              {t('settings.printer_list')}
            </Typography>
            <PrinterTable
              list={list}
              handlePrinterItemMode={handlePrinterItemMode}
              showPrinter={showPrinter}
              refreshList={refreshList}
              handleDelete={handleDelete}
              printerModel={printerModel}
            />

            {/* {!list.length && <EmptyMenu text="There are no printers yet" />} */}
          </div>
        </>
        {loading && <Preloader overlay />}
      </div>
    </ErrorBoundaryWrapper>
  );
};

export default Printers;
