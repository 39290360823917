import { useEffect } from 'react';
import { Grid, Paper } from '@mui/material';
import PosItem from 'components/Providers/PosItem';
import { getPosData } from 'redux/actions/providers';
import EmptyPos from 'components/Providers/EmptyPos';
import { useDispatch, useSelector } from 'react-redux';
import { StyledEmptyPosPaper } from 'components/Providers/styles';
const POS = () => {
  const dispatch = useDispatch();
  const CONNECTED_STATUS = 'connected';
  const { posListData } = useSelector(({ providers }) => providers);
  const { currentBusiness } = useSelector((state) => state.business);
  const filterPosListData =
    posListData.filter(
      (posItem) => posItem?._id === currentBusiness?.pos?.posId && currentBusiness?.pos?.status === CONNECTED_STATUS
    ) || [];

  useEffect(() => {
    dispatch(getPosData());
  }, []);

  // if (posListData && filterPosListData && filterPosListData.length === 0) {
  //   return (
  //     <StyledEmptyPosPaper>
  //       <EmptyPos />
  //     </StyledEmptyPosPaper>
  //   );
  // }

  return (
    <Grid spacing={2} container sx={{ mt: 2 }}>
      {filterPosListData.map((item) => {
        return (
          <Grid item key={item.id} md={6} sm={12}>
            <PosItem data={item} />
          </Grid>
        );
      })}
    </Grid>
  );
};

export default POS;
