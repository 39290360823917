import React, { useEffect, useState } from 'react';
import instance from 'services/api';
import Grid from '@mui/material/Grid';
import { toast } from 'react-toastify';
import { Box, List } from '@mui/material';
import { useParams } from 'react-router-dom';
import AuthorizationButtons from './ActionButton';
import { SIGN_UP_FLOW_AUTHORIZATION } from 'constants/availableButtons';
import { AuthorizationListItem, AuthorizationStepsDescSubTitle, AuthorizationStepsDescTitle } from '../styles';

const StepAuthorization = ({ availableSteps, setActive, active, isAvailableStepCallback }) => {
  const { id } = useParams();
  const [companyName, setCompanyName] = useState('');
  const [brandName, setBrandName] = useState('');
  const handleApprove = () => setActive(SIGN_UP_FLOW_AUTHORIZATION.AUTHORIZATION_ACCOUNT_INFO);

  useEffect(() => {
    instance
      .get(`/auth/location-registration-info/${id}`)
      .then((res) => {
        if (res.data.data.planId !== '') localStorage.setItem('currentPlan', res.data.data.planId);
        if (res.data?.data && Object.keys(res.data.data).length > 0) {
          isAvailableStepCallback(res.data?.data?.planId, res.data?.data?.companyName);
          localStorage.setItem('approvedInvitationInfo', JSON.stringify(res.data.data));
          setCompanyName(res.data?.data?.companyName);
          setBrandName(res.data?.data?.brandName);
        }
      })
      .catch((err) => {
        toast.error(err.message);
      });
  }, [id]);

  return (
    <Box>
      <AuthorizationStepsDescTitle>
        This process will register your business as a servicing location for the{' '}
        <span className="italic">{companyName}</span> brand.
      </AuthorizationStepsDescTitle>
      <AuthorizationStepsDescSubTitle>
        The <span className="italic">{companyName}</span> will have the following permissions for your location:
      </AuthorizationStepsDescSubTitle>
      <Box sx={{ ml: 5 }}>
        <List disablePadding sx={{ listStyleType: 'disc' }}>
          <AuthorizationListItem disablePadding>
            Manage <span className="italic">{brandName}</span> brand order data
          </AuthorizationListItem>
          <AuthorizationListItem disablePadding>
            Manage <span className="italic">{brandName}</span> brand menus
          </AuthorizationListItem>
          <AuthorizationListItem disablePadding>
            Manage <span className="italic">{brandName}</span> brand business settings
          </AuthorizationListItem>
          <AuthorizationListItem disablePadding>
            Manage <span className="italic">{brandName}</span> brand delivery apps
          </AuthorizationListItem>
        </List>
      </Box>
      <AuthorizationStepsDescSubTitle>
        Please review the information mentioned above and approve or decline the authorization.
      </AuthorizationStepsDescSubTitle>
      <Grid container spacing={2}>
        <Grid justifyContent="flex-end" display="flex" gap={'10px'} item xs={12} sx={{ mt: 2 }}>
          <AuthorizationButtons
            onClick={handleApprove}
            steps={availableSteps}
            active={active}
            disabled={false}
            loading={false}
            setActive={setActive}
            title={'Approve'}
            backTitle={'Decline'}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default StepAuthorization;
