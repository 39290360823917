import {
  SET_ORDERS,
  SET_ORDERS_PAGINATION,
  SET_ORDERS_PARAMS,
  RESET_ORDERS_PAGINATION,
  UPDATE_MODIFIED_ORDERS,
  RESET_MODIFIED_ORDERS,
  ORDERS_PUSH,
  ORDERS_EMPTY,
  SET_LAST_UPDATED_ORDER,
  UPDATE_CANCELED_ORDERS,
  RESET_CANCELED_ORDERS,
  SET_LAST_CANCELED_ORDER,
  SET_ORDERS_REPORT,
  SET_DISABLED_ACTIONS,
  ORDERS_HISTORY,
  ORDERS_HISTORY_LOADING,
  SET_HEADER_IS_TOUCHED,
} from 'redux/actions/types';

const initialState = {
  newOrders: [],
  modifiedOrders: [],
  canceledOrders: [],
  orderHistoryList: [],
  orderList: [],
  orderHistoryCount: 0,
  lastUpdatedId: null,
  lastCanceledId: null,
  paginationConfig: {
    limit: 8,
    skip: 0,
    count: null,
    page: 1,
  },
  params: {
    status: null,
    end: null,
    start: null,
  },
  showReport: false,
  disabledActions: false,
  isTouched: 'OPEN',
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_HEADER_IS_TOUCHED:
      return { ...state, isTouched: payload };
    case ORDERS_PUSH:
      return { ...state, orderList: [payload, ...state.orderList], newOrders: [...state.newOrders, payload] };
    case ORDERS_HISTORY:
      return { ...state, orderHistoryCount: payload.data.count, orderHistoryList: payload.data.orders };
    case ORDERS_HISTORY_LOADING:
      return { ...state, orderHistoryLoading: payload };
    case ORDERS_EMPTY:
      return { ...state, newOrders: [] };
    case SET_ORDERS:
      return { ...state, orderList: payload };
    case SET_ORDERS_PAGINATION:
      return { ...state, paginationConfig: { ...state.paginationConfig, ...payload } };
    case RESET_ORDERS_PAGINATION:
      return { ...state, paginationConfig: initialState.paginationConfig };
    case SET_ORDERS_PARAMS:
      return { ...state, params: { ...state.params, ...payload } };
    case UPDATE_MODIFIED_ORDERS:
      return { ...state, modifiedOrders: [...state.modifiedOrders, payload] };
    case RESET_MODIFIED_ORDERS:
      return { ...state, modifiedOrders: [] };
    case SET_LAST_UPDATED_ORDER:
      return { ...state, lastUpdatedId: payload };
    case SET_LAST_CANCELED_ORDER:
      return { ...state, lastCanceledId: payload };

    case UPDATE_CANCELED_ORDERS:
      return { ...state, canceledOrders: [...state.canceledOrders, payload] };
    case RESET_CANCELED_ORDERS:
      return { ...state, canceledOrders: [] };
    case SET_ORDERS_REPORT:
      return { ...state, showReport: payload };
    case SET_DISABLED_ACTIONS:
      return { ...state, disabledActions: payload };
    default:
      return state;
  }
};
