import React, { useEffect, useState } from 'react';
import * as yup from 'yup';
import Box from '@mui/material/Box';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import DeleteIcon from 'assets/icons/TableDelete.png';
import { useDispatch, useSelector } from 'react-redux';
import { yupResolver } from '@hookform/resolvers/yup';
import { confirm } from 'components/MenuNew/ConfirmModal';
import {
  createSectionTO,
  deleteSectionTO,
  getTableOrderingSectionTO,
  updateSectionTO,
} from 'redux/actions/TableOrdering/sections';
import { LAST_CREATED_SECTION_TO } from 'redux/actions/types';
import { MAX_ITEM_COUNT_IN_TABLE, MODE } from 'pages/TableOrdering/config';
import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined';
import IconButton from '@mui/material/IconButton';

const defaultValues = {
  name: '',
};

export const useTableOrderingSections = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const [open, setOpen] = useState(false);
  const [mode, setMode] = useState(MODE.CREAT);
  const [rowsPerPage, setRowsPerPage] = useState(MAX_ITEM_COUNT_IN_TABLE);
  const { currentBusiness } = useSelector(({ business }) => business);
  const { sections = [], loading, lastCreatedSection } = useSelector(({ tableOrdering }) => tableOrdering);
  const { t } = useTranslation();
  const sectionFormSchema = yup.object().shape({
    name: yup.string().required('field_is_required_'),
  });
  useEffect(() => {
    if (currentBusiness?._id) {
      dispatch(getTableOrderingSectionTO(currentBusiness?._id));
    }
  }, [currentBusiness?._id, location]);

  const sectionColumns = [
    {
      field: 'name',
      headerName: t('table_ordering.section_name'),
      flex: 1,
      sortable: false,
      filterable: false,
      editable: false,
    },
    {
      id: 'tools',
      field: 'Tools',
      type: 'actions',
      renderCell: (params) => {
        return (
          <Box sx={{ display: 'flex' }}>
            <IconButton aria-label="delete" onClick={() => editSection(params?.row)}>
              <ModeEditOutlineOutlinedIcon />
            </IconButton>
            <IconButton aria-label="delete" onClick={() => deleteSection(params?.row)}>
              <img src={DeleteIcon} alt="delete icon" />
            </IconButton>
          </Box>
        );
      },
    },
  ];
  useEffect(() => {
    return () => {
      dispatch({ type: LAST_CREATED_SECTION_TO, payload: null });
    };
  }, []);
  const sectionFilter = sections?.map((section) => ({
    id: section._id,
    name: section?.name || '',
  }));

  const formMethods = useForm({
    defaultValues,
    mode: 'all',
    resolver: yupResolver(sectionFormSchema),
  });

  const deleteSection = async (data) => {
    const confirmed = await confirm({
      title: t('table_ordering.delete_section'),
      message: t('table_ordering.are_sure_you_list'),
      cancelText: t('orders.cancel'),
      confirmText: t('table_ordering.yes_delete'),
      confirmColor: 'primary',
      background: 'rgba(226, 21, 8, 1)',
    });
    if (confirmed) {
      dispatch(deleteSectionTO(data?.id));
    }
  };

  const editSection = (data) => {
    setOpen(true);
    formMethods.reset(data);
    setMode(MODE.EDIT);
  };

  const createSection = (values) => {
    const requestData = {
      name: values.name,
    };
    formMethods.clearErrors();
    dispatch(createSectionTO(requestData)).then(() => {
      handleCancel();
    });
  };

  const updateSection = (values) => {
    const requestData = {
      sectionId: values.id,
      body: {
        name: values.name,
      },
    };
    formMethods.clearErrors();
    dispatch(updateSectionTO(requestData)).then(() => {
      handleCancel();
    });
  };

  const handleSubmit = (values) => {
    if (open && mode === MODE.EDIT) {
      updateSection(values);
    } else {
      createSection(values);
    }
  };

  const handleCancel = () => {
    formMethods.reset(defaultValues);
    formMethods.clearErrors();
    setOpen(false);
    setTimeout(() => {
      setMode(MODE.CREAT);
    }, 300);
  };

  return {
    handleSubmit,
    handleCancel,
    formMethods,
    sectionColumns,
    sectionFilter,
    loading,
    open,
    setOpen,
    mode,
    rowsPerPage,
    setRowsPerPage,
    lastCreatedSection,
  };
};
