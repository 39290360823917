import React, { useState, useEffect } from 'react';

import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Divider,
  InputAdornment,
  MenuItem,
  Tooltip,
  Typography,
} from '@mui/material';

import { useForm, FormProvider } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { yupResolver } from '@hookform/resolvers/yup';
import CustomSwitch from 'components/Forms/CustomSwitch';
import CustomTextInput from 'components/Forms/CustomTextInput';
import { LOYALTY_REWARDS_POINTS, LOYALTY_REWARDS_VALUES } from './config';
import { getLoyaltyRewards, updateLoyaltyRewards } from 'redux/actions/rewards';
import { dataAdapterLoyalityRewards, defaultValuesLoyaltyRewards } from './helper';
import Preloader from 'components/Preloader';
import { useSelector } from 'react-redux';
import ConfirmPromptModal from 'components/ConfirmPromptModal/ConfirmPromptModal';

const CashbackRewards = ({ setRewardEnabled }) => {
  const { currentBusiness } = useSelector(({ business }) => business);
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [disabledActions, setDisabledAction] = useState(false);

  const rewardSchema = yup.object().shape({
    pointSingularName: yup.string().trim().required('field_is_required_'),
    pointPluralName: yup.string().trim().required('field_is_required_'),
    redemptionRewardDefinition: yup.object().shape({
      spendingPoints: yup.string().trim().required('field_is_required_'),
      amountEarned: yup.string().trim().required('field_is_required_'),
    }),
  });

  const cashbackRewardsMethods = useForm({
    defaultValues: defaultValuesLoyaltyRewards,
    mode: 'all',
    resolver: yupResolver(rewardSchema),
  });

  const { reset, getValues } = cashbackRewardsMethods;

  useEffect(() => {
    setLoading(true);
    getLoyaltyRewards()
      .then((res) => {
        const adaptedData = dataAdapterLoyalityRewards(res?.data?.data);
        setDisabledAction(adaptedData.enabled);
        reset(adaptedData);
        setRewardEnabled(adaptedData.enabled);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [reset, setRewardEnabled]);

  const handleValidSubmit = (values) => {
    setLoading(true);
    const data = dataAdapterLoyalityRewards(values);
    updateLoyaltyRewards(data)
      .then((res) => {
        const adaptedData = dataAdapterLoyalityRewards(res?.data?.data);
        setRewardEnabled(adaptedData.enabled);
        setDisabledAction(adaptedData.enabled);
        reset(adaptedData, { keepTouched: false, keepDirty: false });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleInValidSubmit = (err) => {
    console.log('err: ', err);
  };

  return (
    <Box sx={{ position: 'relative' }}>
      <ConfirmPromptModal
        handleConfirm={cashbackRewardsMethods.handleSubmit(handleValidSubmit)}
        submitData={cashbackRewardsMethods.getValues()}
        hasUnsavedChanges={cashbackRewardsMethods.formState.isDirty}
        formState={cashbackRewardsMethods}
      />
      <FormProvider {...cashbackRewardsMethods}>
        {loading && <Preloader overlay />}
        <form onSubmit={cashbackRewardsMethods.handleSubmit(handleValidSubmit, handleInValidSubmit)}>
          <Card>
            <CardContent sx={{ p: 0 }}>
              <Box
                sx={{
                  p: 3,
                  '& label': {
                    margin: 0,
                  },
                }}
              >
                <CustomSwitch
                  name="enabled"
                  disabled={currentBusiness?.roles?.name !== 'super' && disabledActions}
                  checked={getValues().enabled}
                  label={
                    <Box sx={{ alignItems: 'center', display: 'flex' }}>
                      {t('Loyalty rewards')}
                      <Tooltip sx={{ ml: 0.5 }} title={t('rewards.loyalty_program')}>
                        <HelpOutlineIcon fontSize={'small'} />
                      </Tooltip>
                    </Box>
                  }
                />
              </Box>
              <Divider />
              <Box sx={{ p: 3 }}>
                <Typography>{t('Name your points')}</Typography>
                <Box sx={{ mt: 2, display: 'flex', justifyContent: 'space-between', gap: 2 }}>
                  <CustomTextInput
                    disabled={currentBusiness?.roles?.name !== 'super' && disabledActions}
                    helperText={t('Please enter a name for a single reward point (e.g.,"Gem") or leave it as is.')}
                    name="pointSingularName"
                    label={`${t('Singular')} *`}
                    sx={{ width: '100%' }}
                  />
                  <CustomTextInput
                    disabled={currentBusiness?.roles?.name !== 'super' && disabledActions}
                    name="pointPluralName"
                    label={`${t('Plural')} *`}
                    sx={{ width: '100%' }}
                    helperText={t('Please enter the plural version of reward points (e.g., "Gems") or leave it as is.')}
                  />
                </Box>
              </Box>
              <Divider />
              <Box sx={{ p: 3 }}>
                <Typography>{t('Point redemption details')}</Typography>
                <Box sx={{ mt: 2, gap: 2, display: 'flex', justifyContent: 'space-between' }}>
                  <CustomTextInput
                    disabled={currentBusiness?.roles?.name !== 'super' && disabledActions}
                    sx={{ width: '100%' }}
                    select={true}
                    name="redemptionRewardDefinition.spendingPoints"
                    label={`${'Points threshold'} *`}
                    helperText={t('The number of points')}
                  >
                    {LOYALTY_REWARDS_POINTS.map((option) => (
                      <MenuItem key={option._id} value={option.value}>
                        {option.value}
                      </MenuItem>
                    ))}
                  </CustomTextInput>
                  <CustomTextInput
                    disabled={currentBusiness?.roles?.name !== 'super' && disabledActions}
                    sx={{ width: '100%' }}
                    select={true}
                    name="redemptionRewardDefinition.amountEarned"
                    label="Redemption value *"
                    InputProps={{ startAdornment: <InputAdornment position="start">$</InputAdornment> }}
                    helperText={t('The amount your customers will receive for 1 point.')}
                  >
                    {LOYALTY_REWARDS_VALUES.map((option) => (
                      <MenuItem key={option._id} value={option.value}>
                        {option.value}
                      </MenuItem>
                    ))}
                  </CustomTextInput>
                </Box>
              </Box>
            </CardContent>
            <CardActions sx={{ p: 3, justifyContent: 'flex-end' }}>
              <Button
                type="submit"
                size="large"
                variant="contained"
                disabled={!cashbackRewardsMethods.formState.isDirty}
              >
                Save
              </Button>
            </CardActions>
          </Card>
        </form>
      </FormProvider>
    </Box>
  );
};

export default CashbackRewards;
