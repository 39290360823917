import React, { useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { SortableElement } from 'react-sortable-hoc';
import moment from 'moment-timezone';
import { Box, Typography, ListItemButton, Avatar, Icon, Grid } from '@mui/material';
import ImageOutlinedIcon from '@mui/icons-material/ImageOutlined';
import useQuery from 'hooks/useQuery';
import DragHandle from 'components/DragHandle';
import LinkedProviderPopover from 'components/MenuNew/LinkedProviderPopover';
import { renderPermanently } from 'utils/soldOut';
import Private from 'components/Private';
import InfoBulkIcon from 'assets/icons/info_icon_bulk.svg';
import { showPriceRange } from 'utils/menu';
import { checkLinkedProviderWebsite, linkedWithProvider } from 'helpers/linkedWithProvider';
import { t } from 'i18next';
import useProviderConfig from 'hooks/useProviderConfig';

const SortableProductItem = SortableElement(({ product, menu, loading, cid, category }) => {
  const { _id } = product;
  const { providers } = useProviderConfig();
  const history = useHistory();
  const query = useQuery();
  const productId = query.get('pid');

  const handleOpenProduct = (e, product) => {
    e.preventDefault();
    e.stopPropagation();
    history.push({
      search: `?entityType=item&pid=${product._id}&cid=${cid}&mode=edit`,
    });
  };

  const linked = useMemo(() => {
    return menu.connectedProviders && linkedWithProvider(menu.connectedProviders, providers);
  }, [menu]);

  const checkWebsite = useMemo(() => {
    return menu.connectedProviders && checkLinkedProviderWebsite(menu.connectedProviders, providers);
  }, [menu]);

  return (
    <Box sx={{ width: '100%' }} className="nav-item-draggable" key={_id}>
      <ListItemButton
        sx={{
          background: 'white',
          display: 'flex',
          justifyContent: 'space-between',
          py: 2,
          px: 3,
        }}
        key={product._id}
        id={!product?.bulkInfo?.isSaved && productId === _id && linked && checkWebsite ? 'overview-selected' : ''}
        className={!product?.bulkInfo?.isSaved && linked && checkWebsite ? 'overview-items' : ''}
        onClick={(e) => handleOpenProduct(e, product)}
      >
        <Box sx={{ display: 'flex', alignItems: 'space-between', width: '100%' }}>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Private permission="MENU_EDIT" disabled={true} background="none">
              <Icon sx={{ color: '#9E9EAD' }} component={DragHandle} />
            </Private>
            <Box sx={{ mr: 1 }}>
              <LinkedProviderPopover
                id={_id}
                categoryId={category._id}
                connectedProviders={product.connectedProviders}
                targetConnectedProviders={category.connectedProviders}
                showCount={false}
                target="item"
                overrideStyle={{
                  m: 0,
                }}
                targetData={product}
              />
            </Box>
          </Box>

          <Box sx={{ display: 'flex', alignItems: 'center', width: '100%' }}>
            <Avatar
              sx={{ bgcolor: '#E2E2E2', width: '48px', height: '48px', ml: 0 }}
              alt={product.name}
              src={`${product?.image?.url}?tr=w-48,h-48`}
            >
              <Icon
                sx={{
                  color: '#323232',
                }}
                component={ImageOutlinedIcon}
              />
            </Avatar>
            <Box sx={{ width: '100%' }}>
              <Grid container>
                <Grid item>
                  <Box
                    sx={{
                      display: 'flex',
                      ml: 2,
                    }}
                  >
                    <Typography sx={{ fontSize: '14px', color: '#141414', fontWeight: 500 }} variant="body1">
                      {product.name || 'Name'}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={12}>
                  <Box
                    sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'unset' }}
                  >
                    <Grid item xs={9}>
                      <Box sx={{ display: 'flex', flexDirection: 'column', ml: 2 }}>
                        <Typography sx={{ fontSize: '12px', color: '#434343', fontWeight: 400 }} variant="caption">
                          {product.description || null}
                        </Typography>

                        <Box
                          sx={{
                            display: 'flex',
                            alignItems: 'center',
                            mt: 0.5,
                          }}
                        >
                          <Typography
                            sx={{
                              fontSize: '14px',
                              color: '#EF3061',
                              fontWeight: 500,
                              justifyContent: 'center',
                              display: 'flex',
                            }}
                            variant="body1"
                          >
                            {renderPermanently(product, moment) ? (
                              <>
                                <Box component="img" sx={{ mr: 0.5 }} src={InfoBulkIcon} alt="INFO_BULK_ICON" />
                                {t(renderPermanently(product, moment))}
                              </>
                            ) : (
                              ''
                            )}
                          </Typography>
                        </Box>
                      </Box>
                    </Grid>
                    <Grid item xs={3}>
                      <Box
                        sx={{
                          display: 'flex',
                          alignItems: 'baseline',
                          justifyContent: 'end',
                        }}
                      >
                        <Typography sx={{ fontSize: '16px', color: '#141414', fontWeight: 500 }} variant="caption">
                          {showPriceRange(product)}
                        </Typography>
                      </Box>
                    </Grid>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Box>
      </ListItemButton>
    </Box>
  );
});

export default SortableProductItem;
