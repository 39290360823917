import React, { useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Button } from 'reactstrap';
import { useTranslation } from 'react-i18next';

function OverlayOrders({ newOrders, modifiedOrders, canceledOrders, openOrders, setOverlay }) {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const { t } = useTranslation();

  return (
    <div
      className={
        canceledOrders.length
          ? 'cancel-order-overlay'
          : modifiedOrders.length
          ? 'update-order-overlay'
          : 'new-orders-overlay'
      }
    >
      <Button id="overlayOrders" close onClick={() => setOverlay(false)}>
        <i className="icon icon-x" />
      </Button>
      {/* modifiedOrders */}
      <div className={'ordersCount'}>
        {canceledOrders.length ? (
          <div className={`order-section ${modifiedOrders.length || newOrders.length ? 'mr-5' : ''}`}>
            <span className="cancel-order-count">{canceledOrders.length}</span>
            <p>
              {t('order_view.canceled')}
              {newOrders.length ? <br /> : null}
              {t('order_view.order')} {canceledOrders.length > 1 ? 's' : ''}
            </p>
          </div>
        ) : null}
        {modifiedOrders.length ? (
          <div className={`order-section ${canceledOrders.length || newOrders.length ? 'mr-5' : ''}`}>
            <span
              className={
                canceledOrders.length
                  ? 'cancel-order-count'
                  : modifiedOrders.length
                  ? 'modified-order-count'
                  : 'new-order-count'
              }
            >
              {modifiedOrders.length}
            </span>
            <p>
              {t('orders_history.updated')}
              {newOrders.length ? <br /> : null} {t('order_view.order')}
              {modifiedOrders.length > 1 ? 's' : ''}
            </p>
          </div>
        ) : null}
        {newOrders.length ? (
          <div className="order-section m-0">
            <span
              className={
                canceledOrders.length
                  ? 'cancel-order-count'
                  : modifiedOrders.length
                  ? 'modified-order-count'
                  : 'new-orders-count'
              }
            >
              {newOrders.length}
            </span>
            <p>
              {t('orders_history.new')}
              {modifiedOrders.length ? <br /> : null} {t('order_view.order')}
              {modifiedOrders.length && newOrders.length > 1 ? 's' : ''}
            </p>
          </div>
        ) : null}
      </div>
      <Button id="overlayOrdersShow" color="light" onClick={openOrders}>
        {t('orders.show_order')}
        {modifiedOrders.length + newOrders.length > 1 ? 's' : ''}{' '}
      </Button>
    </div>
  );
}

export default OverlayOrders;
