import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import CustomTextInput from 'components/Forms/CustomTextInput';
import LabelRequired from 'components/FormFields/LabelRequired';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { Box, Grid, InputAdornment, IconButton } from '@mui/material';

const GrubHubV2Form = () => {
  const { t } = useTranslation();
  const [showPassword, setShowPassword] = useState(false);
  const visiblePassword = () => setShowPassword((prevState) => !prevState);
  return (
    <Box sx={{ height: '415px', mt: 2 }}>
      <Grid spacing={2} container>
        <Grid item md={12} sm={12} lg={6} sx={{ height: '72px' }}>
          <CustomTextInput
            disabled={false}
            sx={{ width: '100%' }}
            name="storeId"
            label={<LabelRequired>{t('Store ID')}</LabelRequired>}
          />
        </Grid>
        <Grid item md={12} sm={12} lg={6} sx={{ height: '72px' }}>
          <CustomTextInput
            disabled={false}
            sx={{ width: '100%' }}
            name="previewUrl"
            label={<LabelRequired>{t('Preview URL')}</LabelRequired>}
          />
        </Grid>
        <Grid item md={12} sm={12} lg={6} sx={{ height: '72px' }}>
          <CustomTextInput
            disabled={false}
            sx={{ width: '100%' }}
            name="username"
            label={<LabelRequired>{t('User name')}</LabelRequired>}
          />
        </Grid>
        <Grid item md={12} sm={12} lg={6} sx={{ height: '72px' }}>
          <CustomTextInput
            disabled={false}
            sx={{ width: '100%' }}
            name="password"
            InputProps={{
              type: showPassword ? 'text' : 'password',
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton aria-label="toggle password visibility" onClick={visiblePassword} edge="end">
                    {showPassword ? (
                      <VisibilityOff sx={{ fontSize: '1.2rem' }} />
                    ) : (
                      <Visibility sx={{ fontSize: '1.2rem' }} />
                    )}
                  </IconButton>
                </InputAdornment>
              ),
            }}
            label={<LabelRequired>{t('Password')}</LabelRequired>}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default GrubHubV2Form;
