import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { PROVIDERS_START_RESUME } from 'redux/actions/types';
import Divider from '@mui/material/Divider';

const MuiDialog = ({
  cancelText,
  confirmText,
  dialogTitle,
  onConfirm,
  onCancel,
  showCancel,
  children,
  description,
  confirmDisabled,
  isOpen,
  maxWidth = 'xs',
  styles = {},
  target = null,
  showCloseIcon = false,
}) => {
  const dispatch = useDispatch();
  const isProgress = target === 'progress';
  const cancelBtn = styles?.cancelBtn || {};

  const handleClose = () => {
    dispatch({ type: PROVIDERS_START_RESUME, payload: { showModal: false } });
  };
  const handleConfirm = () => {
    handleClose();
    onConfirm();
  };
  const handleCancel = () => {
    handleClose();
    onCancel();
  };
  return (
    <Dialog
      open={isOpen}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      maxWidth={maxWidth}
      sx={
        maxWidth === 'lg'
          ? { '.MuiPaper-elevation': { minWidth: '500px', maxWidth: isProgress ? '538px' : '100%' } }
          : {}
      }
    >
      <DialogTitle
        id="alert-dialog-title"
        sx={{
          fontSize: styles?.title?.fontSize ? styles?.title?.fontSize : '1.25rem',
          color: styles?.title?.color ? styles?.title?.color : 'initial',
        }}
      >
        {dialogTitle}
        {showCloseIcon ? (
          <IconButton sx={{ position: 'absolute', top: 16, right: 12 }} aria-label="close" onClick={onCancel}>
            <CloseIcon />
          </IconButton>
        ) : (
          <></>
        )}
      </DialogTitle>
      <DialogContent sx={{ minWidth: styles?.widthModal?.width ? styles?.widthModal?.width : '310px' }}>
        <DialogContentText
          id="alert-dialog-description"
          sx={{
            fontSize: styles?.description?.fontSize ? styles?.description?.fontSize : '14px',
            lineHeight: '20px',
            marginBottom: styles?.description?.marginBottom ? styles?.description?.marginBottom : 'initial',
            color: styles?.description?.color ? styles?.description?.color : '#657786',
          }}
        >
          {description}
        </DialogContentText>
        {children}
      </DialogContent>
      {isProgress && <Divider sx={{ mt: 1 }} />}
      <DialogActions
        sx={{
          flexDirection: isProgress ? 'row-reverse' : 'initial',
          justifyContent: isProgress ? 'end' : 'flex-start',
          p: 2,
        }}
      >
        <Button id="confirmTextDisabled" disabled={confirmDisabled} variant="contained" onClick={handleConfirm}>
          {confirmText}
        </Button>
        {showCancel && (
          <Button
            sx={{
              ...cancelBtn,
            }}
            id="confirmTextCancel"
            onClick={handleCancel}
          >
            {cancelText}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

MuiDialog.propTypes = {
  dialogTitle: PropTypes.string.isRequired,
  showCancel: PropTypes.bool.isRequired,
  confirmDisabled: PropTypes.bool,
  cancelText: PropTypes.string,
  confirmText: PropTypes.string,
  dialogContent: PropTypes.string,
  description: PropTypes.string,
  onConfirm: PropTypes.func,
  onCancel: PropTypes.func,
};

MuiDialog.defaultProps = {
  dialogTitle: '',
  description: '',
  confirmText: 'Save',
  cancelText: 'Cancel',
  confirmDisabled: false,
  showCancel: true,
  onConfirm: () => {},
  onCancel: () => {},
};

export default MuiDialog;
