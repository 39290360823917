import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Paper, Popover } from '@mui/material';
import { StyledTypographyItem } from './styles';
import StatusChangeModal from 'components/Order/OrderStatusChange/StatusChangeModal';

const OrderStatusChange = ({ open, id, anchorEl, handleClose, data }) => {
  const { t } = useTranslation();
  const [openStatusModal, setOpenStatusModal] = useState(false);
  const openChangeOrderStatusModal = () => {
    setOpenStatusModal(true);
    handleClose();
  };
  const closeStatusModal = () => setOpenStatusModal(false);

  return (
    <>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <Paper sx={{ width: '352px' }} onClick={openChangeOrderStatusModal}>
          <StyledTypographyItem>{t('orders_history.change_status')}</StyledTypographyItem>
        </Paper>
      </Popover>
      <StatusChangeModal openStatusModal={openStatusModal} closeStatusModal={closeStatusModal} data={data} />
    </>
  );
};

export default OrderStatusChange;
