import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  DialogActionsButton,
  DialogActionsContentBox,
  DialogContentBox,
  DialogTextProvider,
  DialogTextSubBox,
  DialogTextSubProvider,
  DialogTextSubProviderLink,
} from './styles';
import { Dialog } from '@mui/material';
import DraggableDialogWrapper from 'components/DraggableDialogWrapper/DraggableDialogWrapper';

const ConfirmProviderAction = ({
  showModal,
  handleClose,
  title,
  description,
  label = '',
  icon,
  iconName,
  phoneNumber = '',
}) => {
  const { t } = useTranslation();
  return (
    <Dialog
      open={showModal}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullWidth={true}
      PaperComponent={DraggableDialogWrapper}
      PaperProps={{
        sx: { width: '764px', height: '670px', maxWidth: '100%', background: '#FAFAFA' },
      }}
    >
      <DialogContentBox>
        <img src={icon} alt={iconName} />
        <DialogTextProvider>{t(title)}</DialogTextProvider>
        {label ? (
          <DialogTextSubProvider>{`${t(description, { name: label })}`}</DialogTextSubProvider>
        ) : (
          <DialogTextSubProvider>{`${t(description)}`}</DialogTextSubProvider>
        )}
        {phoneNumber && (
          <DialogTextSubBox>
            <DialogTextSubProvider>{`${t(phoneNumber)}`}</DialogTextSubProvider>
            <DialogTextSubProviderLink href="tel:+18555001070">{`1-(855) 500-1070`}</DialogTextSubProviderLink>
          </DialogTextSubBox>
        )}
      </DialogContentBox>
      <DialogActionsContentBox>
        <DialogActionsButton variant="contained" onClick={handleClose}>
          {t('settings.ok')}
        </DialogActionsButton>
      </DialogActionsContentBox>
    </Dialog>
  );
};

export default ConfirmProviderAction;
