import startStopInitValues from 'constants/resumePause';
import { PROVIDERS_START_RESUME, SET_PROVIDER_CONFIG, SET_POS_DATA, SET_CONNECTION_LOADING } from 'redux/actions/types';

const initStopResumeOrders = {
  loading: false,
  showModal: false,
  needSubmit: false, // submit event flag
  submitComponent: '', // 'settings' | 'navigator'
  provider: 'all',
  pause: true,
  data: {
    duration: startStopInitValues.duration[0].value,
    reason: startStopInitValues.reason[0].value,
    action: startStopInitValues.action[0].value,
    unit: 'seconds',
  },
};

const initStatusesResumePause = {
  message: '',
  status: 'RECEIVING',
  paused_until: '',
  immutable_until: '',
  details: [
    {
      provider: 'grubhub',
      message: '',
      provider_status: 'RECEIVING',
      paused_until: null,
      immutable_until: 1654781164,
    },
    {
      provider: 'ubereats',
      message: '',
      provider_status: 'PAUSED',
      paused_until: 1654847251,
      immutable_until: 1654844251,
    },
    {
      provider: 'doordash',
      message: '',
      provider_status: 'RECEIVING',
      paused_until: null,
      immutable_until: 1653992623,
    },
  ],
};

const initialState = {
  items: [],
  posListData: [],
  providerConfig: {},
  providersSettings: {},
  providerId: null,
  providerConnectData: {
    connectionStarted: false,
  },
  connectionLoading: false,
  targetProviderConnect: null,
  loading: false,
  clicked: false,
  stopResumeOrders: {
    statuses: initStatusesResumePause,
    loading: false,
    showModal: false,
    needSubmit: false,
    submitComponent: '',
    provider: 'all',
    pause: true,
    data: {
      duration: startStopInitValues.duration[0].value,
      reason: startStopInitValues.reason[0].value,
      action: startStopInitValues.action[0].value,
      unit: 'seconds',
    },
    error: '',
  },
};

const reducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_CONNECTION_LOADING:
      return {
        ...state,
        connectionLoading: payload,
      };
    case 'PROVIDERS_REQ':
      return {
        ...state,
        loading: true,
      };
    case 'PROVIDER_CONNECT_CONVENTIONAL_ERROR':
      return {
        ...state,
        error: payload,
        loading: false,
      };
    case 'PROVIDER_CONNECT_DATA_RESPONSE':
      return {
        ...state,
        providerConnectData: {
          ...state.providerConnectData,
          ...payload,
        },
      };
    case 'EMPTY_PROVIDER_CONNECT_DATA_RESPONSE':
      return {
        ...state,
        providerConnectData: {
          connectionStarted: false,
        },
      };
    case 'PROVIDER_CONNECTION_STARTED':
      return {
        ...state,
        providerConnectData: {
          ...state.providerConnectData,
          connectionStarted: payload,
        },
      };
    case 'TARGET_PROVIDER_CONNECT':
      return {
        ...state,
        targetProviderConnect: payload,
      };

    case 'PROVIDERS_ERR':
      return {
        ...state,
        loading: false,
      };
    case 'PROVIDERS_RES':
      return {
        ...state,
        ...payload,
        loading: payload.loading || false,
      };
    case 'PROVIDERS_DOORDASH_CLICK':
      return {
        ...state,
        clicked: payload || false,
      };
    case PROVIDERS_START_RESUME:
      return {
        ...state,
        stopResumeOrders: {
          ...state.stopResumeOrders,
          ...payload,
        },
        loading: false,
      };
    case 'UPDATE_PROVIDER_RESUME_PAUSE_STATUSES':
      return {
        ...state,
        stopResumeOrders: {
          ...state.stopResumeOrders,
          statuses: {
            ...state.stopResumeOrders.statuses,
            ...payload,
          },
        },
        loading: false,
      };
    case 'PROVIDERS_START_RESUME_RESET':
      return {
        ...state,
        stopResumeOrders: {
          ...state.stopResumeOrders,
          ...initStopResumeOrders,
        },
        loading: false,
      };
    case SET_PROVIDER_CONFIG:
      return {
        ...state,
        providerConfig: {
          ...state.providerConfig,
          ...payload,
        },
        loading: false,
      };
    case SET_POS_DATA:
      return {
        ...state,
        posListData: payload || [],
      };
    default:
      return state;
  }
};

export default reducer;
