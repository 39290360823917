import React from 'react';
import DraggableDialogWrapper from 'components/DraggableDialogWrapper/DraggableDialogWrapper';
import { Dialog, DialogTitle, IconButton, DialogContent, Divider } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useTranslation } from 'react-i18next';
import {
  ConfirmDialogAction,
  ConfirmDialogCancelButton,
  ConfirmDialogConfirmButton,
  ConfirmDialogContentDescription,
  ConfirmDialogContentSubDescription,
  ConfirmDialogContentTitle,
} from './styles';
const ConfirmProviderMenu = ({ showModal, handleClose, handleConfirm }) => {
  const { t } = useTranslation();
  const CustomDialogTitle = (props) => {
    const { children, onClose, ...other } = props;
    return (
      <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
        {children}
        {onClose ? (
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: 'absolute',
              right: 24,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon sx={{ fontSize: '38px', color: '#000000', opacity: '80%' }} />
          </IconButton>
        ) : null}
      </DialogTitle>
    );
  };
  return (
    <Dialog
      open={showModal}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullWidth={true}
      PaperComponent={DraggableDialogWrapper}
      PaperProps={{
        sx: { width: '764px', height: '670px', maxWidth: '100%', background: '#FAFAFA' },
      }}
    >
      <CustomDialogTitle
        id="alert-dialog-title"
        onClose={handleClose}
        sx={{ fontSize: '24px', fontWeight: '600', lineHeight: '32px' }}
      >
        {t('menu.confirm_menu_update')}
      </CustomDialogTitle>
      <Divider />
      <DialogContent sx={{ width: '100%' }}>
        <ConfirmDialogContentTitle>{t('menu.confirm_update_title')}</ConfirmDialogContentTitle>
        <ConfirmDialogContentDescription>{t('menu.confirm_update_description')}</ConfirmDialogContentDescription>
        <ConfirmDialogContentSubDescription>
          {t('menu.confirm_update_sub_description')}
        </ConfirmDialogContentSubDescription>
      </DialogContent>
      <Divider />
      <ConfirmDialogAction>
        <>
          <ConfirmDialogCancelButton variant="contained" color="error" onClick={handleClose}>
            {t('orders.cancel')}
          </ConfirmDialogCancelButton>
          <ConfirmDialogConfirmButton variant="contained" loading={false} onClick={handleConfirm}>
            {t('menu.confirm_update')}
          </ConfirmDialogConfirmButton>
        </>
      </ConfirmDialogAction>
    </Dialog>
  );
};
export default ConfirmProviderMenu;
