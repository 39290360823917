import React from 'react';
import { SortableContainer } from 'react-sortable-hoc';
import { Box } from '@mui/material';
import SortableModiferItem from './SortableModiferItem';

const SortableModiferContainerList = SortableContainer(
  ({ items, handleDeleteModifier, handleEditModifier, loadingModifierOption, currentProduct }) => (
    <Box width="100%">
      {items.map((item, index) => (
        <SortableModiferItem
          key={`item-${item.id}`}
          onDelete={() => handleDeleteModifier(item)}
          index={index}
          value={item}
          currentProduct={currentProduct}
          handleEditModifier={handleEditModifier}
          loadingModifierOption={loadingModifierOption}
        />
      ))}
    </Box>
  )
);

export default SortableModiferContainerList;
