import * as React from 'react';
import { DataGrid, GridToolbarContainer, GridToolbarFilterButton } from '@mui/x-data-grid';

const CustomToolbar = ({ setFilterButtonEl }) => (
  <GridToolbarContainer>
    <GridToolbarFilterButton ref={setFilterButtonEl} />
  </GridToolbarContainer>
);

const StyledDataGrid = ({ ...data }) => {
  const [filterButtonEl, setFilterButtonEl] = React.useState(null);

  return (
    <DataGrid
      {...data}
      sx={(theme) => ({
        '& .MuiDataGrid-columnHeaders': {
          backgroundColor: theme.palette.primary.main,
          color: theme.palette.common.white,
        },
        '& .MuiDataGrid-row	': {
          '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.action.hover,
          },
        },
        '& .MuiDataGrid-sortIcon': {
          color: theme.palette.common.white,
        },
        '& .MuiDataGrid-filterIcon': {
          color: theme.palette.common.white,
        },
        '& .MuiDataGrid-menuIcon	': {
          '& .MuiButtonBase-root': { color: theme.palette.common.white },
        },
        '& .MuiTablePagination-toolbar': {
          '& p': { marginBottom: 0 },
        },

        '&.MuiDataGrid-cell:focus': {
          outline: 'none',
        },
      })}
      components={{
        Toolbar: CustomToolbar,
      }}
      componentsProps={{
        panel: {
          anchorEl: filterButtonEl,
        },
        toolbar: {
          setFilterButtonEl,
        },
      }}
    />
  );
};

export default StyledDataGrid;
