import React, { useState, useEffect } from 'react';
import { brandSignUp } from './schema';
import { useHistory } from 'react-router-dom';
import Checkbox from '@mui/material/Checkbox';
import FormGroup from '@mui/material/FormGroup';
import { collectGoogleQueryData, trialVersionDate } from 'utils/soldOut';
import FormControl from '@mui/material/FormControl';
import { yupResolver } from '@hookform/resolvers/yup';
import { BrandSubscriptionTitleTitle } from './styles';
import { useForm, FormProvider } from 'react-hook-form';
import FormHelperText from '@mui/material/FormHelperText';
import CircularProgress from '@mui/material/CircularProgress';
import SubscriptionPlans from 'components/SubscriptionPlans';
import FormControlLabel from '@mui/material/FormControlLabel';
import { Box, Button, Grid, Typography } from '@mui/material';
import CustomTextInput from 'components/Forms/CustomTextInput';
import { SIGN_UP_FLOW_ADD_BRAND } from 'constants/availableButtons';
import GooglAutocomplete from 'components/FormFields/GoogleAutocomplate';
import CustomSelectAddItem from '../../../components/Forms/CustomSelectAddItem';
import { modalBrandFormSchema } from '../../AddLocation/schema';
import DialogAddSelectItem from '../../AddLocation/DialogAddItem/DialogAddSelectItem';
import instance from '../../../services/api';
import SubscriptionPlanChargeDateMessage from 'components/SubscriptionPlanChargeDateMessage';
import { useTranslation } from 'react-i18next';
import LabelRequired from 'components/FormFields/LabelRequired';
import { geocodeByAddress } from 'react-google-places-autocomplete';
const CreateBrand = ({
  handleSubmit,
  selectedPlanPrice,
  getSelectedPlanPrice,
  selectedPlan,
  getAddressFields,
  loading,
  sendDataInfo,
  handleSelectedPlan,
  brands,
  setData,
  setCurrentBrandId,
  isSpecialBrand = false,
}) => {
  const { t } = useTranslation();

  const history = useHistory();
  const [acceptCheckBox, handleAcceptCheckbox] = useState(false);
  const [errorAccept, handleErrorAccept] = useState(false);
  const [selectedBrandName, setSelectedBrandName] = useState('');
  const [open, setOpen] = useState(false);
  const [selectedPlanData, setSelectedPlanData] = useState(null);
  const [addressPlaceId, setAddressPlaceId] = useState('');
  const [agreeToPayRecurringCharges, setAgreeToPayRecurringCharges] = useState(false);
  const [agreeToPayRecurringChargesError, setAgreeToPayRecurringChargesError] = useState(false);
  const [checkNewPlans, setCheckNewPlans] = useState(false);
  const brandFormMethods = useForm({
    defaultValues: sendDataInfo,
    mode: 'all',
    resolver: yupResolver(brandSignUp),
  });
  const modalBrandFormMethods = useForm({
    defaultValues: { brandPhone: '', customBrandName: '' },
    mode: 'all',
    resolver: yupResolver(modalBrandFormSchema),
  });
  const { reset } = modalBrandFormMethods;
  const {
    formState: { isSubmitting, isSubmitted, errors, control },
    getValues,
  } = brandFormMethods;

  const getAddressPlaceId = (address) => {
    geocodeByAddress(address).then((results) => {
      const data = collectGoogleQueryData(results[0]);
      setAddressPlaceId(data?.placeId);
    });
  };

  const handleAcceptChange = (e) => {
    const { checked } = e.target;
    !isSubmitting && !checked ? handleErrorAccept(true) : handleErrorAccept(false);
    handleAcceptCheckbox(checked);
  };
  const brandName = modalBrandFormMethods.getValues().customBrandName;
  const handleFormSubmit = (values) => {
    // if (!acceptCheckBox || !agreeToPayRecurringCharges) {
    //   return handleErrorAccept(true);
    // }
    values['businessPhone'] = values.phone;
    values['agreeToReceiveOrderCommunication'] = acceptCheckBox;
    values['agreeToPayRecurringCharges'] = agreeToPayRecurringCharges;
    delete values?.name;
    values['addressPlaceId'] = addressPlaceId || '';
    handleSubmit(values, SIGN_UP_FLOW_ADD_BRAND.ADD_BRAND_SUCCESS);
  };
  const paymentDate = trialVersionDate();

  useEffect(() => {
    brandFormMethods.reset(
      { ...sendDataInfo.addressInfo, metadata: { ...sendDataInfo.metadata } },
      { keepDefaultValues: true }
    );
    getAddressPlaceId(sendDataInfo?.addressInfo?.address);
  }, [sendDataInfo]);

  const handleAddItem = (value) => {
    let selectedBrand = brandFormMethods.getValues().customBrandName;
    setSelectedBrandName(selectedBrand);
    modalBrandFormMethods.setValue('customBrandName', value ?? '');
    setOpen(true);
  };
  const handleClickSave = (value) => {
    const newBrandName = modalBrandFormMethods.getValues().customBrandName;
    const body = {
      brandName: value.customBrandName,
      brandPhone: value.brandPhone,
    };
    setSelectedBrandName(newBrandName);
    brandFormMethods.setValue('brandName', newBrandName);
    if (value.customBrandName) {
      brandFormMethods.clearErrors('brandName');
    }
    if (value.customBrandName) {
      instance.post(`/brand`, body).then((res) => {
        setData([...brands, res.data.data]);
        setCurrentBrandId(res?.data?.data?._id);
      });
      setOpen(false);
    }
  };
  const handleClose = () => {
    setOpen(false);
    modalBrandFormMethods.reset();
  };

  const handleBack = () => history.push('settings/brands');

  const handleAcceptAgreeToPayRecurringCharges = ({ target }) => {
    setAgreeToPayRecurringCharges(target.checked);
    !target.checked ? setAgreeToPayRecurringChargesError(true) : setAgreeToPayRecurringChargesError(false);
  };

  return (
    <>
      <DialogAddSelectItem
        open={open}
        modalBrandFormMethods={modalBrandFormMethods}
        handleClickSave={handleClickSave}
        handleClose={handleClose}
      />
      <FormProvider {...brandFormMethods}>
        <form onSubmit={brandFormMethods.handleSubmit(handleFormSubmit)}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <CustomSelectAddItem
                name="brandName"
                label={<LabelRequired>{t('sign_up.brands_list')}</LabelRequired>}
                control={control}
                errors={isSubmitted ? errors : null}
                handleAddItem={handleAddItem}
                options={brands}
                setValue={brandFormMethods.setValue}
                setSelectedBrandName={setSelectedBrandName}
                setCurrentBrandId={setCurrentBrandId}
                sx={{
                  width: '100%',
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <GooglAutocomplete
                readOnly={true}
                getAddressFields={getAddressFields}
                name="address1"
                initialValue={sendDataInfo?.addressInfo?.address1}
                label={t('order_view.address')}
                placeholder=""
                required={true}
              />
            </Grid>
            <Grid item xs={12}>
              <CustomTextInput
                disabled={true}
                sx={{ width: '100%' }}
                defaultValue=""
                name="address2"
                label={t('order_view.address2')}
              />
            </Grid>
            <Grid item xs={4}>
              <CustomTextInput
                disabled={true}
                sx={{ width: '100%' }}
                defaultValue=""
                name="city"
                label={t('settings.city')}
              />
            </Grid>
            <Grid item xs={4}>
              <CustomTextInput
                disabled={true}
                sx={{ width: '100%' }}
                defaultValue=""
                name="state"
                label={t('settings.state')}
              />
            </Grid>
            <Grid item xs={4}>
              <CustomTextInput
                disabled={true}
                sx={{ width: '100%' }}
                defaultValue=""
                name="zipCode"
                label={t('settings.zip_code')}
              />
            </Grid>
            <Grid item md={12} xs={6}>
              <Typography sx={{ fontWeight: 700, mt: 2 }}>{t('settings.communication_information_reports')}</Typography>
            </Grid>
            <Grid item md={12} xs={12}>
              <CustomTextInput
                sx={{ width: '100%' }}
                name="metadata.communicationEmail"
                label={<LabelRequired>{t('sign_in.communication_email')}</LabelRequired>}
              />
            </Grid>
            <Grid item xs={12}>
              <BrandSubscriptionTitleTitle>{t('sign_up.select_subscription_plan')}</BrandSubscriptionTitleTitle>
              <Box sx={{ minHeight: 400 }}>
                <SubscriptionPlans
                  required={true}
                  isSubmitted={isSubmitted}
                  getSelectedPlanPrice={getSelectedPlanPrice}
                  handleSelectedPlan={handleSelectedPlan}
                  selectedPlanPrice={selectedPlanPrice}
                  selectedPlan={selectedPlan}
                  setSelectedPlanData={setSelectedPlanData}
                  target="brand"
                  setCheckNewPlans={setCheckNewPlans}
                  isSpecialBrand={isSpecialBrand}
                />
              </Box>
            </Grid>
            {selectedPlan && selectedPlan !== 'null' && checkNewPlans ? (
              <Grid item xs={12}>
                <FormControl
                  name="accept"
                  component="fieldset"
                  error={!!agreeToPayRecurringChargesError}
                  variant="standard"
                >
                  <FormGroup style={{ height: '10px', display: 'flow' }}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          onChange={handleAcceptAgreeToPayRecurringCharges}
                          checked={agreeToPayRecurringCharges}
                          error={true}
                          name="accept"
                        />
                      }
                      label={
                        <SubscriptionPlanChargeDateMessage
                          selectedPlanData={selectedPlanData}
                          selectedPlanPrice={selectedPlanPrice}
                          selectedPlan={selectedPlan}
                          isBrand={true}
                        />
                      }
                    />
                    {agreeToPayRecurringChargesError && (
                      <Box>
                        <FormHelperText sx={{ marginTop: '-18px' }}>{t('field_is_required_')}</FormHelperText>
                      </Box>
                    )}
                  </FormGroup>
                </FormControl>
              </Grid>
            ) : (
              <></>
            )}
            {/*<Grid item xs={12}>*/}
            {/*  <SubscriptionPlanChargeDateMessage*/}
            {/*    selectedPlanData={selectedPlanData}*/}
            {/*    selectedPlanPrice={selectedPlanPrice}*/}
            {/*    selectedPlan={selectedPlan}*/}
            {/*  />*/}
            {/*</Grid>*/}
            <Grid item xs={12}>
              <FormControl name="accept" component="fieldset" error={errorAccept} variant="standard">
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Checkbox onChange={handleAcceptChange} checked={acceptCheckBox} error={true} name="accept" />
                    }
                    label={t('settings.i_agree_grid')}
                  />
                </FormGroup>
                {errorAccept && <FormHelperText>{t('field_is_required_')}</FormHelperText>}
              </FormControl>
            </Grid>
            <Grid justifyContent="flex-end" display="flex" gap={'10px'} item xs={12}>
              <Button id="CreateBrandCancel" type="submit" color="primary" variant="outlined" onClick={handleBack}>
                {t('orders.cancel')}
              </Button>
              <Button
                id="CreateBrandSubmit"
                variant="contained"
                color="primary"
                type="submit"
                disabled={
                  !getValues()?.metadata?.communicationEmail ||
                  loading ||
                  !selectedPlan ||
                  !acceptCheckBox ||
                  !selectedBrandName ||
                  (checkNewPlans && !agreeToPayRecurringCharges)
                }
              >
                {loading && <CircularProgress style={{ width: '25px', height: '25px' }} />}
                {!loading && t('order_view.submit')}
              </Button>
            </Grid>
          </Grid>
        </form>
      </FormProvider>
    </>
  );
};

export default CreateBrand;
