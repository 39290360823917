import styled from '@emotion/styled';
import { Typography, List, Box, Button, ListItemText } from '@mui/material';
export const PauseResumeBigTitle = styled(Typography)({
  fontSize: '32px',
  fontWeight: '500',
  color: '#0F1214',
  lineHeight: '20px',
  letterSpacing: '0.25px',
  paddingBottom: '8px',
  marginTop: '16px',
});
export const PauseResumeSubTitle = styled(Typography)({
  fontSize: '16px',
  color: '#0F1214',
  marginTop: '8px',
  lineHeight: '20px',
  letterSpacing: '0.25px',
});
export const PauseResumeNormalTitle = styled(Typography)({
  fontSize: '16px',
  fontWeight: 500,
  color: '#0F1214',
  marginTop: '24px',
  lineHeight: '20px',
  letterSpacing: '0.25px',
});
export const PauseResumeNormalSubTitle = styled(Typography)({
  fontSize: '16px',
  fontWeight: 400,
  color: '#0F1214',
  marginTop: '8px',
  lineHeight: '20px',
  letterSpacing: '0.25px',
});
export const PauseResumeBusyPrepTime = styled(Typography)({
  fontSize: '20px',
  fontWeight: 400,
  color: '#0F1214',
  margin: '32px 0 16px 0',
  lineHeight: '20px',
  letterSpacing: '0.25px',
});
export const PauseResumeOrdersStatus = styled(Typography)({
  fontSize: '24px',
  fontWeight: 500,
  color: '#0F1214',
  margin: '16px 0 16px 0',
  lineHeight: '20px',
  letterSpacing: '0.25px',
});
export const PauseResumeWillBusy = styled(Typography)({
  fontSize: '16px',
  fontWeight: 500,
  color: '#0F1214',
  margin: '16px 0 0 0',
  lineHeight: '20px',
  letterSpacing: '0.25px',
});
export const PauseResumeSingleBusiness = styled(List)({
  marginLeft: '32px',
  listStyleType: 'circle',
  '& .MuiListItem-root': {
    display: 'list-item',
  },
});
export const PauseResumeAllBusiness = styled(Box)({
  width: '100%',
  borderRadius: '4px',
  marginLeft: '32px',
});
export const PauseResumeAllBusinessList = styled(List)({
  marginLeft: '16px',
  maxHeight: '300px',
  listStyleType: 'circle!important',
  '& .MuiListItem-root': {
    display: 'list-item !important',
  },
});
export const PauseResumeAllBusinessListItem = styled(ListItemText)({
  color: '#929398',
  fontSize: '16px',
  fontWeight: 400,
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  display: '-webkit-box!important',
  WebkitLineClamp: 1,
  WebkitBoxOrient: 'vertical',
});
export const PauseResumeAllBusinessListItemText = styled(ListItemText)({
  color: '#929398',
  fontSize: '16px',
  fontWeight: 400,
});
export const PauseResumeDots = styled(Box)({
  width: '5px',
  height: '5px',
  borderRadius: '10px',
  background: '#929398',
});

export const PauseResumeBackButton = styled(Button)({
  width: '192px',
  height: '54px',
  borderRadius: '6px',
  marginRight: '16px',
  color: '#3C4350',
  border: '1px solid #929398',
  fontSize: '16px',
  fontWeight: 700,
  '&:hover': {
    border: '1px solid #929398',
  },
});
