import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';

export const StyledHeaderBox = styled(Box)(({ theme }) => {
  return {
    width: '100%',
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    marginBottom: theme.spacing(1),
    paddingTop: theme.spacing(1),
    [theme.breakpoints.down(480)]: {
      display: 'flex',
      flexDirection: 'column',
    },
  };
});
