import React, { useEffect, useRef, useState } from 'react';
import cs from 'classnames';
import Preloader from 'components/Preloader';
import { unlinkImage } from 'redux/actions/file';
import IconButton from '@mui/material/IconButton';
import { TextField } from '@mui/material';
import CropPhoto from '../ImageUpolad/CropPhoto';
import { useHistory } from 'react-router-dom';
import { MIN_IMAGE_UPLOAD_HEIGHT, MIN_IMAGE_UPLOAD_WIDTH } from 'constants/file';
const ImageUpload = ({
  name,
  initialValue,
  removable,
  size,
  disabled,
  className = '',
  model,
  setFormValue = () => {},
  setImage = () => {},
  setDisable = () => {},
  setModel = () => {},
  isBackground = false,
  height = '98',
  width = '98',
}) => {
  const history = useHistory();
  const [error, setError] = useState(false);
  const isBusiness = history.location.pathname.includes('/settings/business');
  const [loading, setLoading] = useState(false);
  const [value, setValue] = useState(initialValue);
  const inputRef = useRef();
  const [openCropModal, setOpenCropModal] = useState(false);
  const [imageOptions, setImageOptions] = useState(null);
  const [imageSrc, setImageSrc] = useState(null);
  const handleClose = () => {
    setOpenCropModal(false);
    setImageOptions(null);
    setError(false);
  };
  const handleValidateImageSize = (image) => {
    const reader = new FileReader();
    const img = new Image();
    img.onload = () => {
      const width = img.width;
      const height = img.height;
      setOpenCropModal(true);
      setError(Boolean(width < MIN_IMAGE_UPLOAD_WIDTH || height < MIN_IMAGE_UPLOAD_HEIGHT));
    };
    reader.onloadend = () => {
      setImageSrc(reader.result);
      img.src = reader.result;
    };
    reader.readAsDataURL(image);
  };
  const handleImageCropOpen = (image) => {
    setError(false);
    setOpenCropModal(true);
    const reader = new FileReader();
    reader.onloadend = () => {
      setImageSrc(reader.result);
    };
    reader.readAsDataURL(image);
  };
  const handleChange = ({ target: { files } }) => {
    const image = files[0];
    if (image) {
      const imageOptions = {
        name: image.name,
        type: image.type,
        path: URL.createObjectURL(image),
      };
      setImageOptions(imageOptions);
      isBusiness ? handleImageCropOpen(image) : handleValidateImageSize(image);
    }
    inputRef.current.value = '';
  };
  const handleDelete = () => {
    unlinkImage(value.fileId || value.imageId).then(() => {
      setImage('');
      setValue('');
      if (name === 'avatar') {
        setFormValue('avatar', { fileId: '', url: '' }, { shouldDirty: true });
      } else {
        setFormValue('image', { fileId: '', url: '' }, { shouldDirty: true });
      }
      setDisable(false);
    });
  };
  useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);
  return (
    <div
      className={cs('image-upload', className, {
        disabled,
        empty: !value?.url,
      })}
      style={size ? { width: `${!isBackground ? size : 400}px`, height: `${!isBackground ? size : 225}px` } : {}}
    >
      <TextField value={value?.url} hidden type="text" name={name} />
      <label>
        {!!value?.url && <img src={`${value?.url}?tr=w-${width},h-${height}`} alt="" />}
        <input type="file" onChange={handleChange} disabled={disabled} accept="image/png, image/jpeg" ref={inputRef} />
        <span className={cs({ 'image-loading': loading, empty: !value?.url })}>
          {loading ? <Preloader /> : <i className="icon icon-camera"></i>}
        </span>
      </label>
      {!disabled && removable && !!value?.url && (
        <IconButton
          sx={{
            background: 'red',
            color: 'white',
            padding: '5px',
            position: 'absolute',
            borderRadius: '5px',
          }}
          color="error"
          variant="contained"
          size="sm"
          onClick={handleDelete}
          className="delete-image p-1"
        >
          <i className="icon icon-trash-2"></i>
        </IconButton>
      )}
      {openCropModal ? (
        <CropPhoto
          photoURL={imageSrc}
          setImageSrc={setImageSrc}
          setFormValue={setFormValue}
          setPhotoURL={() => {}}
          openCropModal={openCropModal}
          title={
            name === 'avatar'
              ? 'settings.business_logo'
              : name === 'image' && !isBusiness
              ? 'menu.product_photo'
              : 'settings.cover_photo'
          }
          className={'logo-content'}
          imageOptions={imageOptions}
          handleClose={handleClose}
          setValue={setValue}
          name={name}
          error={error}
          setError={setError}
        />
      ) : (
        <></>
      )}
    </div>
  );
};
export default ImageUpload;
