import useProviderConfig from 'hooks/useProviderConfig';
import React, { useMemo } from 'react';
import { Bar } from 'react-chartjs-2';

const BarChart = ({ data }) => {
  const { providers } = useProviderConfig();

  const chartData = useMemo(() => {
    const keys = Object.keys(data);
    if (providers)
      return {
        labels: keys.length ? Object.keys(data[keys[0]]) : [],
        datasets: keys.map((key) => ({
          borderWidth: 1,
          label: providers[key]?.label,
          data: Object.values(data[key]),
          ...providers[key]?.options,
        })),
      };
  }, [data]);

  return <Bar data={chartData} options={options} />;
};

const options = {
  tooltips: {
    callbacks: {
      label: ({ datasetIndex, yLabel }, { datasets }) => {
        const label = datasets[datasetIndex].label || '';
        return ` ${label}: ${yLabel.toLocaleString('en-US', { style: 'currency', currency: 'USD' })}`;
      },
    },
  },
  scales: {
    yAxes: [
      {
        ticks: {
          callback: (value) => Number(value).toLocaleString('en-US', { style: 'currency', currency: 'USD' }),
          beginAtZero: true,
        },
      },
    ],
  },
  legend: {
    position: 'bottom',
  },
};

// const providers = {
//   website: {
//     label: 'Website',
//     options: {
//       backgroundColor: 'rgba(221, 113, 93, 0.8)',
//       borderColor: 'rgba(221, 113, 93,1)',
//       hoverBackgroundColor: 'rgba(221, 113, 93, 1)',
//       hoverBorderColor: 'rgba(221, 113, 93,1)',
//     },
//   },
//   postmates: {
//     label: 'Postmates',
//     options: {
//       backgroundColor: 'rgba(61, 163, 232, 0.8)',
//       borderColor: 'rgba(61, 163, 232, 1)',
//       hoverBackgroundColor: 'rgba(61, 163, 232, 1)',
//       hoverBorderColor: 'rgba(61, 163, 232, 1)',
//     },
//   },
//   ubereats: {
//     label: 'UberEats',
//     options: {
//       backgroundColor: 'rgba(122, 184, 83, 0.8)',
//       borderColor: 'rgba(122, 184, 83,1)',
//       hoverBackgroundColor: 'rgba(122, 184, 83, 1)',
//       hoverBorderColor: 'rgba(122, 184, 83,1)',
//     },
//   },
//   doordash: {
//     label: 'Doordash',
//     options: {
//       backgroundColor: 'rgba(249, 54, 44, 0.8)',
//       borderColor: 'rgba(249, 54, 44,1)',
//       hoverBackgroundColor: 'rgba(249, 54, 44, 1)',
//       hoverBorderColor: 'rgba(249, 54, 44,1)',
//     },
//   },
//   grubhub: {
//     label: 'Grubhub',
//     options: {
//       backgroundColor: 'rgba(75,192,192, 0.8)',
//       borderColor: 'rgba(75,192,192,1)',
//       hoverBackgroundColor: 'rgba(75,192,192, 1)',
//       hoverBorderColor: 'rgba(75,192,192,1)',
//     },
//   },
//   grubhub_cartwheel: {
//     label: 'Grubhub',
//     options: {
//       backgroundColor: 'rgba(75,192,192, 0.8)',
//       borderColor: 'rgba(75,192,192,1)',
//       hoverBackgroundColor: 'rgba(75,192,192, 1)',
//       hoverBorderColor: 'rgba(75,192,192,1)',
//     },
//   },
//   doordash_cartwheel: {
//     label: 'Doordash',
//     options: {
//       backgroundColor: 'rgba(249, 54, 44, 0.8)',
//       borderColor: 'rgba(249, 54, 44,1)',
//       hoverBackgroundColor: 'rgba(249, 54, 44, 1)',
//       hoverBorderColor: 'rgba(249, 54, 44,1)',
//     },
//   },
//   doocado: {
//     label: 'Doocado',
//     options: {
//       backgroundColor: 'rgba(75,192,192, 0.8)',
//       borderColor: 'rgba(75,192,192,1)',
//       hoverBackgroundColor: 'rgba(75,192,192, 1)',
//       hoverBorderColor: 'rgba(75,192,192,1)',
//     },
//   },
//   deliverycom: {
//     label: 'Deliverycom',
//     options: {
//       backgroundColor: 'rgba(33,150,243, 0.8)',
//       borderColor: '#2196f3',
//       hoverBackgroundColor: '#2196f3',
//       hoverBorderColor: '#2196f3)',
//     },
//   },
//   grubhubv2: {
//     label: 'GrubHub v2',
//     options: {
//       backgroundColor: 'rgb(33,150,120,0.8)',
//       borderColor: '#219678',
//       hoverBackgroundColor: '#219678',
//       hoverBorderColor: '#219678)',
//     },
//   },
//   table_ordering: {
//     label: 'Table Ordering',
//     options: {
//       backgroundColor: 'rgba(240,142,53, 0.8)',
//       borderColor: '#F08E35',
//       hoverBackgroundColor: '#F08E35',
//       hoverBorderColor: '#F08E35)',
//     },
//   },
//   ezcater: {
//     label: 'EzCater',
//     options: {
//       backgroundColor: 'rgba(20,142,52,0.6)',
//       borderColor: '#148e34',
//       hoverBackgroundColor: '#148e34',
//       hoverBorderColor: '#148e34',
//     },
//   },
//   fetchme: {
//     label: 'FetchMe',
//     options: {
//       backgroundColor: 'rgba(153,147,70,0.9)',
//       borderColor: '#999346',
//       hoverBackgroundColor: '#999346',
//       hoverBorderColor: '#999346',
//     },
//   },
//   food2: {
//     label: 'WebsiteV2',
//     options: {
//       backgroundColor: 'rgba(153,147,70,0.9)',
//       borderColor: '#999346',
//       hoverBackgroundColor: '#999346',
//       hoverBorderColor: '#999346',
//     },
//   },
// };

export default BarChart;
