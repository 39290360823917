import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import LoadingButton from '@mui/lab/LoadingButton';
import { useDispatch } from 'react-redux';
import MenuItem from '@mui/material/MenuItem';
import { useTranslation } from 'react-i18next';
import CloseIcon from '@mui/icons-material/Close';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm, FormProvider } from 'react-hook-form';
import CustomTextInput from 'components/Forms/CustomTextInput';
import LabelRequired from 'components/FormFields/LabelRequired';
import { ORDER_STATUS_SUPER_ADMIN } from 'constants/config';
import { formStatusSchema } from 'components/Order/OrderStatusChange/schema';
import DraggableDialogWrapper from 'components/DraggableDialogWrapper/DraggableDialogWrapper';
import { Dialog, DialogTitle, IconButton, DialogContent, Grid, DialogActions, Divider, Button } from '@mui/material';
import { StyledAction, StyledModalTitle, StyledReasonTitle } from './styles';
import { changeOrderStatusBySuperAdmin } from 'redux/actions/orders';

const CustomDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 16,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

CustomDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};
const statusesList = Object.values(ORDER_STATUS_SUPER_ADMIN).map((item) => item.value) || [];
const StatusChangeModal = ({ openStatusModal, closeStatusModal, data }) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const formStatusMethods = useForm({
    mode: 'all',
    defaultValues: '',
    resolver: yupResolver(formStatusSchema),
  });
  let currentStatus = formStatusMethods.getValues()?.status;
  useEffect(() => {
    if (openStatusModal && statusesList.length) {
      formStatusMethods.reset({
        status: statusesList[0],
        note: '',
      });
    }
  }, [openStatusModal, statusesList]);

  function findStatusObjectByValue(statusValue) {
    return Object.values(ORDER_STATUS_SUPER_ADMIN).find((statusObj) => statusObj.value === statusValue);
  }

  const handleSubmit = (values) => {
    setLoading(true);
    const currentStatusObj = findStatusObjectByValue(values?.status);
    return dispatch(
      changeOrderStatusBySuperAdmin(data._id, {
        status: currentStatusObj?.value || '',
        note: values?.note,
      })
        .then(() => {
          toast.success('Status successfully updated');
          window.location.reload();
        })
        .catch((err) => {
          toast.error(err?.response?.message);
        })
        .finally(() => {
          setLoading(false);
          closeStatusModal();
        })
    );
  };

  return (
    <Dialog
      open={openStatusModal}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullWidth={true}
      disableEscapeKeyDown={true}
      onClose={closeStatusModal}
      PaperComponent={DraggableDialogWrapper}
      PaperProps={{ sx: { width: '538px', maxWidth: '100%', background: '#FAFAFA', cursor: 'grab', height: '451px' } }}
    >
      <FormProvider {...formStatusMethods}>
        <form onSubmit={formStatusMethods.handleSubmit(handleSubmit)}>
          <CustomDialogTitle id="customized-dialog-title" onClose={loading ? undefined : closeStatusModal}>
            <StyledModalTitle>{t('orders_history.admin_status_change')}</StyledModalTitle>
          </CustomDialogTitle>
          <DialogContent sx={{ width: '100%' }}>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <CustomTextInput
                  sx={{ mt: 2, width: '274px' }}
                  select={true}
                  name="status"
                  label={t('settings.status')}
                >
                  {Object.values(ORDER_STATUS_SUPER_ADMIN).map((item) => {
                    return (
                      <MenuItem key={item.value} value={item.value}>
                        {t(item.name)}
                      </MenuItem>
                    );
                  })}
                </CustomTextInput>
              </Grid>
              <Grid item xs={12}>
                <StyledReasonTitle>{t('orders_history.status_reason')}</StyledReasonTitle>
                <CustomTextInput
                  sx={{ mt: 2, width: '100%', height: '176px' }}
                  multiline={true}
                  rows={6}
                  name="note"
                  label={<LabelRequired>{t('orders_history.note')}</LabelRequired>}
                />
              </Grid>
            </Grid>
          </DialogContent>
          <Divider mt={6} />
          <DialogActions sx={{ width: '100%' }}>
            <StyledAction>
              <Button
                color="primary"
                variant="outlined"
                sx={{ border: 'none', color: '#626A7A', '&:hover': { border: 'none' } }}
                onClick={closeStatusModal}
              >
                {t('confirm_modal.cancel')}
              </Button>
              <LoadingButton
                loading={loading}
                type="submit"
                color="primary"
                variant="contained"
                sx={{ width: '145px' }}
                disabled={!formStatusMethods.formState.isValid || loading || currentStatus === 'all'}
              >
                {t('orders_history.change')}
              </LoadingButton>
            </StyledAction>
          </DialogActions>
        </form>
      </FormProvider>
    </Dialog>
  );
};

export default StatusChangeModal;
