export const LOYALTY_REWARDS_VALUES = [
  { _id: 1, value: 1 },
  { _id: 2, value: 2 },
  { _id: 3, value: 3 },
  { _id: 4, value: 4 },
  { _id: 5, value: 5 },
  { _id: 6, value: 6 },
  { _id: 7, value: 7 },
  { _id: 8, value: 8 },
  { _id: 9, value: 9 },
  { _id: 10, value: 10 },
];

export const LOYALTY_REWARDS_POINTS = [
  { _id: 1, value: 1 },
  { _id: 2, value: 2 },
  { _id: 3, value: 3 },
  { _id: 4, value: 5 },
  { _id: 5, value: 10 },
  { _id: 6, value: 20 },
  { _id: 7, value: 30 },
  { _id: 8, value: 40 },
  { _id: 9, value: 50 },
  { _id: 10, value: 60 },
  { _id: 11, value: 70 },
  { _id: 12, value: 80 },
  { _id: 13, value: 90 },
  { _id: 14, value: 100 },
  { _id: 15, value: 150 },
  { _id: 16, value: 200 },
  { _id: 17, value: 250 },
];

export const ORDERS_REWARDS_POINTS = [
  { _id: 1, value: 1 },
  { _id: 5, value: 5 },
  { _id: 10, value: 10 },
  { _id: 25, value: 25 },
  { _id: 50, value: 50 },
  { _id: 100, value: 100 },
  { _id: 250, value: 250 },
];

export const ORDERS_REWARDS_VALUES = [
  // { _id: 1, value: 1 },
  // { _id: 5, value: 5 },
  // { _id: 10, value: 10 },
  // { _id: 25, value: 25 },
  // { _id: 50, value: 50 },
  // { _id: 100, value: 100 },
  // { _id: 250, value: 250 },
  // { _id: 500, value: 500 },
  // { _id: 1000, value: 1000 },
  { _id: 1, value: 1 },
  { _id: 2, value: 2 },
  { _id: 3, value: 3 },
  { _id: 4, value: 5 },
  { _id: 5, value: 10 },
  { _id: 6, value: 20 },
  { _id: 7, value: 30 },
  { _id: 8, value: 40 },
  { _id: 9, value: 50 },
  { _id: 10, value: 60 },
  { _id: 11, value: 70 },
  { _id: 12, value: 80 },
  { _id: 13, value: 90 },
  { _id: 14, value: 100 },
  { _id: 15, value: 150 },
  { _id: 16, value: 200 },
  { _id: 17, value: 250 },
];

export const ORDERS_REWARDS_EXPIRATION_DATES = [
  { _id: 2, value: 30 },
  { _id: 3, value: 45 },
  { _id: 4, value: 60 },
  { _id: 5, value: 90 },
  { _id: 6, value: 180 },
  { _id: 7, value: 360 },
];

export const SIGNUP_REWARDS_AMOUNTS = [
  { _id: 100, value: 1 },
  { _id: 200, value: 2 },
  { _id: 300, value: 3 },
  { _id: 500, value: 5 },
  { _id: 1000, value: 10 },
  // { _id: 25, value: 25 },
  // { _id: 50, value: 50 },
  // { _id: 100, value: 100 },
  // { _id: 250, value: 250 },
];

export const SIGNUP_REWARDS_EXPIRATION_DATES = [
  { _id: 2, value: 30 },
  { _id: 3, value: 45 },
  { _id: 4, value: 60 },
  { _id: 5, value: 90 },
  { _id: 6, value: 180 },
  { _id: 7, value: 360 },
];
