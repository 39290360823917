import React from 'react';
import Grid from '@mui/material/Grid';
import { useSelector } from 'react-redux';
import { Box, MenuItem } from '@mui/material';
import { useTranslation } from 'react-i18next';
import CustomTextInput from 'components/Forms/CustomTextInput';
import LabelRequired from 'components/FormFields/LabelRequired';
import { SEATS } from 'pages/TableOrdering/hooks/useTableOrderingTable';
import { PADDING_MODAL_ACTION_LEFT, PADDING_MODAL_ACTION_RIGHT } from 'pages/TableOrdering/config';

const CreateOrEditForm = () => {
  const { t } = useTranslation();
  const { sections = [] } = useSelector(({ tableOrdering }) => tableOrdering);
  return (
    <>
      <Box sx={{ mt: 1 }}>
        <Grid container spacing={2}>
          <Grid item md={6} xs={6} lg={6} xl={6}>
            <Box sx={PADDING_MODAL_ACTION_LEFT}>
              <CustomTextInput
                sx={{ width: '100%', height: '64px' }}
                name="tableNumber"
                label={<LabelRequired>{t('table_ordering.table_number')}</LabelRequired>}
              />
            </Box>
          </Grid>
          <Grid item md={6} xs={6} lg={6} xl={6}>
            <Box sx={PADDING_MODAL_ACTION_RIGHT}>
              <CustomTextInput
                sx={{ width: '100%', height: '64px' }}
                select={true}
                name="section._id"
                label={<LabelRequired>{t('table_ordering.section')}</LabelRequired>}
              >
                {sections?.map((option) => {
                  return (
                    <MenuItem key={option._id} value={option._id}>
                      {option.name}
                    </MenuItem>
                  );
                })}
              </CustomTextInput>
            </Box>
          </Grid>
          <Grid item md={6} xs={6} lg={6} xl={6}>
            <Box sx={PADDING_MODAL_ACTION_LEFT}>
              <CustomTextInput
                sx={{ width: '100%', height: '64px' }}
                select={true}
                name="seats"
                label={<LabelRequired>{t('table_ordering.seats')}</LabelRequired>}
              >
                {SEATS.map((option) => (
                  <MenuItem key={option.id} value={option.value}>
                    {option.name}
                  </MenuItem>
                ))}
              </CustomTextInput>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};
export default CreateOrEditForm;
