import { styled } from '@mui/material/styles';
import { LoadingButton } from '@mui/lab';
import { Box, Typography, Paper, Link as HyperLink } from '@mui/material';
export const StyledPosTitleWrapper = styled(Box)(({ theme }) => {
  return {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: theme.spacing(2),
  };
});

export const StyledPosTitle = styled(Box)(() => {
  return {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  };
});

export const StyledPosActionWrapper = styled(Box)(({ theme }) => {
  return {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    padding: theme.spacing(2),
  };
});

export const StyledPosWrapper = styled(Box)(() => {
  return {
    display: 'flex',
    justifyContent: 'end',
    alignItems: 'center',
    width: '100%',
  };
});

export const StyledProviderWrapper = styled(Box)(({ theme }) => {
  return {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: theme.spacing(2),
  };
});

export const StyledProviderHeader = styled(Box)(() => {
  return {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  };
});

export const StyledProviderImageHeader = styled(Box)(({ theme }) => {
  return {
    width: '50px',
    height: '50px',
    marginRight: theme.spacing(1),
  };
});

export const StyledProviderTitle = styled(Typography)(() => {
  return {
    color: '#000000',
    fontSize: '22px',
    lineHeight: '28px',
    fontWeight: 400,
  };
});

export const StyledProviderTitleWrapper = styled(Box)(() => {
  return {};
});

export const StyledProviderStoreIdTitleBox = styled(Box)(() => {
  return {
    display: 'flex',
    gap: '3px',
  };
});

export const StyledProviderStoreIdTitle = styled(Typography)(() => {
  return {
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    display: 'block',
    fontSize: '16px',
    fontWeight: 500,
    color: '#919191',
  };
});

export const StyledProviderCurrentSettings = styled(Box)(() => {
  return {};
});

export const StyledProviderRenderActions = styled(Box)(({ theme }) => {
  return {
    padding: theme.spacing(2),
    textAlign: 'end',
  };
});

export const StyledProviderRenderActionsConnected = styled(Box)(({ theme }) => {
  return {
    padding: theme.spacing(2),
    textAlign: 'start',
    marginLeft: theme.spacing(7),
  };
});

export const StyledProviderPopover = styled(Box)(({ theme }) => {
  return {
    width: '264px',
    maxWidth: '264px',
    padding: theme.spacing(2),
    color: 'rgba(0, 0, 0, 0.87)',
  };
});

export const StyledButton = styled(LoadingButton)(() => {
  return {
    color: 'rgba(0, 0, 0, 0.87)',
    fontWeight: 400,
    textTransform: 'none',
    lineHeight: '24px',
    letterSpacing: '0.15px',
    fontSize: '16px',
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-start',
  };
});

export const StyledEmptyPosPaper = styled(Paper)(() => {
  return {
    minHeight: '780px',
    display: 'grid',
    placeItems: 'center',
  };
});

export const StyledEmptyPos = styled(Box)(() => {
  return {
    display: 'grid',
    placeItems: 'center',
    maxWidth: '512px',
    textAlign: 'center',
  };
});

export const StyledEmptyPosTitle = styled(Typography)(({ theme }) => {
  return {
    color: '#3C4350',
    fontWeight: 500,
    lineHeight: '32px',
    fontSize: '32px',
    marginTop: theme.spacing(4),
  };
});

export const StyledEmptyPosSubTitle = styled(Typography)(({ theme }) => {
  return {
    color: '#0F1214',
    fontWeight: 400,
    lineHeight: '22px',
    fontSize: '16px',
    marginTop: theme.spacing(2),
  };
});
export const StyledPhoneBoxWrapper = styled(Box)(({ theme }) => ({
  marginTop: theme.spacing(2),
}));

export const StyledPhoneBox = styled(HyperLink)(() => ({
  color: '#2D7FF9',
  fontSize: '16px',
  fontWeight: 600,
  lineHeight: '24px',
  textDecoration: 'none',
  '&:hover': {
    color: '#2D7FF9',
    textDecoration: 'underline',
  },
}));
