import dordashLogo from 'assets/icons/doordashLogo.svg';
import uberEatsLogo from 'assets/icons/uberEatsLogo.svg';
import chargeBackTotal from 'assets/icons/chargeBackIconTotal.svg';
import lost from 'assets/icons/chargeBack/cancel_circle.png';
import pending from 'assets/icons/chargeBack/schedule_circle.png';
import won from 'assets/icons/chargeBack/approve_circle.png';
import ProviderInfo from './ProviderInfo';
import { Box, Divider, Card } from '@mui/material';
import { useHomeReport } from 'components/NewHome/ChargeBack/hooks/useHomeReport';
import { useMemo } from 'react';
import Grid from '@mui/material/Grid';

const ChargeBackReport = () => {
  const { reportsChargeBack } = useHomeReport();
  const ubereats = reportsChargeBack?.ubereats;
  const doordash = reportsChargeBack?.doordash;
  const totalChargeBack = useMemo(() => parseFloat(doordash?.total + ubereats?.total).toFixed(2) || 0, [
    doordash?.total,
    ubereats?.total,
  ]);
  const totalWon = useMemo(() => parseFloat(doordash?.wonTotal + ubereats?.wonTotal).toFixed(2) || 0, [
    doordash?.wonTotal,
    ubereats?.wonTotal,
  ]);
  const totalPending = useMemo(() => parseFloat(doordash?.pendingTotal + ubereats?.pendingTotal).toFixed(2) || 0, [
    doordash?.pendingTotal,
    ubereats?.pendingTotal,
  ]);

  const doorDashData = {
    title: 'Doordash',
    img: {
      src: dordashLogo,
      alt: 'Doordash',
    },
    statuses: [
      {
        title: 'Chargeback',
        price: parseFloat(doordash?.total).toFixed(2) || 0,
        backgroundColor: 'rgba(15, 18, 20, 0.08)',
        img: {
          src: chargeBackTotal,
          alt: 'Chargeback total',
        },
      },
      {
        title: 'Won',
        price: parseFloat(doordash?.wonTotal).toFixed(2) || 0,
        backgroundColor: 'rgba(0, 152, 79, 0.08)',
        img: {
          src: won,
          alt: 'Won amount',
        },
      },
      {
        title: 'Pending',
        price: parseFloat(doordash?.pendingTotal).toFixed(2) || 0,
        backgroundColor: 'rgba(255, 138, 0, 0.08)',
        img: {
          src: pending,
          alt: 'Pending amount',
        },
      },
      {
        title: 'Lost',
        price: parseFloat(doordash?.lostTotal).toFixed(2) || 0,
        backgroundColor: 'rgba(226, 21, 8, 0.08)',
        img: {
          src: lost,
          alt: 'Lost amount',
        },
      },
    ],
  };
  const uberEatsData = {
    title: 'Uber Eats',
    img: {
      src: uberEatsLogo,
      alt: 'Uber Eats',
    },
    statuses: [
      {
        title: 'Chargeback',
        price: parseFloat(ubereats?.total).toFixed(2) || 0,
        backgroundColor: 'rgba(15, 18, 20, 0.08)',
        img: {
          src: chargeBackTotal,
          alt: 'Chargeback total',
        },
      },
      {
        title: 'Won',
        price: parseFloat(ubereats?.wonTotal).toFixed(2) || 0,
        backgroundColor: 'rgba(0, 152, 79, 0.08)',
        img: {
          src: won,
          alt: 'Won amount',
        },
      },
      {
        title: 'Pending',
        price: parseFloat(ubereats?.pendingTotal).toFixed(2) || 0,
        backgroundColor: 'rgba(255, 138, 0, 0.08)',
        img: {
          src: pending,
          alt: 'Pending amount',
        },
      },
      {
        title: 'Lost',
        price: parseFloat(ubereats?.lostTotal).toFixed(2) || 0,
        backgroundColor: 'rgba(226, 21, 8, 0.08)',
        img: {
          src: lost,
          alt: 'Lost amount',
        },
      },
    ],
  };
  const providers = [
    {
      provider: 'doordash',
      data: doorDashData,
    },
    {
      provider: 'uberEats',
      data: uberEatsData,
    },
  ];
  return (
    <Grid container spacing={2}>
      <Grid item sm={12} md={6} lg={6} xl={6}>
        <Card
          sx={{
            height: '100%',
            flex: 1,
            backgroundColor: '#FFF',
            padding: '16px',
            borderRadius: '4px',
          }}
        >
          <Box>
            <h2
              style={{
                fontWeight: 400,
                fontSize: '18px',
                lineHeight: '20px',
                color: '#0F1214',
                letterSpacing: '0.25px',
                margin: 0,
              }}
            >
              Total Chargeback
            </h2>
            <b style={{ color: '#000' }}>{`$${totalChargeBack}`}</b>
            <Divider sx={{ margin: '16px 0' }} />
          </Box>
          <Box sx={{ display: 'flex', flexDirection: 'column', height: '82%', justifyContent: 'space-between' }}>
            <Box>
              <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: '8px' }}>
                <img style={{ width: 16, height: 16, margin: '0 6px 0 0' }} src={won} alt="won" />
                <p
                  style={{ margin: '0', color: 'rgba(0, 152, 79, 1)', fontWeight: 600 }}
                >{`Dispute Won - $${totalWon}`}</p>
              </Box>
              <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: '24px' }}>
                <img style={{ width: 16, height: 16, margin: '0 6px 0 0' }} src={pending} alt="pending" />
                <p
                  style={{ margin: '0', color: 'rgba(255, 138, 0, 1)', fontWeight: 600 }}
                >{`Dispute Pending - $${totalPending}`}</p>
              </Box>
            </Box>
            {/* <Box>
              <a
                style={{
                  fontSize: '14px',
                  backgroundColor: 'rgba(242, 242, 242, 1)',
                  borderRadius: '6px',
                  color: '#000',
                  display: 'flex',
                  width: '100%',
                  alignItems: 'center',
                  justifyContent: 'center',
                  padding: '12px',
                  fontWeight: 500,
                }}
                href="/chargeback"
              >
                VIEW MORE
              </a>
            </Box> */}
          </Box>
        </Card>
      </Grid>
      <Grid item sm={12} md={6} lg={6} xl={6}>
        <Card sx={{ display: 'flex', flexDirection: 'column', gap: '16px', flex: 1 }}>
          {providers.map((provider) => (
            <ProviderInfo data={provider.data} />
          ))}
        </Card>
      </Grid>
    </Grid>
  );
};
export default ChargeBackReport;
