import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  ChargeBackAccept,
  ChargeBackViewDispute,
  ChargeBackViewFooter,
  StatusContainer,
  StatusContainerDescription,
  StatusMessage,
  StatusName,
  StatusTotal,
} from './styles';
import { useForm } from 'react-hook-form';
import {
  checkChargeBackState,
  DEFAULT_REASON_LIST,
  DEFAULT_TIMEZONE,
  initialDataModel,
  OTHER_REASON,
  SET_CHARGE_SELECTED,
  SET_CHARGE_SELECTED_LOADING,
  STATUS_LIST_CHARGE_BACK,
  StatusEnum,
} from 'components/Chargeback/constants';
import { formSchema, formSchemaMessage } from 'components/Chargeback/schema';
import { acceptChargeBack, disputeChargeBack, getChargeBackById, getChargeBackList } from 'redux/actions/chargeBack';
import { useDispatch, useSelector } from 'react-redux';
import { formatPrice } from 'helpers/formPrice';
import Preloader from 'components/Preloader';
import DisputeModal from '../Modal/DisputeModal';
import ConfirmModal from '../Modal/ConfirmModal';
import { formatDateByStatus } from 'utils/globalUtils';

const ChargeBackActions = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [openConfirmModal, setOpenConfirmModal] = useState(false);
  const [openDisputeModal, setOpenDisputeModal] = useState(false);
  const [loadingButton, setLoadingButton] = useState(false);
  const [loading, setLoading] = useState(false);
  const [selectedReason, setSelectedReason] = useState('');
  const { chargeBack } = useSelector((store) => store);
  const { selected } = chargeBack;
  let checkFormSchema = selectedReason === OTHER_REASON.OTHER ? formSchemaMessage : formSchema;
  let history = useHistory();
  const stateChargeBack = checkChargeBackState(history.location.pathname);

  const formMethods = useForm({
    mode: 'all',
    defaultValues: initialDataModel,
    resolver: yupResolver(checkFormSchema),
  });

  const handleAccept = () => setOpenConfirmModal(true);
  const handleDispute = () => setOpenDisputeModal(true);

  const handleClose = () => {
    setOpenDisputeModal(false);
    formMethods.reset(initialDataModel, { keepDirty: false });
    setSelectedReason('');
  };

  const handleCloseConfirmModal = () => setOpenConfirmModal(false);

  const handleChange = (event) => {
    formMethods.reset({
      reason: event.target.value || '',
      note: '',
    });
    setSelectedReason(event.target.value);
  };

  const handleStatus = (currentStatus) => {
    const statusKey = currentStatus?.name.toUpperCase().replace(/-/g, '_');
    return STATUS_LIST_CHARGE_BACK[statusKey];
  };

  const getChargeBackListByIdQuery = (paramsId) => {
    setLoading(true);
    dispatch({ type: SET_CHARGE_SELECTED, payload: null });
    dispatch(getChargeBackList(stateChargeBack));
    getChargeBackById(paramsId)
      .then((res) => {
        if (!res.error) {
          let currentResponse = res?.data?.data;
          localStorage.setItem('business', currentResponse?.orderData.business[0]._id);
          setLoading(false);
        }
        handleClose();
      })
      .finally(() => {
        dispatch({ type: SET_CHARGE_SELECTED_LOADING, payload: false });
      });
    setTimeout(() => {
      setLoading(false);
    }, 500);
  };

  const handleConfirmModal = () => {
    setLoadingButton(true);
    acceptChargeBack(selected?._id)
      .then((res) => {
        if (!res.error) {
          getChargeBackListByIdQuery(selected?._id);
          setLoadingButton(false);
          setOpenConfirmModal(false);
        } else setLoadingButton(false);
      })
      .finally(() => {
        setLoadingButton(false);
        setLoading(false);
        dispatch({ type: SET_CHARGE_SELECTED_LOADING, payload: false });
      });
  };

  const handleFormSubmit = (values) => {
    const findObject = DEFAULT_REASON_LIST(t).find((item) => item.value === values.reason);
    setLoadingButton(true);
    let obj = {
      id: selected?._id,
      data: {
        reason: findObject.key,
        description: values.reason === OTHER_REASON.OTHER ? values.message : findObject.value,
      },
    };
    disputeChargeBack(obj)
      .then((res) => {
        setLoadingButton(false);
        if (!res?.error) {
          getChargeBackListByIdQuery(selected?.id);
        }
      })
      .finally(() => {
        setLoadingButton(true);
        setLoading(false);
        dispatch({ type: SET_CHARGE_SELECTED_LOADING, payload: false });
      });
  };

  const formatDate = (dateString) => {
    const currentBusiness = dateString?.orderData && dateString?.orderData?.business[0];
    const timeZoneId = currentBusiness?.timezone?.timeZoneId || 'America/Los_Angeles';
    if (dateString?.lastDayToDispute) {
      const date = new Date(dateString?.lastDayToDispute);
      const options = { year: 'numeric', month: 'long', day: 'numeric', timeZone: timeZoneId };
      return new Intl.DateTimeFormat('en-US', options).format(date);
    } else {
      const date = new Date(Date.now());
      const options = { year: 'numeric', month: 'long', day: 'numeric', timeZone: timeZoneId };
      return new Intl.DateTimeFormat('en-US', options).format(date);
    }
  };

  const handleStatusSwitch = (currentStatus, total, data, isModal = false) => {
    switch (currentStatus?.type) {
      case StatusEnum.CREATED:
        return (
          <>
            {!isModal && (
              <StatusContainer sx={{ color: currentStatus.color, background: currentStatus.background }}>
                <img src={currentStatus?.icon} alt={currentStatus.name || ''} />
                <StatusName>
                  {currentStatus?.name} - {formatDateByStatus(data, StatusEnum.CREATED)}
                </StatusName>
              </StatusContainer>
            )}
            <StatusContainerDescription
              sx={{
                color: currentStatus.color,
                background: currentStatus.background,
                marginTop: isModal ? 0 : 2,
              }}
            >
              <img src={currentStatus?.icon} alt={currentStatus.name || ''} />
              <StatusMessage>
                {t('chargeBack.createMsgDispute')}{' '}
                <StatusTotal>{`$${Math.abs(selected?.chargeTotal || 0)}`}</StatusTotal>.{' '}
                {t('chargeBack.createMsgDispute2')} <span>{formatDate(data)}</span>.
              </StatusMessage>
            </StatusContainerDescription>
          </>
        );
      case StatusEnum.DISPUTED:
        return (
          <>
            <StatusContainer sx={{ color: currentStatus.color, background: currentStatus.background }}>
              <img src={currentStatus?.icon} alt={currentStatus.name || ''} />
              <StatusName>
                {currentStatus?.name} - {formatDateByStatus(data, StatusEnum.DISPUTED)}
              </StatusName>
            </StatusContainer>
            <StatusContainerDescription sx={{ color: currentStatus.color, background: currentStatus.background }}>
              <img src={currentStatus?.icon} alt={currentStatus.name || ''} />
              <StatusMessage>
                {t('chargeBack.msg_dispute')} <StatusTotal>{`$${Math.abs(selected?.chargeTotal || 0)}`}</StatusTotal>.{' '}
                {t('chargeBack.msg_dispute2')}.
              </StatusMessage>
            </StatusContainerDescription>
          </>
        );
      case StatusEnum.DISPUTE_APPROVED:
        return (
          <>
            <StatusContainer sx={{ color: currentStatus.color, background: currentStatus.background }}>
              <img src={currentStatus?.icon} alt={currentStatus.name || ''} />
              <StatusName>
                {currentStatus?.name} - {formatDateByStatus(data, StatusEnum.DISPUTE_APPROVED)}
              </StatusName>
            </StatusContainer>
            <StatusContainerDescription sx={{ color: currentStatus.color, background: currentStatus.background }}>
              <img src={currentStatus?.icon} alt={currentStatus.name || ''} />
              <StatusMessage>
                {t('chargeBack.msg_dispute_approved')}{' '}
                <StatusTotal>{`$${Math.abs(selected?.refundTotal || 0)}`}</StatusTotal>{' '}
                {t('chargeBack.msg_dispute_approved2')}.
              </StatusMessage>
            </StatusContainerDescription>
          </>
        );
      case StatusEnum.HOLD:
        return (
          <>
            <StatusContainer sx={{ color: currentStatus.color, background: currentStatus.background }}>
              <img src={currentStatus?.icon} alt={currentStatus.name || ''} />
              <StatusName>
                {currentStatus?.name} - {formatDateByStatus(data, StatusEnum.HOLD)}
              </StatusName>
            </StatusContainer>
            <StatusContainerDescription sx={{ color: currentStatus.color, background: currentStatus.background }}>
              <img src={currentStatus?.icon} alt={currentStatus.name || ''} />
              <StatusMessage>{t('chargeBack.holdMessage')}</StatusMessage>
            </StatusContainerDescription>
          </>
        );
      case StatusEnum.EXPIRED:
        return (
          <>
            <StatusContainer sx={{ color: currentStatus.color, background: currentStatus.background }}>
              <img src={currentStatus?.icon} alt={currentStatus.name || ''} />
              <StatusName>
                {currentStatus?.name} - {formatDateByStatus(data, StatusEnum.EXPIRED)}
              </StatusName>
            </StatusContainer>
            <StatusContainerDescription sx={{ color: currentStatus.color, background: currentStatus.background }}>
              <img src={currentStatus?.icon} alt={currentStatus.name || ''} />
              <StatusMessage>
                {t('chargeBack.expired_msg')} <StatusTotal>{`$${Math.abs(selected?.chargeTotal || 0)}`}</StatusTotal>.
              </StatusMessage>
            </StatusContainerDescription>
          </>
        );
      case StatusEnum.DENIED:
        return (
          <>
            <StatusContainer sx={{ color: currentStatus.color, background: currentStatus.background }}>
              <img src={currentStatus?.icon} alt={currentStatus.name || ''} />
              <StatusName>
                {currentStatus?.name} - {formatDateByStatus(data, StatusEnum.DENIED)}
              </StatusName>
            </StatusContainer>
            <StatusContainerDescription sx={{ color: currentStatus.color, background: currentStatus.background }}>
              <img src={currentStatus?.icon} alt={currentStatus.name || ''} />
              <StatusMessage>{t('chargeBack.denied_msg')}</StatusMessage>
            </StatusContainerDescription>
          </>
        );
      case StatusEnum.DECLINED:
        return (
          <>
            <StatusContainer sx={{ color: currentStatus.color, background: currentStatus.background }}>
              <img src={currentStatus?.icon} alt={currentStatus.name || ''} />
              <StatusName>
                {currentStatus?.name} - {formatDateByStatus(data, StatusEnum.DECLINED)}
              </StatusName>
            </StatusContainer>
            <StatusContainerDescription sx={{ color: currentStatus.color, background: currentStatus.background }}>
              <img src={currentStatus?.icon} alt={currentStatus.name || ''} />
              <StatusMessage>{t('chargeBack.denied_msg')}</StatusMessage>
            </StatusContainerDescription>
          </>
        );
      case StatusEnum.REJECTED:
        return (
          <>
            <StatusContainer sx={{ color: currentStatus.color, background: currentStatus.background }}>
              <img src={currentStatus?.icon} alt={currentStatus.name || ''} />
              <StatusName>
                {currentStatus?.name} - {formatDateByStatus(data, StatusEnum.REJECTED)}
              </StatusName>
            </StatusContainer>
            <StatusContainerDescription sx={{ color: currentStatus.color, background: currentStatus.background }}>
              <img src={currentStatus?.icon} alt={currentStatus.name || ''} />
              <StatusMessage>
                {t('chargeBack.rejected_msg')} <StatusTotal>{`$${Math.abs(selected?.chargeTotal || 0)}`}</StatusTotal>{' '}
                {t('chargeBack.rejected_msg2')}.
              </StatusMessage>
            </StatusContainerDescription>
          </>
        );
      case StatusEnum.REFUNDED:
        return (
          <>
            <StatusContainer sx={{ color: currentStatus.color, background: currentStatus.background }}>
              <img src={currentStatus?.icon} alt={currentStatus.name || ''} />
              <StatusName>
                {currentStatus?.name} - {formatDateByStatus(data, StatusEnum.REFUNDED)}
              </StatusName>
            </StatusContainer>
            <StatusContainerDescription sx={{ color: currentStatus.color, background: currentStatus.background }}>
              <img src={currentStatus?.icon} alt={currentStatus.name || ''} />
              <StatusMessage>
                {t('chargeBack.refunded_msg')} <StatusTotal>{`$${Math.abs(selected?.refundTotal || 0)}`}</StatusTotal>.
              </StatusMessage>
            </StatusContainerDescription>
          </>
        );
      case StatusEnum.ACCEPTED:
        return (
          <>
            <StatusContainer sx={{ color: currentStatus.color, background: currentStatus.background }}>
              <img src={currentStatus?.icon} alt={currentStatus.name || ''} />
              <StatusName>
                {currentStatus?.name} - {formatDateByStatus(data, StatusEnum.ACCEPTED)}
              </StatusName>
            </StatusContainer>
            <StatusContainerDescription sx={{ color: currentStatus.color, background: currentStatus.background }}>
              <img src={currentStatus?.icon} alt={currentStatus.name || ''} />
              <StatusMessage>
                {t('chargeBack.approved_msg')} <StatusTotal>{`$${Math.abs(selected?.chargeTotal || 0)}`}</StatusTotal>.
              </StatusMessage>
            </StatusContainerDescription>
          </>
        );
      case StatusEnum.SOLVED_BY_PROVIDER:
        return (
          <>
            <StatusContainer sx={{ color: currentStatus.color, background: currentStatus.background }}>
              <img src={currentStatus?.icon} alt={currentStatus.name || ''} />
              <StatusName>
                {currentStatus?.name} - {formatDateByStatus(data, StatusEnum.SOLVED_BY_PROVIDER)}
              </StatusName>
            </StatusContainer>
            <StatusContainerDescription sx={{ color: currentStatus.color, background: currentStatus.background }}>
              <img src={currentStatus?.icon} alt={currentStatus.name || ''} />
              <StatusMessage>{t('chargeBack.solvedByProviderMessage')}</StatusMessage>
            </StatusContainerDescription>
          </>
        );
      case StatusEnum.UNKNOWN:
        return (
          <>
            <StatusContainer sx={{ color: currentStatus.color, background: currentStatus.background }}>
              <img src={currentStatus?.icon} alt={currentStatus.name || ''} />
              <StatusName>
                {currentStatus?.name} - {formatDateByStatus(data, StatusEnum.UNKNOWN)}
              </StatusName>
            </StatusContainer>
            <StatusContainerDescription sx={{ color: currentStatus.color, background: currentStatus.background }}>
              <img src={currentStatus?.icon} alt={currentStatus.name || ''} />
              <StatusMessage>
                {t('chargeBack.expired_msg')} <StatusTotal>{`$${Math.abs(selected?.chargeTotal || 0)}`}</StatusTotal>.
              </StatusMessage>
            </StatusContainerDescription>
          </>
        );
      case StatusEnum.DISPUTE_NOT_ALLOWED:
        return (
          <>
            <StatusContainer sx={{ color: currentStatus.color, background: currentStatus.background }}>
              <img src={currentStatus?.icon} alt={currentStatus.name || ''} />
              <StatusName>
                {currentStatus?.name} - {formatDateByStatus(data, StatusEnum.DISPUTE_NOT_ALLOWED)}
              </StatusName>
            </StatusContainer>
            <StatusContainerDescription sx={{ color: currentStatus.color, background: currentStatus.background }}>
              <img src={currentStatus?.icon} alt={currentStatus.name || ''} />
              <StatusMessage>
                {t('chargeBack.expired_msg')} <StatusTotal>{`$${Math.abs(selected?.chargeTotal || 0)}`}</StatusTotal>.
              </StatusMessage>
            </StatusContainerDescription>
          </>
        );
      default:
        return <></>;
    }
  };

  if (loading) {
    return <Preloader overlay={true} />;
  }

  return (
    <>
      <DisputeModal
        open={openDisputeModal}
        handleClose={handleClose}
        selectedReason={selectedReason}
        handleChange={handleChange}
        handleFormSubmit={handleFormSubmit}
        formMethods={formMethods}
        handleStatusSwitch={handleStatusSwitch}
        handleStatus={handleStatus}
        loadingButton={loadingButton}
      />
      <ConfirmModal
        handleClose={handleCloseConfirmModal}
        open={openConfirmModal}
        title={t('chargeBack.accept')}
        description={selected?.chargeTotal}
        handleConfirmModal={handleConfirmModal}
        loadingButton={loadingButton}
      />

      <ChargeBackViewFooter component="footer">
        <ChargeBackAccept variant="outlined" onClick={handleAccept}>
          {t('chargeBack.details.accept')}
        </ChargeBackAccept>
        <ChargeBackViewDispute variant="contained" onClick={handleDispute}>
          {t('chargeBack.details.dispute')}
        </ChargeBackViewDispute>
      </ChargeBackViewFooter>
    </>
  );
};

export default ChargeBackActions;
