import React, { useState, useCallback, useEffect, memo, useRef } from 'react';
import { TabContent, TabPane, CustomInput, ListGroup, ListGroupItem } from 'reactstrap';
import instance from 'services/api';
import moment from 'moment';
import Currency from 'components/Currency';
import { getBusinessSettings } from 'redux/actions/business';
import Preloader from 'components/Preloader';
import { Box, Button, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { confirm } from 'components/MenuNew/ConfirmModal';
import useProviderConfig from 'hooks/useProviderConfig';

const ReportView = ({ onClose }) => {
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [loading, setLoading] = useState(true);
  const [report, setReport] = useState(null);
  const refEl = useRef();
  useEffect(() => {
    instance
      .get('/reports/x')
      .then(({ data: { data } }) => {
        setReport(data);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  const goToScreen = useCallback((i) => setSelectedIndex(i), []);

  const handlePrint = useCallback((type) => {
    return instance.get(`/reports/${type}`).then(({ data: { data } }) => {
      setReport(data);
      setTimeout(() => {
        const contentOrders = document.getElementById('order-view');
        contentOrders.classList.add('order-data');
        window.print();
        contentOrders.classList.remove('order-data');
      }, 50);
    });
  }, []);

  return (
    <Box sx={{ zIndex: 9999 }}>
      <div className="aside-view report-view shadow-sm">
        <TabContent activeTab={selectedIndex}>
          <TabPane tabId="0" className={`order-print-screen ${selectedIndex === 0 ? 'active' : ''} `}>
            <MainScreen onClose={onClose} report={report} loading={loading} goToScreen={goToScreen} />
          </TabPane>
          <TabPane tabId="1" className={`${selectedIndex === 1 ? 'active' : ''} `}>
            <PrintScreen onPrint={handlePrint} goToScreen={goToScreen} />
          </TabPane>
        </TabContent>
      </div>
    </Box>
  );
};

const PrintScreen = memo(({ onPrint, goToScreen }) => {
  const { t } = useTranslation();

  const [type, setType] = useState('x');
  const [loading, setLoading] = useState(false);

  const handlePrint = async () => {
    const confirmed =
      type === 'x'
        ? true
        : await confirm({
            title: 'confirm_modal.print',
            message: 'confirm_modal.are_you_sure',
            cancelText: 'confirm_modal.no',
            confirmText: 'confirm_modal.yes',
            confirmColor: 'primary',
          });
    if (confirmed) {
      setLoading(true);
      onPrint(type).finally(() => {
        setLoading(false);
      });
    }
  };

  return (
    <div className="aside-view-tab">
      <div className="aside-view-head">
        <Button id="reportView" onClick={() => goToScreen(0)}>
          <i className="icon icon-arrow-left"></i>
        </Button>
        <h6 category="h6">{t('order_view.print')}</h6>
      </div>

      <div className="aside-view-body">
        <ListGroup>
          <ListGroupItem>
            <CustomInput
              type="radio"
              id="x"
              value="x"
              name="typeX"
              checked={type === 'x'}
              onChange={() => setType('x')}
              label="X Report"
            />
          </ListGroupItem>
          <ListGroupItem>
            <CustomInput
              type="radio"
              id="z"
              value="z"
              name="typeZ"
              checked={type === 'z'}
              onChange={() => setType('z')}
              label="Z Report"
            />
          </ListGroupItem>
        </ListGroup>
      </div>
      <div className="aside-view-actions">
        <Button
          id="reportViewPrint"
          variant="contained"
          color="primary"
          disabled={loading}
          onClick={() => handlePrint()}
        >
          {t('order_view.print')}
        </Button>
      </div>
    </div>
  );
});

const MainScreen = ({ onClose, report, loading, goToScreen }) => {
  const { t } = useTranslation();
  const { providers } = useProviderConfig();

  const printBox = useRef();

  const openPrint = () => {
    printBox.current.scrollTo(0, 0);
    goToScreen(1);
  };

  return (
    <div className="aside-view-tab">
      <div className="aside-view-head">
        <h6 category="h6">{t('batch_report.screen_name')} </h6>
        <Button id="reportViewOpenPrint" onClick={openPrint}>
          <i className="icon icon-printer"></i>
        </Button>
      </div>
      <div id="bachReport" className="aside-view-body" ref={printBox}>
        {report?.report && Object.values(report.report).length ? (
          <div>
            <h6>
              {t('orders_history.printout')}
              {Object.values(report.report)[0].reportModel.printoutType}
            </h6>

            {/* All Businesses */}
            {Object.values(report.report).length > 1 && (
              <>
                <div className="pt-2 mb-3 break-avoid">
                  <h6 className="border-bottom pb-2 mb-1 text-capitalize">{t('orders_history.all_businesses')}</h6>
                </div>
                {report.all_business.totalsByProviders.length
                  ? report.all_business.totalsByProviders.map(
                      ({ ordersCount, providerName, salesTotal, adjustmentsFees, tax, subTotalPrice }, index) => (
                        <div className="pt-2 mb-3 break-avoid" key={`${index}-${providerName}`}>
                          <h6 className="border-bottom pb-2 mb-1">{providerName}</h6>
                          <table className="report-printout-table">
                            <tbody>
                              <tr>
                                <td> {t('order_view.subtotal')}:</td>
                                <td>{<Currency value={subTotalPrice} />}</td>
                              </tr>
                              <tr>
                                <td>{t('batch_report.tax')}:</td>
                                <td>{<Currency value={tax} />}</td>
                              </tr>
                              <tr>
                                <td>{t('batch_report.adjustment_fee')}:</td>
                                <td>
                                  {adjustmentsFees < 0 ? (
                                    <Typography>
                                      <strong>
                                        (
                                        {Number(Math.abs(adjustmentsFees)).toLocaleString('en-US', {
                                          style: 'currency',
                                          currency: 'USD',
                                        })}
                                        )
                                      </strong>
                                    </Typography>
                                  ) : (
                                    <Typography>
                                      <strong>
                                        {Number(adjustmentsFees).toLocaleString('en-US', {
                                          style: 'currency',
                                          currency: 'USD',
                                        })}
                                      </strong>
                                    </Typography>
                                  )}
                                </td>
                              </tr>
                              <tr>
                                <td>{t('order_view.total')}:</td>
                                <td>
                                  <Currency value={salesTotal} />
                                </td>
                              </tr>
                              <tr>
                                <td> {t('sidebar.orders')}:</td>
                                <td>
                                  <b>{ordersCount}</b>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      )
                    )
                  : null}
                {!!report.all_business.websiteTotalValues && (
                  <>
                    <h6 className="border-bottom pb-2 mb-1">
                      {providers[report.all_business.websiteTotalValues.providerName]?.label || 'Orders.co'}
                    </h6>
                    <table className="report-printout-table">
                      <tbody>
                        <tr>
                          <td>{t('order_view.subtotal')}:</td>
                          <td>{<Currency value={report.all_business.websiteTotalValues.subTotalPrice} />}</td>
                        </tr>
                        <tr>
                          <td>{t('batch_report.tax')}:</td>
                          <td>{<Currency value={report.all_business.websiteTotalValues.tax} />}</td>
                        </tr>
                        <tr>
                          <td>{t('batch_report.adjustment_fee')}:</td>
                          <td>
                            {report.all_business.websiteTotalValues.adjustmentsFees < 0 ? (
                              <Typography>
                                <strong>
                                  (
                                  {Number(
                                    Math.abs(report.all_business.websiteTotalValues.adjustmentsFees)
                                  ).toLocaleString('en-US', {
                                    style: 'currency',
                                    currency: 'USD',
                                  })}
                                  )
                                </strong>
                              </Typography>
                            ) : (
                              <Typography>
                                <strong>
                                  {Number(report.all_business.websiteTotalValues.adjustmentsFees).toLocaleString(
                                    'en-US',
                                    {
                                      style: 'currency',
                                      currency: 'USD',
                                    }
                                  )}
                                </strong>
                              </Typography>
                            )}
                          </td>
                        </tr>
                        <tr>
                          <td>{t('order_view.total')}:</td>
                          <td>
                            <Currency value={report.all_business.websiteTotalValues.salesTotal} />
                          </td>
                        </tr>
                        <tr>
                          <td>{t('sidebar.orders')}:</td>
                          <td>
                            <b>{report.all_business.websiteTotalValues.ordersCount}</b>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </>
                )}
                <h6 className="mt-4 d-flex justify-content-between">
                  <span>{t('order_view.subtotal')}:</span>{' '}
                  <Currency value={report.all_business.totalValues.subTotalPrice} />
                </h6>
                <h6 className="d-flex justify-content-between">
                  <span>{t('batch_report.tax')}</span> <b>{<Currency value={report.all_business.totalValues.tax} />}</b>
                </h6>
                <h6 className="d-flex justify-content-between">
                  <span>{t('batch_report.adjustment_fee')}:</span>
                  <b>
                    {report.all_business.totalValues.adjustmentsFees < 0 ? (
                      <Typography>
                        <strong>
                          (
                          {Number(Math.abs(report.all_business.totalValues.adjustmentsFees)).toLocaleString('en-US', {
                            style: 'currency',
                            currency: 'USD',
                          })}
                          )
                        </strong>
                      </Typography>
                    ) : (
                      <Typography>
                        <strong>
                          {Number(report.all_business.totalValues.adjustmentsFees).toLocaleString('en-US', {
                            style: 'currency',
                            currency: 'USD',
                          })}
                        </strong>
                      </Typography>
                    )}
                  </b>
                </h6>
                <h6 className="d-flex justify-content-between">
                  <span>{t('order_view.total')}:</span>{' '}
                  <b>{<Currency value={report.all_business.totalValues.salesTotal} />}</b>
                </h6>
                <h6 className="d-flex justify-content-between">
                  <span>{t('sidebar.orders')}:</span> <b>{report.all_business.totalValues.ordersCount}</b>
                </h6>
              </>
            )}
            {/*  Creating report for each business */}
            {Object.values(report.report).map(({ reportModel, businessId }, index) => {
              const { totalsByProviders, websiteTotalValues, totalValues } = reportModel;
              return (
                <div key={`${index}-${businessId}`}>
                  <div className="pt-2 mb-1 mt-5 break-avoid">
                    <h6 className="border-bottom pb-2 mb-1 text-capitalize">{reportModel.businessName}</h6>
                  </div>
                  <div className="aside-view-detailers pt-1">
                    <div className="detailers pl-0">
                      <div className="order-date">
                        <small className="mr-1">{t('batch_report.start_date')}:</small>
                        <i className="icon icon-alarm-check"></i>
                        <p className="text">{moment(reportModel.startDate).format('hh:mm A - ddd, MMM Do')}</p>
                      </div>
                      <div className="order-date">
                        <small className="mr-1">{t('batch_report.end_date')}:</small>
                        <i className="icon icon-alarm-check"></i>
                        <p className="text">{moment(reportModel.endDate).format('hh:mm A - ddd, MMM Do')}</p>
                      </div>
                    </div>
                  </div>
                  <ContactInfo report={reportModel} />
                  {totalsByProviders?.map(
                    ({ ordersCount, providerName, salesTotal, tax, subTotalPrice, adjustmentsFees }, index) => (
                      <div className="pt-2 mb-3 break-avoid" key={`${index}-${providerName}`}>
                        <h6 className="border-bottom pb-2 mb-1">{providerName}</h6>
                        <table className="report-printout-table">
                          <tbody>
                            <tr>
                              <td>{t('order_view.subtotal')}:</td>
                              <td>{<Currency value={subTotalPrice} />}</td>
                            </tr>
                            <tr>
                              <td>{t('batch_report.tax')}:</td>
                              <td>{<Currency value={tax} />}</td>
                            </tr>
                            <tr>
                              <td>{t('batch_report.adjustment_fee')}:</td>
                              <td>
                                {adjustmentsFees < 0 ? (
                                  <Typography>
                                    <strong>
                                      (
                                      {Number(Math.abs(adjustmentsFees)).toLocaleString('en-US', {
                                        style: 'currency',
                                        currency: 'USD',
                                      })}
                                      )
                                    </strong>
                                  </Typography>
                                ) : (
                                  <Typography>
                                    <strong>
                                      {Number(adjustmentsFees).toLocaleString('en-US', {
                                        style: 'currency',
                                        currency: 'USD',
                                      })}
                                    </strong>
                                  </Typography>
                                )}
                              </td>
                            </tr>
                            <tr>
                              <td>{t('order_view.total')}:</td>
                              <td>
                                <Currency value={salesTotal} />
                              </td>
                            </tr>
                            <tr>
                              <td>{t('sidebar.orders')}:</td>
                              <td>
                                <b>{ordersCount}</b>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    )
                  )}
                  <div className="pt-2 mb-3 break-avoid">
                    {!!websiteTotalValues && (
                      <>
                        <h6 className="border-bottom pb-2 mb-1 ">{websiteTotalValues.providerName}</h6>
                        <table className="report-printout-table">
                          <tbody>
                            <tr>
                              <td>{t('order_view.subtotal')}:</td>
                              <td>{<Currency value={websiteTotalValues.subTotalPrice} />}</td>
                            </tr>
                            <tr>
                              <td>{t('batch_report.tax')}:</td>
                              <td>{<Currency value={websiteTotalValues.tax} />}</td>
                            </tr>
                            <tr>
                              <td>{t('batch_report.adjustment_fee')}:</td>
                              <td>
                                {websiteTotalValues.adjustmentsFees < 0 ? (
                                  <Typography>
                                    <strong>
                                      (
                                      {Number(Math.abs(websiteTotalValues.adjustmentsFees)).toLocaleString('en-US', {
                                        style: 'currency',
                                        currency: 'USD',
                                      })}
                                      )
                                    </strong>
                                  </Typography>
                                ) : (
                                  <Typography>
                                    <strong>
                                      {Number(websiteTotalValues.adjustmentsFees).toLocaleString('en-US', {
                                        style: 'currency',
                                        currency: 'USD',
                                      })}
                                    </strong>
                                  </Typography>
                                )}
                              </td>
                            </tr>
                            <tr>
                              <td>{t('order_view.total')}:</td>
                              <td>
                                <Currency value={websiteTotalValues.salesTotal} />
                              </td>
                            </tr>
                            <tr>
                              <td>{t('sidebar.orders')}:</td>
                              <td>
                                <b>{websiteTotalValues.ordersCount}</b>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </>
                    )}

                    <h6 className="mt-4 d-flex justify-content-between">
                      <span>{t('order_view.subtotal')}:</span> <Currency value={totalValues.subTotalPrice} />
                    </h6>
                    <h6 className="d-flex justify-content-between">
                      <span>{t('batch_report.tax')}</span> <b>{<Currency value={totalValues.tax} />}</b>
                    </h6>
                    <h6 className="d-flex justify-content-between">
                      <span>{t('batch_report.adjustment_fee')}:</span>
                      {totalValues.adjustmentsFees < 0 ? (
                        <Typography>
                          <strong>
                            (
                            {Number(Math.abs(totalValues.adjustmentsFees)).toLocaleString('en-US', {
                              style: 'currency',
                              currency: 'USD',
                            })}
                            )
                          </strong>
                        </Typography>
                      ) : (
                        <Typography>
                          <strong>
                            {Number(totalValues.adjustmentsFees).toLocaleString('en-US', {
                              style: 'currency',
                              currency: 'USD',
                            })}
                          </strong>
                        </Typography>
                      )}
                    </h6>
                    <h6 className="d-flex justify-content-between">
                      <span>{t('order_view.total')}:</span> <b>{<Currency value={totalValues.salesTotal} />}</b>
                    </h6>
                    <h6 className="d-flex justify-content-between">
                      <span>{t('sidebar.orders')}:</span> <b>{totalValues.ordersCount}</b>
                    </h6>
                  </div>
                </div>
              );
            })}
          </div>
        ) : loading ? (
          <Preloader />
        ) : (
          <div className="text-muted text-center py-5">{t('orders_history.there_new_transactions')}</div>
        )}
      </div>
      <div className="aside-view-actions">
        <Button id="reportViewMenuDone" variant="contained" color="primary" onClick={onClose}>
          {t('menu.done')}
        </Button>
      </div>
    </div>
  );
};

const ContactInfo = ({ report }) => {
  const { t } = useTranslation();

  return (
    <div className="break-avoid mb-4">
      <h6>{t('batch_report.contact_info')}</h6>
      <table className="report-contacts-table">
        <tbody>
          <tr>
            <td>{t('batch_report.name')}:</td>
            <td>{report.businessName}</td>
          </tr>
          <tr>
            <td>{t('batch_report.email')}:</td>
            <td>{report.emailAddress}</td>
          </tr>
          <tr>
            <td>{t('batch_report.phone')}:</td>
            <td>{report.phoneNumber}</td>
          </tr>
          <tr>
            <td>{t('batch_report.address')}:</td>
            <td>{report.businessAddress}</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default ReportView;
