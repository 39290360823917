import { useEffect, useState } from 'react';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { useLocation } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import { useDispatch, useSelector } from 'react-redux';
import { getBusinessSettingsTO, updateBusinessSettingsTO } from 'redux/actions/TableOrdering/settings';
const settingsDineInSchema = yup.object().shape({
  isEnable: yup.boolean(),
  autoConfirm: yup.boolean(),
});
const settingsTakeOutSchema = yup.object().shape({
  isEnable: yup.boolean(),
  autoConfirm: yup.boolean(),
});
const settingsDeliverySchema = yup.object().shape({
  isEnable: yup.boolean(),
  autoConfirm: yup.boolean(),
});

export const SETTINGS_TABLE_OPTIONS = {
  DINE_IN: 'dine_in',
  TAKE_OUT: 'take_out',
  DELIVERY: 'delivery',
};

export const useTableOrderingSettings = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const [selectedOptions, setSelectedOptions] = useState(null);
  const { settings = {}, loadingSettings, loading } = useSelector(({ tableOrdering }) => tableOrdering);
  const { currentBusiness } = useSelector(({ business }) => business);
  //Dine In
  const settingsDineIn = useForm({
    defaultValues: { isEnable: false, autoConfirm: false },
    mode: 'all',
    resolver: yupResolver(settingsDineInSchema),
  });
  //Take Out
  const settingsTakeOut = useForm({
    defaultValues: { isEnable: false, autoConfirm: false },
    mode: 'all',
    resolver: yupResolver(settingsTakeOutSchema),
  });
  //Delivery
  const settingsDelivery = useForm({
    defaultValues: { isEnable: false, autoConfirm: false },
    mode: 'all',
    resolver: yupResolver(settingsDeliverySchema),
  });
  useEffect(() => {
    if (currentBusiness?._id) {
      dispatch(getBusinessSettingsTO());
    }
  }, [currentBusiness?._id, location]);

  useEffect(() => {
    if (!settings?.serviceOptions?.length) return;

    const resetSettings = (setting, schema, resetFunc) => {
      resetFunc(
        {
          isEnable: setting?.configs?.isEnable ?? schema.isEnable,
          autoConfirm: setting?.configs?.autoConfirm ?? schema.autoConfirm,
        },
        { keepDirty: false }
      );
    };

    settings.serviceOptions.forEach((setting) => {
      switch (setting?.type) {
        case SETTINGS_TABLE_OPTIONS.DINE_IN:
          resetSettings(setting, settingsDineInSchema, settingsDineIn.reset);
          break;
        case SETTINGS_TABLE_OPTIONS.TAKE_OUT:
          resetSettings(setting, settingsTakeOut, settingsTakeOut.reset);
          break;
        case SETTINGS_TABLE_OPTIONS.DELIVERY:
          resetSettings(setting, settingsDelivery, settingsDelivery.reset);
          break;
        default:
          break;
      }
    });
  }, [settings]);

  const updateSettings = (configs, type) => {
    dispatch(updateBusinessSettingsTO(configs, type));
  };

  const handleTakeOutSubmit = (values) => {
    setSelectedOptions(SETTINGS_TABLE_OPTIONS.TAKE_OUT);
    updateSettings(values, SETTINGS_TABLE_OPTIONS.TAKE_OUT);
  };

  const handleDeliverySubmit = (values) => {
    setSelectedOptions(SETTINGS_TABLE_OPTIONS.DELIVERY);
    updateSettings(values, SETTINGS_TABLE_OPTIONS.DELIVERY);
  };

  const handleDineInSubmit = (values) => {
    setSelectedOptions(SETTINGS_TABLE_OPTIONS.DINE_IN);
    updateSettings(values, SETTINGS_TABLE_OPTIONS.DINE_IN);
  };
  return {
    settingsDineIn,
    handleDineInSubmit,
    settingsTakeOut,
    handleTakeOutSubmit,
    handleDeliverySubmit,
    settingsDelivery,
    loading,
    loadingSettings,
    selectedOptions,
  };
};
