import React from 'react';
import { Link } from 'react-router-dom';
import SuccessIng from 'assets/images/success.png';
import { useTranslation } from 'react-i18next';

const Success = () => {
  const { t } = useTranslation();

  return (
    <div className="wizard-finish">
      <div className="py-4">
        <img src={SuccessIng} alt="SPELL-CHECK" style={{ width: 180, height: 140 }} />
      </div>
      <h2 className="wizard-title">{t('orders.congratulations_step_to')} </h2>
      <h2 className="font-wight-bold wizard-subtitle">{t('add_location.order_mastery')} !</h2>
      <p className="text-muted  wizard-info">
        {t('add_location.expect_next_24_48_hours')}
        <br />
        {t('add_location.if_you_contact_us_at')}&nbsp;
        <a href="tel:+18555001070">855-500-1070</a>
      </p>
      <Link to="/login">{t('add_location.back_login_page')}</Link>
    </div>
  );
};

export default Success;
