export const USER_LOADING = 'USER_LOADING';
export const USER_LOGOUT = 'USER_LOGOUT';
export const USER_LOADED = 'USER_LOADED';
export const BUSINESS_CAL_JAVA_COFFEE = '636bd5a1e578d7ee1654ea49';
export const BUSINESS_FREENESS_ALL_DOGS_GO_TO_HEAVEN = '649ca7fcc5ff16373370fee7';
export const IS_LOGIN_USER = 'IS_LOGIN_USER';
export const GET_ALL_BUSINESS_LIST = 'GET_ALL_BUSINESS_LIST';
export const SUBSCRIPTION_PLANS = 'SUBSCRIPTIONS_PLANS';
export const CURRENT_SUBSCRIPTION_PLAN = 'CURRENT_SUBSCRIPTION_PLAN';
export const MODIFIED_LOG_OUT = 'MODIFIED_LOG_OUT';
