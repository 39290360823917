import React from 'react';
import { Box } from '@mui/material';
import ErrorBoundaryWrapper from 'components/ErrorBoundaryWrapper';
import TableList from './TableList';
import Details from '../Details';

const TableChargeBack = ({ name, columns, rows }) => {
  return (
    <ErrorBoundaryWrapper>
      <Box display="flex" width="100%">
        <Box className="order-content">
          <Box className="orderListBlock">
            <TableList name={name} columns={columns} rows={rows} />
          </Box>
        </Box>
        <Details />
      </Box>
    </ErrorBoundaryWrapper>
  );
};

export default TableChargeBack;
