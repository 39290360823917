import React, { createContext, useState } from 'react';

const PaymentPeriodicityEnum = {
  monthly: 'monthly',
  yearly: 'yearly',
};
const PaymentPeriodicityContext = createContext(PaymentPeriodicityEnum.monthly);

const PaymentPeriodicityProvider = ({ children }) => {
  const [periodicity, setPeriodicity] = useState(PaymentPeriodicityEnum.monthly);

  return (
    <PaymentPeriodicityContext.Provider value={{ periodicity, setPeriodicity }}>
      {children}
    </PaymentPeriodicityContext.Provider>
  );
};

const PaymentPeriodicityConsumer = ({ children }) => {
  return <PaymentPeriodicityContext.Consumer>{children}</PaymentPeriodicityContext.Consumer>;
};

export { PaymentPeriodicityContext, PaymentPeriodicityProvider, PaymentPeriodicityConsumer };
