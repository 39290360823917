import {
  LOYALTY_REWARDS_POINTS,
  LOYALTY_REWARDS_VALUES,
  ORDERS_REWARDS_EXPIRATION_DATES,
  ORDERS_REWARDS_POINTS,
  ORDERS_REWARDS_VALUES,
  SIGNUP_REWARDS_AMOUNTS,
  SIGNUP_REWARDS_EXPIRATION_DATES,
} from './config';

const checkValueIsNull = (value) => value === null;

export const defaultValuesLoyaltyRewards = {
  enabled: false,
  pointSingularName: 'point',
  pointPluralName: 'points',
  redemptionRewardDefinition: {
    spendingPoints: LOYALTY_REWARDS_POINTS[13].value,
    amountEarned: LOYALTY_REWARDS_VALUES[0].value,
  },
};

export const defaultValuesOrderRewards = {
  enabled: true,
  orderPointsValidityInDays: ORDERS_REWARDS_EXPIRATION_DATES[3].value,
  earningRewardDefinition: {
    spendingAmount: ORDERS_REWARDS_POINTS[0].value,
    pointsEarned: ORDERS_REWARDS_VALUES[3].value,
  },
};

export const defaultValuesSignUpRewards = {
  signupPointsValidityInDays: SIGNUP_REWARDS_EXPIRATION_DATES[3].value,
  signupRewardAmount: SIGNUP_REWARDS_AMOUNTS[0].value,
  enabled: false,
};

export const dataAdapterLoyalityRewards = (values) => {
  const valueAdapter = {
    pointSingularName: !checkValueIsNull(values.pointSingularName)
      ? values.pointSingularName
      : defaultValuesLoyaltyRewards.pointSingularName,
    pointPluralName: !checkValueIsNull(values.pointPluralName)
      ? values.pointPluralName
      : defaultValuesLoyaltyRewards.pointPluralName,
    enabled: !checkValueIsNull(values.enabled) ? values.enabled : defaultValuesLoyaltyRewards.enabled,
    redemptionRewardDefinition: {
      amountEarned: !checkValueIsNull(values?.redemptionRewardDefinition?.amountEarned)
        ? Number(values?.redemptionRewardDefinition?.amountEarned)
        : defaultValuesLoyaltyRewards.redemptionRewardDefinition.amountEarned,
      spendingPoints: !checkValueIsNull(values?.redemptionRewardDefinition?.spendingPoints)
        ? Number(values?.redemptionRewardDefinition?.spendingPoints)
        : defaultValuesLoyaltyRewards.redemptionRewardDefinition.spendingPoints,
    },
  };
  return valueAdapter;
};

export const dataAdapterOrdersRewards = (values) => {
  const valueAdapter = {
    orderPointsValidityInDays: !checkValueIsNull(values.orderPointsValidityInDays)
      ? Number(values.orderPointsValidityInDays)
      : defaultValuesOrderRewards.orderPointsValidityInDays,
    enabled: !checkValueIsNull(values.enabled) ? values.enabled : defaultValuesOrderRewards.enabled,
    earningRewardDefinition: {
      spendingAmount: !checkValueIsNull(values?.earningRewardDefinition?.spendingAmount)
        ? Number(values?.earningRewardDefinition?.spendingAmount || null)
        : defaultValuesOrderRewards.earningRewardDefinition.spendingAmount,
      pointsEarned: !checkValueIsNull(values?.earningRewardDefinition?.pointsEarned)
        ? Number(values?.earningRewardDefinition?.pointsEarned || null)
        : defaultValuesOrderRewards.earningRewardDefinition.pointsEarned,
    },
  };
  return valueAdapter;
};

export const dataAdapterSignupRewards = (values) => {
  const valueAdapter = {
    signupPointsValidityInDays: !checkValueIsNull(values.signupPointsValidityInDays)
      ? Number(values.signupPointsValidityInDays)
      : defaultValuesSignUpRewards.signupPointsValidityInDays,
    enabled: !checkValueIsNull(values.enabled) ? values.enabled : defaultValuesSignUpRewards.enabled,
    signupRewardAmount: !checkValueIsNull(values.signupRewardAmount)
      ? Number(values.signupRewardAmount)
      : defaultValuesSignUpRewards.signupRewardAmount,
  };

  return valueAdapter;
};
