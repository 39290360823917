export const convertLocationListForAsyncLoadOption = (list) => {
  const data = [];
  let optionsLocation;
  if (list.length > 0) {
    list.forEach((item) => {
      if (item?.value?.locations && item?.value?.locations?.length > 0) {
        data.push(...item.value?.locations);
      }
    });
  }
  if (data.length > 0) {
    optionsLocation = data.map((item) => ({
      value: item.businessId,
      label: item.locationName,
      name: item.locationName,
      companyName: item?.companyName || '',
    }));
    return optionsLocation;
  } else return [];
};

export const convertAllLocationList = (list) => {
  const data = [];
  if (list.length > 0) {
    list.map((item) => {
      if (item.locations && item?.locations.length > 0) {
        item?.locations.map((loc) => {
          if (!data.find((location) => location?.value === loc?._id)) {
            data.push({
              value: loc._id,
              label: `${loc?.locationName} \n(${loc?.address})`,
              name: loc.locationName,
              companyName: loc?.companyName || '',
              address: loc?.address || '',
              businessId: loc?.businessId || '',
              brandId: item._id || '',
            });
          }
        });
      }
    });
  }

  if (data?.length > 0) {
    // optionsLocation = data.map((item) => ({
    //   value: item._id,
    //   label: `${item?.locationName} ${item?.address}`,
    //   name: item.locationName,
    //   companyName: item?.companyName || '',
    //   address: item?.address || '',
    //   businessId: item?.businessId || '',
    // }));
    return data;
  } else return [];
};

export const filterBrands = (brands) => {
  return brands
    .map((brand) => ({
      ...brand,
      locations: brand.locations.filter((location) => !location.disabled),
    }))
    .filter((brand) => brand.locations.length > 0);
};
