import React from 'react';
import { useTranslation } from 'react-i18next';
import { Grid, Box, MenuItem } from '@mui/material';
import CustomTextInput from 'components/Forms/CustomTextInput';
import LabelRequired from 'components/FormFields/LabelRequired';
import { capitalizeName, employeeJobs } from 'pages/TableOrdering/hooks/useTableOrderingEmployee';
import { PADDING_MODAL_ACTION_LEFT, PADDING_MODAL_ACTION_RIGHT } from 'pages/TableOrdering/config';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import { Visibility, VisibilityOff } from '@mui/icons-material';
const CreateOrEditForm = ({ visiblePinCode, showPinCode }) => {
  const { t } = useTranslation();
  return (
    <Grid sx={{ mt: 1 }}>
      <Grid container spacing={2}>
        <Grid item md={6} xs={6} lg={6} xl={6}>
          <Box sx={PADDING_MODAL_ACTION_LEFT}>
            <CustomTextInput
              sx={{ width: '100%', height: '64px' }}
              name="firstname"
              label={<LabelRequired> {t('First name')}</LabelRequired>}
            />
          </Box>
        </Grid>
        <Grid item md={6} xs={6} lg={6} xl={6}>
          <Box sx={PADDING_MODAL_ACTION_RIGHT}>
            <CustomTextInput
              sx={{ width: '100%', height: '64px' }}
              name="lastname"
              label={<LabelRequired> {t('Last name')}</LabelRequired>}
            />
          </Box>
        </Grid>
        <Grid item md={6} xs={6} lg={6} xl={6}>
          <Box sx={PADDING_MODAL_ACTION_LEFT}>
            <CustomTextInput
              sx={{ width: '100%', height: '64px' }}
              name="pinCode"
              InputProps={{
                type: showPinCode ? 'text' : 'password',
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton aria-label="toggle password visibility" onClick={visiblePinCode} edge="end">
                      {showPinCode ? (
                        <VisibilityOff sx={{ fontSize: '1.2rem' }} />
                      ) : (
                        <Visibility sx={{ fontSize: '1.2rem' }} />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              label={<LabelRequired> {t('Pin code')}</LabelRequired>}
            />
          </Box>
        </Grid>
        <Grid item md={6} xs={6} lg={6} xl={6}>
          <Box sx={PADDING_MODAL_ACTION_RIGHT}>
            <CustomTextInput
              sx={{ width: '100%', height: '64px' }}
              name="role"
              select={true}
              label={<LabelRequired> {t('Job title')}</LabelRequired>}
            >
              {employeeJobs?.map((option) => {
                return (
                  <MenuItem key={option._id} value={capitalizeName(option.name)}>
                    {capitalizeName(option.name)}
                  </MenuItem>
                );
              })}
            </CustomTextInput>
          </Box>
        </Grid>
      </Grid>
    </Grid>
  );
};
export default CreateOrEditForm;
