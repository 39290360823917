import React, { useMemo } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import qs from 'qs';
import Box from '@mui/material/Box';
import { Typography } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ReportsFilter from 'components/Order/ReportsFilter';
import Private from 'components/Private';
import { getReportsFilterPeriodsHome } from 'constants/config';
import { getTitle } from './helper';
import { useTranslation } from 'react-i18next';

const HomeHeader = ({ handleDrawerOpen, open }) => {
  const { search, pathname } = useLocation();
  const history = useHistory();
  const { t } = useTranslation();
  const periods = useMemo(() => getReportsFilterPeriodsHome(), []);
  const query = useMemo(() => {
    const { period } = qs.parse(search, { ignoreQueryPrefix: true });
    return {
      period: periods[period] ? period : 'today',
    };
  }, [periods, search]);

  const handleFilterChange = (type, value) => {
    history.replace({ search: qs.stringify({ ...query, [type]: value }) });
  };

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        flexWrap: 'wrap',
      }}
    >
      <Box display="flex" alignItems="center">
        <IconButton
          aria-label="open drawer"
          onClick={handleDrawerOpen}
          edge="start"
          sx={{ mr: 2, ...(open && { display: 'none' }) }}
        >
          <MenuIcon />
        </IconButton>
        <Typography sx={{ color: '#3D4350', fontSize: '20px', mr: 3 }}>{t(getTitle(pathname))}</Typography>
        <Private permission="HOME_REPORT_FILTER">
          <ReportsFilter query={query} onSelect={handleFilterChange} />
        </Private>
      </Box>
      {/* <Box sx={{ marginY: 1, display: 'flex', flexDirection: 'row' }}>
        <ResumePauseButton />
        <BusinessAutocompletenew />
      </Box> */}
    </Box>
  );
};

export default HomeHeader;
