import React, { useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import MuiDialogTitle from '@mui/material/DialogTitle';
import MuiDialogContent from '@mui/material/DialogContent';
import MuiDialogActions from '@mui/material/DialogActions';
import { IconButton, Dialog, Button, TextField, Typography } from '@mui/material';
import { withDebounce } from 'helpers/withDeboucne';
import DraggableDialogWrapper from 'components/DraggableDialogWrapper/DraggableDialogWrapper';
import { useTranslation } from 'react-i18next';
const DialogTitle = (props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle
      sx={{
        '& button': {
          position: 'absolute',
          right: 1,
          top: 1,
        },
      }}
      disableTypography
      {...other}
    >
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
};

const FilterDialog = ({ handleSaveFilter, formErrors }) => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const [errorText, setErrorText] = useState(false);
  const [filterTitle, handleFilterTitle] = useState('');

  const handleDialogOpen = () => {
    setOpen(true);
  };

  const handleCloseDialog = () => {
    setOpen(false);
  };

  const changeFilterTitle = ({ target: { value } }) => {
    if (value) {
      setErrorText(false);
    } else {
      setErrorText(true);
    }
    handleFilterTitle(value);
  };

  const handleSubmitForm = () => {
    if (!filterTitle.trim()) {
      setErrorText(true);
      handleFilterTitle('');
    } else {
      handleSaveFilter(filterTitle).then(() => {
        setOpen(false);
        setErrorText(false);
        handleFilterTitle('');
      });
    }
  };

  return (
    <div className="report-dialog">
      {/*<Button disabled={formErrors} color="primary" className="banner-btn banner-filter" onClick={handleDialogOpen}>*/}
      {/*  Create Custom Report*/}
      {/*</Button>*/}
      <Dialog onClose={handleCloseDialog} open={open} PaperComponent={DraggableDialogWrapper}>
        <DialogTitle id="dialog-title" onClose={handleCloseDialog} sx={{ cursor: 'grab' }}>
          {t('reports_settings.create_custom_report')}
        </DialogTitle>
        <MuiDialogContent>
          <div className="mt-2">
            <TextField
              id="outlined-basic"
              label="Name your report as"
              variant="outlined"
              value={filterTitle}
              onChange={changeFilterTitle}
              error={errorText}
              helperText={errorText && `Please Type Report Name`}
            />
          </div>
        </MuiDialogContent>
        <MuiDialogActions sx={{ cursor: 'grab' }}>
          <div className="d-flex justify-content-end dialog-button">
            <Button
              id="filterDialogCancel"
              autoFocus
              variant="outlined"
              color="primary"
              className="banner-btn mr-2"
              onClick={handleCloseDialog}
            >
              {t('orders.cancel')}
            </Button>
            <Button
              id="filterDialogSave"
              onClick={withDebounce(handleSubmitForm)}
              autoFocus
              type="submit"
              variant="contained"
              color="primary"
              className="banner-btn ml-2"
            >
              {t('settings.save')}
            </Button>
          </div>
        </MuiDialogActions>
      </Dialog>
    </div>
  );
};

export default FilterDialog;
