import React, { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import { Typography } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import HomeHeader from './HomeHeader';
import { getTitle } from './helper';
import OrdersHeader from './OrdersHeader';
import MasterMenuHeader from './MasterMenuHeader';
import ResumePauseButton from 'components/PauseResume/ResumePauseButton';
import OrdersHistoryHeader from './OrdersHistoryHeader';
import { isPermissionWithCompanyType } from 'hooks/useCompanyType';
import BusinessNewListBox from 'components/BusinessListNew';
import { checkHasAnyConnectedProvider, isTableOrderingProvider } from 'utils/checkAccessDenied';
import { useTranslation } from 'react-i18next';
import useUpdateBusiness from 'hooks/useUpdateBusiness';
import TabsTable from 'pages/TableOrdering/TabsTabel/TabsTable';
import { StyledHeaderBox } from './styles';
import MasterMenuTabs from './MasterMenuTabs';
import HeaderTabs from 'components/Chargeback/HeaderTabs';
import { BoxWrapper } from '../BusinessList/styles';

const drawerWidth = 240;

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  transition: theme.transitions.create(['margin', 'width'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  background: 'white',
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: `${drawerWidth}px`,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Header = ({ handleDrawerOpen, open }) => {
  const { isTypeSingle } = isPermissionWithCompanyType();
  const { t } = useTranslation();
  const history = useHistory();
  const { pathname } = history.location;
  const providers = useSelector(({ providers }) => providers);
  const isTableOrdering = isTableOrderingProvider(providers?.items);
  const hasConnectProvider = checkHasAnyConnectedProvider(providers?.items);
  const location = useLocation();
  const {
    business: { currentBusiness },
  } = useSelector((store) => store);
  const checkPlan = currentBusiness?.settings?.disputeSettings?.enabled;

  useUpdateBusiness();
  const renderHeader = () => {
    if (location.pathname === '/') {
      return <HomeHeader handleDrawerOpen={handleDrawerOpen} open={open} />;
    } else if (location.pathname.includes('/orders')) {
      return <OrdersHeader handleDrawerOpen={handleDrawerOpen} open={open} />;
    } else if (location.pathname.includes('/history')) {
      return <OrdersHistoryHeader handleDrawerOpen={handleDrawerOpen} open={open} />;
    } else if (location.pathname.includes('/menu')) {
      return <MasterMenuHeader handleDrawerOpen={handleDrawerOpen} open={open} />;
    } else if (isTableOrdering && location.pathname.includes('/table-ordering')) {
      return (
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <IconButton
            id="masterMenuHeaderMenu"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{ mr: 2, mt: 2, ...(open && { display: 'none' }) }}
          >
            <MenuIcon />
          </IconButton>
          <Typography sx={{ color: '#3D4350', fontSize: '20px', mr: 3, mt: 2 }}>{t(getTitle(pathname))}</Typography>
        </Box>
      );
    } else if (location.pathname.includes('/onboarding')) {
      return (
        <Box>
          <Typography sx={{ color: '#3D4350', fontSize: '20px', mr: 3 }}>Onboarding</Typography>
        </Box>
      );
    } else if (location.pathname.includes('/chargeback')) {
      return (
        <Box>
          <Typography sx={{ color: '#3D4350', fontSize: '20px', mr: 3 }}>{t('sidebar.chargeback')}</Typography>
        </Box>
      );
    } else {
      return (
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <IconButton
              id="appBarMenu"
              aria-label="open drawer"
              onClick={handleDrawerOpen}
              edge="start"
              sx={{ mr: 2, ...(open && { display: 'none' }) }}
            >
              <MenuIcon />
            </IconButton>
            <Typography sx={{ color: '#3D4350', fontSize: '20px', mr: 3 }}>{t(getTitle(location.pathname))}</Typography>
          </Box>
        </Box>
      );
    }
  };

  return (
    <AppBar
      open={open}
      sx={{
        zIndex: 1000,
      }}
    >
      <Toolbar>
        <StyledHeaderBox
          sx={{
            alignItems: location.pathname.includes('/table-ordering') ? 'flex-start' : 'baseline',
          }}
        >
          {renderHeader()}
          {!location.pathname.includes('/history') && (
            <BoxWrapper>
              {isTypeSingle && <ResumePauseButton hasConnectProvider={hasConnectProvider} />}
              {/* <BusinessAutocomplete /> */}
              <BusinessNewListBox />
            </BoxWrapper>
          )}
        </StyledHeaderBox>
      </Toolbar>
      {location.pathname.includes('/chargeback') && checkPlan && <HeaderTabs />}
      {location.pathname.includes('/table-ordering') && <TabsTable />}
      {location.pathname.includes('/menu') && <MasterMenuTabs />}
    </AppBar>
  );
};

export default Header;
