import React, { useState, useMemo, useEffect } from 'react';
import { Button, FormGroup } from 'reactstrap';
import Preloader from 'components/Preloader';
import moment from 'moment';
import PickupTimeControls from './PickupTimeControls';
import { usePickupTime } from './usePickupTime';
import { getPickupMaxTimeByProvider } from './helpers';
import { MIN_PICKUP_TIME } from './constants';
import { Typography } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { useSelector } from 'react-redux';
import { PAUSE_RESUME_STATUSES } from 'constants/resumePause';
import axios from 'axios';
import instance from 'services/api';
import DraggableDialogWrapper from 'components/DraggableDialogWrapper/DraggableDialogWrapper';
import { useTranslation } from 'react-i18next';
// const pickupTime = [10, 20, 30, 45, 60, 90];

const ConfirmOrderModal = ({
  loading,
  isOpen,
  closeModal,
  handleSubmit,
  beReadyTime,
  tzId,
  defaultPrepTime,
  provider,
  setIsDisabled = () => {},
  isDisabled = false,
  orderCreationTime,
  isScheduled,
  orderBusinessId,
  currentBusinessId,
}) => {
  const { t } = useTranslation();
  const {
    stopResumeOrders: { statuses },
  } = useSelector(({ providers }) => providers);
  const [orderBusinessDefaultPrepTime, setOrderBusinessDefaultPrepTime] = useState(defaultPrepTime);
  const [orderBusinessStatus, setOrderBusinessStatus] = useState(statuses);
  const [isLoading, setIsLoading] = useState(false);
  const { additional_prep_time, status } = orderBusinessStatus;
  const maxTime = getPickupMaxTimeByProvider(orderBusinessDefaultPrepTime, provider);
  const currentStatus = status;
  const isBusy = currentStatus === PAUSE_RESUME_STATUSES.BUSY;
  const [pickupTime, setPickupTime] = usePickupTime(
    isBusy && additional_prep_time
      ? additional_prep_time / 60 + orderBusinessDefaultPrepTime
      : orderBusinessDefaultPrepTime,
    maxTime,
    isScheduled
  );
  useEffect(() => {
    if (isScheduled) {
      setPickupTime(0);
    } else if (isBusy) {
      setPickupTime(additional_prep_time / 60 + orderBusinessDefaultPrepTime);
    } else {
      setPickupTime(orderBusinessDefaultPrepTime);
    }
  }, [
    isBusy,
    orderBusinessDefaultPrepTime,
    setPickupTime,
    additional_prep_time,
    orderCreationTime,
    isOpen,
    isScheduled,
  ]);

  //   const [active, setActive] = useState(0);

  const finalPickupTime = useMemo(() => {
    const finalTime = moment.utc(isScheduled ? beReadyTime : orderCreationTime).tz(tzId);
    /**
     *  After changeStatus PUT request beReadyTime changes and updates in redux
     */
    finalTime
      // beReadyTime already includes orderBusinessDefaultPrepTime, so we need to subtract it to use with manually set pickupTime
      .add(pickupTime, 'm');

    return finalTime;
  }, [orderCreationTime, tzId, pickupTime, isBusy, isScheduled]);

  const handleClose = () => {
    closeModal();
    setPickupTime(orderBusinessDefaultPrepTime);
    setIsDisabled(false);
  };

  const handleConfirm = () => {
    handleSubmit({
      prepTime: isScheduled
        ? moment(finalPickupTime).add(orderBusinessDefaultPrepTime, 'm').format()
        : moment(finalPickupTime).format(),
      // prepTime: moment(orderCreationTime).add(pickupTime, 'm').format(),
      minute: pickupTime,
    });
    setPickupTime(orderBusinessDefaultPrepTime);
    setIsDisabled(true);
  };

  const getBusinessStatus = (orderBusinessId) => {
    setIsLoading(true);
    instance
      .get(`/business/status/${orderBusinessId}`)
      .then(({ data: { data } }) => {
        const { additionalPrepTimeMins, defaultPrepTimeMins, status } = data;
        setOrderBusinessDefaultPrepTime(defaultPrepTimeMins);
        setOrderBusinessStatus({ additional_prep_time: additionalPrepTimeMins * 60, status });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    if (isOpen && orderBusinessId !== currentBusinessId) {
      getBusinessStatus(orderBusinessId);
    } else {
      setOrderBusinessStatus({ additional_prep_time: statuses.additional_prep_time, status: statuses.status });
      setOrderBusinessDefaultPrepTime(defaultPrepTime);
    }
  }, [currentBusinessId, isOpen, orderBusinessId, statuses, defaultPrepTime]);

  return (
    <Dialog open={isOpen} onClose={handleClose} className="confirm-order" PaperComponent={DraggableDialogWrapper}>
      <DialogTitle onClose={handleClose} sx={{ cursor: 'grab' }}>
        {t('order_view.when_will_be_ready')}
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
            cursor: 'pointer',
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent dividers sx={{ width: '550px', minHeight: '150px' }}>
        <PickupTimeControls
          loading={loading || isLoading}
          pickupTime={pickupTime}
          defaultPrepTime={orderBusinessDefaultPrepTime}
          minTime={!isScheduled ? MIN_PICKUP_TIME : 0}
          maxTime={maxTime}
          changePickupTime={setPickupTime}
          isScheduled={isScheduled}
          isBusy={isBusy}
          additional_prep_time={additional_prep_time}
        />
        <Typography fontWeight="bold" align="center">
          {t('order_view.ready_for_pickup_at')} {finalPickupTime.format('hh:mm A')}
        </Typography>
        <FormGroup>
          <Button
            id="orderViewConfirmOrder"
            disabled={isDisabled}
            color="primary"
            className="or--width-100"
            onClick={handleConfirm}
          >
            {t('order_view.confirm_order')}
          </Button>
        </FormGroup>
      </DialogContent>
      {(loading || isLoading) && <Preloader overlay />}
    </Dialog>
  );
};

export default ConfirmOrderModal;
