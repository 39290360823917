import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  getEventsList,
  setEventActions,
  getEventDefault,
  deleteEvent,
  updateEvent,
  createEvent,
} from 'redux/actions/events';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import MenuItem from '@mui/material/MenuItem';

import Preloader from 'components/Preloader';
import EventItem from './EventItem';
import LabelTooltip from 'components/FormFields/LabelTooltip';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';

import { getPrintersList } from 'redux/actions/printers';
import EventTable from './EventTable';
import ErrorBoundaryWrapper from 'components/ErrorBoundaryWrapper';
import { useTranslation } from 'react-i18next';
import { confirm } from 'components/MenuNew/ConfirmModal';

const initialValues = {
  active: true,
  type: '',
  action: '',
  sendTo: '',
  copy: 1,
};

const Events = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [showEvent, handleShowEvent] = useState(false);
  const [eventModel, handleEventModel] = useState(initialValues);
  const [useTypeEvent, setTypeEvent] = useState('');
  const [isDirty, setDirty] = useState(false);
  const [mode, handleMode] = useState(null);
  const { eventList, eventDefault, eventActions, loading } = useSelector(({ events }) => events);

  const printers = useSelector(({ printers }) => printers);

  const handleDelete = async (e, _id) => {
    e.stopPropagation();
    const confirmed = await confirm({
      title: 'confirm_modal.event',
      message: t('settings.are_you_sure_event'),
      cancelText: t('orders.cancel'),
      confirmText: t('table_ordering.yes_delete'),
      confirmColor: 'primary',
    });
    if (confirmed) {
      dispatch(deleteEvent(_id));
      return true;
    }
  };

  const updateEventActions = (data) => {
    dispatch(setEventActions(data));
  };

  function handleEventItemMode(id) {
    if (id) {
      window.scrollTo(0, 0);
      const modelDate = eventList.find((item) => item._id === id);

      const newData = {
        ...modelDate,
        action: modelDate.action?._id || modelDate.action,
        type: modelDate.type?._id || modelDate.type,
      };
      handleEventModel(newData);
      handleShowEvent(true);
      handleMode('Edit');
      setDirty(false);
    } else {
      const currentEventActions = eventDefault.find((item) => item._id === (useTypeEvent || eventActions[0]._id))
        ?.actions;
      if (currentEventActions) {
        updateEventActions(currentEventActions);
      }
      handleMode('Add');
      handleEventModel({
        ...initialValues,
        action: currentEventActions?.[0]?._id || eventActions[0]._id,
        type: useTypeEvent || eventDefault[0]._id,
      });
      handleShowEvent(true);
      setDirty(true);
    }
  }

  const handleSubmit = (value) => {
    if (value._id) {
      return dispatch(updateEvent({ ...value })).then(() => {
        handleShowEvent(false);
        handleEventModel(initialValues);
        handleMode(null);
        setTypeEvent('');
        setDirty(false);
      });
    } else {
      return dispatch(createEvent({ ...value })).then(() => {
        handleShowEvent(false);
        handleEventModel(initialValues);
        handleMode(null);
        setTypeEvent('');
        setDirty(false);
      });
    }
  };

  useEffect(() => {
    dispatch(getEventsList());
    dispatch(getEventDefault());
    dispatch(getPrintersList());
    setDirty(false);
  }, []);

  const onChangeDefault = ({ target }) => {
    const { value } = target;
    setTypeEvent(value);
  };

  return (
    <ErrorBoundaryWrapper>
      <div className="">
        <div className="settings-page-content-header">
          <Typography sx={{ my: 2 }} variant="h5">
            <LabelTooltip id="printerSettings" value={t('settings.events_notifications')} tooltip="" />
          </Typography>
        </div>
        <>
          <Grid container justifyContent="space-between" spacing={2} columns={{ xs: 12 }}>
            <Grid item xs={10}>
              <TextField
                size="small"
                disabled={Boolean(mode) || showEvent}
                sx={{ width: '100%' }}
                select
                value={useTypeEvent}
                InputLabelProps={{ shrink: true }}
                label={t('settings.select_event')}
                onChange={onChangeDefault}
              >
                {eventDefault.map((item) => (
                  <MenuItem key={item._id} value={item._id}>
                    {item.name}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={2} sx={{ justifyContent: 'flex-end', display: 'flex' }}>
              <Button
                id="eventsAdd"
                sx={{ width: 'max-content' }}
                color="primary"
                variant="contained"
                type="button"
                disabled={showEvent && true}
                onClick={() => handleEventItemMode()}
              >
                <span className="icon icon-plus"></span>
                <span className="">{t('settings.add')}</span>
              </Button>
            </Grid>
          </Grid>
          {/* Edit and Add Event */}
          {showEvent && (
            <EventItem
              printersList={printers.list}
              handleEventModel={handleEventModel}
              eventModel={eventModel}
              handleSubmit={handleSubmit}
              setDirty={setDirty}
              isDirtyEvent={isDirty}
              mode={mode}
              handleMode={handleMode}
              handleShowEvent={handleShowEvent}
              eventActions={eventActions}
              eventDefault={eventDefault}
              updateEventActions={updateEventActions}
            />
          )}
          {/* Event List */}
          <div className="event-list">
            <Typography sx={{ mt: 2 }} variant="h5">
              {t('settings.events_list')}
            </Typography>
            <EventTable
              printers={printers}
              showEvent={showEvent}
              eventList={eventList}
              handleEventItemMode={handleEventItemMode}
              handleDelete={handleDelete}
            />
          </div>
        </>
        {loading && <Preloader overlay />}
      </div>
    </ErrorBoundaryWrapper>
  );
};

export default Events;
