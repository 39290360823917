import React, { useState, useMemo, useCallback, useEffect } from 'react';
import { Box, Typography, Tabs, Tab, AppBar } from '@mui/material';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import { useDispatch, useSelector } from 'react-redux';
import startStopInitValues from 'constants/resumePause';
import { updateStopResumeOrders } from 'redux/actions/providers';
import {
  PauseResumeBusyPrepTime,
  PauseResumeNormalSubTitle,
  PauseResumeNormalTitle,
  PauseResumeOrdersStatus,
  PauseResumeWillBusy,
} from './styles';
import { PAUSE_RESUME_STATUSES } from 'constants/resumePause';
import { useTranslation } from 'react-i18next';

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Box
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-force-tabpanel-${index}`}
      aria-labelledby={`scrollable-force-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <Typography>{children}</Typography>
        </Box>
      )}
    </Box>
  );
}

const PauseResume = () => {
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const { stopResumeOrders } = useSelector(({ providers }) => providers);
  const {
    data,
    statuses: { status },
  } = stopResumeOrders;
  const [value, setValue] = useState(0);
  const [valueDuration, setValueDuration] = useState(0);

  const handleChange = (event, newValue) => {
    const newStatus = startStopInitValues.action[newValue].value;
    dispatch(
      updateStopResumeOrders({
        data: {
          ...stopResumeOrders.data,
          action: newStatus,
          duration: 300,
        },
      })
    );
    setValue(newValue);
    setValueDuration(0);
  };

  const handleChangeDuration = (event, newValue) => {
    setValueDuration(newValue);
  };

  const handleDurationClick = (duration, unit) => {
    dispatch(
      updateStopResumeOrders({
        data: {
          ...stopResumeOrders.data,
          duration: duration,
          unit,
        },
      })
    );
  };

  const getPauseDurationLabel = useMemo(() => {
    if (data.action === PAUSE_RESUME_STATUSES.BUSY) {
      return startStopInitValues.durationsBusy.find((item) => item.value === (data.duration || 300))?.description || '';
    } else {
      return startStopInitValues.duration.find((item) => item.value === data.duration).description || '';
    }
  }, [data]);

  const iconStyle = useCallback((status) => {
    const styleObject = { fontSize: '16px', mt: '6px' };
    if (status === PAUSE_RESUME_STATUSES.RECEIVING) return { ...styleObject, color: '#11AF22' };
    if (status === PAUSE_RESUME_STATUSES.BUSY)
      return {
        ...styleObject,
        color: '#F2AE48',
      };
    return {
      ...styleObject,
      color: 'red',
    };
  }, []);

  return (
    <Box className="tabs-override-main">
      <PauseResumeOrdersStatus>{t('pause_resume.orders_status')}</PauseResumeOrdersStatus>
      <AppBar
        position="static"
        color="default"
        sx={{ borderRadius: '8px', overflow: 'hidden', boxShadow: '0px 0px 16px #A3A3A329' }}
      >
        <Tabs
          value={value}
          visibleScrollbar={false}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          sx={{ '& .Mui-selected': { background: '#E9EEF9' }, background: '#FFF', overflow: 'hidden' }}
        >
          {startStopInitValues.action.map((item, index) => {
            return (
              <Tab
                value={index}
                key={item.value}
                sx={{
                  textTransform: 'none',
                  width: '233px',
                  p: 0,
                }}
                label={
                  <Box sx={{ display: 'flex', width: '233px', padding: '12px 16px' }}>
                    <FiberManualRecordIcon sx={iconStyle(item.value)} />
                    <Box sx={{ ml: 1 }}>
                      <Typography sx={{ fontSize: '20px', color: '#050505', fontWeight: '500' }} align="left">
                        {t(item.text)}
                      </Typography>
                      <Typography sx={{ fontSize: '14px', color: '#050505' }}>{t(item.description)}</Typography>
                    </Box>
                  </Box>
                }
              />
            );
          })}
        </Tabs>
      </AppBar>
      <TabPanel value={value} index={0}>
        <PauseResumeNormalTitle>{t('pause_resume.changing_operation_normal')}</PauseResumeNormalTitle>
        <PauseResumeNormalSubTitle>{t('pause_resume.no_longer_ETAs_ordering')}</PauseResumeNormalSubTitle>
        <PauseResumeNormalSubTitle>{t('pause_resume.send_admins_managers')}</PauseResumeNormalSubTitle>
      </TabPanel>
      <TabPanel value={value} index={1} sx={{ width: '100%', fontSize: '16px', color: '#050505', padding: '24px 0' }}>
        {status === PAUSE_RESUME_STATUSES.PAUSED ? (
          <Box
            sx={{
              width: '400px',
              textAlign: 'center',
              display: 'flex',
              justifyContent: 'center',
              flexDirection: 'column',
              alignItems: 'center',
              margin: '100px auto',
            }}
          >
            <Typography variant="h6">{t('pause_resume.the_paused_now')}</Typography>
            <Typography sx={{ mt: 1 }}>{t('pause_resume.busy_status_will_becomes_normal')}</Typography>{' '}
          </Box>
        ) : (
          <>
            <PauseResumeBusyPrepTime>{t('pause_resume.how_much_prep_time_need')}</PauseResumeBusyPrepTime>
            <Tabs
              value={valueDuration}
              onChange={handleChangeDuration}
              scrollButtons="auto"
              indicatorColor="primary"
              variant="scrollable"
              textColor="primary"
              aria-label="scrollable force tabs"
              sx={{
                '& .Mui-selected': { background: '#E9EEF9' },
                background: '#FFF',
                // '& .MuiTabs-flexContainer': { display: 'block' },
              }}
            >
              {startStopInitValues.durationsBusy.map((item, index) => {
                return (
                  <Tab
                    sx={{
                      textTransform: 'none',
                    }}
                    key={item.value}
                    onClick={() => handleDurationClick(item.value, item.unit)}
                    label={<Typography sx={{ fontSize: '20px', color: '#050505' }}>{t(item.text)}</Typography>}
                    value={index}
                    {...a11yProps(index)}
                  />
                );
              })}
            </Tabs>
            <PauseResumeWillBusy>{t('pause_resume.changing_operation_busy')}</PauseResumeWillBusy>
            <PauseResumeNormalSubTitle>{t('pause_resume.add_default_prep_time')}</PauseResumeNormalSubTitle>
            <PauseResumeNormalSubTitle>{t('pause_resume.set_timer_after_60_mins')}</PauseResumeNormalSubTitle>
          </>
        )}
      </TabPanel>
      <TabPanel value={value} index={2} sx={{ width: '100%', fontSize: '16px', color: '#050505', padding: '24px 0' }}>
        <PauseResumeBusyPrepTime>{t('pause_resume.for_how_pause_store')}</PauseResumeBusyPrepTime>
        <AppBar
          position="static"
          color="default"
          sx={{
            textTransform: 'none',
            borderRadius: '8px',
            boxShadow: '0px 0px 16px #A3A3A329',
            width: '100%',
          }}
        >
          <Tabs
            // orientation="horizontal"
            value={valueDuration}
            variant="scrollable"
            // scrollButtons="auto"
            onChange={handleChangeDuration}
            indicatorColor="primary"
            textColor="primary"
            aria-label="scrollable force tabs"
            sx={{
              '& .Mui-selected': { background: '#E9EEF9' },
              background: '#FFF',
              '& .MuiTabs-flexContainer': { display: 'block' },
            }}
          >
            {startStopInitValues.duration.map((item, index) => {
              return (
                <Tab
                  sx={{
                    textTransform: 'none',
                  }}
                  key={item.value}
                  onClick={() => handleDurationClick(item.value, item.unit)}
                  label={<Typography sx={{ fontSize: '20px', color: '#050505' }}>{t(item.text)}</Typography>}
                  value={index}
                  {...a11yProps(index)}
                />
              );
            })}
          </Tabs>
        </AppBar>
        <PauseResumeWillBusy>{t('pause_resume.changing_operation_paused')}</PauseResumeWillBusy>
        <PauseResumeNormalSubTitle>{t(getPauseDurationLabel)}</PauseResumeNormalSubTitle>
        <PauseResumeNormalSubTitle>{t('pause_resume.the_store_email_status')}</PauseResumeNormalSubTitle>
      </TabPanel>
    </Box>
  );
};

export default PauseResume;
