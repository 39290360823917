import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { render, unmountComponentAtNode } from 'react-dom';
import Button from '@mui/material/Button';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';
import { DialogActions } from '@mui/material';

export const confirm = (props) => {
  return new Promise((resolve) => {
    let el = document.createElement('div');
    const handleResolve = (result) => {
      unmountComponentAtNode(el);
      el = null;
      resolve(result);
    };

    const handleDiscard = (result) => {
      unmountComponentAtNode(el);
      el = null;
      resolve(result);
    };

    render(<ConfirmModal {...props} closeIcon={props.onClose} onDiscard={handleDiscard} onClose={handleResolve} />, el);
  });
};

const ConfirmModal = ({
  title = 'confirm_modal.title',
  onClose,
  onDiscard = () => {},
  message = 'confirm_modal.message',
  className,
  cancelText = 'confirm_modal.cancel',
  confirmText = 'confirm_modal.save_and_leave',
  discardText = 'confirm_modal.discard',
  hideCloseIcon = false,
  hideDiscardBtn = true,
  // open = true,
  closeIcon,
  URL,
  background = '',
}) => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(true);

  const handleClose = (val) => {
    if (typeof val !== 'boolean') {
      return;
    }
    return onClose(val);
  };

  const handleDiscard = (val) => {
    return onDiscard(val);
  };

  closeIcon = () => {
    setOpen(false);
  };
  const redirectUrl = (path) => window.location.replace(path);
  return (
    // <Dialog onClose={handleClose} open={open} PaperProps={{ sx: { p: 3 } }}>
    //   {!hideCloseIcon && (
    //     <IconButton sx={{ position: 'absolute', top: 0, right: 0 }} aria-label="close" onClick={() => closeIcon()}>
    //       <CloseIcon />
    //     </IconButton>
    //   )}
    //   <DialogTitle sx={{ textAlign: 'center', p: 0 }}>{title}</DialogTitle>
    //   <Typography sx={{ fontSize: '14px', fontWeight: 500, textAlign: 'center', color: '#3D4350', mt: 1 }}>
    //     {t(message)}
    //   </Typography>
    //   <Stack justifyContent="center" direction="row" sx={{ mt: 4, cursor: 'grab' }}>
    //     <Button variant="contained" onClick={() => handleClose(true)}>
    //       {t(confirmText)}
    //     </Button>
    //     <Button sx={{ ml: 2, color: 'black' }} onClick={() => handleClose(false)}>
    //       {t(cancelText)}
    //     </Button>
    //   </Stack>
    // </Dialog>

    <Dialog onClose={handleClose} open={open} sx={{ '.MuiPaper-elevation': { minWidth: '500px' } }} maxWidth="lg">
      <Typography
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          fontSize: '24px',
          fontWeight: 400,
          textAlign: 'center',
          color: '#3D4350',
          mt: 3,
          pl: 3,
          pr: 3,
        }}
      >
        {t(title)}
        {!hideCloseIcon ? (
          <IconButton
            sx={{ position: 'absolute', top: 16, right: 12 }}
            aria-label="close"
            onClick={() => handleClose(false)}
          >
            <CloseIcon />
          </IconButton>
        ) : (
          <></>
        )}
      </Typography>
      <DialogTitle sx={{ p: 0, pl: 3, pr: 3 }}>
        <Typography sx={{ fontSize: '15px', fontWeight: 400, color: '#49454F', mt: 1 }}>{t(message)}</Typography>
      </DialogTitle>
      <DialogActions
        sx={{
          display: 'flex',
          mt: 3,
          pt: 2,
          pb: 2,
          pl: 3,
          pr: 3,
          borderTop: '1px solid rgba(0, 0, 0, 0.12)',
        }}
      >
        <Button
          id="confirmCancelText"
          sx={{ borderRadius: '6px', ml: 2, color: '#626A7A', fontSize: 14, fontWeight: 500 }}
          onClick={() => (URL ? redirectUrl(URL) : handleClose(false))}
        >
          {t(cancelText)}
        </Button>
        {!hideDiscardBtn ? (
          <Button
            id="confirmDiscardText"
            onClick={() => {
              handleDiscard('discard');
            }}
            sx={{
              borderRadius: '6px',
              background: 'none',
              color: '#626A7A',
              fontSize: 14,
              border: '1px solid rgba(98, 106, 122, 0.50)',
              boxShadow: 0,
              '&:hover': {
                backgroundColor: 'rgb(238 238 238 / 50%)',
                boxShadow: 0,
              },
            }}
            variant="contained"
          >
            {t(discardText)}
          </Button>
        ) : (
          <></>
        )}
        <Button
          id="confirmTextModal"
          onClick={() => {
            handleClose(true);
          }}
          sx={{
            borderRadius: '6px',
            boxShadow: 0,
            fontSize: 14,
            border: `1px solid ${background || '#1976d2'}`,
            background: background ? background : '#1976d2',
            '&:hover': {
              background: background ? background : '#1976d2',
            },
          }}
          variant="contained"
        >
          {t(confirmText)}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

ConfirmModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};

ConfirmModal.defaultProps = {
  // message: 'Are you sure?',
  // title: 'Are you sure?',
  // confirmText: 'Ok',
  // cancelText: '',
  confirmColor: 'primary',
  // cancelColor: '',
  className: '',
  buttonsComponent: null,
  size: null,
};

export default ConfirmModal;
