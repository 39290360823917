import instance from 'services/api';
import { toast } from 'react-toastify';
import {
  SET_LOADING_TO,
  GET_EMPLOYEE_TO,
  ADD_EMPLOYEE_TO,
  LAST_CREATED_EMPLOYEE_TO,
  DELETE_EMPLOYEE_TO,
  UPDATE_EMPLOYEE_TO,
} from '../types';

export const getEmployeesOrderingTO = () => (dispatch, getState) => {
  const lastCreatedEmployeesId = getState().tableOrdering.lastCreatedEmployee;
  dispatch({ type: SET_LOADING_TO, payload: true });
  return instance
    .get(`/table-ordering/employee`)
    .then((response) => {
      const data = response?.data?.data;
      if (lastCreatedEmployeesId) {
        const index = data.findIndex((employee) => employee?._id === lastCreatedEmployeesId);
        if (index !== -1) {
          const [lastCreatedEmployees] = data.splice(index, 1);
          data.unshift(lastCreatedEmployees);
        }
      }
      dispatch({ type: GET_EMPLOYEE_TO, payload: data });
      dispatch({ type: SET_LOADING_TO, payload: false });
      return data;
    })
    .catch((err) => {
      dispatch({ type: SET_LOADING_TO, payload: false });
      return Promise.reject(err);
    });
};

export const createEmployeesTO = (body) => (dispatch, getState) => {
  const employees = getState().tableOrdering.employees;
  dispatch({ type: SET_LOADING_TO, payload: true });
  return instance
    .post(`/table-ordering/employee`, body)
    .then((response) => {
      const data = response?.data?.data;
      const lastCreatedEmployeesId = response?.data?.data?._id || null;
      const filterEmployees = employees.filter((employee) => employee?._id !== data?._id);
      filterEmployees.push(data);
      dispatch({ type: ADD_EMPLOYEE_TO, payload: filterEmployees || [] });
      dispatch({ type: LAST_CREATED_EMPLOYEE_TO, payload: lastCreatedEmployeesId });
      toast.success('Employee has been created successfully!');
      dispatch({ type: SET_LOADING_TO, payload: false });
      dispatch(getEmployeesOrderingTO());
      return data;
    })
    .catch((err) => {
      dispatch({ type: SET_LOADING_TO, payload: false });
      return Promise.reject(err);
    });
};

export const deleteEmployeesTO = (employeesId) => (dispatch, getState) => {
  const employees = getState().tableOrdering.employees;
  dispatch({ type: SET_LOADING_TO, payload: true });
  return instance
    .delete(`/table-ordering/employee/${employeesId}`)
    .then(() => {
      const filterEmployees = employees.filter((employee) => employee?._id !== employeesId);
      dispatch({ type: DELETE_EMPLOYEE_TO, payload: filterEmployees });
      toast.success('Employee has been deleted  successfully!');
      dispatch({ type: SET_LOADING_TO, payload: false });
      dispatch(getEmployeesOrderingTO());
      return filterEmployees;
    })
    .catch((err) => {
      dispatch({ type: SET_LOADING_TO, payload: false });
      return Promise.reject(err);
    });
};

export const updateEmployeesTO = (dataReq) => (dispatch, getState) => {
  const employees = getState().tableOrdering.employees;
  dispatch({ type: SET_LOADING_TO, payload: true });
  return instance
    .patch(`/table-ordering/employee/${dataReq.employeeId}`, dataReq.body)
    .then((response) => {
      const data = response?.data?.data;
      const findIndex = employees.findIndex((employee) => {
        return employee._id === dataReq.employeeId;
      });
      if (findIndex !== -1) {
        employees[findIndex] = data;
      }
      dispatch({ type: UPDATE_EMPLOYEE_TO, payload: employees || [] });
      toast.success('Employee has been update  successfully!');
      dispatch({ type: SET_LOADING_TO, payload: false });
      dispatch(getEmployeesOrderingTO());
      return data;
    })
    .catch((err) => {
      dispatch({ type: SET_LOADING_TO, payload: false });
      return Promise.reject(err);
    });
};
