import { toast } from 'react-toastify';
import instance from 'services/api';

// ***********************Loyalty rewards Start *******************************

export const getLoyaltyRewards = () => {
  return instance.get(`/business/reward/settings`);
  // .catch((err) => toast.error(err.response.message || 'Oops something went wrong !'));
};

export const updateLoyaltyRewards = (body) => {
  return instance
    .post('/business/reward/settings', body)
    .catch((err) => toast.error(err.response.message || 'Oops something went wrong !'))
    .then((res) => {
      toast.success('Successfully updated');
      return res;
    });
};

// ***********************Loyalty rewards End *******************************

// ***********************Orders rewards Start *******************************

export const getOrderRewards = () => {
  return instance.get(`/business/reward/settings/order`);
  // .catch((err) => toast.error(err.response.message || 'Oops something went wrong !'));
};

export const updateOrderRewards = (body) => {
  return instance
    .patch('/business/reward/settings/order', body)
    .catch((err) => toast.error(err.response.message || 'Oops something went wrong !'))
    .then((res) => {
      toast.success('Successfully updated');
      return res;
    });
};

// ***********************Orders rewards End *******************************

// ***********************Sign up rewards Start *******************************

export const getSignUpRewards = () => {
  return instance.get(`/business/reward/settings/sign-up`);
  // .catch((err) => toast.error(err.response.message || 'Oops something went wrong !'));
};

export const updateSignUpRewards = (body) => {
  return instance
    .post('/business/reward/settings/sign-up', body)
    .catch((err) => toast.error(err.response.message || 'Oops something went wrong !'))
    .then((res) => {
      toast.success('Successfully updated');
      return res;
    });
};

// ***********************Sign up rewards End *******************************
