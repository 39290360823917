import React from 'react';
import POS from 'pages/Providers/Pos';
import Providers from 'pages/Providers/Providers';
import { Switch, Redirect } from 'react-router-dom';
import ProtectedRoute from 'components/ProtectedRoute';
import ProviderSettings from 'pages/Providers/ProviderSettings';
import ErrorBoundaryWrapper from 'components/ErrorBoundaryWrapper';
export default function () {
  return (
    <ErrorBoundaryWrapper>
      <Switch>
        <ProtectedRoute exact path="/providers" component={Providers} />
        {/*<ProtectedRoute exact path="/providers/pos" component={POS} />*/}
        <ProtectedRoute exact path="/providers/:srv/settings" component={ProviderSettings} />
        <Redirect to="/providers" />
      </Switch>
    </ErrorBoundaryWrapper>
  );
}
