import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { disabledAdmin, enabledAdmin } from 'redux/actions/user';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import Button from '@mui/material/Button';
import { LoadingButton } from '@mui/lab';
import { useTranslation } from 'react-i18next';

const ChangeAdminModal = ({ loading }) => {
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const { currentBusiness } = useSelector(({ business }) => business);

  const [changeAdmin, setChangeAdmin] = useState(false);
  const toggleChangeAdmin = () => setChangeAdmin(!changeAdmin);

  const handleDisableAdmin = () => {
    toggleChangeAdmin();
    dispatch(disabledAdmin(currentBusiness._id));
  };

  const handleEnabledAdmin = () => {
    toggleChangeAdmin();
    dispatch(enabledAdmin(currentBusiness._id));
  };
  return (
    <>
      <LoadingButton
        variant="outlined"
        color={!currentBusiness.disabled ? 'error' : 'primary'}
        onClick={toggleChangeAdmin}
        loading={loading}
      >
        {!currentBusiness.disabled ? t('settings.disable_account') : t('settings.enable_account')}
      </LoadingButton>
      <Modal isOpen={changeAdmin} toggle={toggleChangeAdmin} className="changeAdmin-modal">
        <ModalHeader toggle={toggleChangeAdmin} className="border-0"></ModalHeader>
        <ModalBody className="border-0">
          <div className="d-flex flex-column text-center">
            <div>
              {t('settings.are_you_sure')}
              {!currentBusiness.disabled ? (
                <span className="ml-1">{t('settings.disable')}</span>
              ) : (
                <span className="ml-1">{t('settings.enable')}</span>
              )}
            </div>
            <div>
              <span className="font-weight-bold mr-1">{currentBusiness.companyName}</span>
              {t('settings.account_')}{' '}
            </div>
          </div>
        </ModalBody>
        <ModalFooter className="border-0">
          <div className=" d-flex ">
            <Button id="adminModalConect" outline color="primary" onClick={toggleChangeAdmin} className="mr-1">
              {t('orders.cancel')}
            </Button>
            <Button
              id="adminModalDisable"
              type="button"
              color="primary"
              className={!currentBusiness.disabled ? 'disable-admin' : 'enable-admin'}
              onClick={!currentBusiness.disabled ? handleDisableAdmin : handleEnabledAdmin}
            >
              {!currentBusiness.disabled ? t('settings.disable') : t('settings.enable')}
            </Button>
          </div>
        </ModalFooter>
      </Modal>
    </>
  );
};

export default ChangeAdminModal;
