import React, { useState, useEffect } from 'react';
import Preloader from 'components/Preloader';
import Button from '@mui/material/Button';
import {
  Box,
  Card,
  CardActions,
  CardContent,
  Divider,
  InputAdornment,
  MenuItem,
  Tooltip,
  Typography,
} from '@mui/material';
import { useForm, FormProvider } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { yupResolver } from '@hookform/resolvers/yup';
import CustomSwitch from 'components/Forms/CustomSwitch';
import CustomTextInput from 'components/Forms/CustomTextInput';
import { ORDERS_REWARDS_EXPIRATION_DATES, ORDERS_REWARDS_POINTS, ORDERS_REWARDS_VALUES } from './config';
import { getOrderRewards, updateOrderRewards } from 'redux/actions/rewards';
import { dataAdapterOrdersRewards, defaultValuesOrderRewards } from './helper';
import ConfirmPromptModal from 'components/ConfirmPromptModal/ConfirmPromptModal';

const OrderRewards = ({ rewardEnabled }) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);

  const rewardSchema = yup.object().shape({
    orderPointsValidityInDays: yup.string().trim().required('field_is_required_'),
    earningRewardDefinition: yup.object().shape({
      pointsEarned: yup.string().trim().required('field_is_required_'),
      spendingAmount: yup.string().trim().required('field_is_required_'),
    }),
  });

  const orderRewardsMethods = useForm({
    defaultValues: defaultValuesOrderRewards,
    mode: 'all',
    resolver: yupResolver(rewardSchema),
  });

  const { reset } = orderRewardsMethods;

  useEffect(() => {
    setLoading(true);
    getOrderRewards()
      .then((res) => {
        reset(dataAdapterOrdersRewards(res?.data?.data?.orderSettings));
      })
      .finally(() => {
        setLoading(false);
      });
  }, [reset]);

  const handleValidSubmit = (values) => {
    setLoading(true);
    updateOrderRewards(dataAdapterOrdersRewards(values)).finally(() => {
      setLoading(false);
      reset(values, { keepTouched: false, keepDirty: false });
    });
  };

  const handleInValidSubmit = (err) => {
    console.log('err: ', err);
  };

  return (
    <Box sx={{ position: 'relative' }}>
      <ConfirmPromptModal
        handleConfirm={orderRewardsMethods.handleSubmit(handleValidSubmit)}
        submitData={orderRewardsMethods.getValues()}
        hasUnsavedChanges={orderRewardsMethods.formState.isDirty}
        formState={orderRewardsMethods}
      />
      {loading && <Preloader overlay />}
      <FormProvider {...orderRewardsMethods}>
        <form onSubmit={orderRewardsMethods.handleSubmit(handleValidSubmit, handleInValidSubmit)}>
          <Card>
            <CardContent sx={{ p: 0 }}>
              <Box
                sx={{
                  p: 3,
                  '& label': {
                    margin: 0,
                  },
                }}
              >
                <CustomSwitch
                  name="enabled"
                  label={
                    <Box sx={{ alignItems: 'center', display: 'flex' }}>
                      {t('Orders rewards')}
                      <Tooltip sx={{ ml: 0.5 }} title={t('rewards.orders_rewards_program')}>
                        <HelpOutlineIcon fontSize={'small'} />
                      </Tooltip>
                    </Box>
                  }
                />
              </Box>
              <Divider />
              <Box sx={{ p: 3, minHeight: '85px' }}>
                <Typography>{t('Point earning details')}</Typography>
                <Box sx={{ mt: 2, display: 'flex', justifyContent: 'space-between', gap: 2 }}>
                  <CustomTextInput
                    sx={{ width: '100%' }}
                    select={true}
                    name="earningRewardDefinition.spendingAmount"
                    label={'Dollar spend *'}
                    InputProps={{ startAdornment: <InputAdornment position="start">$</InputAdornment> }}
                    helperText={t('The number of points')}
                  >
                    {ORDERS_REWARDS_POINTS.map((option) => (
                      <MenuItem key={option._id} value={option.value}>
                        {option.value}
                      </MenuItem>
                    ))}
                  </CustomTextInput>
                  <CustomTextInput
                    sx={{ width: '100%' }}
                    select={true}
                    name="earningRewardDefinition.pointsEarned"
                    label={'Points value *'}
                    helperText={t('The points that your clients earn for the amount you indicated.')}
                  >
                    {ORDERS_REWARDS_VALUES.map((option) => (
                      <MenuItem key={option._id} value={option.value}>
                        {option.value}
                      </MenuItem>
                    ))}
                  </CustomTextInput>
                </Box>
                <CustomTextInput
                  sx={{ width: '50%', mt: 3 }}
                  select={true}
                  name="orderPointsValidityInDays"
                  label={'Expiration details *'}
                  helperText={t('The number of days after earning for reward points to expire.')}
                >
                  {ORDERS_REWARDS_EXPIRATION_DATES.map((option) => (
                    <MenuItem key={option._id} value={option.value}>
                      {option.value}
                    </MenuItem>
                  ))}
                </CustomTextInput>
              </Box>
            </CardContent>
            <CardActions sx={{ p: 3, justifyContent: 'flex-end' }}>
              <Button size="large" variant="contained" type="submit" disabled={!orderRewardsMethods.formState.isDirty}>
                {t('settings.save')}
              </Button>
            </CardActions>
          </Card>
        </form>
      </FormProvider>
    </Box>
  );
};

export default OrderRewards;
