import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box } from '@mui/material';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import { PAUSE_RESUME_STATUSES } from 'constants/resumePause';
import { useTranslation } from 'react-i18next';
import ApplyAllBusiness from 'components/PauseResume/ApplyAllBusiness';
import Divider from '@mui/material/Divider';
import DraggableDialogWrapper from 'components/DraggableDialogWrapper/DraggableDialogWrapper';
import { PauseResumeBackButton } from './styles';
import {
  updateStopResumeOrders,
  resumeProvider,
  stopProvider,
  busyProvider,
  pinCodeCheck,
  // getProvidersStatuses,
} from 'redux/actions/providers';
import PauseResumeModal from './PauseResumeModal';
import { LoadingButton } from '@mui/lab';
import PinCode from './VerificationCodeModal';
import Preloader from 'components/Preloader';

const MuiDialog = () => {
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const pinRef = useRef();
  const { currentBusiness } = useSelector(({ business }) => business);
  const { stopResumeOrders } = useSelector(({ providers }) => providers);
  const [forAllBusinesses, setForAllBusinesses] = useState(false);
  const {
    data,
    showModal,
    loading,
    statuses: { status },
  } = stopResumeOrders;
  const [pinCodeSubmitted, setVerifyCodeSubmitted] = useState(false);
  const [currentStep, setCurrentStep] = useState(0);
  const handleClose = () => {
    dispatch(
      updateStopResumeOrders({
        showModal: false,
      })
    );
    setCurrentStep(0);
    dispatch({ type: 'PROVIDERS_START_RESUME_RESET' });
    setForAllBusinesses(false);
  };

  useEffect(() => {
    if (showModal) {
      setCurrentStep(0);
      setForAllBusinesses(false);
    }
  }, [showModal]);

  const handleStartStop = () => {
    if (data.action === PAUSE_RESUME_STATUSES.PAUSED) {
      return dispatch(stopProvider(forAllBusinesses));
    } else if (data.action === PAUSE_RESUME_STATUSES.BUSY) {
      return dispatch(busyProvider(forAllBusinesses));
    }
    return dispatch(resumeProvider(forAllBusinesses));
  };
  const handleConfirm = () => {
    setCurrentStep((prevState) => prevState + 1);
  };
  const handleCloseStep = () => {
    setCurrentStep((prevState) => prevState - 1);
  };
  const handleSubmit = () => handleStartStop();

  const onCompleteCode = (value) => {
    dispatch(pinCodeCheck(value))
      .then(() => {
        setVerifyCodeSubmitted(true);
      })
      .catch(() => {
        pinRef?.current?.clear();
      });
  };

  useEffect(() => {
    setVerifyCodeSubmitted(!currentBusiness?.settings?.pinCode?.enable);
  }, [currentBusiness?.settings?.pinCode?.enable, showModal]);
  const CustomDialogTitle = (props) => {
    const { children, onClose, ...other } = props;
    return (
      <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
        {children}
        {onClose ? (
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: 'absolute',
              right: 24,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon sx={{ fontSize: '38px', color: '#000000', opacity: '80%' }} />
          </IconButton>
        ) : null}
      </DialogTitle>
    );
  };
  return (
    <Dialog
      open={showModal}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullWidth={true}
      PaperComponent={DraggableDialogWrapper}
      PaperProps={{
        sx: { width: '764px', height: '670px', maxWidth: '100%', background: '#FAFAFA' },
      }}
    >
      {loading && <Preloader />}
      <CustomDialogTitle id="alert-dialog-title" onClose={handleClose} sx={{ fontSize: '24px', fontWeight: '550' }}>
        {pinCodeSubmitted ? t('orders.store_status') : t('store_pin_code.title')}
      </CustomDialogTitle>
      <Divider />
      <DialogContent sx={{ width: '100%' }}>
        {currentStep === 0 ? (
          <>{pinCodeSubmitted ? <PauseResumeModal /> : <PinCode pinRef={pinRef} onCompleteCode={onCompleteCode} />}</>
        ) : (
          <ApplyAllBusiness status={data?.action} duration={data.duration} setForAllBusinesses={setForAllBusinesses} />
        )}
      </DialogContent>
      <Divider />
      <DialogActions sx={{ justifyContent: 'space-between', p: 2, cursor: 'grab' }}>
        {pinCodeSubmitted && (
          <>
            <Button
              id="pauseResumeCancel"
              sx={{ width: '192px', height: '54px', borderRadius: '6px' }}
              variant="contained"
              color="error"
              onClick={handleClose}
            >
              {t('orders.cancel')}
            </Button>
            <Box>
              {currentStep === 1 && (
                <PauseResumeBackButton variant="outlined" onClick={handleCloseStep}>
                  {t('sign_up.back')}
                </PauseResumeBackButton>
              )}
              <LoadingButton
                sx={{ width: currentStep === 0 ? '300px' : '192px', height: '54px', borderRadius: '6px' }}
                variant="contained"
                loading={loading}
                disabled={data.action === PAUSE_RESUME_STATUSES.BUSY && status === PAUSE_RESUME_STATUSES.PAUSED}
                onClick={currentStep === 0 ? handleConfirm : handleSubmit}
              >
                {currentStep === 0 ? t('settings.continue') : t('settings.save')}
              </LoadingButton>
            </Box>
          </>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default MuiDialog;
