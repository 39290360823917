import React, { useEffect, useState } from 'react';
import Grid from '@mui/material/Grid';
import ActionButtons from './actions';
import { Typography } from '@mui/material';
import { useHistory } from 'react-router-dom';
import IconButton from '@mui/material/IconButton';
// import { companyTypes } from 'constants/companyTypes';
import { yupResolver } from '@hookform/resolvers/yup';
import { SIGNUP_STEPS, SignUpPathEnum } from 'constants/signUpPath';
import { useForm, FormProvider, useWatch } from 'react-hook-form';
import InputAdornment from '@mui/material/InputAdornment';
import CustomTextInput from 'components/Forms/CustomTextInput';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { accountInfoFormValidationSchema } from './signupSchemas';
import { signupUserV2, updateUserV2 } from 'redux/actions/userAuthV2';
import CustomMobilePhone from 'components/MobilePhone/CustomMobilePhone';
import { useTranslation } from 'react-i18next';
import LabelRequired from 'components/FormFields/LabelRequired';
import { patchSignUpProgress } from 'redux/actions/user';
import useTabClose from 'hooks/useTabClose';

const data = localStorage.getItem('accountInfo');
const parsedData = data && JSON.parse(data);

const initialState = {
  firstName: '',
  email: '',
  lastName: '',
  phone: '',
  password: '',
  rePassword: '',
  // companyType: '',
  metadata: { communicationEmail: '' },
};

const AcountTabInfo = ({ active, steps, setCompanyType }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const progressUrl = `${window.location.origin}`;

  const accountInfoFormMethods = useForm({
    defaultValues: parsedData ? parsedData : initialState,
    mode: 'all',
    resolver: yupResolver(accountInfoFormValidationSchema),
  });

  const { watch, control, getValues, clearErrors, setError } = accountInfoFormMethods;
  const passwordField = useWatch({ control, name: 'password' });
  const rePasswordField = useWatch({ control, name: 'rePassword' });
  useTabClose();
  useEffect(() => {
    const currentModel = getValues();
    if (currentModel?.password === currentModel.rePassword) {
      clearErrors('rePassword');
    } else if (currentModel?.password !== currentModel.rePassword && currentModel.rePassword !== '') {
      setError('rePassword', { type: 'custom', message: 'Passwords must match' });
    }
  }, [passwordField, rePasswordField]);

  useEffect(() => {
    setCompanyType('');
  }, []);

  // watch((data, { name }) => {
  //   if (name === 'companyType') {
  //     setCompanyType(data.companyType);
  //     localStorage.setItem('companyType', data.companyType);
  //   }
  // });
  const visiblePassword = () => setShowPassword((prevState) => !prevState);
  const visibleConfirmPassword = () => setShowConfirmPassword((prevState) => !prevState);
  useEffect(() => {
    const data = localStorage.getItem('accountInfo');
    const parsedData = data && JSON.parse(data);
    if (parsedData) {
      accountInfoFormMethods.reset({
        ...parsedData,
        metadata: { communicationEmail: parsedData?.communicationEmail },
      });
    } else {
      accountInfoFormMethods.reset(initialState);
      // setCompanyType('');
    }
  }, [accountInfoFormMethods]);

  const navigationAndSetQueries = () => {
    const accountInfo = JSON.parse(localStorage.getItem('accountInfo'));
    history.push(
      `${SignUpPathEnum.LEGAL_INFO}?userId=${accountInfo?.userId}&communicationEmail=${accountInfo?.communicationEmail}&email=${accountInfo?.email}`
    );
  };

  const handleSubmit = (values) => {
    setLoading(true);
    const existingData = localStorage.getItem('accountInfo');
    localStorage.setItem('accountInfo', JSON.stringify({ ...values }));
    const communicationEmail = values?.metadata?.communicationEmail;
    delete values.metadata.communicationEmail;
    if (existingData) {
      const parsedData = JSON.parse(existingData);

      if (parsedData && parsedData.userId) {
        return updateUserV2(values, parsedData.userId)
          .then((response) => {
            values.communicationEmail = communicationEmail;
            localStorage.setItem(
              'accountInfo',
              JSON.stringify({
                ...values,
                ...response?.data.data,
                metadata: { communicationEmail: communicationEmail },
              })
            );
            // history.push(SignUpPathEnum.LEGAL_INFO);
            navigationAndSetQueries();
            patchSignUpProgress({
              step: SIGNUP_STEPS.user_account,
              url: `${progressUrl}${SignUpPathEnum.LEGAL_INFO}?userId=${response?.data.data?.userId}&communicationEmail=${communicationEmail}&email=${values?.email}`,
              userId: response?.data?.data?.userId || '',
            });
          })
          .catch(() => {
            setLoading(false);
            accountInfoFormMethods.reset({
              ...parsedData,
              metadata: { communicationEmail: values?.communicationEmail },
            });
          })
          .finally(() => setLoading(false));
      }
    }
    return signupUserV2(values)
      .then((response) => {
        values.communicationEmail = communicationEmail;
        localStorage.setItem(
          'accountInfo',
          JSON.stringify({ ...values, ...response?.data?.data, communicationEmail: communicationEmail })
        );
        // history.push(SignUpPathEnum.LEGAL_INFO);
        patchSignUpProgress({
          step: SIGNUP_STEPS.user_account,
          url: `${progressUrl}${SignUpPathEnum.LEGAL_INFO}?userId=${response?.data.data?.userId}&communicationEmail=${communicationEmail}&email=${values?.email}`,
          userId: response?.data?.data?.userId || '',
        });
        navigationAndSetQueries();
      })
      .catch(() => {
        accountInfoFormMethods.reset({
          ...values,
          metadata: { communicationEmail: communicationEmail },
        });
        return setLoading(false);
      })
      .finally(() => setLoading(false));
  };
  return (
    <FormProvider {...accountInfoFormMethods}>
      <form onSubmit={accountInfoFormMethods.handleSubmit(handleSubmit)}>
        <Grid container spacing={2}>
          <Grid item md={12} xs={12}>
            <Typography sx={{ fontSize: '16px', mt: 2, fontWeight: 700, mb: 1 }}>
              {t('account_information.account_information')}
            </Typography>
          </Grid>
          <Grid item md={6} xs={12} sx={{ height: '72px' }}>
            <CustomTextInput
              sx={{ width: '100%' }}
              name="firstName"
              label={<LabelRequired>{t('account_information.first_name')}</LabelRequired>}
            />
          </Grid>
          <Grid item md={6} xs={12} sx={{ height: '72px' }}>
            <CustomTextInput
              sx={{ width: '100%' }}
              name="lastName"
              label={<LabelRequired>{t('account_information.last_name')}</LabelRequired>}
            />
          </Grid>

          <Grid item md={6} xs={12} sx={{ height: '72px' }}>
            <CustomTextInput
              sx={{ width: '100%' }}
              name="email"
              label={<LabelRequired>{t('batch_report.email')}</LabelRequired>}
            />
          </Grid>

          <Grid item md={6} xs={12} sm={12} sx={{ height: '72px' }}>
            <CustomMobilePhone
              sx={{ width: '100%' }}
              name="phone"
              label={<LabelRequired>{t('settings.phone_number')}</LabelRequired>}
            />
          </Grid>
          {/* <Grid item md={6} xs={12}>
            <CustomTextInput
              sx={{ width: '100%' }}
              select={true}
              name="companyType"
              size="small"
              label={t('sign_up.company_type')}
            >
              {companyTypes.map(({ value, label }) => (
                <MenuItem key={value} value={value}>
                  {t(label)}
                </MenuItem>
              ))}
            </CustomTextInput>
          </Grid> */}
          <Grid item md={6} xs={12} sm={12} sx={{ height: '72px' }}>
            <CustomTextInput
              sx={{ width: '100%' }}
              name="password"
              label={<LabelRequired>{t('sign_in.password')}</LabelRequired>}
              InputProps={{
                type: showPassword ? 'text' : 'password',
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton aria-label="toggle password visibility" onClick={visiblePassword} edge="end">
                      {showPassword ? (
                        <VisibilityOff sx={{ fontSize: '1.2rem' }} />
                      ) : (
                        <Visibility sx={{ fontSize: '1.2rem' }} />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item md={6} xs={12} sm={12} sx={{ height: '72px' }}>
            <CustomTextInput
              sx={{ width: '100%' }}
              name="rePassword"
              label={<LabelRequired>{t('settings.confirm_password')}</LabelRequired>}
              InputProps={{
                type: showConfirmPassword ? 'text' : 'password',
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton aria-label="toggle password visibility" onClick={visibleConfirmPassword} edge="end">
                      {showConfirmPassword ? (
                        <VisibilityOff sx={{ fontSize: '1.2rem' }} />
                      ) : (
                        <Visibility sx={{ fontSize: '1.2rem' }} />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item md={12} xs={6}>
            <Typography sx={{ fontWeight: 700, mt: 2 }}>{t('settings.communication_information_reports')}</Typography>
          </Grid>
          <Grid item md={6} xs={6} sx={{ height: '72px' }}>
            <CustomTextInput
              sx={{ width: '100%' }}
              name="metadata.communicationEmail"
              label={<LabelRequired>{t('sign_in.communication_email')}</LabelRequired>}
            />
          </Grid>

          <Grid item xs={12}>
            <ActionButtons
              id="acountTabInfoSteps"
              hideProgressButton={true}
              steps={steps}
              active={active}
              loading={loading}
              disabled={!accountInfoFormMethods.formState.isValid}
            />
          </Grid>
        </Grid>
      </form>
    </FormProvider>
  );
};

export default AcountTabInfo;
