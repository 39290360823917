import React from 'react';
import { useTranslation } from 'react-i18next';
import { FormProvider } from 'react-hook-form';
import CloseIcon from '@mui/icons-material/Close';
import DialogActions from '@mui/material/DialogActions';
import { ButtonWrapper, CancelButton, SaveButton } from '../styles';
import { Dialog, DialogContent, DialogTitle, IconButton, Box } from '@mui/material';
import DraggableDialogWrapper from 'components/DraggableDialogWrapper/DraggableDialogWrapper';

const CustomDialogTitle = (props) => {
  const { children, onClose, ...other } = props;
  return (
    <DialogTitle sx={{ ml: 1, p: 2, fontSize: '24px', fontWeight: 400 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 16,
            top: 16,
            color: '#929398',
          }}
        >
          <CloseIcon sx={{ color: '#929398' }} />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

const TableOrderModal = ({
  children,
  open,
  handleClose,
  title,
  cancelTitle,
  saveTitle,
  disabled,
  formMethods,
  handleSubmit,
  loading,
}) => {
  const { t } = useTranslation();
  return (
    <Dialog
      open={open}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullWidth={true}
      disableEscapeKeyDown={true}
      onClose={handleClose}
      PaperComponent={DraggableDialogWrapper}
      PaperProps={{ sx: { width: '654px', maxWidth: '100%', background: '#FAFAFA', cursor: 'grab' } }}
    >
      <FormProvider {...formMethods}>
        <form
          onSubmit={formMethods.handleSubmit(handleSubmit)}
          style={{
            width: '100%',
          }}
        >
          <Box>
            <CustomDialogTitle id="customized-dialog-title" onClose={handleClose}>
              {t(title)}
            </CustomDialogTitle>
            <DialogContent sx={{ width: '100%', padding: 0, cursor: 'auto' }}>{children}</DialogContent>
          </Box>
          <DialogActions sx={{ borderTop: '1px solid #E7E7E7' }}>
            <ButtonWrapper>
              <CancelButton variant="text" onClick={handleClose}>
                {t(cancelTitle)}
              </CancelButton>
              <SaveButton variant="contained" type="submit" loading={loading} disabled={loading || disabled}>
                {t(saveTitle)}
              </SaveButton>
            </ButtonWrapper>
          </DialogActions>
        </form>
      </FormProvider>
    </Dialog>
  );
};

export default TableOrderModal;
