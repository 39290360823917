import React, { useEffect, useState, useMemo, memo, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import { Typography } from '@mui/material';
import Hidden from '@mui/material/Hidden';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import UpdateIcon from '@mui/icons-material/Update';
import LinkOffIcon from '@mui/icons-material/LinkOff';
import { SettingsOutlined } from '@mui/icons-material';
import { styled } from '@mui/material/styles';
import { useIsWidthDown, useIsWidthUp } from 'hooks/mediaQuery';
import { doordashMapProvider, grubHubMapProvider, updateBulk } from 'redux/actions/Menu/menuV2';
import { getTitle } from './helper';
import { checkLinkedProviderWebsite, linkedWithProvider } from 'helpers/linkedWithProvider';
import { SET_LINK_PROVIDER_VISIBILITY, SET_LINK_PROVIDER, SET_LOADING } from 'redux/actions/types';
import { PROVIDER_CONNECTED } from 'constants/providerStatuses';
import Private from 'components/Private';
import CircularProgress from '@mui/material/CircularProgress';
import { isPermissionWithCompanyType } from 'hooks/useCompanyType';
import { useTranslation } from 'react-i18next';
import useProviderConfig from 'hooks/useProviderConfig';
import ConfirmProviderMenu from 'components/Confirm/ConfirmProviderMenu';
import { isWebsiteConnected } from 'utils/checkAccessDenied';

// const tabRoutes = [
//   { label: 'overview_', path: 'overview' },
//   { label: 'categories_', path: 'categories' },
//   { label: 'items_', path: 'items' },
//   { label: 'popular_items', path: 'popularItems' },
//   { label: 'modifier_groups', path: 'modifierGroups' },
// ];

const CustomizedSettings = styled(SettingsOutlined)`
  :hover {
    color: #fff;
  }
`;
const CustomizedButton = styled(Button)`
  :hover svg {
    color: #fff;
  }
`;

const MasterMenuHeader = ({ handleDrawerOpen, open }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { pathname } = history.location;
  const [isBulked, setIsBulked] = useState(false);
  const { providers } = useProviderConfig();
  const [showModal, setShowModal] = useState(false);
  const { menu, loading, formProduct, isDoordashProviderLoading, isGrubHubProviderLoading } = useSelector(
    ({ menu }) => menu
  );
  const { items, clicked } = useSelector(({ providers }) => providers);
  const { currentBusiness } = useSelector(({ business }) => business);
  const catProducts = [];
  const categoriesBulk = [];
  const modifiersGroup = [];
  const menuBulk = useMemo(() => menu?.bulkInfo?.isSaved, [menu, menu?.bulkInfo?.isSaved]);
  const userData = useSelector(({ user }) => user);
  const { isTypeSingle } = isPermissionWithCompanyType();
  const [showButton, setShowButton] = useState(false);
  const { t } = useTranslation();
  useEffect(() => {
    if (menu?.categories?.length) {
      menu.categories.forEach((item) => {
        categoriesBulk.push(item);
        if (item?.products?.length) {
          item.products.forEach((product) => {
            catProducts.push(product);
          });
        }
      });
    }
    if (menu?.modifiersAll?.length) {
      menu.modifiersAll.forEach((item) => {
        modifiersGroup.push(item);
      });
    }

    const checkSaveProducts = catProducts.every((item) => item.bulkInfo?.isSaved);
    const checkSaveModifiers = modifiersGroup.every((item) => item.bulkInfo?.isSaved);
    const checkSaveCategories = categoriesBulk.every((item) => item.bulkInfo?.isSaved);
    setIsBulked(!checkSaveProducts || !checkSaveCategories || !checkSaveModifiers || !menuBulk);
  }, [menu?.categories, isBulked, catProducts, categoriesBulk, modifiersGroup]);

  useEffect(() => {
    if (menu?.connectedProviders && menu?.connectedProviders?.doordash) {
      setShowButton(false);
    } else if (menu?.connectedProviders && !menu?.connectedProviders?.doordash) {
      const doordashStatus = items?.find((item) => item.srv === providers.doordash.srv);
      if (!clicked && doordashStatus?.state && doordashStatus?.status === 2) {
        setShowButton(true);
      }
    }
  }, [menu?.connectedProviders, items, showButton, clicked]);

  useEffect(() => {
    if (!formProduct.bulkInfo?.isSaved) {
      setIsBulked(!formProduct.bulkInfo?.isSaved);
    }
  }, [formProduct.bulkInfo?.isSaved]);


  const linked = useMemo(() => {
    return menu.connectedProviders && linkedWithProvider(menu.connectedProviders, providers);
  }, [menu]);

  const targetConnectedProviders = useMemo(() => {
    const isWebsite = isWebsiteConnected(items);
    const res = { website: isWebsite };
    items.forEach((providerItem) => {
      res[providerItem.srv] = providerItem.status == PROVIDER_CONNECTED;
    });
    return res;
  }, [items]);

  const getConnectedDefaultProvider = () => {
    dispatch({ type: SET_LINK_PROVIDER_VISIBILITY, payload: true });
    dispatch({
      type: SET_LINK_PROVIDER,
      payload: {
        connectedProviders: menu.connectedProviders,
        targetConnectedProviders: targetConnectedProviders,
        target: 'menu',
      },
    });
  };

  const handleMenuLinkProvider = () => {
    getConnectedDefaultProvider();
  };

  const handleUpdateProvider = () => setShowModal(true);

  const handleBulk = () => {
    dispatch({ type: SET_LOADING, payload: true });
    if (linked) {
      dispatch(updateBulk());
      setShowModal(false);
      // .then(() => {
      //   dispatch({ type: SET_LOADING, payload: true });
      //   if (catProducts) {
      //     dispatch(getMenusV2()).then((res) => {
      //       res.categories.forEach((item) => {
      //         dispatch(getCategoryProductsV2(item._id)).finally(() => {
      //           dispatch({ type: SET_LOADING, payload: false });
      //         });
      //       });
      //     });
      //   }
      //   if (modifiersGroup) {
      //     modifiersGroup.forEach((item) => {
      //       if (!item.bulkInfo?.isSaved) {
      //         dispatch(getModifiersAll()).finally(() => {
      //           dispatch({ type: SET_LOADING, payload: false });
      //         });
      //       }
      //     });
      //   }
      //   if (categoriesBulk) {
      //     categoriesBulk.forEach((item) => {
      //       if (!item.bulkInfo?.isSaved) {
      //         dispatch(getMenusV2()).finally(() => {
      //           dispatch({ type: SET_LOADING, payload: false });
      //         });
      //       }
      //     });
      //   }
      // })
      // .finally(() => {
      //   setTimeout(() => {
      //     dispatch({ type: SET_LOADING, payload: false });
      //   }, 2000);
      // });
    } else {
      getConnectedDefaultProvider();
      dispatch({ type: SET_LOADING, payload: false });
      setShowModal(false);
    }
  };

  const handleConfirm = () => handleBulk();
  const handleClose = () => setShowModal(false);

  const checkWebsite = useMemo(() => {
    return menu.connectedProviders && checkLinkedProviderWebsite(menu.connectedProviders, providers);
  }, [menu]);

  const checkCurrentBusiness = useMemo(() => {
    return currentBusiness?.roles?.name === 'super';
  }, [currentBusiness]);

  const handleProviderMap = (provider) => {
    if (provider === providers.doordash.srv) {
      let mapBodyDoorDash = {
        provider: provider,
        action: 'map',
        isLinked: true,
      };
      setShowButton(false);
      dispatch(doordashMapProvider(mapBodyDoorDash));
      setTimeout(() => {
        dispatch({ type: 'PROVIDERS_DOORDASH_CLICK', payload: true });
      }, 500);
    } else if (provider === providers.grubhub.srv) {
      let mapBodyGrubHub = {
        provider: provider,
        action: 'map',
        isLinked: true,
      };
      dispatch(grubHubMapProvider(mapBodyGrubHub));
    }
  };

  return (
    <Box>
      {showModal && (
        <ConfirmProviderMenu handleClose={handleClose} showModal={showModal} handleConfirm={handleConfirm} />
      )}
      <Box
        sx={{
          pt: 3,
          // width: '100%',
          flexWrap: 'wrap',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Box display="flex" alignItems="center">
          <IconButton
            id="masterMenuHeaderMenu"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{ mr: 2, ...(open && { display: 'none' }) }}
          >
            <MenuIcon />
          </IconButton>
          <Typography sx={{ color: 'black', fontSize: '20px' }}>{getTitle(pathname)}</Typography>
          {isTypeSingle ? (
            <Private permission="MENU_EDIT" disabled={true}>
              <Button
                id="masterMenu"
                disabled={(!isBulked && linked) || loading || (linked && !checkWebsite)}
                onClick={linked ? handleUpdateProvider : handleBulk}
                sx={{ ml: 2 }}
                variant="contained"
                startIcon={linked ? <UpdateIcon /> : <LinkOffIcon />}
              >
                {loading
                  ? t('menu.updating')
                  : linked
                  ? t('menu.update_provider_menus')
                  : t('menu.connect_provider_menus')}
              </Button>
              {linked ? (
                <CustomizedButton
                  id="masterMenuHeaderProvider"
                  onClick={handleMenuLinkProvider}
                  sx={{
                    ml: 2,
                    background: !isBulked ? 'rgba(0, 0, 0, 0.12)' : '#E5E5E5',
                    minWidth: '40px',
                    width: '1em',
                    height: '2.62em',
                  }}
                  variant="contained"
                >
                  <CustomizedSettings sx={{ color: '#323232', fontSize: '20px' }} />
                </CustomizedButton>
              ) : (
                <></>
              )}
            </Private>
          ) : (
            <></>
          )}
          {checkCurrentBusiness && (
            <>
              {showButton && (
                <Button
                  onClick={() => handleProviderMap(providers?.doordash?.srv)}
                  id="providerMap"
                  sx={{ ml: 2 }}
                  variant="contained"
                  // disabled={isDoordashProviderLoading || !menu?.connectedProviders?.doordash}
                  startIcon={isDoordashProviderLoading && <CircularProgress size={14} />}
                >
                  {`${providers.doordash.label} map`}
                </Button>
              )}
            </>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default MasterMenuHeader;
